import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableHeaderCellData, TableRowData, DataTableType, TableClickEvent, TableCellData } from 'src/app/modals/datatable/datatable';

@Component({
  selector: 'datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent {
  // @Input()
  // cssClass:string="";

  // @Input()
  // headers:TableHeaderCellData[]=[];

  // @Input()
  // rows:TableRowData<any>[]=[];

  // @Input()
  // rowIndex!:number;

  // @Input()
  // type:DataTableType = DataTableType.TRANSPARENT;

  // @Output()
  // onHeaderClicked:EventEmitter<TableClickEvent<any>>=new EventEmitter<TableClickEvent<any>>();

  // @Output()
  // onCellClicked:EventEmitter<TableClickEvent<any>>=new EventEmitter<TableClickEvent<any>>();


  // onHeaderClick(_event:TableClickEvent<TableHeaderCellData>){
  //   this.onHeaderClicked.emit(_event);
  // }

  // onCellClick(_event:TableClickEvent<TableCellData<any>>){
  //   this.onCellClicked.emit(_event);
  // }

  // onRowClick(ev: KeyboardEvent,row:TableRowData<any>){
  //    if (ev.key === "Enter"){
  //     this.onCellClicked.emit({cell: row.cells[0],event:ev,column:0, row:this.rowIndex});
  //    }
  // }

  // getTableClass():string{
  //   let cssCls:string =this.cssClass;
  //   switch(this.type){
  //     case 1:
  //       cssCls += ' highlight_rows';
  //       break;
  //     case 2:
  //       cssCls += ' table-striped';
  //       break;
  //     case 3:
  //       cssCls += ' transparent collapsable';
  //       break;
  //     default:
  //       cssCls += ' transparent';
  //   }
  //   return cssCls;
  // }
  // constructor() { 
    
  // }

  // ngOnInit(): void {
  // }

  @Input()
  cssClass:string="";
 
  @Input()
  headers:TableHeaderCellData[]=[];
 
  @Input()
  rows:TableRowData<any>[]=[];
 
  @Input()
  rowIndex!:number;
 
  @Input()
  type:DataTableType = DataTableType.TRANSPARENT;
 
  @Output()
  onHeaderClicked:EventEmitter<TableClickEvent<any>>=new EventEmitter<TableClickEvent<any>>();
 
  @Output()
  onCellClicked:EventEmitter<TableClickEvent<any>>=new EventEmitter<TableClickEvent<any>>();
 
 
  onHeaderClick(_event:TableClickEvent<TableHeaderCellData>){
    this.onHeaderClicked.emit(_event);
  }
 
  onCellClick(_event:TableClickEvent<TableCellData<any>>){
    this.onCellClicked.emit(_event);
  }
 
  onRowClick(ev: KeyboardEvent,row:TableRowData<any>){
     if (ev.key === "Enter"){
      this.onCellClicked.emit({cell: row.cells[0],event:ev,column:0, row:this.rowIndex});
     }
  }
 
  getTableClass():string{
    let cssCls:string =this.cssClass;
    switch(this.type){
      case 1:
        cssCls += ' highlight_rows';
        break;
      case 2:
        cssCls += ' table-striped';
        break;
      case 3:
        cssCls += ' transparent collapsable';
        break;
      default:
        cssCls += ' transparent';
    }
    return cssCls;
  }
  constructor() {
   
  }
 
  ngOnInit(): void {
  }
}
