import { Drugsearchwebrequest } from "../drugSearch/drugsearchwebrequest"

export class viewAllNotificationsRequest {
    loginRoleId!: string
    maxCount!: string
    toDate!: string
    fromDate!: string
    adminPresbrId!: string|undefined
    presbrId!: string
    viewAllPresbrs!: string
  }
  export class memberNotificationsRequest{
presbrId ! : string|undefined
paCaseId! : string|undefined
memberId!:string|undefined
npi! : string|undefined
paStatus! : string|undefined
uuid ! : string|undefined
drugSearchRequest!: Drugsearchwebrequest
notificationRequired:"Y" | undefined;
drugSearchRequired:"Y"|undefined;
  }