import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

import { environment} from 'src/environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  context_path:string=environment.sso_contextpath;

  constructor(protected router: Router) {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);

  }

  ngOnInit(): void {
    //this.router.navigateByUrl("/home");
  }
  goToPrivacyLink(url: string) {
    // window.open(url, "_blank","noopener noreferrer");
    this.router.navigateByUrl(url);
  }
  goToTermsLink(url: string) {
    // debugger;
    // window.open(url, "_blank","noopener noreferrer");
    this.router.navigateByUrl(url);
  }
  goToContactusLink(url: string) {
     debugger;
    this.router.navigateByUrl(url);

    // window.open(url, "_blank");
    // let url2 = this.router.serializeUrl(
    //   this.router.createUrlTree([url])
    // );
    
    // window.open(url2, "_blank");
  }
}
