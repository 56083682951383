import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, interval } from 'rxjs';
import { DUR_PROFESSIONAL_SERVICECODES, DUR_RESULTOF_SERVICECODES, EPA_STATUS, PRESCRIBERDETAIL_STATUS } from 'src/app/constants/appconstants';
import { ClaimSummaryDetails } from 'src/app/modals/claimsummary/claimsummary';
import { EpaRunnerInitiationEvent } from 'src/app/modals/epa/priorauthrequest';
import { MemberDetailWebRequesthemi, MemberDetailwebrequest } from 'src/app/modals/membersearch/memberdetailwebrequest';
//import { MemberDetailWebResponse } from 'src/app/modals/membersearch/memberdetailwebresponse';
import { CalculateQuantityService } from 'src/app/services/Quantity/calculate-quantity.service';
import { CheckCoveragePageService } from 'src/app/services/drugsearch/check-coverage-page.service';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ClaimsummaryComponent } from '../claimsummary/claimsummary.component';
import { PARefIDWebResponse } from 'src/app/modals/epa/priorauthresponse';
import { SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { fade, listAnimation, slideDown, explainerAnim } from 'src/animations/animations';
import { ScenarioClaim } from 'src/app/modals/drugSearch/Trailadjudicationwebresponse';
import { Router } from '@angular/router';
import { MemberDetailService } from 'src/app/services/memberdetail/memberdetail.service';
import { MemberDetailWebResponsehemi } from 'src/app/modals/membersearch/memberdetailresponsehemi';

@Component({
  selector: 'app-payble-claim-summary',
  templateUrl: './payble-claim-summary.component.html',
  styleUrls: ['./payble-claim-summary.component.css'],
  animations: [
    fade,
    listAnimation,
    slideDown,
    explainerAnim

  ]
})
export class PaybleClaimSummaryComponent implements OnInit{

  
  @ContentChild(ClaimsummaryComponent)
  childRef!: ClaimsummaryComponent;
  constructor( public quantityService: CalculateQuantityService,
    private trailclaimadjudicationHelper: CheckCoveragePageService,
    private memberdetailService:MemberDetailService,
    private modalService: NgbModal,
    private epaProcessor: EpaprocessorService,
    private profileService: ProfileService,
    private logger: LoggerService,
    private memberSearchProcessor:MembersearchService,
    private prescriberSearch:PrescriberSearchService, 
    private router: Router) { 
    
    
  }
  isProviderPage!:any;
  @Input() claimsummaryDetailsrequest!: ClaimSummaryDetails;
  @Input() claimresponse!: SelectedMedicine;
  scenarioClaimsList: ScenarioClaim[] = [];

  @Output() updateevent = new EventEmitter<any>();
  @ViewChild("claimSummaryProgress")
  claimSummaryProgress?: ElementRef<HTMLElement>;
  @ViewChild("editProviderTemplate")
  editProviderTemplateRef!:ElementRef<HTMLElement>;
  editProviderStatus!:PRESCRIBERDETAIL_STATUS;

  @ViewChild("errorModalTemplate")
  errorModalTemplateRef!:ElementRef<HTMLElement>;
  errorMessage:string="";

  ePARunnerData!:EpaRunnerInitiationEvent;

  durProfessionalCodes:string[] = Array.from(DUR_PROFESSIONAL_SERVICECODES.keys());
  durResultCodes:string[] = Array.from(DUR_RESULTOF_SERVICECODES.keys());

  selectedMedicine!: SelectedMedicine;
  targetrejectcode:any;
  targetTransitionRejectCode:any;
  counter = 1;
  trailAdjuducicationError = "";
  memberResponse: any;
  subscription: Subscription = new Subscription();
  blnChgQtyDOS = 0;
  printAlternative: boolean=false;
  changeQTYDOSForm = new FormGroup({
    Quantity: new FormControl('', { validators: [Validators.required, Validators.pattern('[0-9]+$'), Validators.maxLength(11), Validators.min(1)] }),
    Days: new FormControl('', { validators: [Validators.required, Validators.pattern('[0-9]+$'), Validators.maxLength(3), Validators.min(1)] }),
  });

  get Quantity() {
    return this.changeQTYDOSForm.get('Quantity')
  }

  get Days() {
    return this.changeQTYDOSForm.get('Days')
  }

  @ViewChild("overwriteConfirmModal")
  overwriteConfirmModal?: ElementRef<HTMLElement>;

  @ViewChild("selectAlternateDrug")
  selectAlternateDrugModal?: ElementRef<HTMLElement>;


  ngOnInit(): void {
    
    //console.log(this.claimsummaryDetailsrequest);
    //console.log("Testing Data2:" + this.claimresponse);
    debugger;
    this.isProviderPage=sessionStorage.getItem('editProvider')!=undefined?sessionStorage.getItem('editProvider'):false;
    console.log(this.isProviderPage);
    this.selectedAlternateDrug=this.claimresponse.trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims[0];
    //console.log("Testing Data1:");
    //console.log(this.claimsummaryDetailsrequest);
    //console.log("Testing Data2:" + this.claimresponse);
    debugger;

    const memrequest: MemberDetailWebRequesthemi = new MemberDetailWebRequesthemi();
          memrequest.accountId = this.claimresponse.selectedMember.accountId;
          memrequest.id = this.claimresponse.selectedMember.memberId;
          memrequest.sourceSystemInstance = this.claimresponse.selectedMember.instanceId;
          memrequest.groupId = this.claimresponse.selectedMember.groupId;
          memrequest.carrierId = this.claimresponse.selectedMember.carrierId;
         this.memberdetailService.getMemberDetailshemi(memrequest).then((memresponse:MemberDetailWebResponsehemi)=>{
          this.claimresponse.memberDetailResponse = memresponse;
           //console.log("memresponse",this.claimresponse.memberDetailResponse);
         });
    //  sessionStorage.getItem('selectedMedicine');
    //sessionStorage.setItem("selectedMedicineList", JSON.stringify(this.selectedMedicine));
    //console.log(this.selectedMedicine);
    // <!-- For QA automation purpose -->
    // if(this.claimresponse.trailadjudicationwebresponse.response.rejectCode.length==0){
    //   this.targetrejectcode='Paid';
    // }else{
    //   this.targetrejectcode=this.claimresponse.trailadjudicationwebresponse.response.rejectCode;
    // }

    // if(this.claimresponse.trailadjudicationwebresponse.response.transitionBenefits!=undefined){
    //   this.claimresponse.trailadjudicationwebresponse.response.transitionBenefits.forEach((item:TransitionBenefit)=>{
    //     this.targetTransitionRejectCode.push(item.rejectedReason);
    //   });
    // }else{
    //   this.targetTransitionRejectCode=[];
    // }
    // <!-- End QA automation purpose -->


  }
  blnShowFullMedicationDetails:boolean=false;

  saveToDB = false;
  upadateOrReexecute(saveToDB: boolean = false) {
    if (this.Quantity?.valid && this.Days?.valid) {
      this.saveToDB = saveToDB;
      this.modalService.open(this.overwriteConfirmModal, { modalDialogClass: 'overwriteConfirmModal' ,backdrop:'static'});
    }
  }

  chgQtyDOS(saveToDB: boolean = false) {
    debugger;
    this.modalService.dismissAll();
    saveToDB = this.saveToDB;
    if (this.blnChgQtyDOS == 0) {
      this.blnChgQtyDOS = 1;
      this.Days?.setValue(""+this.claimsummaryDetailsrequest.DaysSupply);
      this.Quantity?.setValue(this.claimsummaryDetailsrequest.QuantityEntered);
    } else {
      this.blnChgQtyDOS = 0;
      this.logger.userAction("Changed Quantity/ Days");
      this.reexecute(""+this.Days?.value, ""+this.Quantity?.value);
    }
    if (saveToDB) {
      this.reexecute(""+this.Days?.value, ""+this.Quantity?.value, saveToDB);
    }
  }


  reexecute(Days: string, Quantity: string, saveToDB: boolean = false) {
    //console.log("params", Days, Quantity);
    debugger;
    this.logger.userAction("Re-executing Claims");
    this.selectedMedicine = JSON.parse("" + sessionStorage.getItem('selectedMedicine'));
    //console.log(this.selectedMedicine);
      
      
        this.selectedMedicine.Days = Days;
        this.selectedMedicine.Quantity = Quantity;
        let object = this.quantityService.quantityCalculate(parseFloat(Quantity), this.selectedMedicine.drugSearchItem);
        this.selectedMedicine.quantytyCalculate = ""+parseFloat(object.qtycaliculated);
        this.selectedMedicine.quantityEntered = object.qtyEntered;
        this.selectedMedicine.quantytyDesc = object.qtydesc;
      

    this.modalService.open(this.claimSummaryProgress, { modalDialogClass: 'claimSummaryProgress',backdrop:'static' });
    this.trailclaimadjudicationHelper.checkCoverage(this.selectedMedicine).then((resp: SelectedMedicine) => {
      //console.log("data", resp);

      if (resp) {
        this.counter = 2;
        sessionStorage.setItem("selectedMedicine", JSON.stringify(this.selectedMedicine));
        //console.log(this.selectedMedicine);
        const source = interval(2000);
        this.subscription = source.subscribe(() => {
          this.counter++;
          //console.log(this.counter);
          if (this.counter == 4) {
            //console.log(this.counter);
            this.subscription.unsubscribe();
            this.modalService.dismissAll();
            this.updateevent.emit(saveToDB);
          }
        });
      }
    },(error:any)=>{

    }).catch((error:any)=>{
      
    });
  }
  cancelQtyDOS() {
    this.blnChgQtyDOS = 0;
  }
  dismissAll() {
    this.modalService.dismissAll();
  }

  editProvider():void{
    debugger;
    console.log(this.claimresponse);
    this.logger.userAction("Editing Provider");  
    this.modalService.open(this.editProviderTemplateRef,{size:"lg",backdrop:'static'});
  }
  isEditProviderValid(status:PRESCRIBERDETAIL_STATUS):void{
    this.editProviderStatus = status;
    console.log(this.editProviderStatus);
  }
  initiatePA():void{
    
    this.logger.userAction("Initiating PA.");
    this.epaProcessor.generatePaRefId(""+this.profileService.loginuser?.prescriber?.uuid).then((response: PARefIDWebResponse)=>{
      this.submitPAInitiationRequest(response);
    }, (error:any)=>{
      this.modalService.open(this.errorModalTemplateRef,{size:'md',backdrop:'static'});
      if(error!=undefined && error.status!=undefined && error.status.statusDesc!=undefined){
        this.errorMessage = error.status.statusDesc;
      } else {
        this.logger.log("Error Occured while Initiating PA.");
        this.errorMessage = "An error occured. Please try again.";
      }
    }).catch((error:any)=>{
      this.logger.error("Error Occured while Initiating PA.");
      this.modalService.open(this.errorModalTemplateRef,{size:'md',backdrop:'static'});
      this.errorMessage = "An error occured. Please try again.";
    });
  }

  

  private submitPAInitiationRequest(paRef:PARefIDWebResponse):void{
    
    const ePARunnerStatus:EpaRunnerInitiationEvent = new EpaRunnerInitiationEvent();
    ePARunnerStatus.status = EPA_STATUS.PA_INITIATION;
    ePARunnerStatus.claimresponse = this.claimresponse;
    ePARunnerStatus.paRefId = paRef;
    this.epaProcessor.epaSubscription.next(ePARunnerStatus);
  }

  updateScenarioClaimsList() :boolean{
    debugger;
    this.scenarioClaimsList=this.claimresponse.trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims
                .filter((item:ScenarioClaim)=>item.scenarioPharmacy==this.claimsummaryDetailsrequest.scenariopharmacyIndex && this.claimsummaryDetailsrequest.ProductID!=item.responseClaimSegment.productOrServiceId);
     
                return true;
                
    }

    selectedAlternateDrug!:ScenarioClaim;

    selectAlternateDrugInfo(item:ScenarioClaim):void{
      this.modalService.dismissAll();
      this.modalService.open(this.selectAlternateDrugModal,{size:'lg',backdrop:'static'});
      this.selectedAlternateDrug = item;
    }

    goToSelectMember() {
      //console.log("Redirecting to Select Member");
      this.modalService.dismissAll();
      this.logger.userAction("Redirecting to Home")
      this.router.navigateByUrl("/home");
    }

    // printMode=false;
  printFun(divName2:string){
    this.printAlternative=true;
    this.modalService.dismissAll(); 
    setTimeout(() => {
      window.print();
      this.modalService.open(this.selectAlternateDrugModal,{size:'lg',backdrop:'static'});
      this.printAlternative=false;
    });
  }
}
