import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { RegisterComponent} from 'src/app/components/routecontent/register/register.component';
import { RegistersteponeComponent } from 'src/app/components/registerstepone/registerstepone.component';
import { RegistersteptwoComponent} from 'src/app/components/registersteptwo/registersteptwo.component';
import { RegisterstepthreeComponent } from 'src/app/components/registerstepthree/registerstepthree.component';
import { DatatableModule } from 'src/app/modules/datatable/datatable.module';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from "../shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


export const registrationRoutes: Routes = [
  {path:'stepone',component:RegistersteponeComponent,pathMatch:'full'},
  {path: 'steptwo', component: RegistersteptwoComponent,pathMatch:'full'},
  {path: 'stepthree', component: RegisterstepthreeComponent,pathMatch:'full'},
  {path:'', redirectTo:'/register/stepone',pathMatch:'full'}
]

@NgModule({
    declarations: [RegisterComponent,
        RegistersteponeComponent,
        RegistersteptwoComponent,
        RegisterstepthreeComponent,
    ],
    imports: [
        CommonModule,
        DatatableModule,
        RouterModule.forChild(registrationRoutes),
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        NgbModule,
        BrowserAnimationsModule,
    ],
    exports: [
      RegistersteponeComponent,
      RegistersteptwoComponent,
      RegisterstepthreeComponent
    ]
})
export class RegistrationModule { }
