import {Status} from 'src/app/modals/webresponsestatus';
import { MemberSearchItem } from 'src/app/modals/drugSearch/drugsearchwebrequest';

export interface Membersearchwebresponse {
    maxRowExceededInd:boolean;
    memberSearchV5Items: MemberSearchV5Item[];
    status:Status;
}

export interface MemberSearchV5Item extends MemberSearchItem {
}

export interface Address{
    address1:string;
    address2:string;
    address3:string;
    city:string;
    state:string;
    zip1:string;
    zip2:string;
    zip3:string;
    country:string;
}

export enum FindMemberResultType{
    SUCCESS=0,
    NO_RESULTS=1,
    BAD_REQUEST=2,
    INTERNAL_SERVER_ERROR=3,
    TIMEOUT=4
}

export class FavouriteMember{
    memberId!:string;
    lastName!:string;
    firstName!:string;
    dateOfBirth!:string;
    zipCode!:string;
    address1!:string;
    address2!:string;
    mbrSearchId!:number;
    presbrId?:string;
    uuid?:string;
   
}
export interface FavouriteMembersWebResponse{
    memberSearchFavoriteList:FavouriteMember[];
    status:Status;
}