import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'benefitinquiryhomepage',
  templateUrl: './benefitinquiryhomepage.component.html',
  styleUrls: ['./benefitinquiryhomepage.component.css']
})
export class BenefitinquiryhomepageComponent implements OnInit{
  constructor(private logger: LoggerService,private epaProcessor:EpaprocessorService,private modalService: NgbModal,private profileService:ProfileService,private memberSearchHelper: MembersearchService,private loggerService:LoggerService,protected router:Router)
  {}

  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
  }
  goto(routeUrl:string){
    debugger;
    this.loggerService.userAction("Going to " +routeUrl);
    
    this.router.navigateByUrl("/"+routeUrl);

  }

}
