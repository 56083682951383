import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { uploadHistoryWebResponse } from 'src/app/modals/provisioning/UploadHistoryWebResponse';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { environment } from 'src/environments/environment';
import { DownloadTemplateRequest, DownloadTemplateResponse } from 'src/app/modals/provisioning/DownloadTemplateResponse';
import { Observable } from 'rxjs';
import { Status } from 'src/app/modals/webresponsestatus';
import { InitReviewProvisionResponse, ResubmitProvisionRequest, ReviewProvisionResponse, SubmitUserProvisionListRequest, UpdateProvisionStatusResponse } from 'src/app/modals/provisioning/submitUserProvisioningrequest';
import { ProvisioningAddUser } from 'src/app/modals/provisioning/adduserResponse';
import {  SubmitSingleUserProvisioningRequest } from 'src/app/modals/provisioning/submitSingleUserProvisioning';

@Injectable({
  providedIn: 'root'
})
export class ProvisioningService {

  constructor(private http: HttpClient, private logger: LoggerService) { }


  getuploadHistory(presbrId: string): Promise<uploadHistoryWebResponse> {
    debugger;
    const promise: Promise<uploadHistoryWebResponse> = new Promise<uploadHistoryWebResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getProvisioningUploadHistoryUrl.replace("{presbrId}", presbrId)});
      this.http.post(environment.provisoningproxyurl,null,{headers:header}).subscribe((response: any) => {
        debugger;
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined && response.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_SUCCESS_NBR) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  getUserProvisioningSheet(webrequest:DownloadTemplateRequest): Promise<DownloadTemplateResponse>{
    debugger;
    const promise: Promise<DownloadTemplateResponse> = new Promise<DownloadTemplateResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.downloadExcelTemplateUrl});
      this.http.post(environment.provisoningproxyurl,webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  provisioningFileUpload(presbrId: string, file: File): Observable<HttpEvent<any>> {
    debugger;
    const formData: FormData = new FormData();
    formData.append('file', file);
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('presbrId', presbrId);
    httpParams = httpParams.append('fileName', file.name);
    // httpParams = httpParams.append('link',environment.uploadFileUrl);
    let header = new HttpHeaders({ "endpoint": environment.provisioningFileUploadUrl.replace("{presbrId}", presbrId).replace("{file}", file.name) });
    const req = new HttpRequest('POST', environment.proxyurlProvisioningFileUpload, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: header,
      params: httpParams
    });

    return this.http.request(req);
  }

  submitUserProvisionList(webrequest:ResubmitProvisionRequest): Promise<Status>{
    debugger;
    const promise: Promise<Status> = new Promise<Status>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.submitUserProvisionListUrl});
      this.http.post(environment.provisoningproxyurl,webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if (response.status != undefined && response.status.dbStatusCode!=undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT && response.status.dbStatusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
            debugger;
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }
  
  submitSingleUserProvisionList(webrequest:SubmitSingleUserProvisioningRequest): Promise<Status>{
    debugger;
    const promise: Promise<Status> = new Promise<Status>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.submitUserProvisionListUrl});
      this.http.post(environment.provisoningproxyurl,webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if (response.status != undefined && response.status.dbStatusCode!=undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT && response.status.dbStatusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
            debugger;
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }


  updateUserProvision(webrequest:ReviewProvisionResponse): Promise<UpdateProvisionStatusResponse>{
    debugger;
    const promise: Promise<UpdateProvisionStatusResponse> = new Promise<UpdateProvisionStatusResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.updateProvisionStatusUrl});
      this.http.post(environment.provisoningproxyurl,webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if (response.status != undefined && response.presbrIdStatus!=undefined
           && response.status.dbStatusCode!=undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT && response.status.dbStatusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
            debugger;
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  reviewProvision(provisionId: string): Promise<InitReviewProvisionResponse> {
    debugger;
    const promise: Promise<InitReviewProvisionResponse> = new Promise<InitReviewProvisionResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getReviewProvisionUrl.replace("{provisionId}", provisionId)});
      this.http.post(environment.provisoningproxyurl,null,{headers:header}).subscribe((response: any) => {
        debugger;
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  provisioningaddUser(optumID:string):Promise<ProvisioningAddUser>{
    debugger;
    const promise: Promise<ProvisioningAddUser> = new Promise<ProvisioningAddUser>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.optumIdSearchQueryUserUrl.replace("{optumID}", optumID)});
      this.http.post(environment.proxyurl,null,{headers:header}).subscribe((response: any) => {
        debugger;
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.queryUserResponse !=undefined && response.status.queryUserResponse.status != undefined &&  response.status.queryUserResponse.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
         && response.status.queryUserResponse.Resource !=undefined && response.status.queryUserResponse.Resource.status!=undefined && response.status.queryUserResponse.Resourcestatus.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;

  }
  
}
