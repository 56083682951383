<div class="container-fluid" [ngClass]="{'nopadding':!blnProgressBar}">
    <div class="row">
        <div class="col">
            <div *ngIf="routedfromnotifications==false && routedfromSummaryPage==false">
                <div class="container-fluid" *ngIf="blnProgressBar">
                    <div class="col-10 offset-1 mt-5 mb-3 timelinetitle" *ngIf="this.blnquespage!=4">
                        <h1>Initiate Prior Authorization
                            <p class="float-end mt-2">PA Reference ID: {{this.PaREfID}}</p>
                        </h1>
                    </div>
                    <div class="col-10 offset-1 mt-5 mb-3 timelinetitle" *ngIf="this.blnquespage==4">
                        <h1>You're Almost There!</h1>
                    </div>
                    <!-- <div class="float-end">
                    <a class="btn invisiblebtn btn-link">Cancel</a>
                </div> -->
                </div>
                <div class="container-fluid" *ngIf="blnProgressBar">
                    <div class="col-10 offset-1 timeline">
                        <!-- Progress bar  -->
                        <app-progress-tabs></app-progress-tabs>
                    </div>
                </div>
            </div>
            <div class="container-fluid" [ngClass]="{'nopadding':!blnProgressBar}">
                <div [ngClass]="{'timelinestepholder':blnProgressBar}">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <!-- <div class="col-12 float-end">
            <a class="col-3 p-5 float-end btn invisiblebtn btn-link">Cancel</a>
        </div> -->
    </div>
</div>

<ng-template #errorModalTemplate let-modal>
    <div class="content-card errorModal">
        <div class="card-content text-center">
            <i class="fa fa-ban"></i>
            <br /><br />
            <div [innerHtml]="errorMessage"></div>
            <br />
            <button class="btn btn-primary" (click)="modal.dismiss()">Ok</button>
        </div>
    </div>
</ng-template>