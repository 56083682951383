<div>
    <div class="row" style="margin: 2%;">
        <div class="col-md-7 col-xs-6" style="padding-left:0px;padding-right:0px">
            <h2 class="ng-binding">Notifications</h2>
            <span style="display:inline;font-size:15px;" class="a11y_h6 paragraph">You have<span class="notification_count">{{numberofnewnotifications}} new</span>notifications for this member,<br>which are indicated by the icon in the corner.
            </span>
            <span style="display:inline-block">
                <span style="display:inline-block;font-size:15px" class="arrow-up notification_new"></span>.
                <span>Up to ten of the most recent notifications for this member are shown below</span>
                
            </span>
        </div>
        <div class="col-md-5 col-xs-6" style="float:right">
            <button class="btn btn-primary btn-primary-link float-end" (click)="onViewAllNotificationsClick()">View all Notifications</button>
        </div>
        </div>
    <div class="row notifications_noresults">
        <div class="col-xs-12">
            <!-- <div ng-show="timeoutmodal &amp;&amp; !blnhide" style="margin-left:20px" class="ng-binding ng-hide"><br>We were unable to retrieve this information. Refresh the page to try again. <a href="javascript:void(0)" ng-click="refreshHandler()" class="ng-binding">Refresh page</a></div> -->
            <div class="submitted_PA_results fadeout-widget" >
                <div *ngIf="numberofnotifications==0">
                    <span style="display:block;padding: 20px 20px 0px;" class="paragraph">There are no notifications for
                        this prescriber.</span>
                </div>
                <div class="submitted_PA_progress text-center" 
                    *ngIf="numberofnotifications==undefined">
                  <!----<div>
                        <i class="fa fa-circle-o-notch fa-spin"
                            style="color: #ef6c00;font-size: 30px;display: inline-block;vertical-align: middle;"></i>
                        <span style="padding-left: 5px;position: relative;top: 2px">Loading...</span>
                    </div>-->
                </div>
                <div  ng-show="items.length>0 && !hide">
                    
                   <viewalldatatable [tabledata]="allNotifications" [action]="dltNtfyType" (updateondelete)="onDelete($event)" (viewPa)="onViewPAClick($event)" >

                    </viewalldatatable>                    
                </div>
            </div>

        </div>
    </div>

   <!--- <div class="row" style="margin: 0px;">
        <div class="col-xs-12" style="padding-right: 35px;margin-bottom: 30px;">
            <button class="btn btn-primary btn-primary-link float-end" (click)="onViewAllNotificationsClick()">ViewallNotifications</button>
            <button class="btn secondary pull-right" (click)="onViewAllNotificationsClick()">View all notifications</button>
        </div>
    </div>-->
</div>
<!-- <viewalldatatable ></viewalldatatable> -->