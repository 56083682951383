<div class="container showallNotifications" @fade *ngIf="!submitsuccess">
    <div class="notificationHeader">
        <div style="margin-left: 8%;padding: 0 !important;width: 99.5%;display: flex;">
            <div class="col-md-12 col-lg-10 col-lg-offset-1"
                style="display: flex;align-items: center;justify-content: space-between;">
                <h1 class="pull-left">Benefit Inquiry Request</h1>
                <div class="pull-right text-right cancel">
                    <span class="cancel_button" (click)="goto('benefitinquiry/homepage')">Cancel</span>
                </div>
            </div>
        </div>
    </div>

    <div class="row home_dashboard">
        <div style="margin-left: 8%;padding: 0 !important;width: 99.5%;display: flex;">
            <div class="col-md-12 col-lg-10 col-lg-offset-1">
                <div class="content-card no-shadow nopadding col-12">
                    <div style="padding: 26px;">
                        <div class="error-Style" *ngIf="errorinform" style="margin-bottom:10px;">
                            Please enter all required fields.
                        </div>
                        <div class="panelgroup stretch" id="accordion">
                            <div class="pannelbody">
                                <div class="panelheading" id="headingOne">
                                    <div class="paneltitle">
                                        <span class="pannel_button">
                                            <span (click)="toggleInOut1()">General</span>
                                            <span (click)="toggleInOut1()"
                                                style="float: right;color: grey !important; text-transform: none !important;">
                                                <i class="fa fa-chevron-right" *ngIf="slidedownupstate1 == 'out'"></i>
                                                <i class="fa fa-chevron-down" *ngIf="slidedownupstate1 == 'in'"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div id="BIGeneralSection" class="datadropdown" [@slideInOut]="slidedownupstate1">
                                    <form [formGroup]="GeneralForm">
                                        <div class="row">
                                            <div class="colxs4 feildstyle">
                                                <label for="consumingAppBI" class="inputFieldLabel ">* Consuming
                                                    App</label><br>
                                                <select class="drop-list" id="consumingAppBI"
                                                    formControlName="ConsumingApp">
                                                    <option value="RXLINK">RXLINK</option>
                                                    <option value="ALLSCRIPTS">ALLSCRIPTS</option>
                                                    <option value="ATHENA">ATHENA</option>
                                                    <option value="DRFIRST">DRFIRST</option>
                                                    <option value="NEWCROP">NEWCROP</option>
                                                    <option value="RXREVU">RXREVU</option>
                                                    <option value="CENTERX">CENTERX</option>
                                                </select>
                                            </div>
                                            <div class="colxs4 feildstyle">
                                                <label for="groupIdBI" class="inputFieldLabel">Group ID</label><br>
                                                <input type="text" class="emrManualFlow disabledinput" id="groupIdBI"
                                                    formControlName="GroupID">
                                            </div>
                                            <div class="colxs4 feildstyle">
                                                <label for="userIdBI" class="inputFieldLabel">* User ID</label><br>
                                                <input type="text" class="emrManualFlow disabledinput" id="userIdBI"
                                                    formControlName="UserID">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="colxs4 feildstyle">
                                                <label for="userIdTypeBI" class="inputFieldLabel">* User ID
                                                    Type</label><br>
                                                <input type="text" class="emrManualFlow disabledinput" id="userIdTypeBI"
                                                    formControlName="UserIDType">
                                            </div>
                                            <div class="colxs4 feildstyle">
                                                <label for="binBI" class="inputFieldLabel">BIN Number</label><br>
                                                <input type="text" class="emrManualFlow disabledinput" id="binBI"
                                                    formControlName="BINNumber">
                                            </div>
                                            <div class="colxs4 feildstyle">
                                                <label for="pcnBI" class="inputFieldLabel ">Personal Control
                                                    Number</label><br>
                                                <input type="text" class="emrManualFlow disabledinput" id="pcnBI"
                                                    formControlName="PersonalControlNumber">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="pannelbody pannelgap">
                                <div class="panelheading" id="headingOne">
                                    <div class="paneltitle">
                                        <span class="pannel_button">
                                            <span (click)="toggleInOut2()">Member</span>
                                            <span (click)="toggleInOut2()"
                                                style="float: right;color: grey !important; text-transform: none !important;">
                                                <i class="fa fa-chevron-right" *ngIf="slidedownupstate2 == 'out'"></i>
                                                <i class="fa fa-chevron-down" *ngIf="slidedownupstate2 == 'in'"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div id="BIMemberSection" class="datadropdown" [@slideInOut]="slidedownupstate2">
                                    <form [formGroup]="MemberForm">
                                        <div class="panel-body">
                                            <div class="row">
                                                <div class="colxs6 feildstyle">
                                                    <label for="lastNameBI" class="inputFieldLabel">* Patient Last
                                                        Name</label><br>
                                                    <input type="text" class="emrManualFlow" name="lastNameBI"
                                                        id="lastNameBI" formControlName="PatientLastName"
                                                        (input)="checkerror('lastNameBI',MemberForm.controls.PatientLastName.errors?.['required'])"
                                                        (focus)="applyRedErrorShadow('lastNameBI',MemberForm.controls.PatientLastName.errors?.['required'])"
                                                        (blur)="removeRedErrorShadow('lastNameBI')"
                                                        [ngClass]="{'error_border':(MemberForm.controls.PatientLastName.errors?.['required'] && submitted)}">
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="FirstNameBI" class="inputFieldLabel">Patient First
                                                        Name</label><br>
                                                    <input type="text" class="emrManualFlow" id="FirstNameBI"
                                                        formControlName="PatientFirstName">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="colxs3 feildstyle">
                                                    <label for="dobBI" class="inputFieldLabel">* Date of
                                                        Birth</label><br>
                                                    <input type="number" class="emrManualFlow" name="dobBI" id="dobBI"
                                                        data-maxlength="8"
                                                        oninput="this.value=this.value.slice(0,this.dataset.maxlength)"
                                                        placeholder="YYYYMMDD" formControlName="DateOfBirth"
                                                        (input)="checkerror('dobBI',MemberForm.controls.DateOfBirth.errors?.['required'])"
                                                        (focus)="applyRedErrorShadow('dobBI',MemberForm.controls.DateOfBirth.errors?.['required'])"
                                                        (blur)="removeRedErrorShadow('dobBI')"
                                                        [ngClass]="{'error_border':(MemberForm.controls.DateOfBirth.errors?.['required'] && submitted)}">
                                                </div>
                                                <div class="colxs3 feildstyle">
                                                    <label for="mbrZipBI" class="inputFieldLabel">* Patient Zip
                                                        code</label><br>
                                                    <input type="number" class="emrManualFlow" name="mbrZipBI"
                                                        data-maxlength="6"
                                                        oninput="this.value=this.value.slice(0,this.dataset.maxlength)"
                                                        id="mbrZipBI" formControlName="PatientZipCode"
                                                        (input)="checkerror('mbrZipBI',MemberForm.controls.PatientZipCode.errors?.['required'])"
                                                        (focus)="applyRedErrorShadow('mbrZipBI',MemberForm.controls.PatientZipCode.errors?.['required'])"
                                                        (blur)="removeRedErrorShadow('mbrZipBI')"
                                                        [ngClass]="{'error_border':(MemberForm.controls.PatientZipCode.errors?.['required'] && submitted)}">
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="mbrIdBI" class="inputFieldLabel">* Cardholder
                                                        ID</label><br>
                                                    <input type="text" class="emrManualFlow" name="mbrIdBI" id="mbrIdBI"
                                                        formControlName="CardholderID"
                                                        (input)="checkerror('mbrIdBI',MemberForm.controls.CardholderID.errors?.['required'])"
                                                        (focus)="applyRedErrorShadow('mbrIdBI',MemberForm.controls.CardholderID.errors?.['required'])"
                                                        (blur)="removeRedErrorShadow('mbrIdBI')"
                                                        [ngClass]="{'error_border':(MemberForm.controls.CardholderID.errors?.['required'] && submitted)}">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="colxs6 feildstyle">
                                                    <label for="genderCodeBI" class="inputFieldLabel">* Patient Gender
                                                        Code</label><br>
                                                    <select class="drop-list" id="genderCodeBI"
                                                        formControlName="PatientGenderCode">
                                                        <option value="0">0 - Not specified/Unknown</option>
                                                        <option value="1" selected>1 - Male</option>
                                                        <option value="2">2 - Female</option>
                                                    </select>
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="mbrCarrierIdBI" class="inputFieldLabel">Carrier
                                                        ID</label><br>
                                                    <input type="text" class="emrManualFlow" id="mbrCarrierIdBI"
                                                        formControlName="CarrierID">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="colxs3 feildstyle">
                                                    <label for="mbrCodeBI" class="inputFieldLabel">Person
                                                        Code</label><br>
                                                    <input type="text" class="emrManualFlow" id="mbrCodeBI"
                                                        formControlName="PersonCode">
                                                </div>
                                                <div class="colxs3 feildstyle">
                                                    <label for="reltnshipCodeBI" class="inputFieldLabel">Person
                                                        Relationship
                                                        Code</label><br>
                                                    <select class="drop-list" id="reltnshipCodeBI"
                                                        formControlName="PersonRelationshipCode">
                                                        <option value="">Please Select</option>
                                                        <option value="0">0 - Not specified</option>
                                                        <option value="1">1 - Cardholder</option>
                                                        <option value="2">2 - Spouse</option>
                                                        <option value="3">3 - Child</option>
                                                        <option value="4">4 - Other</option>
                                                    </select>
                                                </div>
                                                <div class="colxs3 feildstyle">
                                                    <label for="mbrPlaceOfServiceBI" class="inputFieldLabel">Place of
                                                        Service</label><br>
                                                    <input type="text" class="emrManualFlow" id="mbrPlaceOfServiceBI"
                                                        formControlName="PlaceOfService">
                                                </div>
                                                <div class="colxs3 feildstyle">
                                                    <label for="mbrResidenceBI" class="inputFieldLabel">Patient
                                                        Residence</label><br>
                                                    <input type="text" class="emrManualFlow" id="mbrResidenceBI"
                                                        formControlName="PatientResidence">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                            <div class="pannelbody pannelgap">
                                <div class="panelheading" id="headingOne">
                                    <div class="paneltitle">
                                        <span class="pannel_button">
                                            <span (click)="toggleInOut3()">Prescriber</span>
                                            <span (click)="toggleInOut3()"
                                                style="float: right;color: grey !important; text-transform: none !important;">
                                                <i class="fa fa-chevron-right" *ngIf="slidedownupstate3 == 'out'"></i>
                                                <i class="fa fa-chevron-down" *ngIf="slidedownupstate3 == 'in'"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div id="BIPrescriberSection" class="datadropdown" [@slideInOut]="slidedownupstate3">
                                    <form [formGroup]="PrescriberForm">
                                        <div class="panel-body">
                                            <div class="row">
                                                <div class="colxs6 feildstyle">
                                                    <label for="prscbrIdQlfrBI" class="inputFieldLabel">* Prescriber ID
                                                        Qualifier</label><br>
                                                    <input type="text" id="prscbrIdQlfrBI"
                                                        class="emrManualFlow disabledinput"
                                                        formControlName="PrescriberIDQualifier">
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="prscbrIdBI" class="inputFieldLabel">* Prescriber
                                                        ID</label><br>
                                                    <input type="number" number="" id="prscbrIdBI" data-maxlength="10"
                                                        oninput="this.value=this.value.slice(0,this.dataset.maxlength)"
                                                        name="prscbrIdBI" class="emrManualFlow"
                                                        formControlName="PrescriberID"
                                                        (input)="checkerror('prscbrIdBI',PrescriberForm.controls.PrescriberID.errors?.['required'])"
                                                        (focus)="applyRedErrorShadow('prscbrIdBI',PrescriberForm.controls.PrescriberID.errors?.['required'])"
                                                        (blur)="removeRedErrorShadow('prscbrIdBI')"
                                                        [ngClass]="{'error_border':(PrescriberForm.controls.PrescriberID.errors?.['required'] && submitted)}">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="pannelbody pannelgap">
                                <div class="panelheading" id="headingOne">
                                    <div class="paneltitle">
                                        <span class="pannel_button">
                                            <span (click)="toggleInOut4()">Drug</span>
                                            <span (click)="toggleInOut4()"
                                                style="float: right;color: grey !important; text-transform: none !important;">
                                                <i class="fa fa-chevron-right" *ngIf="slidedownupstate4 == 'out'"></i>
                                                <i class="fa fa-chevron-down" *ngIf="slidedownupstate4 == 'in'"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div id="BIDrugSection" class="datadropdown" [@slideInOut]="slidedownupstate4">
                                    <form [formGroup]="DrugForm">
                                        <div class="panel-body">
                                            <div class="row">
                                                <div class="colxs6 feildstyle">
                                                    <label for="productIdQlfrBI" class="inputFieldLabel">Product/Service
                                                        ID
                                                        Qualifier</label><br>
                                                    <select class="drop-list" id="productIdQlfrBI"
                                                        formControlName="ProductServiceIDQualifier">
                                                        <option value="01">01 - UPC</option>
                                                        <option value="02">02 - HRI</option>
                                                        <option value="03">03 - NDC</option>
                                                    </select>
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="drugpharmacyIdBI" class="inputFieldLabel">*
                                                        Product/Service
                                                        ID</label><br>
                                                    <div style="display: flex;">
                                                        <input type="number" class="emrManualFlow" id="drugpharmacyIdBI"
                                                            data-maxlength="11"
                                                            oninput="this.value=this.value.slice(0,this.dataset.maxlength)"
                                                            name="drugpharmacyIdBI" formControlName="ProductServiceID"
                                                            (input)="checkerror('drugpharmacyIdBI',DrugForm.controls.ProductServiceID.errors?.['required'])"
                                                            (focus)="applyRedErrorShadow('drugpharmacyIdBI',DrugForm.controls.ProductServiceID.errors?.['required'])"
                                                            (blur)="removeRedErrorShadow('drugpharmacyIdBI')"
                                                            [ngClass]="{'error_border':(DrugForm.controls.ProductServiceID.errors?.['required'] && submitted)}">
                                                        <button class="search-btn" type="button" title="search">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="colxs3 feildstyle">
                                                    <label for="qtyBI" class="inputFieldLabel">* Quantity
                                                        Dispensed</label><br>
                                                    <input type="text" id="qtyBI" name="qtyBI" class="emrManualFlow"
                                                        formControlName="QuantityDispensed"
                                                        (input)="checkerror('qtyBI',DrugForm.controls.QuantityDispensed.errors?.['required'])"
                                                        (focus)="applyRedErrorShadow('qtyBI',DrugForm.controls.QuantityDispensed.errors?.['required'])"
                                                        (blur)="removeRedErrorShadow('qtyBI')"
                                                        [ngClass]="{'error_border':(DrugForm.controls.QuantityDispensed.errors?.['required'] && submitted)}">
                                                </div>
                                                <div class="colxs2 feildstyle">
                                                    <label for="daySupplyBI" class="inputFieldLabel">* Days
                                                        Supply</label><br>
                                                    <input type="number" number="" id="daySupplyBI" name="daySupplyBI"
                                                        class="emrManualFlow" formControlName="DaysSupply"
                                                        data-maxlength="10"
                                                        oninput="this.value=this.value.slice(0,this.dataset.maxlength)"
                                                        (input)="checkerror('daySupplyBI',DrugForm.controls.DaysSupply.errors?.['required'])"
                                                        (focus)="applyRedErrorShadow('daySupplyBI',DrugForm.controls.DaysSupply.errors?.['required'])"
                                                        (blur)="removeRedErrorShadow('daySupplyBII')"
                                                        [ngClass]="{'error_border':(DrugForm.controls.DaysSupply.errors?.['required'] && submitted)}">
                                                </div>
                                                <div class="colxs7 feildstyle">
                                                    <label for="dawBI" class="inputFieldLabel">DAW/Product Selection
                                                        Code</label><br>
                                                    <select class="drop-list" id="dawBI"
                                                        formControlName="DAWProductSelectionCode">
                                                        <option value="0">0 - no product selection indicated</option>
                                                        <option value="1">1 - substitution not allowed by prescriber
                                                        </option>
                                                        <option value="2">2 - substitution allowed - patient requested
                                                            product dispensed</option>
                                                        <option value="3">3 - substitution allowed - pharmacist selected
                                                            product dispensed</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="colxs6 feildstyle">
                                                    <label class="inputFieldLabel">* Request Alternate Drugs</label><br>
                                                    <div class="btn-group">
                                                        <label (click)="DrugForm.value.RequestAlternateDrugs=true"
                                                            class="radioInput">
                                                            <input type="radio" id="requestalternatedrugtrue"
                                                                value="true" formControlName="RequestAlternateDrugs">
                                                            <i class="fa fa-circle-o hoverchange"
                                                                *ngIf="DrugForm.value.RequestAlternateDrugs!==true"></i>
                                                            <i class="fa fa-dot-circle-o hoverchange"
                                                                *ngIf="DrugForm.value.RequestAlternateDrugs===true"></i>
                                                            <label for="requestalternatedrugtrue"
                                                                class="radiolabel hoverchange"
                                                                style="padding-left: 10px;cursor: pointer;">True</label>
                                                        </label>

                                                        <label (click)="DrugForm.value.RequestAlternateDrugs=false"
                                                            class="radioInput ">
                                                            <input type="radio" id="requestalternatedrugfalse"
                                                                value="false" formControlName="RequestAlternateDrugs">
                                                            <i class="fa fa-circle-o hoverchange"
                                                                *ngIf="DrugForm.value.RequestAlternateDrugs!==false"></i>
                                                            <i class="fa fa-dot-circle-o hoverchange"
                                                                *ngIf="DrugForm.value.RequestAlternateDrugs===false"></i>
                                                            <label for="requestalternatedrugfalse"
                                                                class="radiolabel hoverchange"
                                                                style="padding-left: 10px;cursor: pointer;">False</label>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="pannelbody pannelgap">
                                <div class="panelheading" id="headingOne">
                                    <div class="paneltitle">
                                        <span class="pannel_button">
                                            <span (click)="toggleInOut5()">Pharmacy</span>
                                            <span (click)="toggleInOut5()"
                                                style="float: right;color: grey !important; text-transform: none !important;">
                                                <i class="fa fa-chevron-right" *ngIf="slidedownupstate5 == 'out'"></i>
                                                <i class="fa fa-chevron-down" *ngIf="slidedownupstate5 == 'in'"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div id="BIPharmacySection" class="datadropdown" [@slideInOut]="slidedownupstate5">
                                    <form [formGroup]="PharmacyForm">
                                        <div class="panel-body">
                                            <div class="row">
                                                <div class="colxs6 feildstyle">
                                                    <label for="pharmacyIdQlfrBI" class="inputFieldLabel">* Service
                                                        Provider
                                                        ID Qualifier</label><br>
                                                    <select class="drop-list" id="pharmacyIdQlfrBI"
                                                        formControlName="ServiceProviderIDQualifier">
                                                        <option value="01">01 - NPI</option>
                                                        <option value="07">07 - NCPDP</option>
                                                    </select>
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="pharmacyIdBI" class="inputFieldLabel">* Service Provider
                                                        ID</label><br>
                                                    <div style="display: flex;">
                                                        <input type="text" class="emrManualFlow" id="pharmacyIdBI"
                                                            name="pharmacyIdBI" formControlName="ServiceProviderID"
                                                            (input)="checkerror('pharmacyIdBI',PharmacyForm.controls.ServiceProviderID.errors?.['required'])"
                                                            (focus)="applyRedErrorShadow('pharmacyIdBI',PharmacyForm.controls.ServiceProviderID.errors?.['required'])"
                                                            (blur)="removeRedErrorShadow('pharmacyIdBI')"
                                                            [ngClass]="{'error_border':(PharmacyForm.controls.ServiceProviderID.errors?.['required'] && submitted)}">
                                                        <button class="search-btn" type="button" title="search">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="pannelbody pannelgap">
                                <div class="panelheading" id="headingOne">
                                    <div class="paneltitle">
                                        <span class="pannel_button">
                                            <span (click)="toggleInOut6()">Alternate Pharmacy</span>
                                            <span (click)="toggleInOut6()"
                                                style="float: right;color: grey !important; text-transform: none !important;">
                                                <i class="fa fa-chevron-right" *ngIf="slidedownupstate6 == 'out'"></i>
                                                <i class="fa fa-chevron-down" *ngIf="slidedownupstate6 == 'in'"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div id="BIAltPharmacySection" class="datadropdown" [@slideInOut]="slidedownupstate6">
                                    <div class="panel-body">
                                        <form [formGroup]="AlternatePharmacyForm">
                                            <div class="row">
                                                <div class="colxs6 feildstyle">
                                                    <label class="inputFieldLabel">* Request Alternate
                                                        Pharmacies</label><br>
                                                    <div class="btn-group">
                                                        <label
                                                            (click)="AlternatePharmacyForm.value.RequestAlternatePharmacies=true"
                                                            class="radioInput">
                                                            <input type="radio" id="requestalternatepharmaciestrue"
                                                                value="true"
                                                                formControlName="RequestAlternatePharmacies">
                                                            <i class="fa fa-circle-o hoverchange"
                                                                *ngIf="AlternatePharmacyForm.value.RequestAlternatePharmacies!==true"></i>
                                                            <i class="fa fa-dot-circle-o hoverchange"
                                                                *ngIf="AlternatePharmacyForm.value.RequestAlternatePharmacies===true"></i>
                                                            <label for="requestalternatepharmaciestrue"
                                                                class="radiolabel hoverchange"
                                                                style="padding-left: 10px;cursor: pointer;">True</label>
                                                        </label>

                                                        <label
                                                            (click)="AlternatePharmacyForm.value.RequestAlternatePharmacies=false"
                                                            class="radioInput ">
                                                            <input type="radio" id="requestalternatepharmaciesfalse"
                                                                value="false"
                                                                formControlName="RequestAlternatePharmacies">
                                                            <i class="fa fa-circle-o hoverchange"
                                                                *ngIf="AlternatePharmacyForm.value.RequestAlternatePharmacies!==false"></i>
                                                            <i class="fa fa-dot-circle-o hoverchange"
                                                                *ngIf="AlternatePharmacyForm.value.RequestAlternatePharmacies===false"></i>
                                                            <label for="requestalternatepharmaciesfalse"
                                                                class="radiolabel hoverchange"
                                                                style="padding-left: 10px;cursor: pointer;">False</label>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label class="inputFieldLabel">Identify Alternate Pharmacies
                                                        Only</label><br>
                                                    <div class="btn-group">
                                                        <label
                                                            (click)="AlternatePharmacyForm.value.IdentifyAlternatePharmaciesOnly=true"
                                                            class="radioInput">
                                                            <input type="radio" id="identifyalternatepharmaciesonlytrue"
                                                                value="true"
                                                                formControlName="IdentifyAlternatePharmaciesOnly">
                                                            <i class="fa fa-circle-o hoverchange"
                                                                *ngIf="AlternatePharmacyForm.value.IdentifyAlternatePharmaciesOnly!==true"></i>
                                                            <i class="fa fa-dot-circle-o hoverchange"
                                                                *ngIf="AlternatePharmacyForm.value.IdentifyAlternatePharmaciesOnly===true"></i>
                                                            <label for="identifyalternatepharmaciesonlytrue"
                                                                class="radiolabel hoverchange"
                                                                style="padding-left: 10px;cursor: pointer;">True</label>
                                                        </label>

                                                        <label
                                                            (click)="AlternatePharmacyForm.value.IdentifyAlternatePharmaciesOnly=false"
                                                            class="radioInput ">
                                                            <input type="radio" id="identifyalternatepharmaciesonlyfalse"
                                                                value="false"
                                                                formControlName="IdentifyAlternatePharmaciesOnly">
                                                            <i class="fa fa-circle-o hoverchange"
                                                                *ngIf="AlternatePharmacyForm.value.IdentifyAlternatePharmaciesOnly!==false"></i>
                                                            <i class="fa fa-dot-circle-o hoverchange"
                                                                *ngIf="AlternatePharmacyForm.value.IdentifyAlternatePharmaciesOnly===false"></i>
                                                            <label for="identifyalternatepharmaciesonlyfalse"
                                                                class="radiolabel hoverchange"
                                                                style="padding-left: 10px;cursor: pointer;">False</label>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="row" style="margin: 0px 0px 10px;">
                                            <div class="inputFieldLabel nopadding"
                                                style="border-bottom:2px solid rgb(169,169,179);">
                                                Mail Order Pharmacy</div>
                                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-5 nopadding">
                                                <button type="button" class="btn primary btn-primary"
                                                    style="padding: 6px 20px;height: 44px;font-family: Frutiger-Bold, Helvetica, Arial, sans-serif;"><span
                                                        style="padding-left: 15px;padding-right: 15px;">Select
                                                        Pharmacy</span>
                                                </button>
                                            </div>
                                        </div>
                                        <form [formGroup]="MailOrderPharmacyForm">
                                            <div class="row">
                                                <div class="colxs6 feildstyle">
                                                    <label for="pharmacyIdQlfrBIOne" class="inputFieldLabel">Service
                                                        Provider ID Qualifier</label><br>
                                                    <select class="drop-list " id="pharmacyIdQlfrBIOne"
                                                        formControlName="ServiceProviderIDQualifier">
                                                        <option value="01">01 - NPI</option>
                                                        <option value="07">07 - NCPDP</option>
                                                    </select>
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="pharmacyIdBIOne" class="inputFieldLabel">Service
                                                        Provider
                                                        ID</label><br>
                                                    <input type="text" id="pharmacyIdBIOne" class="emrManualFlow"
                                                        formControlName="ServiceProviderID">
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="pharmacyNameBIOne" class="inputFieldLabel">Service
                                                        Provider
                                                        Name</label><br>
                                                    <input type="text" id="pharmacyNameBIOne" class="emrManualFlow"
                                                        formControlName="ServiceProviderName">
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="pharmacyPhoneBIOne" class="inputFieldLabel">Service
                                                        Provider
                                                        Phone</label><br>
                                                    <input type="text" id="pharmacyPhoneBIOne" class="emrManualFlow"
                                                        formControlName="ServiceProviderPhone">
                                                </div>
                                            </div>
                                        </form>
                                        <div class="row" style="margin: 0px 0px 10px;">
                                            <div class="inputFieldLabel nopadding"
                                                style="border-bottom:2px solid rgb(169,169,179);">
                                                Specialty Pharmacy</div>
                                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-5 nopadding">
                                                <button type="button" class="btn primary btn-primary"
                                                    style="padding: 6px 20px;height: 44px;font-family: Frutiger-Bold, Helvetica, Arial, sans-serif;"><span
                                                        style="padding-left: 15px;padding-right: 15px;">Select
                                                        Pharmacy</span>
                                                </button>
                                            </div>
                                        </div>
                                        <form [formGroup]="SpecialtyPharmacyForm">
                                            <div class="row">
                                                <div class="colxs6 feildstyle">
                                                    <label class="inputFieldLabel">Med D Member</label><br>
                                                    <div class="btn-group">
                                                        <label (click)="SpecialtyPharmacyForm.value.MedDMember=true"
                                                            class="radioInput">
                                                            <input type="radio" id="requestalternatedrugtrue"
                                                                value="true" formControlName="MedDMember">
                                                            <i class="fa fa-circle-o hoverchange"
                                                                *ngIf="SpecialtyPharmacyForm.value.MedDMember!==true"></i>
                                                            <i class="fa fa-dot-circle-o hoverchange"
                                                                *ngIf="SpecialtyPharmacyForm.value.MedDMember===true"></i>
                                                            <label for="requestalternatedrugtrue"
                                                                class="radiolabel hoverchange"
                                                                style="padding-left: 10px;cursor: pointer;">True</label>
                                                        </label>

                                                        <label (click)="SpecialtyPharmacyForm.value.MedDMember=false"
                                                            class="radioInput ">
                                                            <input type="radio" id="requestalternatedrugfalse"
                                                                value="false" formControlName="MedDMember">
                                                            <i class="fa fa-circle-o hoverchange"
                                                                *ngIf="SpecialtyPharmacyForm.value.MedDMember!==false"></i>
                                                            <i class="fa fa-dot-circle-o hoverchange"
                                                                *ngIf="SpecialtyPharmacyForm.value.MedDMember===false"></i>
                                                            <label for="requestalternatedrugfalse"
                                                                class="radiolabel hoverchange"
                                                                style="padding-left: 10px;cursor: pointer;">False</label>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row"
                                                style="margin:10px -10px 0px -10px;border:1px solid rgb(169,169,169);">
                                                <div class="colxs6 feildstyle">
                                                    <label for="pharmacyIdQlfrBITwo" class="inputFieldLabel">Service
                                                        Provider ID Qualifier</label><br>
                                                    <select class="drop-list" id="pharmacyIdQlfrBITwo"
                                                        formControlName="ServiceProviderIDQualifier1">
                                                        <option value="01">01 - NPI</option>
                                                        <option value="07">07 - NCPDP</option>
                                                    </select>
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="pharmacyIdBITwo" class="inputFieldLabel">Service
                                                        Provider
                                                        ID</label><br>
                                                    <input type="text" id="pharmacyIdBITwo" class="emrManualFlow"
                                                        formControlName="ServiceProviderID1">
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="spclPharmNameBIOne" class="inputFieldLabel">Service
                                                        Provider
                                                        Name</label><br>
                                                    <input type="text" id="spclPharmNameBIOne" class="emrManualFlow"
                                                        formControlName="ServiceProviderName1">
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="spclPharmPhoneBIOne" class="inputFieldLabel">Service
                                                        Provider Phone</label><br>
                                                    <input type="text" id="spclPharmPhoneBIOne" class="emrManualFlow"
                                                        formControlName="ServiceProviderPhone1">
                                                </div>
                                            </div>
                                            <div class="row"
                                                style="margin:15px -10px 0px -10px;border:1px solid rgb(169,169,169);">
                                                <div class="colxs6 feildstyle">
                                                    <label for="pharmacyIdQlfrBIThree" class="inputFieldLabel">Service
                                                        Provider ID Qualifier</label><br>
                                                    <select class="drop-list" id="pharmacyIdQlfrBIThree"
                                                        formControlName="ServiceProviderIDQualifier2">
                                                        <option value="01">01 - NPI</option>
                                                        <option value="07">07 - NCPDP</option>
                                                    </select>
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="pharmacyIdBIThree" class="inputFieldLabel">Service
                                                        Provider
                                                        ID</label><br>
                                                    <input type="text" id="pharmacyIdBIThree" class="emrManualFlow"
                                                        formControlName="ServiceProviderID2">
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="spclPharmNameBITwo" class="inputFieldLabel">Service
                                                        Provider
                                                        Name</label><br>
                                                    <input type="text" id="spclPharmNameBITwo" class="emrManualFlow"
                                                        formControlName="ServiceProviderName2">
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="spclPharmPhoneBITwo" class="inputFieldLabel">Service
                                                        Provider Phone</label><br>
                                                    <input type="text" id="spclPharmPhoneBITwo" class="emrManualFlow"
                                                        formControlName="ServiceProviderPhone2">
                                                </div>
                                            </div>
                                            <div class="row"
                                                style="margin:15px -10px 0px -10px;border:1px solid rgb(169,169,169);">
                                                <div class="colxs6 feildstyle">
                                                    <label for="pharmacyIdQlfrBIFour" class="inputFieldLabel">Service
                                                        Provider ID Qualifier</label><br>
                                                    <select class="drop-list" id="pharmacyIdQlfrBIFour"
                                                        formControlName="ServiceProviderIDQualifier3">
                                                        <option value="01">01 - NPI</option>
                                                        <option value="07">07 - NCPDP</option>
                                                    </select>
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="pharmacyIdBIFour" class="inputFieldLabel">Service
                                                        Provider
                                                        ID</label><br>
                                                    <input type="text" id="pharmacyIdBIFour" class="emrManualFlow"
                                                        formControlName="ServiceProviderID3">
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="spclPharmNameBIThree" class="inputFieldLabel">Service
                                                        Provider Name</label><br>
                                                    <input type="text" id="spclPharmNameBIThree" class="emrManualFlow"
                                                        formControlName="ServiceProviderName3">
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="spclPharmPhoneBIThree" class="inputFieldLabel">Service
                                                        Provider Phone</label><br>
                                                    <input type="text" id="spclPharmPhoneBIThree" class="emrManualFlow"
                                                        formControlName="ServiceProviderPhone3">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="pannelbody pannelgap">
                                <div class="panelheading" id="headingOne">
                                    <div class="paneltitle">
                                        <span class="pannel_button">
                                            <span (click)="toggleInOut7()">DUR/PPS</span>
                                            <span (click)="toggleInOut7()"
                                                style="float: right;color: grey !important; text-transform: none !important;">
                                                <i class="fa fa-chevron-right" *ngIf="slidedownupstate7 == 'out'"></i>
                                                <i class="fa fa-chevron-down" *ngIf="slidedownupstate7 == 'in'"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div id="BIDurPpsSection" class="datadropdown" [@slideInOut]="slidedownupstate7">
                                    <form [formGroup]="DurPpsForm">
                                        <div class="panel-body">
                                            <div class="row">
                                                <div class="colxs6 feildstyle">
                                                    <label for="reasonServiceCodeBI" class="inputFieldLabel">Reason for
                                                        Service Code</label><br>
                                                    <select class="drop-list" id="reasonServiceCodeBI"
                                                        formControlName="ReasonForServiceCode"
                                                        (change)="checkerror('reasonServiceCodeBI',(DurPpsFormcheck==false && DurPpsForm.value.ReasonForServiceCode==''))"
                                                        (blur)="removeRedErrorShadow('reasonServiceCodeBI')"
                                                        (focus)="applyRedErrorShadow('reasonServiceCodeBI',(DurPpsFormcheck==false && DurPpsForm.value.ReasonForServiceCode==''))"
                                                        [ngClass]="{'error_border':DurPpsFormcheck==false && DurPpsForm.value.ReasonForServiceCode==''}">
                                                        <option value="">Please Select</option>
                                                        <option value="AD">AD - additional drug neededI</option>
                                                        <option value="AN">AN - prescription authentication</option>
                                                        <option value="AR">AR - adverse drug reaction</option>
                                                    </select>
                                                </div>
                                                <div class="colxs6 feildstyle">
                                                    <label for="profServiceCodeBI" class="inputFieldLabel">Professional
                                                        Service Code</label><br>
                                                    <select class="drop-list" id="profServiceCodeBI"
                                                        formControlName="ProfessionalServiceCode"
                                                        (change)="checkerror('profServiceCodeBI',(DurPpsFormcheck==false && DurPpsForm.value.ProfessionalServiceCode==''))"
                                                        (blur)="removeRedErrorShadow('profServiceCodeBI')"
                                                        (focus)="applyRedErrorShadow('profServiceCodeBI',(DurPpsFormcheck==false && DurPpsForm.value.ProfessionalServiceCode==''))"
                                                        [ngClass]="{'error_border':DurPpsFormcheck==false && DurPpsForm.value.ProfessionalServiceCode==''}">
                                                        <option value="">Please Select</option>
                                                        <option value="00">00 - no intervention</option>
                                                        <option value="AS">AS - patient assessment</option>
                                                        <option value="CC">CC - coordination of care</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="colxs6 feildstyle">
                                                    <label for="resultServiceCodeBI" class="inputFieldLabel">Result Of
                                                        Service Code</label><br>
                                                    <select class="drop-list" id="resultServiceCodeBI"
                                                        formControlName="ResultOfServiceCode"
                                                        (change)="checkerror('resultServiceCodeBI',(DurPpsFormcheck==false && DurPpsForm.value.ResultOfServiceCode==''))"
                                                        (blur)="removeRedErrorShadow('resultServiceCodeBI')"
                                                        (focus)="applyRedErrorShadow('resultServiceCodeBI',(DurPpsFormcheck==false && DurPpsForm.value.ResultOfServiceCode==''))"
                                                        [ngClass]="{'error_border':DurPpsFormcheck==false && DurPpsForm.value.ResultOfServiceCode==''}">
                                                        <option value="">Please Select</option>
                                                        <option value="00">00 - not specified</option>
                                                        <option value="1A">1A - filled as is, false positive</option>
                                                        <option value="1B">1B - filled prescription as is</option>
                                                        <option value="1C">1C - filled with different dose</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="footerstyle">
                            <div class="back" (click)="goto('benefitinquiry/homepage')">
                                <span>Back</span>
                            </div>
                            <button type="submit" class="btn btn-primary submitbtn" title="submit" (click)="onSubmit()">
                                <span>Submit</span>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
<div class="container showallNotifications" @fade *ngIf="submitsuccess">
    <div class="notificationHeader">
        <div style="margin-left: 8%;padding: 0 !important;width: 99.5%;display: flex;">
            <div class="col-md-12 col-lg-10 col-lg-offset-1"
                style="display: flex;align-items: center;justify-content: space-between;">
                <h1 class="pull-left">Benefit Inquiry Response</h1>
                <div class="pull-right text-right cancel">
                    <span class="cancel_button" (click)="onViewJsonClick()">View JSON</span>
                </div>
            </div>
        </div>
    </div>

    <div class="row home_dashboard">
        <div style="margin-left: 8%;padding: 0 !important;width: 99.5%;display: flex;">
            <div class="col-md-12 col-lg-10 col-lg-offset-1">
                <div class="content-card no-shadow nopadding col-12">
                    <div style="padding: 26px;">
                        <div class="error-Style" *ngIf="errorinform" style="margin-bottom:10px;">
                            Please enter all required fields.
                        </div>
                        <div class="panelgroup stretch" id="accordion">
                            <div class="pannelbody">
                                <div class="panelheading" id="headingOne">
                                    <div class="paneltitle">
                                        <span class="pannel_button">
                                            <span (click)="toggleInOut1()">General</span>
                                            <span (click)="toggleInOut1()"
                                                style="float: right;color: grey !important; text-transform: none !important;">
                                                <i class="fa fa-chevron-right" *ngIf="slidedownupstate1 == 'out'"></i>
                                                <i class="fa fa-chevron-down" *ngIf="slidedownupstate1 == 'in'"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div id="BIGeneralSection" class="datadropdown" [@slideInOut]="slidedownupstate1">
                                    <div class="row successfeild">
                                        <div class="colxs3">
                                            <label for="instanceId" class="inputFieldLabel">Instance ID</label><br>
                                        </div>
                                        <div class="colxs1">:</div>
                                        <div class="colxs8">
                                            <label name="instanceId" id="instanceId" class="inputFieldLabel ng-binding">
                                                {{InstanceID}}</label>
                                        </div>
                                    </div>
                                    <div class="row successfeild">
                                        <div class="colxs3">
                                            <label for="correlationId" class="inputFieldLabel">Correlation
                                                ID</label><br>
                                        </div>
                                        <div class="colxs1">:</div>
                                        <div class="colxs8">
                                            <label name="correlationId" id="correlationId"
                                                class="inputFieldLabel ">{{CorrelationID}}</label>
                                        </div>
                                    </div>
                                    <div class="row successfeild">
                                        <div class="colxs3">
                                            <label for="pstatus" class="inputFieldLabel">Provider Status</label><br>
                                        </div>
                                        <div class="colxs1">:</div>
                                        <div class="colxs8">
                                            <label name="pstatus" id="pstatus" class="inputFieldLabel">
                                                {{ProviderStatus}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row successfeild">
                                        <div class="colxs3">
                                            <label for="esbstatus" class="inputFieldLabel">ESB Status</label><br>
                                        </div>
                                        <div class="colxs1">:</div>
                                        <div class="colxs8">
                                            <label name="esbstatus" id="esbstatus"
                                                class="inputFieldLabel ">{{ESBStatus}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 text-right"
                    style="position: relative;min-height: 1px;padding-right: 15px;margin-top: 60px;">
                    <span class="go_to_bi" (click)="onBackToBIRequestClick()" href="javascript:void(0)">
                        <i class="fa fa-angle-left"
                            style="padding-right: 3px;font-size: 24px !important;vertical-align: bottom;"
                            aria-hidden="true"></i>
                        Back to Benefit Inquiry Request
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="popup-greybackground" *ngIf="showJSON"></div>

<div class="popup-container" *ngIf="showJSON">
    <div class="popup" style="display: flex;flex-direction: column;justify-content: space-between;">
        <div class="row" style="flex-grow: 1;">
            <div class="col-6" style="padding-left: 15px;padding-right: 20px;">
                <div style="height: 100%; display: flex;flex-direction: column;">
                    <label for="staticManualBIReq" class="inputFieldLabel">Request :</label>
                    <textarea id="staticManualBIReq" style="resize:none;flex-grow: 1;" cols="50" readonly="true">
                    {{birequeststring}}
                    </textarea>
                </div>
            </div>
            <div class="col-6" style="padding-left: 20px;padding-right: 15px;">
                <div style="height: 100%; display: flex;flex-direction: column;">
                    <label for="staticManualBIResp" class="inputFieldLabel">Response :</label>
                    <textarea id="staticManualBIResp" style="resize:none;flex-grow: 1;" cols="50" readonly="true">
                        {{biresponsestring}}
                    </textarea>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top:30px;display: flex;justify-content: end;">
            <div class="colxs3" style="display: flex;justify-content: end;">
                <button type="button" class="btn btn-primary text-capatilize" (click)="closeJsonClick()" style="height: 44px;padding: 0px 37px;font-family: Frutiger-Bold;">
                    <span>Close</span>
                </button>
            </div>
        </div>
    </div>
</div>