<html>
    <body *ngIf="action==1">
        <form name="AuthServer" [action]="env.sso_authurl" method="POST">
            <input type='hidden' name='client_id' [value]='env.sso_clientId'/>
            <input type='hidden' name='redirect_uri' [value]='env.sso_redirectUri'/>
            <input type='hidden' name='state' [value]='env.sso_state'/>
            <input type='hidden' name='response_type' [value]='env.sso_responseType'/>
            <input type='hidden' name='scope' [value]='env.sso_scope'/>
        </form>
    </body>
</html>