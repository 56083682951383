<div class="container-fluid nopadding manage_prescribers_container excelreport ng-scope" style="margin-top: 60px;">
    <div class="row">
        <div class="col-md-12">
            <div class="content-card-holder provisioningupload successfulusers">
                <div class="content-card no-shadow">
                    <div class="card-content header container-fluid bg-white" style="border-bottom:2px solid #ddd">
                        <div class="row">
                            <div class="col-md-8" style="padding-top:5px;">
                                <h4>USER PROVISIONING VALIDATION</h4>
                            </div>
                            <div class="col-md-3 text-right"
                                style="padding-top:5px;font-size:14px;color:rgb(98, 111, 125);font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;">
                                <span>{{this.uploadDate}}</span>&nbsp;&nbsp;<span>{{this.uploadTime}}</span>
                            </div>
                            <div class="col-md-1 text-right" *ngIf="this.provisionStatus=='Success'">
                                <button class="btn btn-link fontbold" (click)="editPanel()" *ngIf="this.blnEdit==false"> Edit </button>
                                <button class="btn btn-link fontbold" (click)="dismissEdit()" *ngIf="this.blnEdit==true"> Cancel </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content body bg-white pb-5" *ngIf="this.provisionStatus=='Success'">
                        <datatable [headers]="excelSuccessfulrecordheaders" [rows]="excelSuccessfulrecordentries"></datatable>
                    </div>
                    <div class="card-content body bg-white pb-5" *ngIf="this.provisionStatus=='Failure'">
                        <p>You have no successful records.</p>
                    </div>
                    <div class="card-content footer" *ngIf="this.blnEdit==true" style="background-color:rgba(242,246,249,1) !important;">
                        <div class="container-fluid nopadding">
                            <div class="row">
                                <div class="col-xs-3 offset-10">
                                    <button type="button" class="btn btn-primary text-capatilize" (click)="SaveChangesClick()">Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12" style="margin-top: 25px; margin-bottom:15px;">
            <div class="pull-right" style="padding:10px 0px;">
                <i class="fa fa-file-text" style="color:#0470c5"></i>
                <button type="button" style="margin-left:5px;" (click)="downloadProvisionSheet()">Export to Excel</button>
                <img src="assets/images/microsoft-excel.png" alt="excel image"
                    style="width: 25px; height: 25px; position: relative; top: -3px;">
            </div>
        </div>
        <div class="col-md-12">
            <div class="content-card-holder provisioningupload unsuccessfulusers">
                <div class="content-card no-shadow">
                    <div class="card-content header container-fluid bg-white" style="border-bottom:2px solid #ddd">
                        <div class="row">
                            <div class="col-md-12" style="padding-top:5px;">
                                <h4 class="text-capatilize">UNSUCCESSFUL</h4>
                             </div>
                        </div>
                    </div>
                    <div class="card-content body bg-white pb-5" *ngIf="this.provisionStatus=='Failure'">
                        <datatable [headers]="excelUnSuccessfulrecordheaders" [rows]="excelUnSuccessfulrecordentries"></datatable>
                    </div>
                    <div class="card-content body bg-white pb-5" *ngIf="this.provisionStatus=='Success'">
                        <p>You have no unsuccessful records.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 col-xs-8"></div>
        <div class="col-md-2 col-xs-4">
            <button type="button" style="margin-top:20px;" class="btn btn-primary disabled pull-right" *ngIf="this.provisionStatus=='Success'">
                <span class="text">Provision</span></button>
            <button type="button" style="margin-top:20px;" class="btn btn-primary pull-right" *ngIf="this.provisionStatus=='Failure'" (click)="reprovisionUsers()">
             <span class="text">Provision</span>
        </button>
        </div>
    </div>




</div>


<ng-template #optumIDTemplate let-data="data">
    <div class="paragraph text-center" enableAction="true" *ngIf="data.editMode==false">
        {{data.reviewProvisionUser.optumId}}
    </div>
    <div class="paragraph" *ngIf="data.editMode==true">
        <input  class="offset-1 p-2" type="text" [(ngModel)]="data.reviewProvisionUser.optumId" [ngClass]="{'error': data.reviewProvisionUser.optumId==''}"
            style="height:50px;width:80%;border: 2px solid #626f7d;">
    </div>
</ng-template>

<ng-template #npiTemplate let-data="data">
    <div class="paragraph" enableAction="true" *ngIf="data.editMode==false">
        {{data.reviewProvisionUser.npiList}}
    </div>
    <div class="paragraph" *ngIf="data.editMode==true">
        <input type="text" [(ngModel)]="data.reviewProvisionUser.npiList" [ngClass]="{'error': data.reviewProvisionUser.npiList==''}"
            style="height:50px;width:80%;border: 2px solid #626f7d;">
    </div>
</ng-template>

<ng-template #roleTemplate let-data="data">
    <div class="paragraph" enableAction="true" *ngIf="data.editMode==false">
        {{data.reviewProvisionUser.role}}
    </div>
    <div class="paragraph" *ngIf="data.editMode==true">
        <select [(ngModel)]="data.reviewProvisionUser.role" class="rounded-0 inp form-select dropdown" style="height:50px">
            <option value="Prescriber">Prescriber</option>
            <option value="Admin">Admin</option>
            <option value="Advisor">Advisor</option>
            <option value="Pharmacist">Pharmacist</option>
            <option value="Specialtypharmacist">Specialtypharmacist</option>
        </select>
    </div>
</ng-template>

<ng-template #internalTemplate let-data="data">
    <div class="label text-center" *ngIf="this.blnEdit==false && data.editMode==false">
        <div class="label" *ngIf="data.reviewProvisionUser.userType=='I'">
            <span class="paragraph">Yes</span>
        </div>
        <div class="label" *ngIf="data.reviewProvisionUser.userType=='E'">
            <span class="paragraph">No</span>
        </div>
    </div>
    
    <div class="label" *ngIf="data.editMode==false && this.blnEdit==true">
        <div class="toggle-switch">
            <label class="toggle-switch">
                <input type="checkbox" [(ngModel)]="data.isInternalUser" [checked]="data.isInternalUser==true" (click)="updateUserType(data)">
                <span class="slider">
                    <div style="display: flex;justify-content: start;width: 100%;height: 100%;align-items: center;">
                        <span style="padding-left: 17px;" *ngIf="data.isInternalUser">Yes</span>
                        <span style="padding-left: 37px;" *ngIf="!data.isInternalUser">No</span>
                    </div>
                </span>
            </label>
        </div>
    </div>

    <div class="label" *ngIf="data.editMode==true && this.blnEdit==false">
        <div class="toggle-switch">
            <label class="toggle-switch">
                <input type="checkbox" [(ngModel)]="data.isInternalUser" [checked]="data.isInternalUser==true">
                <span class="slider">
                    <div style="display: flex;justify-content: start;width: 100%;height: 100%;align-items: center;">
                        <span style="padding-left: 17px;" *ngIf="data.isInternalUser">Yes</span>
                        <span style="padding-left: 37px;" *ngIf="!data.isInternalUser">No</span>
                    </div>
                </span>
            </label>
        </div>
    </div>
</ng-template>

<ng-template #emailTemplate let-data="data">
    <div class="paragraph" enableAction="true" *ngIf="data.editMode==false">
        {{data.reviewProvisionUser.email}}
    </div>
    <div class="paragraph" *ngIf="data.editMode==true">
        <input type="text" [(ngModel)]="data.reviewProvisionUser.email" [ngClass]="{'error': data.reviewProvisionUser.email==''}"
            style="height:50px;width:100%;border: 2px solid #626f7d;">
    </div>
</ng-template>

<ng-template #statusTemplate let-data="data">
    <div class="label text-center" *ngIf="this.blnEdit==false">
    <i class="fa fa-check-circle" *ngIf="this.provisionStatus=='Success'"></i>
    <i class="fa fa-times" *ngIf="this.provisionStatus=='Failure'"></i>
    </div>

    <div *ngIf="this.blnEdit==true && data.editMode==false" class="toggle-swicth">
        <label class="toggle-switch1">
            <input type="checkbox" [(ngModel)]="data.isUserActive" [checked]="data.isUserActive=='Y'" (click)="updateUserActiveType(data)">
            <span class="slider1">
                <div style="display: flex;justify-content: start;width: 100%;height: 100%;align-items: center;">
                    <span style="padding-left: 14px;" *ngIf="data.isUserActive">Active</span>
                    <span style="padding-left: 30px;" *ngIf="!data.isUserActive">Inactive</span>
                </div>
            </span>

        </label>
    </div>
</ng-template>

<ng-template #editTemplate let-data="data">
    <button class="btn btn-link fontbold nopadding" *ngIf="this.provisionStatus=='Failure' && data.editMode==false" (click)="edit(data)">
        Edit
    </button>
    <button class="btn btn-link fontbold" *ngIf="data.editMode==true" (click)="save(data)">
        Save
    </button>
</ng-template>


<ng-template #provisionConfirmModal let-modal>
    <div class="p-3">
        <div class="text-center progress-notification"><i class="fa fa-check-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            File upload process is initiated and users will be <br> provisioned.
        </div>
        <div class="row p-2">
            <div class="col text-center">
                <button type="button" class="btn btn-secondary" (click)="dismiss(); goto()">Ok</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #UpdatesSavedConfirmModal let-modal>
    <div class="p-3">
        <div class="text-center progress-notification"><i class="fa fa-check-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            Updates saved successfully.
        </div>
        <div class="row p-2">
            <div class="col text-center">
                <button type="button" class="btn btn-secondary" (click)="dismiss(); goto()">Ok</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #RecordsNotModifiedModal let-modal>
    <div class="p-3">
        <div class="text-center progress-notification"><i class="fa fa-ban" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            No records modified.
        </div>
        <div class="row p-2">
            <div class="col text-center">
                <button type="button" class="btn btn-secondary" (click)="dismiss(); goto()">Ok</button>
            </div>
        </div>
    </div>
</ng-template>



