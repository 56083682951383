// export class SteponePharmacyRequest
// {   
//         zipCode !: string;
//         distance !: string;
//         dispenserType !: string[];
//         dispenserClass !: string[];
//         pharmacyName !: string;
// }
export class SteponePharmacyRequesthemi {
        address!: Address
        searchRadius!: string
        primaryProviderTypeCode!: string[]
        dispenserClassCode!: string[]
        pharmacyName!: string
        sourceSystemInstance!: string
        planSearch!: PlanSearch
      }
      
      export class Address {
        zip!: string;
      }
      
      export class PlanSearch {
        planCode!: string
        planEffectiveDate!: string
      }