import { CUSTOM_ELEMENTS_SCHEMA, NgModule,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FindmemberComponent } from '../../components/findmember/findmember.component';
import { CheckcoverageComponent } from 'src/app/components/routecontent/checkcoverage/checkcoverage.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgressTabsComponent } from 'src/app/components/progress-tabs/progress-tabs.component';
import { SharedModule } from '../shared/shared.module';
import { FavouritesModule } from '../favourites/favourites/favourites.module';
import { MemberresultComponent } from 'src/app/components/memberresult/memberresult.component';
import { SelectMedicationComponent } from 'src/app/components/select-medication/select-medication.component';
import { VerifyProviderComponent } from 'src/app/components/verify-provider/verify-provider.component';
import { UserProvisioningComponent } from 'src/app/components/user-provisioning/user-provisioning.component';
import { AddUserProvisioningComponent } from 'src/app/components/add-user-provisioning/add-user-provisioning.component';
import { ManageprescribersComponent } from 'src/app/components/manageprescribers/manageprescribers.component';
import { ClaimsummaryComponent } from 'src/app/components/claimsummary/claimsummary.component';
import { MemberSummaryComponent } from 'src/app/components/member-summary/member-summary.component';
import { PaybleClaimSummaryComponent } from 'src/app/components/payble-claim-summary/payble-claim-summary.component';
import { AddPrescribersComponent } from 'src/app/components/add-prescribers/add-prescribers.component';
import { EditproviderComponent } from 'src/app/components/editprovider/editprovider.component';
import { PrescriberprofileComponent } from 'src/app/components/prescriberprofile/prescriberprofile.component';
import { FilterforprescriberModule } from '../filterforprescriber/filterforprescriber.module';
import { ProvisionreportComponent } from 'src/app/components/provisionreport/provisionreport.component';
import { AddPharmacyComponent } from 'src/app/components/add-pharmacy/add-pharmacy.component';
import { SelectPrescriberComponent } from 'src/app/components/select-prescriber/select-prescriber.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


export const coverageRoutes: Routes = [
  {
    path:'', 
    redirectTo:'/PriorAuthChecker/MemberSearch',
    pathMatch:'full'
  },
  {path:'MemberSearch', component: FindmemberComponent, data:{breadcrumb:{label:'Check Coverage: Select member', step:1}},pathMatch:'full'},
  {path:'DrugSearch', component:SelectMedicationComponent, data:{breadcrumb:{label:'Select medication', step:2}}, pathMatch:'full'},
  {path:'VerifyPrescriber', component:VerifyProviderComponent, data:{breadcrumb:{label:'Verify Prescriber', step:3}}, pathMatch:'full'},
  {path:'summary', component:ClaimsummaryComponent, data:{breadcrumb:{label:'Summary Page', step:4}}, pathMatch:'full'}
];

export const coverageRoutes2: Routes = [
  {
    path:'', 
    redirectTo:'/PriorAuthchecker/MemberSearch',
    pathMatch:'full'
  },
  {path:'MemberSearch', component: FindmemberComponent, data:{breadcrumb:{label:'Check Coverage: Select member', step:1}},pathMatch:'full'},
  {path:'DrugSearch', component:SelectMedicationComponent, data:{breadcrumb:{label:'Select medication', step:2}}, pathMatch:'full'},
  {path:'PrescriberSearch',component:SelectPrescriberComponent, data:{breadcrumb:{label:'Select Prescriber', step:3}}, pathMatch:'full'},
  {path:'summary', component:ClaimsummaryComponent, data:{breadcrumb:{label:'Summary Page', step:4}}, pathMatch:'full'}
];

export const manageprescribers: Routes = [
  {
    path:'', 
    redirectTo:'/prescribers/manage',
    pathMatch:'full'
  },
  {path:'manage', component: ManageprescribersComponent, data:{breadcrumb:{label:'Manage prescribers', step:1}},pathMatch:'full'},
  {path:'add', component: AddPrescribersComponent, data:{breadcrumb:{label:'Add prescribers', step:2}},pathMatch:'full'},

];

export const prescriberprofile: Routes = [
  {
    path:'', 
    redirectTo:'/prescriber/profile',
    pathMatch:'full'
  },
  {path:'profile', component: PrescriberprofileComponent, data:{breadcrumb:{label:'Prescriber profile', step:1}},pathMatch:'full'},

];

export const userprovision: Routes = [
  {
    path:'', 
    redirectTo:'/provision/upload',
    pathMatch:'full'
  },
  {path:'upload', component: UserProvisioningComponent, data:{breadcrumb:{label:'User Provisioning', step:1}},pathMatch:'full'},
  {path:'addusers', component:AddUserProvisioningComponent, data:{breadcrumb:{label:'Add Users', step:2}}, pathMatch:'full'},
  {path:'report', component:ProvisionreportComponent, data:{breadcrumb:{label:'Report', step:2}}, pathMatch:'full'},
  {path:'addpharmacy', component:AddPharmacyComponent, data:{breadcrumb:{label:'Add a pharmacy', step:3}}, pathMatch:'full'}
];
@NgModule({
    declarations: [
        CheckcoverageComponent,
        FindmemberComponent,
        ProgressTabsComponent,
        MemberresultComponent,
        SelectMedicationComponent,
        VerifyProviderComponent,
        SelectPrescriberComponent,
        MemberSummaryComponent,
        ClaimsummaryComponent,
        MemberSummaryComponent,
        PaybleClaimSummaryComponent,
        EditproviderComponent,


    ],
    exports: [
        ProgressTabsComponent,
        FindmemberComponent,
        MemberresultComponent,
        SelectMedicationComponent,
        VerifyProviderComponent,
        SelectPrescriberComponent,



    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        RouterModule.forChild(coverageRoutes),
        // DatatableModule,
        SharedModule,
        FavouritesModule,
        FilterforprescriberModule,
        BrowserAnimationsModule

    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA,
       NO_ERRORS_SCHEMA,

    ],
})
export class CheckcoverageModule { }
