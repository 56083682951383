<div>
  <div class="back1">
    <div class="container-fluid back2 ">
      <div class="container-fluid c1">
        <div class="row" style="margin: 0px;">
          <div class="col-2" style="padding-right: 0px;width: auto;">
            <img class="header_pcms_logo responsive font_color" src="assets/images/u407.jpeg" alt="HTML5 Icon">
          </div>
          <div class="col" style="padding: 0px;margin-top: 10px;margin-bottom: 10px">
            <div class="page_header header_font">
              PreCheck MyScript Registration
            </div>
          </div>
        </div>
        <div class="container-fluid c2">
          <div class="welcome_text font_color">
            Welcome to PreCheck MyScript,
          </div>
          <div class="text_2 font_color">
            your formulary alternative solution.
          </div>
          <div class="header_font" style="font-size: 16px;margin-top: 15px;margin-bottom: 15px;">
            Are you supporting a
            <select name="sh" [(ngModel)]="sh" (click)="clearPrescriberPharmacySS()" [disabled]="userDeactivated"
              style="width: 29%; height: 40px;border: 2px solid #626f7d;background-color: #fff;font-size: 16px;"
              (focus)="setBlueBorder()" (blur)="removeBlueBorder()">
              <option [value]="2" default>-Select-</option>
              <option [value]="1">Prescriber</option>
              <option [value]="0">Pharmacy</option>
            </select>
          </div>
          <div *ngIf="sh == 1" class="header_font" style="font-size: 16px;">
            <form [formGroup]="Prescriber_Role">
              <!-- <div class="form-check">
              <input formControlName="prescriberRoleValue" value="1"  class="form-check-input" type="radio" name="prescriberRoleValue" id="flexRadioDefault1">
              <label class="form-check-label" for="flexRadioDefault1">
                I am a prescriber 
              </label>
            </div>
            <div class="form-check">
              <input formControlName="prescriberRoleValue" value="2" class="form-check-input" type="radio" name="prescriberRoleValue" id="flexRadioDefault2" >
              <label class="form-check-label" for="flexRadioDefault2">
                I am a supporting role that completes tasks on behalf of prescriber
              </label>
            </div> -->
              <div class="form-check" style="padding-left: 0%;padding-bottom: 4px;margin: 0;" [ngClass]="{'deact_disable':userDeactivated}">
                <label (click)="filterStatus=1;setPrescriber()" class="radioInput" style="cursor: pointer;" >
                  <input formControlName="prescriberRoleValue" class="disabled" type="radio" id="prescriber_admin" value="1">
                  <i class="fa fa-circle-o" *ngIf="filterStatus!==1" [ngClass]="{'deact_disable':userDeactivated}"></i>
                  <i class="fa fa-dot-circle-o" *ngIf="filterStatus===1" [ngClass]="{'deact_disable':userDeactivated}"></i>
                  <label for="prescriber_admin" style="padding-left: 10px;cursor: pointer;">I am a Prescriber.</label>
                </label>
              </div>
              <div class="form-check" style="padding-left: 0%;padding-top: 4px;margin: 0;" [ngClass]="{'deact_disable':userDeactivated}">
                <label (click)="filterStatus=2; setAdmin()" class="radioInput" style="cursor: pointer;">
                  <input formControlName="prescriberRoleValue" type="radio" id="prescriber_support" value="2" class="disabled">
                  <i class="fa fa-circle-o" *ngIf="filterStatus!==2" [ngClass]="{'deact_disable':userDeactivated}"></i>
                  <i class="fa fa-dot-circle-o" *ngIf="filterStatus===2" [ngClass]="{'deact_disable':userDeactivated}"></i>
                  <label for="prescriber_support" style="padding-left: 10px;cursor: pointer;">I am a supporting role
                    that completes tasks on behalf of Prescriber.</label>
                </label>
              </div>

            </form>
          </div>
          <div *ngIf="sh == 0" class="header_font" name="pharmacyRoleCheck" style="font-size: 16px;">
            <form [formGroup]="Pharmacy_Role">
              <div class="form-check" style="padding-left: 0%;padding-bottom: 4px;margin: 0;">
                <label (click)="filterStatus=3; setPharmacist()" class="radioInput" style="cursor: pointer;">
                  <input formControlName="pharmacyRoleValue" type="radio" id="pharmacy_admin" value="1">
                  <i class="fa fa-circle-o" *ngIf="filterStatus!==3"></i>
                  <i class="fa fa-dot-circle-o" *ngIf="filterStatus===3"></i>
                  <label for="pharmacy_admin" style="padding-left: 10px;cursor: pointer;">I am a Pharmacist</label>
                </label>
              </div>
              <div class="form-check" style="padding-left: 0%;padding-top: 4px;margin: 0;">
                <label (click)="filterStatus=4; setSpecialityPharmacist()" class="radioInput" style="cursor: pointer;">
                  <input formControlName="pharmacyRoleValue" type="radio" id for="pharmacy_support" value="2">
                  <i class="fa fa-circle-o" *ngIf="filterStatus!==4"></i>
                  <i class="fa fa-dot-circle-o" *ngIf="filterStatus===4"></i>
                  <label for="pharmacy_support" style="padding-left: 10px;cursor: pointer;">I am a Specialty Pharmacist</label>
                </label>
              </div>
            </form>
          </div>
        </div>
        <div class="container c3" style="font-size: 16px;">
          <div *ngIf="sh == 1" class="font_bold">
            <div>To begin the registration process,</div>
            <div>you will need:</div>
            <ul style="padding-left: 17px;">
              <li>a prescriber NPI or name.</li>
              <li>access to your office fax machine.</li>
            </ul>
          </div>
          <div *ngIf="sh == 0" class="font_bold">
            <div>To begin the registration process,</div>
            <div>you will need:</div>
            <ul style="padding-left: 17px;">
              <li>a pharmacy name or zip code.</li>
              <li>access to your office fax machine.</li>
            </ul>
          </div>
        </div>
        <!-- <div *ngIf="sh == 1 || selectedTab =='prescriberNPI'" style="display: flex; font-size: 18px;font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;color: #fff;padding-bottom: 10px;padding-left: 18px;">
          Step 1 of 3: Search for prescriber
        </div>
        <div *ngIf="sh == 0" style="display: flex; font-size: 18px;font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;color: #fff;padding-bottom: 10px;padding-left: 18px;">
          Step 1 of 3: Search for pharmacy
        </div> -->
      </div>
      <div class="container-fluid" style="position: absolute;top:0; padding: 0;margin: 0;left: 0; right: 0;">
        <div class="row" style="height: 6px;position: fixed;
      width: 101%;
      z-index: 1002">
          <div class="col-4" style="background-color: rgba(251,176,60,1);"></div>
          <div class="col-8" style="background-color: rgba(254,239,216,1)"></div>
        </div>

        <div style="display:flex;flex-direction:row;justify-content: center;position: relative;top: 8px;"
          *ngIf="showErrorPopup==true">
          <div style="display:flex;color: #c60f13;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px;background-color: #fff4f4;
      width: 76%;flex-direction:row;">

            <i class="fa fa-ban" style="font-size: 25px;padding-right: 10px;"></i>
            <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
        font-size: 16px;flex-grow: 1;">We're unable to complete your request at this time. Please try again
              later.</span>
            <div class="fa fa-remove" style="font-size: 16px;cursor: pointer;" (click)="closeErrorPopup()"></div>
          </div>
        </div>

        <div style="display:flex;flex-direction:row;justify-content: center;position: relative; top: 15px;"
          *ngIf="displayCancelPopup==true" #vanish>
          <div
            style="display:flex;    background-color: #e4f3e4;color: #007a4b;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px; width: 76%;flex-direction:row;">

            <i class="fa fa-check" style="font-size: 25px;padding-right: 10px;"></i>
            <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
          font-size: 16px;flex-grow: 1;">Registration is successfully cancelled</span>
            <div class="fa fa-remove" style="font-size: 16px;" (click)="displayCancelPopup=false"></div>
          </div>
        </div>

        <div style="display:flex;flex-direction:row;justify-content: center;position: relative;top: 8px;"
          *ngIf="roleNotSelected==true">
          <div style="display:flex;color: #c60f13;;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px;background-color: #fff4f4;
            width: 76%;flex-direction:row;">

            <i class="fa fa-ban" style="font-size: 25px;padding-right: 10px;"></i>
            <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
              font-size: 16px;flex-grow: 1;">Please select a role.</span>
            <div class="fa fa-remove" style="font-size: 16px;" (click)="closeRoleNotSelectedPopup()"></div>
          </div>
        </div>

        <!-- <div style="display:flex;flex-direction:row;justify-content: center;" *ngIf="noSearchResult==true;">
              <div style="display:flex;    background-color: #dce9f3;color: #1565c0;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px; width: 76%;flex-direction:row;box-shadow: 0 4px 6px rgba(0,0,0,.2);"  >
               
                <i class="fa fa-info-circle push-left" style="font-size: 25px;vertical-align: middle;display: inline-block;padding-right:10px;" aria-hidden="true"></i>
                <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
                font-size: 16px;flex-grow: 1;">Info: No search results found.</span>
      
                </div>
              </div> -->

        <div style="display:flex;flex-direction:row;justify-content: center;position: relative;top: 8px;"
          *ngIf="noSearchResult==true">
          <div style="display:flex;color: #1565c0;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px;background-color: #dce9f3;;
                width: 76%;flex-direction:row;box-shadow: 0 4px 6px rgba(0,0,0,.2);">

            <i class="fa fa-info-circle push-left"
              style="font-size: 25px;vertical-align: middle;display: inline-block;padding-right:10px;"
              aria-hidden="true"></i>
            <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
                  font-size: 16px;flex-grow: 1;">Info: No search results found.</span>
            <i class="fa fa-remove" aria-hidden="true" style="font-size: 16px;"
              (click)="closeNosearchResultPopup()"></i>
          </div>
        </div>


      </div>
    </div>
    <!-- <div style="position:absolute; display: flex; font-size: 18px;font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;color: #fff;">
  <div *ngIf="sh == 1 || selectedTab =='prescriberNPI'" >
    Step 1 of 3: Search for prescriber
  </div>
  <div *ngIf="sh == 0" >
    Step 1 of 3: Search for pharmacy
  </div>
</div> -->
    <div class="container c4" *ngIf="sh == 1 || sh == 2">
      <div
        style="position:relative;top: -89px;display: flex; font-size: 18px;font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;color: #fff;">
        <div *ngIf="sh == 1 || selectedTab =='prescriberNPI' || selectedTab =='prescriberName'">
          Step 1 of 3: Search for prescriber
        </div>
      </div>

      <ul class="nav nav-tabs nav-justified" role="tablist">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="{'active':selectedTab =='prescriberNPI'}"
            aria-controls="PA_Notifications" href="javascript:void(0)" (click)="showTab('prescriberNPI')"><span
              style="font-size: 16px;">Search by Prescriber NPI</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="{'active':selectedTab =='prescriberName'}"
            aria-controls="PA_History" href="javascript:void(0)" (click)="showTab('prescriberName')"><span
              style="font-size: 16px;">Search by Prescriber Name</span></a>
        </li>
      </ul>

      <div class="tab-content justify-content-center" style="top:-84px; position: relative; border-top: none;"
        id="prescriber_tab_Content">
        <div class="tab-pane fade show active" id="prescriber" *ngIf="selectedTab =='prescriberNPI'">
          <div class="card card_size" style="background-color: #fff!important;
            border-radius: 0 0 2px 2px;
            -moz-box-shadow: 0 4px 6px rgba(0,0,0,.2);
            -webkit-box-shadow: 0 4px 6px rgba(0,0,0,.2);
            box-shadow: 0 4px 6px rgba(0,0,0,.2);
            padding-top: 25px;border-top:  #fff!important">
            <div class="card-body" style="padding-top: 0;padding-bottom: 0px;">
              <div class="card-text text-muted "
                style="color: #626f7d; text-transform: capitalize; margin-bottom: 12px; font-weight: 500;font-size: 16px;">
                Prescriber NPI</div>
              <form [formGroup]="Prescriber_NPI_Form" id="Prescriber_NPI_Form"
                (ngSubmit)=" onSubmitPrescriberNPI(Prescriber_NPI_Form.value)">
                <div class="form-group">
                  <img src="assets/images/u362.png"
                    style="width: 22px;height: 22px;left:27px;top: 71px;position: absolute;pointer-events: none;"
                    alt="HTML5 Icon">
                  <input formControlName="Prescriber_NPI_Field" required type="text" maxlength="10" minlength="10"
                    class="form-control "
                    [ngClass]="{'error_border':((Prescriber_NPI_Form.controls.Prescriber_NPI_Field.errors?.['pattern'] || Prescriber_NPI_Form.controls.Prescriber_NPI_Field.errors?.['minlength']) && show)
                || (submittedNPIForm==true && Prescriber_NPI_Form.controls.Prescriber_NPI_Field.errors?.['required'] && show)}"
                    style="height: 44px;border: 2px solid #626f7d;background-color: #fff;color: #050709;width: 100%;padding: 10px;padding-left:40px;border-radius: 0 0 2px 2px;"
                    (focus)="insideTheBox()" (blur)="outsideTheBox()">
                  <div style="position: relative;height: 35px;align-items: center;display: flex;">
                    <span class="error-msg"
                      *ngIf="(Prescriber_NPI_Form.controls.Prescriber_NPI_Field.errors?.['pattern'] || Prescriber_NPI_Form.controls.Prescriber_NPI_Field.errors?.['minlength']) && show;else invalid1">Please
                      enter a 10-digit number.</span>
                    <ng-template #invalid1>
                      <div
                        *ngIf="submittedNPIForm==true && Prescriber_NPI_Form.controls.Prescriber_NPI_Field.errors?.['required'] && show">
                        <span class="error-msg">Prescriber NPI is required.</span>
                      </div>
                    </ng-template>
                  </div>

                </div>
              </form>
            </div>
            <div class="card-footer" style="border-top: #fff; background-color: rgba(242, 246, 249, 1);padding: 0px;">
              <div class="container c5">
                <div class="cancel">
                  <span class="underline font_bold_no_color" id='underline5' (mouseover)="underline('underline5')"
                    (mouseout)="removeUnderline('underline5')" (click)="openPopup()" style="cursor: pointer;">Cancel
                    registration</span>
                </div>
                <div class=" card-link clear underline font_bold_no_color" href="javascript:void(0)" id='underline6'
                  (mouseover)="underline('underline6')" (mouseout)="removeUnderline('underline6')"
                  (click)="clearClick1()" style="cursor: pointer;">Clear</div>
                <button class=" btn btn-primary search_button font_bold_no_color" type="submit"
                  form="Prescriber_NPI_Form" style="cursor: pointer;">Search</button>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade show active" style="position: relative; border-top: none;" id="profile"
          *ngIf="selectedTab =='prescriberName'">
          <div class="card card_size" style="background-color: #fff!important;
            border-radius: 0 0 2px 2px;
            -moz-box-shadow: 0 4px 6px rgba(0,0,0,.2);
            -webkit-box-shadow: 0 4px 6px rgba(0,0,0,.2);
            box-shadow: 0 4px 6px rgba(0,0,0,.2);
            padding-top: 0;border-top:  #fff!important">
            <div class="card-body" style="padding-top: 0;padding-bottom: 0;">
              <form [formGroup]="Prescriber_Name_Form" id="Prescriber_Name_Form"
                (ngSubmit)="onSubmitPrescriberNPIName(Prescriber_Name_Form.value)">
                <div class="form-group">
                  <div class=" container c6">
                    <div class="item_1">
                      <div class="card-text text-muted "
                        style="color: #626f7d; text-transform: capitalize; margin-bottom: 12px; font-weight: 500;font-size: 16px;">
                        Last Name</div>
                      <input minlength="2" maxlength="15" formControlName="Last_Name" type="text" class="form-control"
                        required
                        style="height: 44px;border: 2px solid #626f7d;background-color: #fff;color: #050709;width: 100%;padding: 10px;border-radius: 0 0 2px 2px;"
                        [ngClass]="{'error_border':(Prescriber_Name_Form.controls.Last_Name.errors?.['pattern'])
                    || (Prescriber_Name_Form.controls.Last_Name.errors?.['minlength'] && show)
                    || ((submittedNPINameForm==true) && Prescriber_Name_Form.controls.Last_Name.errors?.['required'] && show),'error_border_shadow':Prescriber_Name_Form.controls.Last_Name.errors?.['pattern']}"
                        (focus)="insideTheBox()" (blur)="outsideTheBox()">
                      <div style="position: relative;height: 35px;align-items: center;display: flex;">

                        <span *ngIf="Prescriber_Name_Form.controls.Last_Name.errors?.['pattern'];else invalid2"
                          class="error-msg">Invalid character.</span>

                        <ng-template #invalid2>
                          <div
                            *ngIf="Prescriber_Name_Form.controls.Last_Name.errors?.['minlength'] && show;else invalid3">
                            <span class="error-msg">Please enter at least 2 characters.</span>
                          </div>
                        </ng-template>

                        <ng-template #invalid3>
                          <div
                            *ngIf="(submittedNPINameForm==true) && Prescriber_Name_Form.controls.Last_Name.errors?.['required'] && show">
                            <span class="error-msg">Last Name is required.</span>
                          </div>
                        </ng-template>

                      </div>

                    </div>
                    <div class="item_2">
                      <div class="card-text text-muted "
                        style="color: #626f7d;  margin-bottom: 12px; font-weight: 500;font-size: 16px;">First
                        Name(optional)</div>
                      <input class="form-control" maxlength="15" formControlName="First_Name" type="text"
                        style="height: 44px;border: 2px solid #626f7d;background-color: #fff;color: #050709;width: 100%;padding: 10px;border-radius: 0 0 2px 2px;"
                        [ngClass]="{'error_border error_border_shadow':Prescriber_Name_Form.controls.First_Name.errors?.['pattern']}"
                        (focus)="insideTheBox()" (blur)="outsideTheBox()">
                      <div style="position: relative;height: 35px;align-items: center;display: flex;">
                        <span *ngIf="Prescriber_Name_Form.controls.First_Name.errors?.['pattern']"
                          class="error-msg">Invalid character.</span>
                      </div>
                    </div>
                  </div>
                  <div class="container c7">
                    <div class="item_3">
                      <div class="card-text text-muted "
                        style="color: #626f7d; margin-bottom: 12px; font-weight: 500;font-size: 16px;">City(optional)
                      </div>
                      <input maxlength="35" class="form-control" formControlName="City" type="text"
                        style="height: 44px;border: 2px solid #626f7d;background-color: #fff;color: #050709;width: 100%;padding: 10px;border-radius: 0 0 2px 2px;"
                        [ngClass]="{'error_border error_border_shadow':Prescriber_Name_Form.controls.City.errors?.['pattern']}"
                        (focus)="insideTheBox()" (blur)="outsideTheBox()">
                      <div style="position: relative;height: 35px;align-items: center;display: flex;">
                        <span *ngIf="Prescriber_Name_Form.controls.City.errors?.['pattern']" class="error-msg">Invalid
                          character.</span>
                      </div>
                    </div>
                    <div class="item_4">
                      <div class="card-text text-muted "
                        style="color: #626f7d; text-transform: capitalize; margin-bottom: 12px; font-weight: 500;font-size: 16px;">
                        State</div>
                      <select name="state" formControlName="State" required
                        style="width:100%;height: 44px;border: 2px solid #626f7d;background-color: #fff;border-radius: 0 0 2px 2px;font-size: 16px;"
                        [ngClass]="{'error_border':(submittedNPINameForm==true) && Prescriber_Name_Form.value.State=='select'}">
                        <option value="select">Select</option>
                        <option value="AL">AL</option>
                        <option value="AK">AK</option>
                        <option value="AZ">AZ</option>
                        <option value="AR">AR</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DE">DE</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="GA">HI</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="IA">IA</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="ME">ME</option>
                        <option value="MD">MD</option>
                        <option value="MA">MA</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MS">MS</option>
                        <option value="MO">MO</option>
                        <option value="MT">MT</option>
                        <option value="NE">NE</option>
                        <option value="NV">NV</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NY">NY</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VT">VT</option>
                        <option value="VA">VA</option>
                        <option value="WA">WA</option>
                        <option value="WV">WV</option>
                        <option value="WI">WI</option>
                        <option value="WY">WY</option>
                      </select>
                      <div style="position: relative;height: 35px;align-items: center;display: flex;">
                        <div *ngIf="(submittedNPINameForm==true) && Prescriber_Name_Form.value.State=='select'">
                          <span class="error-msg">State is required.</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>
            <div class="card-footer" style="border-top: #fff; background-color: rgba(242, 246, 249, 1);padding: 0px;">
              <div class="container c5">
                <div class="cancel">
                  <span class="underline font_bold_no_color" id='underline3' (mouseover)="underline('underline3')"
                    (mouseout)="removeUnderline('underline3')" (click)="openPopup()" style="cursor: pointer;">Cancel
                    registration</span>
                </div>
                <div class=" card-link clear underline font_bold_no_color" href="javascript:void(0)" id='underline4'
                  (mouseover)="underline('underline4')" (mouseout)="removeUnderline('underline4')"
                  (click)="clearClick2()" style="cursor: pointer;">Clear</div>
                <button class=" btn btn-primary search_button font_bold_no_color" type="submit"
                  form="Prescriber_Name_Form" style="cursor: pointer;">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--datapoulation code under npi id -->
      <div style="position: relative;top:-84px;">
        <div *ngIf="prescriberList.length>0">
          <div class="container dataTable" style="display: flex;flex-direction: row; padding-top: 30px;">
            <div class="listLength" style="font-weight: 200;font-size: 48px;font-style: normal;color: #424249;font-family: Frutiger-Light,Helvetica,Arial,sans-serif;
      padding: 0;">{{prescriberList.length}}</div>
            <div class=" container foundAndshowing"
              style="display: flex;flex-direction: column;padding-left: 10px;padding-right: 0%;">
              <div class="container found"
                style="display:flex;padding-left: 0%;padding-right: 0%;padding-top: 15px;flex-direction: row;">
                <div
                  style="line-height: 20px;font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;font-weight: 400;font-size: 18px;color: #555559;padding-right: 20px;">
                  Prescriber found
                </div>
                <hr class="hidden-xs"
                  style="margin-top: 10px;margin-bottom: 10px;margin-right: 19px;border-color: rgb(117, 116, 116);flex-grow: 1;">

              </div>

              <div class="showing"
                style="padding-top: 5px;color: #626f7d!important;font-size: 14px;line-height: 21px;word-wrap: break-word;font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;font-weight: 400;font-style: normal;text-align: left;">
                <div *ngIf="prescriberList.length>10">
                  Showing
                  {{((currentPageNumber-1)*recordsPerPage)+1}} of {{currentPageNumber * recordsPerPage}} presriber
                </div>
                <div *ngIf="prescriberList.length<10">
                  Showing
                  {{((currentPageNumber-1)*recordsPerPage)+1}} of {{prescriberList.length}} presriber
                </div>
              </div>
            </div>
          </div>
        </div>



        <datatable *ngIf="prescriberList.length>0" [headers]="PrescribersHeaders" [rows]="prescriberSearchHistory"
          [type]="datatableType" (onHeaderClicked)="onPrescriberHeaderClick($event)"
          (onCellClicked)="onPrescriberCellClick($event)" aria-labelledby="prescriberResults">
        </datatable>

        <div class="container-fluid" *ngIf="totalEntries>1">
          <div class="row">
            <div class="col text-center">
              <pagination aria-label="Prescriberresults pagination" [totalPages]="totalPagesCount"
                (onChangeEntriesPerPage)="onPrescriberChangeEntriesPerPage($event)"
                (onPageChange)="onPrescriberPageChange($event)" 
                style="display: flex;justify-content: center;"></pagination>

            </div>
          </div>
        </div>

        <div class="col"
          style="margin-top: 15px;margin-bottom: 15px;padding: 35px;background-color:#d4d1d1;font-family: Frutiger-Light,Helvetica,Arial,sans-serif;font-size: 24px;color: #565555;vertical-align: middle;text-align: center;"
          *ngIf="totalPagesCount==0 && (submittedNPINameForm==true || submittedNPIForm==true) && (Prescriber_NPI_Form.valid || Prescriber_Name_Form.valid)">
          No search results found.
        </div>
      </div>



    </div>


    <div class="container c4" *ngIf="sh == 0">
      <!-- <ul class="nav nav-tabs search" id="pharmacy_tab" role="tablist" >
      <li class="nav-link" style="cursor: pointer;">Search by Pharmacy Name
      </li>
    </ul> -->

      <div
        style="position:relative;top: -89px;display: flex; font-size: 18px;font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;color: #fff;">

        Step 1 of 3: Search for pharmacy

      </div>

      <div style="display: flex;">
        <div style="width: 50%">
          <ul class="nav nav-tabs nav-justified" role="tablist">
            <li class="nav-item">
              <a class="nav-link text-center" [ngClass]="{'active':sh == 0}" href="javascript:void(0)"> <span
                  style="font-size: 16px;line-height:48px;">Search by Pharmacy Name</span></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" style="top:-84px; position: relative; border-top: none;" id="myTabContent">
        <div class="tab-pane fade show active" id="pharmacy" role="tabpanel" aria-labelledby="pharmacy_name_tab">
          <div class="card card_size" style="background-color: #fff!important;
        border-radius: 0 0 2px 2px;
        box-shadow: 0 4px 6px rgba(0,0,0,.2);
        padding-top: 25px;border-top:  #fff!important">
            <div class="card-body pharmacy_card_body">
              <form [formGroup]="Pharmacy_Form" id="Pharmacy_Form" (ngSubmit)="onSubmitPharmacy(Pharmacy_Form.value)">
                <div class="form-group form_width" style="display: flex; flex-direction: column;">
                  <div class="c8">
                    <div class="card-text text-muted"
                      style="color: #626f7d; margin-bottom: 12px; font-weight: 500;font-size :16px;">Pharmacy Name
                      (optional)</div>
                    <input type="text" formControlName="Pharmacy_Name" class="form-control" minlength="3" maxlength="35"
                      (focus)="insideTheBox()" (blur)="outsideTheBox()"
                      [ngClass]="{'error_border':Pharmacy_Form.controls.Pharmacy_Name.errors?.['minlength'] && show}"
                      style="height:44px;border:2px solid #626f7d;background-color:#fff;color: #050709;width: 100%;padding: 10px;border-radius: 0 0 2px 2px;">
                    <div *ngIf="Pharmacy_Form.controls.Pharmacy_Name.errors?.['minlength'] && show"
                      style="position: relative;padding-top: 10px;padding-bottom: 10px ;align-items: center;display: flex;">
                      <span class="error-msg">Please enter at least 3 characters.</span>
                    </div>
                  </div>
                  <div class="container c6">
                    <div class="item_5">
                      <p
                        style="color: #626f7d; text-transform: capitalize; margin-bottom: 12px; font-weight: 500;font-size :16px;">
                        ZIP Code</p>
                      <input type="text" formControlName="Zip_Code" class="form-control" minlength="5" maxlength="5"
                        required
                        style="height:44px;border:2px solid #626f7d;background-color:#fff;color: #050709;width: 100%;padding: 10px;border-radius: 0 0 2px 2px;"
                        (focus)="insideTheBox()" (blur)="outsideTheBox()" [ngClass]="{'error_border':(Pharmacy_Form.controls.Zip_Code.errors?.['pattern'])
                ||(Pharmacy_Form.controls.Zip_Code.errors?.['minlength'] && show)
                ||(((submittedPharmacyForm==true && Pharmacy_Form.controls.Zip_Code.errors?.['required'])||(Pharmacy_Form.controls.Zip_Code.touched && Pharmacy_Form.controls.Zip_Code.errors?.['required'])) && show)
                ,'error_border_shadow':Pharmacy_Form.controls.Zip_Code.errors?.['pattern']}">
                      <div
                        style="position: relative;padding-top: 10px;padding-bottom: 10px ;align-items: center;display: flex;">

                        <span *ngIf="Pharmacy_Form.controls.Zip_Code.errors?.['pattern'];else invalid4"
                          class="error-msg">Invalid character.</span>
                        <ng-template #invalid4>
                          <span class="error-msg"
                            *ngIf="Pharmacy_Form.controls.Zip_Code.errors?.['minlength'] && show;else invalid5">Please
                            enter a valid ZIP code.</span>
                        </ng-template>
                        <ng-template #invalid5>
                          <span class="error-msg"
                            *ngIf="((submittedPharmacyForm==true && Pharmacy_Form.controls.Zip_Code.errors?.['required'])||(Pharmacy_Form.controls.Zip_Code.touched && Pharmacy_Form.controls.Zip_Code.errors?.['required'])) && show">Please
                            enter a valid ZIP code.</span>
                        </ng-template>
                        <!-- <ng-template #invalid6>
                  <small class="error-msg" [class.d-none]="Pharmacy_Form.controls.Zip_Code.valid || Pharmacy_Form.controls.Zip_Code.untouched" *ngIf="show">Please enter a valid ZIP code.</small>
                </ng-template> -->

                        <!-- <ng-template #invalid6>
                  <small class="error-msg" *ngIf="Pharmacy_Form.controls.Zip_Code.touched && Pharmacy_Form.controls.Zip_Code.errors?.['required'] && show">Please enter a valid ZIP code.</small>
                </ng-template> -->

                      </div>
                    </div>
                    <div class="item_6">
                      <p
                        style="color: #626f7d; text-transform: capitalize; margin-bottom: 12px; font-weight: 500;font-size :16px;">
                        Distance</p>
                      <select name="distance" formControlName="Distance"
                        style="width: 100%; height: 44px;border: 2px solid #626f7d;background-color: #fff;font-size: 16px;">
                        <option value="1">1 mile</option>
                        <option value="5">5 miles</option>
                        <option value="10">10 miles</option>
                        <option value="20">20 miles</option>
                        <option value="25">25 miles</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- <div class="card-footer" style="border-top: #fff; background-color: rgba(242, 246, 249, 1);padding: 0px;">  
            <div class="container c5">
              <div class=" card-link cancel underline" href="javascript:void" id='underline1' (mouseover)="underline('underline1')" (mouseout)="removeUnderline('underline1')" (click)="openPopup()">Cancel registration</div>
              <div class=" card-link clear underline" href="javascript:void" id='underline2' (click)="clearClick3()"  (mouseover)="underline('underline2')" (mouseout)="removeUnderline('underline2')">Clear</div>
              <button class=" btn btn-primary search_button" type="submit" form="Pharmacy_Form" style="cursor: pointer;">Search</button>
            </div>  
          </div> -->
            <div class="card-footer" style="border-top: #fff; background-color: rgba(242, 246, 249, 1);padding: 0px;">
              <div class="container c5">
                <div class="cancel">
                  <span class="underline font_bold_no_color" id='underline1' (mouseover)="underline('underline1')"
                    (mouseout)="removeUnderline('underline1')" (click)="openPopup()" style="cursor: pointer;">Cancel
                    registration</span>
                </div>
                <div class=" card-link clear underline font_bold_no_color" href="javascript:void(0)" id='underline2'
                  (mouseover)="underline('underline2')" (mouseout)="removeUnderline('underline2')"
                  (click)="clearClick3()" style="cursor: pointer;">Clear</div>
                <button class=" btn btn-primary search_button font_bold_no_color" type="submit" form="Pharmacy_Form"
                  style="cursor: pointer;">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--datapoulation code under npi id -->
      <div style="position: relative;top:-84px;">
        <div *ngIf="pharmacyList.length>0">
          <div class="container dataTable" style="display: flex;flex-direction: row; padding-top: 30px;">
            <div class="listLength" style="font-weight: 200;font-size: 48px;font-style: normal;color: #424249;font-family: Frutiger-Light,Helvetica,Arial,sans-serif;
      padding: 0;">{{pharmacyList.length}}</div>
            <div class=" container foundAndshowing"
              style="display: flex;flex-direction: column;padding-left: 10px;padding-right: 0%;">
              <div class="container found"
                style="display:flex;padding-left: 0%;padding-right: 0%;padding-top: 15px;flex-direction: row;">
                <div
                  style="line-height: 20px;font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;font-weight: 400;font-size: 18px;color: #555559;padding-right: 20px;">
                  Pharmacy found
                </div>
                <hr class="hidden-xs"
                  style="margin-top: 10px;margin-bottom: 10px;margin-right: 19px;border-color: rgb(117, 116, 116);flex-grow: 1;">

              </div>

              <div class="showing"
                style="padding-top: 5px;color: #626f7d!important;font-size: 14px;line-height: 21px;word-wrap: break-word;font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;font-weight: 400;font-style: normal;text-align: left;">
                <div *ngIf="pharmacyList.length>10">
                  Showing
                  {{((currentPageNumber-1)*recordsPerPage)+1}} of {{currentPageNumber * recordsPerPage}} pharmacy
                </div>
                <div *ngIf="pharmacyList.length<10">
                  Showing
                  {{((currentPageNumber-1)*recordsPerPage)+1}} of {{pharmacyList.length}} pharmacy
                </div>
              </div>
            </div>
          </div>
        </div>

        <datatable *ngIf="pharmacyList.length>0" [headers]="PharmacyHeaders" [rows]="pharmacySearchHistory"
          [type]="datatableType" (onHeaderClicked)="onPharmacyHeaderClick($event)"
          (onCellClicked)="onPharmacyCellClick($event)" aria-labelledby="pharmacyResults">
        </datatable>

        <div class="container-fluid" *ngIf="totalEntries>1">
          <div class="row">
            <div class="col text-center">
              <pagination aria-label="Pharmacyresults pagination" [totalPages]="totalPagesCount"
                (onPageChange)="onPharmacyPageChange($event)" style="display: flex;justify-content: center;"></pagination>

            </div>
          </div>
        </div>


        <div class="col"
          style="margin-top: 15px;margin-bottom: 15px;padding: 35px;background-color:#d4d1d1;font-family: Frutiger-Light,Helvetica,Arial,sans-serif;font-size: 24px;color: #565555;vertical-align: middle;text-align: center;"
          *ngIf="totalPagesCount==0 && (submittedPharmacyForm==true) && (Pharmacy_Form.valid)">
          No search results found.
        </div>
      </div>


    </div>

    <!-- <div id="footer " class="container-fluid" style=" padding-top: 30px;"  >
    <div class="row " style=" background-color: #424249;">
      <div class="col-md-12 text-center" style="padding-right: 15px;
      padding-left: 15px;padding-top: 15px;">
        <p style="color: white">© 2023 Optum, Inc. All rights reserved
        <span> | </span>
        <span href="">Privacy Policy</span>
        <span> | </span>
        <span href="">Terms of Use</span>
        <span> | </span>
        <span href="">Terms of Use</span>
      </p>
      </div>
    </div>
    </div> -->






    <div class="responsive_image" style="top: 40px;right:11%;position: absolute;"><img
        style="width: 465px;height: 430px;" src="assets/images/u1.jpeg"></div>
  </div>
</div>

<ng-template #NameTemplate let-data="data">
  <div style="padding:10px 20px" aria-labelledby="NameTemplate">
    <p class="large">
      <span class="fontbold">{{data.lastName}}</span>
      <br />
      {{data.firstName}}
    </p>
  </div>
</ng-template>
<ng-template #AddressTemplate let-data="data">
  <div style="padding:10px 20px;text-transform: capitalize;" aria-labelledby="AddressTemplate">
    <p class="large">
      <span>{{data.address.address1}}</span>
      <br />
      {{data.address.city}},{{data.address.state}}&nbsp;{{data.address.zip1}}
    </p>
  </div>
</ng-template>
<ng-template #NPITemplate let-data="data">
  <div style="padding:10px 20px;" aria-labelledby="NPITemplate">
    <p class="large" aria-labelledby="NPITemplate">{{data.identifiers[0].id}}</p>
  </div>
</ng-template>
<ng-template #actionPrescriberSearchSelect let-data="data">
  <div style="padding:10px 20px" aria-labelledby="actionPrescriberSearchSelect">
    <div style="text-align: center;">
      <a class="text_underline" href="javascript:void(0)"
        style="font-size:16px !important;cursor: pointer;color: #0470c5 !important;">Select</a>
      <i class="fa fa-angle-right" aria-hidden="true"></i>
    </div>
  </div>
</ng-template>





<ng-template #PharmacyNameTemplate let-data="data">
  <div style="text-transform: capitalize;padding: 10px 20px;" aria-labelledby="PharmacyNameTemplate">
    <p class="large">
      <span class="fontbold">{{data.pharmacyName}}</span>
    </p>
  </div>
</ng-template>

<ng-template #PharmacyAddressTemplate let-data="data">
  <div style="text-transform: capitalize;padding: 10px 20px;" aria-labelledby="PharmacyAddressTemplate">
    <p class="large">
      <span>{{data.physicalLocation.address1}}&nbsp;{{data.physicalLocation.address2}}
        <br />
        {{data.physicalLocation.city}}&nbsp;{{data.physicalLocation.state}}&nbsp;{{data.physicalLocation.zip1}}</span>
    </p>
  </div>
</ng-template>

<ng-template #DistanceTemplate let-data="data">
  <div style="padding: 10px 20px;" aria-labelledby="DistanceTemplate">
    <p class="large" *ngIf="data.physicalLocation.distance < 1"> &lt;1 mi</p>
      <p class="large" *ngIf="data.physicalLocation.distance >= 1">{{data.physicalLocation.distance | number:'1.2-2'}} mi</p>
  </div>
</ng-template>

<ng-template #actionPharmacySearchSelect let-data="data">
  <div style="text-transform: capitalize;padding: 10px 20px;" aria-labelledby="actionPharmacySearchSelect">
    <div style="text-align: center;">
      <a class="text_underline" href="javascript:void(0)"
        style="font-size:16px !important;cursor: pointer;color: #0470c5 !important;">Select</a>
      <i class="fa fa-angle-right" aria-hidden="true"></i>
    </div>
  </div>
</ng-template>


<div class="popup-greybackground" *ngIf="showPopup"></div>

<div class="popup-container" *ngIf="showPopup">
  <div class="popup">
    <div style="display:flex;flex-direction:row;justify-content: center;"><i class="fa fa-exclamation-circle"
        aria-hidden="true"></i></div>
    <div class="popup-text">
      Are you sure you want to cancel this registration request?
      <br />
      You will not be able to access it after this point.
    </div>
    <div style="display:flex;flex-direction:row;justify-content: center;">
      <button class="btn" (click)="onNoClick()" style="border-radius: 2px;border: 2px solid #0470c5;font-size: 16px; color: #0470c5!important;background-color: transparent;font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
    margin: 0 10px;
    padding: 10px 20px;
    width: 75px;
    height: 45px;
    ">
        No
      </button>
      <button class="btn btn-primary" (click)="onYesClick()" style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;border-radius: 2px;background: #0470c5;font-size: 16px;color: #fff;    margin: 0 10px;
    padding: 10px 20px;width: 75px;height: 45px;">
        Yes
      </button>
    </div>
  </div>
</div>

<!-- <a routerLink="/register/steptwo" routerLinkActive="active" ariaCurrentWhenActive="page">Second Component</a> -->