import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit{

  @Input()
   totalPages!:number;
  
   private _currentPageNumber : number=1;
   public get currentPageNumber() : number {
     return this._currentPageNumber;
   }
   @Input()
   public set currentPageNumber(v : number) {
     if(v<=this.totalPages){
       this._currentPageNumber = v;
       this.onPageChange.emit(v);
     }
   }
  
   @Output()
   onPageChange: EventEmitter<number>= new EventEmitter<number>();
  
   show:boolean=true;
  
   constructor() { }
  
   ngOnInit(): void {
     this.checkRequiredFields([this.totalPages]);
   }
  
   counter() {
     return new Array(this.totalPages);
   }
  
   private checkRequiredFields(inputs:any[]) {    
     inputs.forEach( (element:any)=>{
       if(element === null || element === undefined){
         this.show=false;
       }
     });
     if(!this.show){
        throw new Error("One of the madatory input property in the list [totalPages] on pagination component is missing.");
     }
   }
}
