export class SteponePrescriberDetailsResponse {
    response !: Response
    status !: Status
  }
  
  export interface Response {
    prescriberDetailItem: PrescriberDetailItem
  }
  
  export interface PrescriberDetailItem {
    identifiers: Identifier[]
    lastName: string
    firstName: string
    middleInitial: string
    primaryDegree: string
    address: Address
    contact: Contact
    alternateAddresses: AlternateAddress[]
    network: Network[]
    specialty: Specialty[]
    audit: Audit
  }
  
  export interface Identifier {
    id: string
    qualifier: string
    state: string
    renewalDate: string
    expiredDate: string
  }
  
  export interface Address {
    address1: string
    address2: string
    city: string
    state: string
    zip1: string
    zip2: string
    zip3: string
    country: string
  }
  
  export interface Contact {
    phone1: string
    phone1Ext: string
    phone2: string
    phone2Ext: string
    fax: string
    faxExt: string
    email: string
  }
  
  export interface AlternateAddress {
    qualifier: string
    address: Address
    contact: Contact
    audit: Audit
  }  
  export interface Audit {
    addUser: string
    addDate: string
    addTime: string
    changeUser: string
    changeDate: string
    changeTime: string
  }
  
  export interface Network {
    id: string
    name: string
    activeIndicator: string
    contractPeriod: ContractPeriod
  }
  
  export interface ContractPeriod {
    fromDate: string
    thruDate: string
  }
  
  export interface Specialty {
    code: string
    name: string
    audit: Audit
  }
  

  
  export interface Status {
    statusCode: string
    respCode: string
    respMessage: string
  }
  