<div class="container-fluid bg-white content-card">
    <div class="row">
        <div class="col card-header">
            <h3 class="float-start">Saved Members</h3>
            <button class="btn invisiblebtn float-end" (click)="dismiss()">
                <i class="fa fa-close"></i>
            </button>
        </div>
    </div>
    <div class="row" *ngIf="favouriteActionError==1">
        <div class="col nopadding">
            <div class="alert alert-danger">
                We were unable to retrieve this information
                <a class="link-danger float-end" enableAction="true" (click)="fetchSavedMembers()" tabindex="0">Try again</a>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="favouriteActionError==2">
        <div class="col nopadding">
            <div class="alert alert-danger">
                An error occured. Please try again later.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <datatable [headers]="headers" [rows]="entries"  (onHeaderClicked)="onHeaderClicked($event)" (onCellClicked)="select($event)">
            </datatable>
            <!-- <div class="label" *ngIf="this.entries.length==0">
                <h6>{{this.message}}</h6>
            </div> -->
        </div>
    </div>
    <div class="row" *ngIf="totalPagesCount>1">
        <div class="col text-center">
            <pagination [totalPages]="totalPagesCount" (onPageChange)="onPageChange($event)"></pagination>
        </div>
    </div>
</div>


<ng-template #memberNameTemplate let-data="data">
    <div *ngIf="data.isMedD" class="medicareInd">Medicare</div>
    <div class="memberData">
        <span [innerHtml]="data.lastName" class="text-capitalize fontbold"></span>
        <br/>
        <span [innerHtml]="data.firstName" class="text-capitalize"></span>
    </div>
</ng-template>

<ng-template #memberAddressTemplate let-data="data">
    <div class="memberData">
        {{data.address1}}&nbsp;{{data.address2}}
    </div>
</ng-template>

<ng-template #memberIdTemplate let-data="data">
    <div class="memberData">
        {{data.memberId}}
    </div>
</ng-template>

<ng-template #memberActionTemplate let-data="data">
    <div class="memberData">
        <a class="btn btn-link invisiblebtn text-danger" enableAction="true" tabindex="0">Remove</a>
    </div>
</ng-template>
<ng-template #memberSelectSymbolTemplate let-data="data">
    <a class="btn invisiblebtn btn-link">
        <i class="fa fa-angle-right"></i>
    </a>
</ng-template>

<ng-template #noSavedMembersTemplate let-data="data">
    <p class="large">No saved members.</p>
</ng-template>


<div class="modal-body" *ngIf="this.members.length==0">
    <h6 class="mt-3 mb-3">{{this.message}}</h6>
</div>