import { Component, OnInit } from '@angular/core';

import { ProfileService, Features } from 'src/app/services/profile/profile.service';
import { USER_ROLE } from 'src/app/constants/appconstants';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'globalmenu',
  templateUrl: './globalmenu.component.html',
  styleUrls: ['./globalmenu.component.css']
})
export class GlobalmenuComponent {
  blnOpened:boolean=false;
  blnMenuRequired:boolean=true;
  features!:Features;
  constructor(private profile:ProfileService,private loggerService:LoggerService,protected router:Router){
    this.features = profile.features;
    this.blnMenuRequired = profile.USER_ROLE == USER_ROLE.ADVISOR?false:true;
  }

  goto(routeUrl:string){
    debugger;
    this.blnOpened=false;
    this.loggerService.userAction("Going to "+routeUrl);
    this.router.navigateByUrl("/"+routeUrl);
  }

  // goToContactusLink(url: string) {
  //   this.blnOpened=false;
  //   this.loggerService.userAction("Going to "+url);
  //   window.open(url, "_blank");
  // }
}
