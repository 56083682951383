import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { response } from 'express';
import { SavedPARequest } from 'src/app/modals/epa/priorauthrequest';
import { SavedPAResponse } from 'src/app/modals/epa/priorauthresponse';
import { QuestionsetViewmodal } from 'src/app/modals/epa/questionsetviewmodal';
import { updateNotificationStatusRequest } from 'src/app/modals/viewallnotificationscalls/updateNotificationStatusRequest';
import { updateNotificationStatusResponse } from 'src/app/modals/viewallnotificationscalls/updateNotificationStatusResponse';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { NotificationsService } from 'src/app/services/viewAllNotificationsServices/notifications.service';

@Component({
  selector: 'viewalldatatable',
  templateUrl: './viewalldatatable.component.html',
  styleUrls: ['./viewalldatatable.component.css']
})
export class ViewalldatatableComponent implements OnInit {
  constructor(private notificaionsService: NotificationsService, private profileservice: ProfileService, private epaservice: EpaprocessorService, private router: Router,  private modalService: NgbModal,) { }
  @Input() tabledata!: any;
  @Input() action!: any;

  @ViewChild("errorsavedpa", { static: false })
  errorsavedpaErrorElementRef?: ElementRef<HTMLElement>;

  @Output() updateondelete = new EventEmitter<string>();
  @Output() viewPa = new EventEmitter<any>();


  // tabledata=this.notificationsData;
  showPopup: boolean = false;
  dontSHowAgain: boolean = true;
  deleteId: string = '';
  showErrorPopup: boolean = false
  isChecked: boolean = false;
  recentsavedPaID!:number;
  recentsavedPanotificationId!:number;

  ngOnInit(): void {
    var temp = sessionStorage.getItem('notificationModalStatus')
    if (temp == 'true') {
      this.dontSHowAgain = false;
    }
    console.log(this.tabledata);
  }

  underline(elementID: string) {
    const div = document.getElementById(elementID);
    div?.classList.add('underlined');
  }

  removeUnderline(elementID: string) {
    const div = document.getElementById(elementID);
    div?.classList.remove('underlined');
  }
  onViewPriorAuthclick(data: any) {
    debugger;
    this.viewPa.emit(data);
  }

  onDeleteclick(elementID: string) {

    this.showPopup = true;
    this.deleteId = elementID;
    if (this.dontSHowAgain == false) {
      this.onYesClick();
    }
  }

  onYesClick() {

    var apiparams = new updateNotificationStatusRequest();
    apiparams.notificationId = parseInt(this.deleteId);
    if (this.action == 'permanent') {
      apiparams.action = 13;
    }
    else if (this.action == 'delete') {
      apiparams.action = 11;
    }

    this.notificaionsService.postUpdateNotfication(apiparams).then((data: updateNotificationStatusResponse) => {

      if (data.status.statusCode == 'Success') {
        this.updateNotificationRequest();
      }
      else {
        this.showPopup = false
        this.showErrorPopup = true;
      }
    });
    if (this.isChecked == true && this.dontSHowAgain == true) {
      sessionStorage.setItem('notificationModalStatus', 'true')
      this.dontSHowAgain = false;
    }

  }
  updateNotificationRequest() {

    this.showPopup = false
    this.showErrorPopup = false;
    console.log(this.tabledata);
    var notificationArray: Array<any> = this.tabledata;
    this.tabledata = undefined;
    for (let i = 0; i < notificationArray.length; i++) {
      if (notificationArray[i].notificationId == this.deleteId) {
        notificationArray.splice(i, 1);
        i = i - 1;
      }
    }
    debugger
    console.log(notificationArray);
    this.updateondelete.emit('true');
    this.tabledata = notificationArray
    this.deleteId = '';
  }
  onNoClick() {
    this.showPopup = false;
    this.deleteId = '';
    this.showErrorPopup = false;
    if (this.isChecked == true) {
      sessionStorage.setItem('notificationModalStatus', 'true')
      this.dontSHowAgain = false;
    }
  }
  resumeSavedPA(referenceId: number,notificationId:number) {
    debugger;
    this.recentsavedPaID=referenceId;
    this.recentsavedPanotificationId
    let uuid = '' + this.profileservice.loginuser?.prescriber?.uuid;
    this.epaservice.resumeNtfsSavedPA(this.recentsavedPaID, uuid,notificationId).then((response: SavedPAResponse) => {
      debugger;
      const epaQuestions: QuestionsetViewmodal = this.epaservice.extactQuestionSetModalFromSavedPA(response.paInitiationResponseWeb.body, response.paResumeDetails);
      this.epaservice.storeQuestionSetViewModal(epaQuestions);
      this.router.navigateByUrl("/ePA/ePAquestions");
      sessionStorage.setItem('routedfrom','notifications');
      sessionStorage.setItem('disableanchortag','true');
    }, (error: any) => {
      this.modalService.open(this.errorsavedpaErrorElementRef, { modalDialogClass: 'errorsavedpa', size: 'xs',backdrop:'static' });
    }).catch((reason: any) => {
      this.modalService.open(this.errorsavedpaErrorElementRef, { modalDialogClass: 'errorsavedpa', size: 'xs',backdrop:'static' });
    });
  }
  rerunsavedPA(){
    debugger;
    this.resumeSavedPA(this.recentsavedPaID,this.recentsavedPanotificationId)
  }

}

