export class SteponeInitiationRequest {
    presQualTypeID !: string;
    optumID !: string;
    linkReqID !: string;
    npi !: string;
    uuid !: string;
    instcID !: string ; 
    reActivateInd !: string;
    presbrId !: string;
    role !: number;
  }

