<span class="pull-right" style="display:inline-block;width:100%;margin-top: 5px;min-width: 350px;">
    <div class="paragraph apply push-left text-left" style="color:#626F7D;" *ngIf="this.presLabel==false">Selected
        Prescriber </div>
        <div class="form-label fontbold" *ngIf="this.presLabel==true"> Prescriber <br/></div>
    <input #inputField type="text"
        style="border: 2px solid #626f7d !important;border-radius: 0px;height: 44px;margin-top: 5px;width: 100%;font-size: 16px;padding-left: 5px;cursor: pointer;"
        [(ngModel)]="searchQuery" (focus)="showDropdown()" (blur)="hideDropdown()" (input)="searchNames()"
        placeholder="Select a prescriber" />
    <i *ngIf="!dropdownVisible" class="caret pull-right caretposition" (click)="selectText()"></i>
    <div *ngIf="dropdownVisible"
        style="padding: 10px 15px 0px;position: absolute;z-index: 1;background-color: #fff;max-width: 350px;border: 1px solid rgba(0, 0, 0, .15);border-radius: 4px;">
        <ul>
            <li class="dropdown-list" [ngClass]="{'selectedBack':selectedName==viewallprescribers}"
                *ngIf="!searchQuery || viewallprescribers.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())"
                ngClass="'Selected-blue':" (pointerdown)="selectAllName()">
                <span [innerHTML]="highlightMatch(viewallprescribers)"></span>
            </li>
            <li *ngIf="filteredNames.length != 0"
                style="font-size: 16px; font-family: Frutiger-Roman, Helvetica, Arial, sans-serif;color: #333;padding-top: 10px;">
                <span style="font-size: 14px;color: #626f7d !important;padding: 0px 16px 5px !important;">All of Your
                    Prescribers</span>

                <div class="dropdown-list" [ngClass]="{'selectedBack':selectedName==name}"
                    *ngFor="let name of filteredNames" (pointerdown)="selectName(name)">
                    <span [innerHTML]="highlightMatch(name)"></span>
                </div>
            </li>

        </ul>

    </div>
    <div style="padding:12px 16px;    color: #050709;
        font-family: Frutiger-Roman, Helvetica, Arial, sans-serif;
        font-size: 16px;width: 100%;;max-width:350px;position: absolute;z-index: 10000;background-color: #fff;max-width: 350px;border: 1px solid rgba(0, 0, 0, .15);border-radius: 4px;"
        *ngIf="dropdownVisible && searchQuery && filteredNames.length === 0 && !viewallprescribers.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())">
        No results found</div>
    <!-- ngIf="dropdownVisible" -->
</span>