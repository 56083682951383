import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_ROLE } from 'src/app/constants/appconstants';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'specialty-pharmacyhome',
  templateUrl: './specialty-pharmacyhome.component.html',
  styleUrls: ['./specialty-pharmacyhome.component.css']
})
export class SpecialtyPharmacyhomeComponent implements OnInit{
  username!:string;
  userrole!:USER_ROLE;
  recentSearchSession:any = undefined;
  recentSearchDrugSession:any = undefined;

  constructor(private logger: LoggerService,private titleService:Title,private epaProcessor:EpaprocessorService,private modalService: NgbModal,private profileService:ProfileService,private memberSearchHelper: MembersearchService,private loggerService:LoggerService,protected router:Router){
    this.username="";
    if(this.profileService.loginuser!=undefined){
      this.username = this.profileService.loginuser.username.replace(',',"");
      this.userrole = this.profileService.USER_ROLE;
      console.log(this.userrole);
    }
    //for member search and drug search recent search session
    if(sessionStorage.getItem("recentMemberSearches")!=undefined)
      this.recentSearchSession= JSON.parse(""+sessionStorage.getItem("recentMemberSearches"));
      if(sessionStorage.getItem("recentDrugSearches")!=undefined)
      this.recentSearchDrugSession= JSON.parse(""+sessionStorage.getItem("recentDrugSearches"));
      sessionStorage.clear();
      if(this.recentSearchSession!=undefined)
      sessionStorage.setItem("recentMemberSearches",JSON.stringify(this.recentSearchSession));
      console.log(this.recentSearchSession);
      if(this.recentSearchDrugSession!=undefined)
        sessionStorage.setItem("recentDrugSearches",JSON.stringify(this.recentSearchDrugSession));
        console.log(this.recentSearchDrugSession);
  
  }

  ngOnInit(): void {
  }

  goto(routeUrl:string){
    debugger;

    if(routeUrl=='ePA'){
      sessionStorage.setItem('IntiatePA',"true");
    }
    this.router.navigateByUrl("/"+routeUrl);
  }
}
