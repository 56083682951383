import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { TableCellData, TableClickEvent, TableHeaderCellData, TableRowData } from 'src/app/modals/datatable/datatable';
import { DownloadTemplateRequest, DownloadTemplateResponse } from 'src/app/modals/provisioning/DownloadTemplateResponse';
import { ExcelProvisionUser, ExcelProvisionUserDatatable, UserProvisionAttachmentResponse, provisionUser, uploadHistoryWebResponse } from 'src/app/modals/provisioning/UploadHistoryWebResponse';
import { ProvisionUser, ResubmitProvisionRequest, ResubmitProvisionUser, SubmitUserProvisionListRequest } from 'src/app/modals/provisioning/submitUserProvisioningrequest';
import { Status } from 'src/app/modals/webresponsestatus';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ProvisioningService } from 'src/app/services/provisioning/provisioning.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-provisioning',
  templateUrl: './user-provisioning.component.html',
  styleUrls: ['./user-provisioning.component.css']
})
export class UserProvisioningComponent implements OnInit,OnDestroy {
  currentDate = new Date();
  intervalId: any;

  private contentType: string = "application/x-www-form-urlencoded; charset=UTF-8";

  blnhide: boolean = true;
  isPcmsProvisioning: boolean = true;
   currentPageNumber: number = 1;
  // recordsPerPage: number = environment.paginationRecordsPerPage;
  recordsPerPage!:number;
  totalPagesCount!: number;
  filename = "sheet.xlsx";
  event: any;
  historyLoader:boolean = false;

  blneditSave:boolean = false;
  blnShow:boolean = false;

  noOfPages: number = 0;
  noOfRecords: number = 0;
  recordsDisplayedCount: string = "";
  currentPage = 1;
  blnUploadHistory: boolean = false;

  excelrecordsnoOfPages: number = 0;
  excelNoOfRecords: number = 0;
  excelrecordsDisplayedCount: string = "";
  excelrecordcurrentPage = 1;
  eventresposeObject: any;

  optumID:FormControl;
  npi:FormControl;
  role:FormControl;
  email:FormControl;

  headers!: TableHeaderCellData[];
  entries!: TableRowData<provisionUser>[];

  excelrecordheaders!: TableHeaderCellData[];
  excelrecordentries!: TableRowData<ExcelProvisionUserDatatable>[];

  firstHeader!: TableHeaderCellData;
  secondHeader!: TableHeaderCellData;
  thirdHeader!: TableHeaderCellData;
  forthHeader!: TableHeaderCellData;
  fifthHeader!: TableHeaderCellData;
  sixthHeader!: TableHeaderCellData;
  seventhHeader!: TableHeaderCellData;
  eighthHeader!: TableHeaderCellData;
  ninethHeader!: TableHeaderCellData;
  tenthHeader!: TableHeaderCellData;



  provisionHistory: provisionUser[] = [];

  selectedFile: File | null = null;
  progress!: number;
  errorMessage!: string;

  maxFileSize: number = APPCONSTANTS.ATTACHMENT_SIZE;
  fileTypesAllowed = APPCONSTANTS.BI_UPLOAD_TYPE;

  @ViewChild("provisionConfirmModal")
  provisionConfirmModalElmtRef?: ElementRef<HTMLElement>;
  
  @ViewChild("norecordsToProvision")
  norecordsToProvisionElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("recordsToProvision")
  recordsToProvisionElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("UploadProgress")
  uploadProgressElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("UploadError")
  uploadErrorElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("FileSizeExceeded")
  fileSizeExceededElmRef?: ElementRef<HTMLElement>;

  @ViewChild("FileTypeError")
  fileTypeErrorElmRef?: ElementRef<HTMLElement>;

  @ViewChild("dateTemplate", { static: true })
  dateTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("timeTemplate", { static: true })
  timeTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("recordTemplate", { static: true })
  recordTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("statusTemplate", { static: true })
  statusTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("optumIDTemplate", { static: true })
  optumIDTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("npiTemplate", { static: true })
  npiTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("roleTemplate", { static: true })
  roleTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("internalTemplate", { static: true })
  internalTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("emailTemplate", { static: true })
  emailTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("editTemplate", { static: true })
  editTemplateRef!: TemplateRef<HTMLElement>;


  @ViewChild("noSavedHistoryTemplate", { static: true })
  noSavedHistoryTemplateRef!: TemplateRef<HTMLElement>;


  constructor(private modalService: NgbModal, private uploadHistoryHelper: ProvisioningService, private profileService: ProfileService, private loggerService: LoggerService, protected router: Router) {

    this.headers = [];
    this.excelrecordheaders = [];

    this.firstHeader = new TableHeaderCellData();
    this.firstHeader.content = "Date";
    this.firstHeader.sortable = true;
    this.firstHeader.sortAscending = true;
    this.firstHeader.selectedForSort = true;
    this.headers.push(this.firstHeader);

    this.secondHeader = new TableHeaderCellData();
    this.secondHeader.content = "Time";
    this.secondHeader.sortable = true;
    this.headers.push(this.secondHeader);

    this.thirdHeader = new TableHeaderCellData();
    this.thirdHeader.content = "Records";
    this.headers.push(this.thirdHeader);

    this.forthHeader = new TableHeaderCellData();
    this.forthHeader.content = "Status";
    this.forthHeader.sortable = true;
    this.headers.push(this.forthHeader);

    this.fifthHeader = new TableHeaderCellData();
    this.fifthHeader.content = "Optum ID";
    this.fifthHeader.sortable = true;
    this.fifthHeader.sortAscending = true;
    this.fifthHeader.selectedForSort = true;
    this.excelrecordheaders.push(this.fifthHeader);

    this.sixthHeader = new TableHeaderCellData();
    this.sixthHeader.content = "NPI";
    this.sixthHeader.sortable = true;
    this.sixthHeader.sortAscending = true;
    this.sixthHeader.selectedForSort = true;
    this.excelrecordheaders.push(this.sixthHeader);

    this.seventhHeader = new TableHeaderCellData();
    this.seventhHeader.content = "Role";
    this.seventhHeader.sortable = true;
    this.seventhHeader.sortAscending = true;
    this.seventhHeader.selectedForSort = true;
    this.excelrecordheaders.push(this.seventhHeader);

    this.eighthHeader = new TableHeaderCellData();
    this.eighthHeader.content = "Internal";
    this.eighthHeader.sortable = true;
    this.eighthHeader.sortAscending = true;
    this.eighthHeader.selectedForSort = true;
    this.excelrecordheaders.push(this.eighthHeader);

    this.ninethHeader = new TableHeaderCellData();
    this.ninethHeader.content = "Email";
    this.ninethHeader.sortable = true;
    this.ninethHeader.sortAscending = true;
    this.ninethHeader.selectedForSort = true;
    this.excelrecordheaders.push(this.ninethHeader);

    this.tenthHeader = new TableHeaderCellData();
    this.tenthHeader.content = "Edit";
    this.tenthHeader.sortable = true;
    this.tenthHeader.sortAscending = true;
    this.tenthHeader.selectedForSort = true;
    this.excelrecordheaders.push(this.tenthHeader);

    this.optumID = new FormControl(Validators.required);
    this.npi = new FormControl(Validators.required);
    this.role = new FormControl(Validators.required);
    this.email = new FormControl(Validators.required);

    


  }

  private getEditedOptumId():string{
    debugger;
    return this.optumID.value;
  }
  private setEditedOptumId(val:string):void{
    debugger;
    this.optumID.setValue(val);
  }
  private getEditedNPI():string{
    debugger;
    return this.npi.value;
  }
  private setEditedNPI(val:string):void{
    debugger;
    this.npi.setValue(val);
  }
  private getEditedEmail():string{
    debugger;
    return this.email.value;
  }
  private setEditedEmail(val:string):void{
    debugger;
    this.email.setValue(val);
  }

  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    this.historyLoader=true;
    if (this.profileService.loginuser?.prescriber?.presbrId != undefined) {
      this.uploadHistoryHelper.getuploadHistory(this.profileService.loginuser.prescriber.presbrId).then((response: uploadHistoryWebResponse) => {
        debugger;
        if (response.provisionUserList != undefined) {
          this.provisionHistory = response.provisionUserList;
          this.generateProvisionHistory();
        } else {
          this.blnUploadHistory = true;
        }
      this.historyLoader = false;
      }, (error: any) => {
        this.errorMessage = "Unable to upload history.";
        this.modalService.open(this.uploadErrorElmtRef, { size: 'md',backdrop:'static' });
      }).catch((reason: any) => {

      });
    }

    this.intervalId = setInterval(() => {
      this.currentDate = new Date();
    }, 1000);

  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  reloadHistory(){
    this.ngOnInit();
  }


  generateProvisionHistory() {
    debugger;
    this.entries = [];
    this.historyLoader = true;
    if (this.provisionHistory.length > 0) {
      debugger;
      this.provisionHistory = this.provisionHistory.reverse();
      this.updateTotalPagesCount();
      this.getCurrentPageMembers().forEach((history: provisionUser) => {
        debugger;
        const row: TableRowData<provisionUser> = new TableRowData<provisionUser>();
        debugger;
        const firstCell: TableCellData<provisionUser> = new TableCellData<provisionUser>();
        firstCell.data = history;
        firstCell.sortable = true;
        firstCell.template = this.dateTemplateRef;
        row.cells.push(firstCell);

        const secondCell: TableCellData<provisionUser> = new TableCellData<provisionUser>();
        secondCell.data = history;
        secondCell.template = this.timeTemplateRef;
        row.cells.push(secondCell);

        const thirdCell: TableCellData<provisionUser> = new TableCellData<provisionUser>();
        thirdCell.data = history;
        thirdCell.template = this.recordTemplateRef;
        row.cells.push(thirdCell);

        const forthCell: TableCellData<provisionUser> = new TableCellData<provisionUser>();
        forthCell.data = history;
        forthCell.template = this.statusTemplateRef;
        row.cells.push(forthCell);
        this.entries.push(row);
      });
    } else {
      const row: TableRowData<provisionUser> = new TableRowData<provisionUser>();
      row.cssClass = "norow";
      const firstCell: TableCellData<provisionUser> = new TableCellData<provisionUser>();
      firstCell.data = undefined;
      firstCell.colspan = this.headers.length;
      firstCell.clickable = false;
      firstCell.sortable = true;
      firstCell.template = this.noSavedHistoryTemplateRef;
      row.cells.push(firstCell);
      this.entries.push(row);
    }
    this.historyLoader = false;
  }

  private getCurrentPageMembers(): provisionUser[] {
    debugger;
    this.recordsPerPage = this.provisionHistory.length;
    if (this.provisionHistory.length <= this.recordsPerPage) {
      return this.provisionHistory;
    }
    else {
      const startIndex: number = (this.currentPageNumber - 1) * this.recordsPerPage;
      const endIndex: number = (this.currentPageNumber * this.recordsPerPage);
      return this.provisionHistory.slice(startIndex, endIndex);
    }
  }
  private updateTotalPagesCount(): void {
    debugger;
    this.totalPagesCount = (this.provisionHistory.length % this.recordsPerPage) != 0 ? Math.floor(this.provisionHistory.length / this.recordsPerPage) + 1 : Math.floor(this.provisionHistory.length / this.recordsPerPage);
  }

  goto(routeUrl: string) {
    debugger;
    this.loggerService.userAction("Going to " + routeUrl);
    this.router.navigateByUrl("/" + routeUrl);
  }
  downloadTemplate() {
    let webrequest: DownloadTemplateRequest = new DownloadTemplateRequest();
    webrequest.isEmptySheet = "Y";
    this.uploadHistoryHelper.getUserProvisioningSheet(webrequest).then((response: DownloadTemplateResponse) => {
      if (response.status.statusCode == "Success") {
        var b64String = response.fileBytes;
        let blob: Blob = this.b64toBlob(b64String);
        var objectUrl = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = objectUrl;
        a.target = '_blank';
        a.download = this.filename;
        document.body.appendChild(a);
        a.click();
      }
    }, (error: any) => {
    }).catch((reason: any) => {

    });
  }
  private b64toBlob(base64Data: any): Blob {
    let byteCharacters: string, blobData: [Uint8Array], blob: Blob;

    byteCharacters = atob(base64Data);
    blobData = this.getBlobDataAtOnce(byteCharacters);
    blob = new Blob(blobData, { type: this.contentType });

    return blob;
  }
  private getBlobDataAtOnce(byteCharacters: string): [Uint8Array] {
    let byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    return [byteArray];
  }

  onSelectClick(event: any): void {
    debugger
    this.onDeleteFileClick();
    const selectedFiles: FileList = event.target.files;
    if (selectedFiles != undefined && selectedFiles.item != undefined && selectedFiles.item(0) != undefined) {
      const file: File | null = selectedFiles.item(0);
      if (file != null && this.isFileValid(file)
        && this.uploadProgressElmtRef != undefined
        && this.uploadErrorElmtRef != undefined) {
        this.selectedFile = file;
        // this.transferFile();
      }
    }
    //reset file upload element after capturing the file bytes.
    event.target.value = "";
  }
  triggerFileInput() {
    document.getElementById('uploadbutton')?.click();
  }
  onDeleteFileClick() {
    this.selectedFile = null;
  }
  private isFileValid(file: File): boolean {
    debugger;
    const fileType: string = file.type;
    const fileSize: string = this.getFileSize(file);
    if (fileType != this.fileTypesAllowed) {
      this.modalService.open(this.fileTypeErrorElmRef, { size: 'md',backdrop:'static' });
      return false;
    }
    else {
      if (this.maxFileSize < parseFloat(fileSize)) {
        this.modalService.open(this.fileSizeExceededElmRef, { size: 'md',backdrop:'static' });
        return false;
      }
    }
    return true;
  }
  getFileSize(file?: File): string {
    debugger
    if (file != undefined)
      return (file.size / 1048576).toFixed(2);
    else
      return "0";
  }
  uploadExcelFile(): void {
    if (this.selectedFile != undefined) {
      this.progress = 1;
      this.excelrecordentries = [];
      this.modalService.open(this.uploadProgressElmtRef, { size: "md" ,backdrop:'static'});
      this.uploadHistoryHelper.provisioningFileUpload("" + this.profileService.loginuser?.prescriber?.presbrId, this.selectedFile).subscribe({
        next: (event: any) => {
          debugger
          if (event.type === HttpEventType.UploadProgress) {
            debugger;
            this.progress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            debugger;
            if (event.status.toString() == "200"
              && event.body != undefined && event.body.status != undefined && event.body.status.statusCode != undefined
              && event.body.status.statusCode == "Success") {
              this.progress = 0;
              this.modalService.dismissAll();
              sessionStorage.setItem("event", JSON.stringify(event));

              if (event.body.provisionUsers.length == 0) {
                debugger;
                this.modalService.open(this.norecordsToProvisionElmtRef, { size: 'lg' ,backdrop:'static'});
              }
              else {
                this.eventresposeObject = event.body.provisionUsers;
                this.excelNoOfRecords = event.body.provisionUsers.length;
                this.excelrecordsnoOfPages = Math.ceil(this.excelNoOfRecords / 10);
                this.excelrecordsDisplayedCount = "" + ((this.currentPage - 1) * 10 + 1) + " - " + (this.currentPage * 10 <= this.excelNoOfRecords ? this.currentPage * 10 : this.excelNoOfRecords);
                this.loadExcelRecordsHistory(this.eventresposeObject.slice(((this.currentPage - 1) * 10), (this.excelNoOfRecords >= this.currentPage * 10 ? this.currentPage * 10 : this.excelNoOfRecords)));
                this.modalService.open(this.recordsToProvisionElmtRef, { size: 'lg' ,backdrop:'static'});
              }
            } else {
              this.onDeleteFileClick();
              this.modalService.dismissAll();
              if (event.status == -1) {
                this.errorMessage = event.statusText;
              } else {
                this.errorMessage = "An error occurred while trying to upload the file.<br/>Please try again.";
              }
              this.modalService.open(this.uploadErrorElmtRef, { size: 'md',backdrop:'static' });
            }
          }
        },
        error: (err: any) => {
          this.onDeleteFileClick();
          this.modalService.dismissAll();
          if (err.error && err.error.message) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = 'An error occurred while trying to upload the file.<br/>Please try again.';
          }
          this.modalService.open(this.uploadErrorElmtRef, { size: 'md' ,backdrop:'static'});
        }
      });
    }
  }

  // Load previous page in pharmacy Search DataTable
  loadPreviousExcelPageRecords() {
    debugger;
    this.excelrecordentries = [];
    if (this.currentPage > 1) {
      this.currentPage--;
      this.excelrecordsDisplayedCount = "" + ((this.currentPage - 1) * 10 + 1) + " - " + (this.currentPage * 10 <= this.excelNoOfRecords ? this.currentPage * 10 : this.excelNoOfRecords);
      //console.log("Min: " + ((this.currentPage - 1) * 20) + ", Max: " + (this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords));
      this.loadExcelRecordsHistory(this.eventresposeObject.slice((this.currentPage - 1) * 10, this.excelNoOfRecords >= this.currentPage * 10 ? this.currentPage * 10 : this.excelNoOfRecords));
    }
  }

  // Load next page inpharmacy Search DataTable
  loadNextExcelPageRecords() {
    debugger;
    this.excelrecordentries = [];
    if (this.currentPage < this.excelrecordsnoOfPages) {
      this.currentPage++;
      this.excelrecordsDisplayedCount = "" + ((this.currentPage - 1) * 10 + 1) + " - " + (this.excelNoOfRecords >= this.currentPage * 10 ? this.currentPage * 10 : this.excelNoOfRecords);
      //console.log("Min: " + ((this.currentPage - 1) * 20) + ", Max: " + (this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords));
      this.loadExcelRecordsHistory(this.eventresposeObject.slice((this.currentPage - 1) * 10, this.excelNoOfRecords >= this.currentPage * 10 ? this.currentPage * 10 : this.excelNoOfRecords));
    }

  }

  excelProvisionUserDatatableList:ExcelProvisionUserDatatable[]=[];

  loadExcelRecordsHistory(event:any) {
    for(let x=0;x<event.length;x++){
      let excelProvisionUserDatatable= new ExcelProvisionUserDatatable();
      excelProvisionUserDatatable.excelProvisionUser=event[x];
      excelProvisionUserDatatable.editMode=false;
      excelProvisionUserDatatable.isInternalUser=event[x].userType=="I"?true:false;
      this.excelProvisionUserDatatableList[x]=excelProvisionUserDatatable;
    }

    let i = 0;
    this.excelProvisionUserDatatableList.forEach((history: ExcelProvisionUserDatatable) => {
      i++;
      const row: TableRowData<ExcelProvisionUserDatatable> = new TableRowData<ExcelProvisionUserDatatable>();
      debugger;
      const fifthCell: TableCellData<ExcelProvisionUserDatatable> = new TableCellData<ExcelProvisionUserDatatable>();
      fifthCell.data = history;
      fifthCell.sortable = true;
      fifthCell.template = this.optumIDTemplateRef;
      row.cells.push(fifthCell);
      this.optumID.setValue(history.excelProvisionUser.optumId);

      const sixthCell: TableCellData<ExcelProvisionUserDatatable> = new TableCellData<ExcelProvisionUserDatatable>();
      sixthCell.data = history;
      sixthCell.sortable = true;
      sixthCell.template = this.npiTemplateRef;
      row.cells.push(sixthCell);

      const seventhCell: TableCellData<ExcelProvisionUserDatatable> = new TableCellData<ExcelProvisionUserDatatable>();
      seventhCell.data = history;
      seventhCell.sortable = true;
      seventhCell.template = this.roleTemplateRef;
      row.cells.push(seventhCell);

      const eighthCell: TableCellData<ExcelProvisionUserDatatable> = new TableCellData<ExcelProvisionUserDatatable>();
      eighthCell.data = history;
      eighthCell.sortable = true;
      eighthCell.template = this.internalTemplateRef;
      row.cells.push(eighthCell);

      const ninethCell: TableCellData<ExcelProvisionUserDatatable> = new TableCellData<ExcelProvisionUserDatatable>();
      ninethCell.data = history;
      ninethCell.sortable = true;
      ninethCell.template = this.emailTemplateRef;
      row.cells.push(ninethCell);

      const tenthCell: TableCellData<ExcelProvisionUserDatatable> = new TableCellData<ExcelProvisionUserDatatable>();
      tenthCell.data = history;
      tenthCell.sortable = true;
      tenthCell.template = this.editTemplateRef;
      row.cells.push(tenthCell);

      this.excelrecordentries.push(row);
    });
  }

  edit(data:any){
    debugger;
    console.log(data);
    data.editMode = true;
  }

  save(data: any) {
    data.editMode = false;
    if(data.isInternalUser){
      data.excelProvisionUser.userType = "I";
    }else{
      data.excelProvisionUser.userType = "E";
    }
    debugger;
    console.log(this.excelProvisionUserDatatableList);
    // this.event.body.provisionUsers =  this.eventresposeObject;
  }

  provisionUser(){
    debugger;
    this.event=JSON.parse(""+sessionStorage.getItem("event"));
    this.event.body.provisionUsers = this.excelProvisionUserDatatableList.map((excelProvisionUserDatatable:ExcelProvisionUserDatatable)=>(excelProvisionUserDatatable.excelProvisionUser));
    let webrequest:ResubmitProvisionRequest= new ResubmitProvisionRequest();
    let provisionUserObj:ResubmitProvisionUser[]=[];
    webrequest.provisionId=this.event.body.provisionId;
    provisionUserObj= this.event.body.provisionUsers;
    webrequest.provisionUsers = provisionUserObj;
    this.uploadHistoryHelper.submitUserProvisionList(webrequest).then((response:Status)=>{
      console.log(response);
      this.modalService.dismissAll();
      this.modalService.open(this.provisionConfirmModalElmtRef,{ size: 'md' ,backdrop:'static'});

    }, (error: any) => {
      this.errorMessage = 'An error occurred while trying to provision the user.<br/>Please try again.';
      this.modalService.open(this.uploadErrorElmtRef, { size: 'md' ,backdrop:'static'});
    }).catch((reason: any) => {

    }); 
  }

  onHeaderClicked(headerClickEvent:TableClickEvent<provisionUser>):void{
  
  }
  
  onCellClicked(cellClickEvent:TableClickEvent<provisionUser>):void{
    debugger;
    console.log(cellClickEvent.cell?.data?.provisionId);
    if(cellClickEvent.column==0 || cellClickEvent.column== 1 || cellClickEvent.column==2 || cellClickEvent.column==3){
    sessionStorage.setItem("provisionId",""+cellClickEvent.cell?.data?.provisionId);
    sessionStorage.setItem("uploadDate",""+cellClickEvent.cell?.data?.dateSubmitted);
    sessionStorage.setItem("uploadTime",""+cellClickEvent.cell?.data?.timeSubmitted);
    this.router.navigateByUrl("/provision/report");
    }
  }

  refreshPage(){
    this.historyLoader = true;
    this.generateProvisionHistory();
  }

  dismiss() {
    this.modalService.dismissAll();
  }

}
