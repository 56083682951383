<div class="pagination" *ngIf="totalPages>1 && show">
    <ng-container *ngFor="let page of counter();index as i; first as isFirst; last as isLast">
        <button class="btn invisblebtn" [class.lock]="currentPageNumber==1" *ngIf="totalPages>1 && isFirst"
            (click)="currentPageNumber>1 && currentPageNumber=currentPageNumber-1">
            <i class="goToPreviousPage fa fa-circle-thin">
                <i class="fa fa-angle-left"></i>
            </i>
        </button>
        <button class="btn invisblebtn paginationpage" [class.active]="currentPageNumber-1==i" [innerHtml]="i+1" (click)="currentPageNumber=(i+1)">
        </button>
        <button class="btn invisblebtn" [class.lock]="currentPageNumber==totalPages" *ngIf="totalPages>1 && isLast"
            (click)="currentPageNumber<totalPages && currentPageNumber=currentPageNumber+1">
            <i class="goToNextPage fa fa-circle-thin">
                <i class="fa fa-angle-right"></i>
            </i>
        </button>
    </ng-container>
</div>
