import { Status } from "../webresponsestatus"

export class DownloadTemplateRequest {
    isEmptySheet!: string
  }

export class DownloadTemplateResponse {
    fileBytes!: string
    status!: Status
  }

/********download provision sheet which is uploaded ******/
export class DownloadProvisionSheetRequest {
  isEmptySheet!: string
  provisionUserList!: ProvisionUserList
}

export class ProvisionUserList {
  provisionUsers!: ProvisionUser[]
}

export class ProvisionUser {
  email!: string
  firstName!: string
  lastName!: string
  note!: string
  npiList!: string[]
  optumId!: string
  role!: string
  userType!: string
}  
 