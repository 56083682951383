import {Status} from 'src/app/modals/webresponsestatus';

export class provisionUser{
    provisionId!: number;
	presbrId!: number;
	fileName!:String;
	fileCtgyTyp!: String;
	provisionUsers!: any;
	stsTypId!:number;
	date!: Date;
	recordsCount!:number;
	uploadTimestamp!: Date;
	dateSubmitted!: String;
	timeSubmitted!: String;
	uploadStatus!: String;
    reviewProvisionYesNo!: boolean;
}

export class uploadHistoryWebResponse{
    provisionUserList!:provisionUser[];
    status!:Status;
}

export interface UserProvisionAttachmentResponse {
	provisionId: number
	fileCtgyTyp: string
	provisionUsers: ExcelProvisionUser[]
	status: Status
	recordsCount: number
	reviewProvisionYesNo: boolean
  }
  
  export interface ExcelProvisionUser {
	optumId: string
	firstName: string
	lastName: string
	npiList: string[]
	role: string
	userType: string
	note: string
	email: string
	flagRecordChange: boolean
	editMode:boolean
	isInternalUser:boolean
  }

  export class ExcelProvisionUserDatatable {
	excelProvisionUser!:ExcelProvisionUser;
	editMode!:boolean
	isInternalUser!:boolean
  }
  
  