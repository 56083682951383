import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import { BOOLEAN_FLAG, VIEWALLPA_DATERANGE_LEBELS } from 'src/app/constants/appconstants';
import { ViewAllRouteState, PAGE_TYPE } from 'src/app/constants/routeconstants';
import { VIEWALL_DATERANGE_LEBELS, VIEWALL_DATERANGE, VIEWALL_SUBMITTEDPA_STATUS, VIEWALL_SUBMITTEDPA_STATUS_LABELS, VIEWALL_SAVEDPA_STATUS_LABELS , VIEWALL_SAVEDPA_STATUS, USER_ROLE} from 'src/app/constants/appconstants';
import { DateFieldEvent, DateFieldResultType, DateFieldValidationMode } from 'src/app/modals/datefield/datefield';

import { SubmittedpahistoryComponent } from 'src/app/components/submittedpahistory/submittedpahistory.component';
import { SavedpahistoryComponent } from 'src/app/components/savedpahistory/savedpahistory.component';
import { DatefieldComponent } from 'src/app/components/datefield/datefield.component';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { Title } from '@angular/platform-browser';
import { PAHistorywebrequest } from 'src/app/modals/pahistory/pahistorywebrequest';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { NotificationsService } from 'src/app/services/viewAllNotificationsServices/notifications.service';
//import { getNotificationDetails } from 'src/app/modals/viewallnotificationscalls/getNotificationDetails';
import { PahistoryService } from 'src/app/services/pahistory/pahistory.service';
import { PAHistorywebresponse, PAResponseHistory } from 'src/app/modals/pahistory/pahistorywebresponse';
import { DatetoDisplayString } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { isDefined } from '@ng-bootstrap/ng-bootstrap/util/util';

class SubmittedPAHistory{
  
  blnShowFromDate:boolean;
  private _fromDateValue : string="";
  public get fromDateValue() : string {
    return this._fromDateValue;
  }
  public set fromDateValue(v : string) {
    this._fromDateValue = v;
    if((new Date(v)).toString()!="Invalid Date")
      this.fromDate = new Date(v);
  }
  fromDate:Date=new Date();//Different from fromDateValue. This fromDate is used to bind to history component where fromDateValue is used for validation on datefield.
  fromDateResult: DateFieldResultType = DateFieldResultType.EMPTY;
  fromDateValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONBLUR;
  onFromDateUpdate(event: DateFieldEvent): void {
    this.fromDateValue = event.value;
    this.fromDateResult = event.valid;
  }

  blnCustomDateFormSubmitted:boolean=false;
  dateFormatPlaceHolder:string="MM/DD/YYYY";

  
  private _toDateValue : string="";
  public get toDateValue() : string {
    return this._toDateValue;
  }
  public set toDateValue(v : string) {
    this._toDateValue = v;
    if((new Date(v)).toString()!="Invalid Date")
      this.toDate = new Date(v);
  }
  toDate:Date = new Date();//Different from toDateValue. This toDate is used to bind to history component where toDateValue is used for validation on datefield.
  toDateResult: DateFieldResultType = DateFieldResultType.EMPTY;
  toDateValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONBLUR;
  onToDateUpdate(event: DateFieldEvent): void {
    this.toDateValue = event.value;
    this.toDateResult = event.valid;
  }

  dateRange:Map<number,string> = VIEWALL_DATERANGE_LEBELS;

  blnStatusChanged:boolean=false;
  private _selectedStatus:number = VIEWALL_SUBMITTEDPA_STATUS.ALL;
  public get selectedStatus() : number {
    return this._selectedStatus;
  }
  public set selectedStatus(v : number) {
    debugger;
    this.blnStatusChanged=true;
    this._selectedStatus = v;
  }
  
  blnDateChanged:boolean;
  private _selectedDate !: number;
  public get selectedDate() : number {
    return this._selectedDate;
  }
  public set selectedDate(v : number) {
    this.blnShowFromDate=false;
    this._selectedDate = v;
    this.blnDateChanged=true;
    if(parseInt(v.toString())!=VIEWALL_DATERANGE.CUSTOM_DATE){
      //Today should represent either 11:59:59PM of today or 12AM of next day - 12AM of next day would be easy to code.
      let today:Date = new Date();
      today.setDate(today.getDate()+1);
      this.toDate = today;

      this.fromDate = new Date();
      switch(parseInt(v.toString())){
        case VIEWALL_DATERANGE.DAYS_THIRTY:
          this.fromDate.setDate(this.fromDate.getDate()- 30);
          break;
        case VIEWALL_DATERANGE.DAYS_SIXTY:
          this.fromDate.setDate(this.fromDate.getDate()-60);
          break;
        case VIEWALL_DATERANGE.MONTHS_SIX:
          this.fromDate.setMonth(this.fromDate.getMonth()-6);
          break;
        case VIEWALL_DATERANGE.YEARS_ONE:
          this.fromDate.setFullYear(this.fromDate.getFullYear()-1);
          break;
      }
      
    } else {
      this.fromDateValue="";
      this.toDateValue="";
      this.fromDateResult= DateFieldResultType.EMPTY;
      this.toDateResult = DateFieldResultType.EMPTY;
      this.blnCustomDateFormSubmitted=false;
      setTimeout(()=>{
        this.updateCustomSelectedDate();
        this.blnShowFromDate=true;
      },200);
      
    }
  }
  updateCustomSelectedDate():void{
    if(this.fromDateResult == DateFieldResultType.SUCCESS && this.fromDateValue!=undefined && this.fromDateValue.length>0){
      this.fromDate = new Date(this.fromDateValue);
    }
    if(this.toDateResult == DateFieldResultType.SUCCESS &&  this.toDateValue!=undefined && this.toDateValue.length>0){
      this.toDate = new Date(this.toDateValue);
    }
  }

  statuses:Map<number, string> = VIEWALL_SUBMITTEDPA_STATUS_LABELS;
  // selectedStatus:number = VIEWALL_SUBMITTEDPA_STATUS.ALL;

  searchText:string="";

  constructor(){
    this.selectedDate = VIEWALL_DATERANGE.DAYS_THIRTY;
    this.blnDateChanged=false;
    this.blnShowFromDate=false;
  }
}

class SavedPAHistory{

  searchText:string="";

  savedStatus: Map<number,string>=VIEWALL_SAVEDPA_STATUS_LABELS;
  blnStatusChanged:boolean=false;
  
  private _selectedStatus : number = VIEWALL_SAVEDPA_STATUS.VIEW_ALL;
  public get selectedStatus() : number {
    return this._selectedStatus;
  }
  public set selectedStatus(v : number) {
    this.blnStatusChanged=true;
    this._selectedStatus = v;
  }
  

}

@Component({
  selector: 'viewallpa',
  templateUrl: './viewallpa.component.html',
  styleUrls: ['./viewallpa.component.css']
})
export class ViewallpaComponent implements OnInit {
  state!:ViewAllRouteState;
  memberId!:any;
  userRole!: string;
  userID!: string;
  useruuid!: string;
  //@Input() role!: number;
  role!: USER_ROLE;
  memberSpecific!:any;
  associatedUsers!: any;
  providernpi!:String;
  changedprovidernpi:any = this.providernpi
  viewallprescribers: string = 'View all prescribers';
  changedPrescriberId:any=this.viewallprescribers;
  private sourceData:PAResponseHistory[]=[];
  submittedHistory:SubmittedPAHistory;
  savedHistory:SavedPAHistory;
  loading:boolean=false;
  @ViewChild(SubmittedpahistoryComponent,{static:false})
  private submittedHistoryRef!:SubmittedpahistoryComponent;

  @ViewChild(SavedpahistoryComponent, {static:false})
  private savedHistoryRef!:SavedpahistoryComponent;

  @ViewChild("FromDate",{static:false})
  private FromDateComponent!: DatefieldComponent;

  @ViewChild("ToDate",{static:false})
  ToDateComponent!: DatefieldComponent;
  

  constructor(private router:Router,private profileService:ProfileService,private logininfo:LoggerService ,private dateToDisplayStringConversion: DatetoDisplayString,private pahistoryService:PahistoryService, private notificaionsService: NotificationsService,private memberSearchHelper: MembersearchService,private logger: LoggerService,private titleService:Title,private loginProfile: ProfileService,) {
    this.submittedHistory = new SubmittedPAHistory();
    this.savedHistory = new SavedPAHistory();
    this.role = this.profileService.USER_ROLE;
    this.userID = "" + this.profileService.loginuser?.prescriber?.presbrId;
    this.useruuid = "" + this.profileService.loginuser?.prescriber?.uuid;
  }

  ngOnInit(): void {
    this.reload();
  }
  reload(){
    debugger;
    var apiParams = new PAHistorywebrequest();
    apiParams.fromDate = this.dateToDisplayStringConversion.transform(this.submittedHistory.fromDate);
    apiParams.toDate = this.dateToDisplayStringConversion.transform(this.submittedHistory.toDate);
    apiParams.maxResults = 10;
    apiParams.providerId = this.loginProfile.loginuser.prescriber?.uuid;
    if (this.role == 20) {
      apiParams.adminPresbrID = this.loginProfile.loginuser.prescriber?.presbrId;
    }
    else if (this.role == 10 || this.role == 13) {
      
      apiParams.adminPresbrID = '';
    }
    this.getNotifications(apiParams);
  }
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;

  }

  getDate180DaysBack(): Date {
    const date = new Date();
    date.setDate(date.getDate() - 180);
    return date;
  }
  onNpiSelected($event: String) {
    //this.providernpi = $event;
    console.log(`Provider NPI updated to: ${this.providernpi}`);
    var apiParams = new PAHistorywebrequest();
    apiParams.toDate = this.formatDate(new Date());
    apiParams.fromDate = this.formatDate(this.getDate180DaysBack());
    apiParams.maxResults = 10;
    apiParams.providerId = this.useruuid;
    if ($event != this.viewallprescribers) {
      apiParams.adminPresbrID =this.profileService.loginuser.prescriber?.presbrId;
      apiParams.providerNPI = this.changedprovidernpi;
      this.changedprovidernpi=$event;
    }
    else if ($event == this.viewallprescribers) {
      apiParams.providerNPI = '';
      this.changedprovidernpi=this.viewallprescribers;

    }
    this.getNotifications(apiParams);
  }
  getNotifications(params:any) {
    debugger;
    this.state = history.state;
     if(this.state.pageType==1){
    this.pahistoryService.getSubmittedPAHistory(params).then((response:PAHistorywebresponse)=>{
      this.loading=true;
      if(this.memberId!=undefined){
        this.sourceData = response.paResponseHistory.filter((item)=>item.memberId==this.memberId);
      }else{
        this.sourceData = response.paResponseHistory;
      }
      //this.numberofnotifications=response.paResponseHistory.length;
   this.submittedHistoryRef.loadHistory;  
  }
  ,(error:any)=>{
    this.submittedHistoryRef.submittedPaHistory=[];
    this.submittedHistoryRef.loadHistory; 
  }).catch((reason:any)=>{
    this.submittedHistoryRef.submittedPaHistory=[];
    this.submittedHistoryRef.loadHistory; 
  });
}
  else if(this.state.pageType==0)
    this.pahistoryService.getSavedPAHistory(params).then((response: PAHistorywebresponse) => {
      this.loading=true;
      if (this.memberId != undefined) {
        this.sourceData = response.savedPaAutherizations.filter((item) => item.memberId == this.memberId);
      } else {
        this.sourceData = response.savedPaAutherizations;
      }  
         this.savedHistoryRef.loadHistory;
        // this.numberofnotifications=response.savedPaAutherizations.length;
      //this.sourceData = response.paResponseHistory     
      }
      ,(error:any)=>{
        this.savedHistoryRef.savedPaHistory=[];
        //this.savedHistoryRef.loadHistory;
      }).catch((reason:any)=>{
        this.savedHistoryRef.savedPaHistory=[];
        
        //this.savedHistoryRef.loadHistory;
      });
      
}
changeTimeformat(time: string) {
  var year = time.slice(0, 4);
  var month = time.slice(5, 7);
  var day = time.slice(8, 10);
  return month + '/' + day + '/' + year;
}
  goto(routerUrl:string){
    this.logger.userAction("Goto Home Page");
    this.router.navigateByUrl("/"+ routerUrl);
  }

  //filterHistory():void
  filterHistory()
  {
     debugger;
     //if(this.state.pageType == PAGE_TYPE.SUBMITTEDPAS){
      this.submittedHistory.blnCustomDateFormSubmitted=false;
      if(parseInt(this.submittedHistory.selectedDate.toString())==VIEWALL_DATERANGE.CUSTOM_DATE){
        this.submittedHistory.blnCustomDateFormSubmitted=true;
        this.ToDateComponent.onFocusOut();
        this.FromDateComponent.onFocusOut();
      setTimeout(()=>{
        if(this.submittedHistory.fromDateResult == DateFieldResultType.SUCCESS
          && this.submittedHistory.toDateResult == DateFieldResultType.SUCCESS){
            this.submittedHistoryRef.reload(this.submittedHistory.blnDateChanged);
            this.submittedHistory.blnDateChanged=false;
          }
      },250);
    } 
  else {
     if(this.savedHistory.blnStatusChanged==false){
      this.submittedHistoryRef.reload(this.submittedHistory.blnDateChanged);
      this.savedHistory.blnStatusChanged=false;
     }
     else{
      this.savedHistoryRef.reload(this.savedHistory.blnStatusChanged);
       this.savedHistory.blnStatusChanged=true;
     }
    }  
  }
}
