<div class=" container-fluid shadow" style="padding: 20px; background-color: white;">
    <div class="row">

        <div class="col-8" style="padding-top: 20px;">
            <h5 class="text-sm-left lh-base font-normal text-none text-decoration-none text-reset">
                STEP 2 OF 3: MEDICATION REQUEST INFORMATION
            </h5>
        </div>

        <!-- Saved and Recent Searches Buttons  -->
        <div class="col-4" style="color: #0470c5; padding-top: 20px;">
            <ul class="list-inline">
                <li (click)="showRecentSeraches()" class="list-inline-item underline">Recent Searches</li>
                <li class="list-inline-item">|</li>
                <li (click)="showSavedSeraches()" class="list-inline-item underline">Saved Searches</li>
            </ul>
        </div>
    </div>
</div>
<form name="addMedicationForm" [formGroup]="addMedicationForm">
    <div class=" container-fluid shadow content-card mt-1" style="padding: 20px; background-color: white;">
        <div class="row">
            <div class="col-md-5 col-sm-5 col-xs-12">
                <div class="mb-3">
                    <div class="form-group">
                        <label for="text" class="form-label float-left">Medication Name or NDC </label>
                        <div class="search" placement="bottom" triggers="hover:blur" aria-hidden="true">
                            <i *ngIf="searchByNDC.length>0" class="fa fa-check"></i>
                            <i *ngIf="searching" class="spinner-border spinner-border-sm"></i>
                            <input (focus)="blnNDCFocus=true" (blur)="blnNDCFocus=false"
                                [ngClass]="{'inpError':  (invalidNDC || !blnDrugSearchFormSubmitted && addMedicationForm.controls['drugSearchModel'] && !addMedicationForm.controls['drugSearchModel'].valid) || 
                                (blnDrugSearchFormSubmitted && addMedicationForm.controls['drugSearchModel'] && !addMedicationForm.controls['drugSearchModel'].pristine && addMedicationForm.controls['drugSearchModel'].errors && 
                                (addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors['pattern'] || (!blnNDCFocus && addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors['minlength']) )),'validInput':(searchByNDC.length>0)}"
                                id="ndcname" type="text" class=" rounded-0 form-control inp"
                                [ngbTypeahead]="searchNDC" #val (selectItem)="itemSelected($event)"
                                (blur)="itemSelected($event)" formControlName="drugSearchModel" minlength="3"
                                [editable]="true" required>
                        </div>
                        <div *ngIf="drugSearchModel && drugSearchModel.invalid && drugSearchModel.dirty">
                            <div class="text-danger"
                                *ngIf="addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors['minlength']">
                                <small>Please enter min 3 characters.</small>
                            </div>
                            <div class="text-danger no-data shadow p-2"
                                *ngIf="invalidNDC && addMedicationForm.controls['drugSearchModel'].errors && !addMedicationForm.controls['drugSearchModel'].errors['minlength']">
                                <br>
                                <h3 class="fontbold">Medication not found.</h3>
                                <br>
                                <span class="paragraph">
                                    Please check the spelling.
                                </span><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-6 col-xs-11" >
                <div class="form-group">
                    <label for="exampleInputEmail1" class="form-label">Medication Dose </label>
                    <div *ngIf="formLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true">
                    </div>
                    <select *ngIf="searchByNDC.length==0 && dropdown.length!=1"
                        class=" custom-disabled rounded-0 inp form-select"
                        (change)="onChangeMediactionStrenth()" placement="bottom" triggers="hover:blur"
                        formControlName="MedStrength" aria-hidden="true">
                        <option value="">Select Dose</option>
                        <option *ngFor="let item of dropdown| drugnamesort; let i = index"
                            [value]="dropdown[i].productId">
                            {{item.detailedDrugName}}
                        </option>
                    </select>
                    <input *ngIf="searchByNDC.length>0 || dropdown.length==1" aria-hidden="true" type="text"
                        class="custom-disabled rounded-0 form-control inp"
                        value="{{dropdown[0].detailedDrugName}}" required disabled>
                </div>
            </div>
        </div>
    </div>
</form>



<ng-template #savedSearches let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Saved Medications</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click');"></button>
    </div>
    <div class="modal-body">
        <datatable [headers]="savedSearchHeaders" [rows]="savedSearchHistory"
            (onHeaderClicked)="onSavedHeaderClick($event)" (onCellClicked)="onSavedCellClick($event)">
        </datatable>
    </div>
    <div class="modal-body" *ngIf="this.savedSearchHistory.length==0">
        <h6>{{this.nosavedMessage}}</h6>
    </div>
</ng-template>
