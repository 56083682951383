import {Status} from 'src/app/modals/webresponsestatus';

export class Prescriber {
    presbrId!:string;
    uuid!:string;
    npi!:string;
    lastName!:string;
    firstName!:string;
    addressLine1!:string;
    addressLine2!:string;
    city!:string;
    stateProvince!:string;
    postalCode!:string;
    countryCode!:string;
    primaryTelephoneNumber!:string;
    faxNumber!:string;
    roleTypeId!:string|any;
    approveInd!:string;
    defaultInd!:string;
    instanceId!:string;
    userType!:string;
    optumId!:string;
}

interface SelfRegistration{
    ohid?:string;
    uuid?:string;
}

export interface Loginwebresponse extends SelfRegistration {
    prescribers?:Prescriber[];
	dfltOrCurrentNPI?:string;
	npiValidOrNot?:string;
	status?:Status;
	reActivateInd?:string;
	reActivateSts?:string;
}
//===============================================
