<ng-template #paInitiationTemplate let-modal>
    <div class="content-card bg-white" aria-labelledby="paInitiationTemplate">
        <div class="card-content painitiation" *ngIf="epaState<5">
            <div class="text-center">
                <!-- <loader [loaderText]="loaderText" [cssClass]="loaderClass"></loader> -->
                <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i><span style="font-size: 16px;"></span>
            </div>
            <div class="step" [ngClass]="{'active':epaState>0}">
                <i class="fa fa-check" aria-hidden="true"></i>
                <span class="paragraph large inputFieldLabel">Verifying information.</span>
            </div>
            <div class="step" [ngClass]="{'active':epaState>1}">
                <i class="fa fa-check" aria-hidden="true"> </i>
                <span class="paragraph large inputFieldLabel">Submitting PA request form.</span>
            </div>
            <div class="step" [ngClass]="{'active':epaState>2}">
                <i class="fa fa-check" aria-hidden="true"> </i>
                <span class="paragraph large inputFieldLabel">Retrieving PA evaluation questions.</span>
            </div>
            <div class="step" [ngClass]="{'active':epaState>3}">
                <i class="fa fa-check" aria-hidden="true"> </i>
                <span class="paragraph large inputFieldLabel">Questions received.</span>     
            </div>
            <div class="painitiationfooter text-center">
                <!-- <span class="paragraph" style="line-height: normal;">This process may take up to 3 minutes.</span> -->							
                <span class="paragraph large inputFieldLabel">Thank you and we've received your request. Your question set will typically be returned in less than 1 minute.</span>
            </div>
        </div>
        <div class="card-content text-center timeline" *ngIf="epaState==5">
            <i class="fa fa-check-circle"></i>
            <br/>
            <span x-ms-format-detection="none" class="paragraph large inputFieldLabel">
                The questions have been received. You have...
            </span>
            <br/>
            <div class="text-center">
                <div class="timerblock">
                    <div class="inputFieldLabel">{{epaHoursRemaining}}</div>
                    <div>Hours</div>
                </div>
                <div class="timerblock">
                    <div class="inputFieldLabel">{{epaMinutesRemaining}}</div>
                    <div>Minutes</div>
                </div>
                <div class="timerblock">
                    <div class="inputFieldLabel">{{epaSecondsRemaining}}</div>
                    <div>Seconds</div>
                </div>
            </div>
            <br/>
            <span x-ms-format-detection="none" class="paragraph large inputFieldLabel">
                ...to complete the questions before this request expires.
            </span>
            <br/><br/>            
        </div>
        <div class="card-footer text-center" *ngIf="epaState==5">
            <button class="btn btn-primary rounded-pill" (click)="goToEPAQuestions()">View PA questions</button>
        </div>
    </div>
</ng-template>

<ng-template #paInitiationFaxFormTemplate let-modal>
    <div class="content-card bg-white" aria-labelledby="paInitiationFaxFormTemplate">
        <div class="card-content text-center">
            <i class="fa fa-ban"></i>
            <br/>
            <span x-ms-format-detection="none" class="paragraph large inputFieldLabel" [innerHtml]="errorMessage"></span>
            <br/><br/>
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
            &nbsp;&nbsp;
            <button type="button" class="btn btn-primary rounded-pill" (click)="openfaxforms()">View fax forms</button>
        </div>
    </div>
</ng-template>

<ng-template #paInitiationErrorTemplate let-modal>
    <div class="content-card bg-white" aria-labelledby="paInitiationErrorTemplate">
        <div class="card-content text-center">
            <i class="fa fa-ban"></i>
            <br/>
            <span x-ms-format-detection="none" class="paragraph large inputFieldLabel" [innerHtml]="errorMessage"></span>
            <br/><br/>
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss();goToHome()">Ok</button>
        </div>
    </div>
</ng-template>