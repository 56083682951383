<div class="row addressbook_contentrow" style="margin-bottom: 100px;">
    <div class="col-xs-12 col-md-12 nopadding">
        <div class="container-fluid nopadding">
            <div class="row addressbook_headerrow">
                <div class="provisioning_headerimg col-xs-12 col-md-12">
                    <div class="container-fluid manage_prescribers_container fadeout-widget">
                        <div class="col-xs-12 col-md-12 col-lg-12 nopadding prescriberbook_headertext">
                            <div class="heading_mega">Add Users</div>
                            <p style="color: #fff">Add users by entering their Optum ID. Once added, you can assign
                                their
                                role and up to 20 NPIs.<br>
                                Review and confirm the assigned NPIs by clicking "Provision".</p>
                        </div>
                        <div class="col-sm-12 nopadding content-card-holder fadeout-widget" style="margin-top: 20px;">
                            <div class="content-card no-shadow">
                                <div class="card-content header container-fluid bg-white"
                                    style="border-bottom:2px solid #ddd">
                                    <h2 class="header nomargin"></h2>
                                    <span class="heading_3 ng-binding">Add A User</span>
                                </div>
                                <form name="optumidsearchform" role="form" [formGroup]="optumidsearchform">
                                    <div class="card-content body bg-white">
                                        <div class="container-fluid nopadding">
                                            <div class="row">
                                                <div class="col-sm-10">
                                                    <span for="optumId">Optum ID</span>
                                                    <br><br>
                                                    <input type="text" maxlength="50" name="OptumId"
                                                        formControlName="OptumId" class="rounded-0 form-control inp"
                                                        aria-required="false" (focus)="optumIdFocus=true"
                                                        (blur)="optumIdFocus=false"
                                                        [ngClass]="{'inpError': (blnFormSubmitted && !optumidsearchform.controls['OptumId'].valid) || 
                                                    (!optumidsearchform.controls['OptumId'].pristine && optumidsearchform.controls['OptumId'].errors && 
                                                    (optumidsearchform.controls['OptumId'].errors['pattern'] || (!optumIdFocus && optumidsearchform.controls['OptumId'].errors['minlength'])))}">
                                                    <p class="text-danger" *ngIf="optumidsearchform.controls['OptumId'] && optumidsearchform.controls['OptumId'].errors && !optumidsearchform.controls['OptumId'].errors['pattern'] && 
                                                        optumidsearchform.controls['OptumId'].errors['minlength']"
                                                        style="margin: 0 !important">
                                                        Please enter atleast two characters. </p>
                                                    <p class="text-danger" style="margin: 0 !important"
                                                        *ngIf="optumidsearchform.controls['OptumId'] && optumidsearchform.controls['OptumId'].errors && optumidsearchform.controls['OptumId'].errors['pattern']">
                                                        Invalid Character.
                                                    </p>
                                                    <p class="text-danger" style="margin: 0 !important"
                                                        *ngIf="optumidsearchform.controls['OptumId'].value==''&& blnFormSubmitted">
                                                        OptumId required.</p>
                                                </div>
                                                <div class="col-sm-2" style="margin-top: 40px;">
                                                    <button type="button" class="btn btn-primary p-2" style="width:96%"
                                                        (click)="onAddClick()">Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid manage_prescribers_container ng-scope" style="margin-top: 10px;">
        <div class="col-sm-12 noresults_box_style ng-scope" style="margin-top: 20px;"
            *ngIf="this.isoptumIdFound==undefined && this.blnAddUserAdmin==false">
            <h2 style="margin-top: 10px;">No users have been added.</h2>
        </div>
        <div class="content-card no-shadow" *ngIf="this.blnAddUserAdmin==true && this.OptumIdvalue!=undefined">
            <div class="card-content header container-fluid bg-white" style="border-bottom:2px solid #ddd">
                <div class="row">
                    <div class="col-1 checkbox" style="min-height: 25px;" *ngIf="this.blnEditUser==false">
                        <input type="checkbox" class="form-check-input" id="checkbox" name="checkbox"
                            style="width: 1.2em; height: 1.2em;border:2px solid gray;border-radius:0px"
                            (click)="editCheckboxClick()" checked>
                    </div>
                    <div class="col-8">
                        <p class="fontbold" style="margin: 0;">User: {{this.OptumIdvalue}}</p>
                        <span class="paragraph">Email: {{this.email}}</span>
                        &nbsp;&nbsp;
                        <div style="display: inline-block;vertical-align: middle;margin-left:15px;"
                            *ngIf="this.blnEditUser==false">
                            <p style="margin: 0;"></p>
                            <span class="paragraph" *ngIf="this.selectedRole=='20'">Role:Admin</span>
                            <span class="paragraph" *ngIf="this.selectedRole=='10'">Role:Prescriber</span>
                            <span class="paragraph" *ngIf="this.selectedRole=='11'">Role:Pharmacist</span>
                            <span class="paragraph" *ngIf="this.selectedRole=='13'">Role:Specialtypharmacist</span>

                        </div>
                        <div style="display: inline;vertical-align: middle;margin-left:25px;"
                            *ngIf="this.blnEditUser==true&&this.selectedRole!=12">
                            <label style="text-transform: none;display:inline;padding-right:8px;"
                                class="inputFieldLabel">Assign a role</label>
                            <select class="drop-list" style="width: 20%;" (change)="onRoleChangeEdit(selectedRole)" [(ngModel)]="selectedRole">
                                <option value="20">Admin</option>
                                <option value="10">Prescriber</option>
                                <option value="12">Advisor</option>
                                <option value="11">Pharmacist</option>
                                <option value="13">Specialtypharmacist</option>

                            </select>
                        </div>
                    </div>
                    <div class="col-2" style="display: inline-block;float: right;">
                        <button type="button" style="font-size: 14px;" class="btn btn-link fontbold"
                            (click)="editUser()" *ngIf="this.blnEditUser==false">Edit User</button>
                    </div>
                    <div class="col-2" style="display: inline-block;">
                        <button type="button" style="font-size: 14px;" class="btn btn-link fontbold"
                            (click)="removeUser()" *ngIf="this.blnEditUser==true"
                            style="color:#c60f13; font-size:14px;font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;">Remove
                            User</button>
                    </div>
                </div>
            </div>
            <div class="card-content body bg-white">
                <div class="container-fluid nopadding">
                    <div class="row" *ngIf="this.selectedRole==12">
                        <div class="col-sm-6">
                            <span for="optumId">Assign a role</span><br><br>
                            <select class="drop-list" style="width: 100%;" (change)="onRoleChange(selectedRole)"
                                [(ngModel)]="selectedRole"
                                [ngClass]="{'inpError': (this.selectedRole==undefined && blncheckboxChecked==true)}">
                                <option value="-1" selected>-Select-</option>
                                <option value="10">Prescriber</option>
                                <option value="20">Admin</option>
                                <option value="12">Advisor</option>
                                <option value="11">Pharmacist</option>
                                <option value="13">Specialtypharmacist</option>
                            </select>
                        </div>
                    </div>
                    <div class="row" *ngIf="this.selectedRole!=12 && (this.selectedRole==10 || this.selectedRole==20)">
                        <div class="col-sm-3">
                            <p>
                                <span
                                    style="font-family:'FrutigerLTStd-Bold', 'Frutiger LT Std 65 Bold', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:700;">Showing:</span>
                                <span *ngIf="this.filterAddedPrescribersList.length>4"
                                    style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;">
                                    4 of {{this.filterAddedPrescribersList.length}}
                                    NPIs</span>
                                <span *ngIf="this.filterAddedPrescribersList.length<=4"
                                    style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;">
                                    {{this.filterAddedPrescribersList.length}} of {{this.filterAddedPrescribersList.length}}
                                    NPIs</span>

                            </p>
                        </div>
                        <div class="col-sm-3" style="padding-left:0px;">
                            <button type="button" class="btn btn-link fontbold"
                                *ngIf="this.filterAddedPrescribersList.length>4 && this.blnShowNPI==false"
                                (click)="showAllNpi()" style="font-size: 14px;">Show all NPIs</button>
                            <button type="button" class="btn btn-link fontbold"
                                *ngIf="this.filterAddedPrescribersList.length>4 && this.blnShowNPI==true" (click)="hideNpi()"
                                style="font-size: 14px;">Hide NPIs</button>
                        </div>
                    </div>
                    <div class="row" *ngIf="this.selectedRole!=12 && (this.selectedRole==11 || this.selectedRole==13)">
                        <div class="col-sm-3">
                            <p>
                                <span
                                    style="font-family:'FrutigerLTStd-Bold', 'Frutiger LT Std 65 Bold', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:700;">Showing:</span>
                                <span *ngIf="this.filterAddedPharmacyList.length>4"
                                    style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;">
                                    4 of {{this.filterAddedPharmacyList.length}}
                                    NPIs</span>
                                <span *ngIf="this.filterAddedPharmacyList.length<=4"
                                    style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;">
                                    {{this.filterAddedPharmacyList.length}} of {{this.filterAddedPharmacyList.length}}
                                    NPIs</span>

                            </p>
                        </div>
                        <div class="col-sm-3" style="padding-left:0px;">
                            <button type="button" class="btn btn-link fontbold"
                                *ngIf="this.filterAddedPharmacyList.length>4 && this.blnShowNPI==false"
                                (click)="showAllNpi()" style="font-size: 14px;">Show all NPIs</button>
                            <button type="button" class="btn btn-link fontbold"
                                *ngIf="this.filterAddedPharmacyList.length>4 && this.blnShowNPI==true" (click)="hideNpi()"
                                style="font-size: 14px;">Hide NPIs</button>
                        </div>
                    </div>
                    <div class="row" style="padding:15px 15px 0px 15px;"
                        *ngIf="((this.filterAddedPrescribersList!=undefined && this.filterAddedPrescribersList.length>0)||(this.filterAddedPrescribersList.length==0))&&(this.selectedRole!=12 && (this.selectedRole==10 || this.selectedRole==20))">
                        <div class="col-sm-6" style="padding:0px;margin-bottom:8px;" *ngIf="this.blnEditUser==true">
                            <button type="button" class="btn btn-secondary" (click)="goto('/prescribers/add')"
                                style="height:60px;width:100%;max-width:435px;font-family:'FrutigerLTStd-Bold', 'Frutiger LT Std 65 Bold', 'Frutiger LT Std';font-weight:700;">
                                <i class="fa fa-plus-square" aria-hidden="true"
                                    style="font-size:26px;vertical-align:middle;"></i>
                                <span>&nbsp;&nbsp;Add More NPIs or Pharmacy</span>
                            </button>
                        </div>

                        <div class="col-sm-6 p-0" *ngFor="let item of filterAddedPrescribersList; let i=index">
                                <div *ngIf="i<itemsToShow || this.blnShowNPI==true"
                                    style="height:60px; padding:10px; border:1px solid #b2b6b9;border-radius:2px;background-color:#f2f6f9;max-width:435px;margin-bottom:8px;">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <p style="margin-bottom:0px;">
                                                <span
                                                    style="font-family:'FrutigerLTStd-Bold', 'Frutiger LT Std 65 Bold', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:700;color:#333333;">{{item.firstName}}
                                                    {{item.middleInitial}}.{{item.lastName}}
                                                    <span>,{{item.primaryDegree}}</span></span>
                                            </p>

                                            <p>
                                                <span
                                                    style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;color:#333333;">NPI:</span>
                                                <span
                                                    style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;">{{item.npi}}</span>
                                            </p>
                                        </div>
                                        <div class="col-sm-2 nopadding" style="vertical-align:middle;line-height:35px;"
                                            *ngIf="this.blnEditUser==true">
                                            <button type="button" class="btn btn-link"
                                                style="color:#c60f13; font-size:14px;font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;"
                                                (click)="removeNPISelected(item)">
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="col-sm-6"
                            style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';height:60px;vertical-align:middle;line-height:40px;padding:10px;max-width:435px;margin-bottom:8px;"
                            *ngIf="this.blnEditUser==false && this.filterAddedPrescribersList.length==1 && this.selectedRole==10">
                            *Prescriber users may only have 1 NPI assigned to their account.
                        </div>
                    </div>

                    <div class="row" style="padding:15px 15px 0px 15px;"
                        *ngIf="((this.filterAddedPharmacyList!=undefined && this.filterAddedPharmacyList.length>0)||(this.filterAddedPharmacyList.length==0))&&(this.selectedRole!=12 && (this.selectedRole==11 || this.selectedRole==13))">
                        <div class="col-sm-6" style="padding:0px;margin-bottom:8px;" *ngIf="this.blnEditUser==true">
                            <button type="button" class="btn btn-secondary" (click)="goto('/provision/addpharmacy')"
                                style="height:60px;width:100%;max-width:435px;font-family:'FrutigerLTStd-Bold', 'Frutiger LT Std 65 Bold', 'Frutiger LT Std';font-weight:700;">
                                <i class="fa fa-plus-square" aria-hidden="true"
                                    style="font-size:26px;vertical-align:middle;"></i>
                                <span>&nbsp;&nbsp;Add More NPIs or Pharmacy</span>
                            </button>
                        </div>
                        <ng-container *ngFor="let item of filterAddedPharmacyList; let i=index">
                            <div class="col-sm-6" *ngIf="i<itemsToShow || this.blnShowNPI==true"
                                style="height:60px;padding:10px;border:1px solid #b2b6b9;border-radius:2px;background-color:#f2f6f9;max-width:435px;margin-bottom:8px;">
                                <div class="row">
                                    <div class="col-sm-10">
                                        <p style="margin-bottom:0px;">
                                            <span
                                                style="font-family:'FrutigerLTStd-Bold', 'Frutiger LT Std 65 Bold', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:700;color:#333333;">
                                                {{item.pharmacyName}}</span>
                                        </p>
                                        <p>
                                            <span
                                                style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;color:#333333;">NPI:</span>
                                            <span
                                                style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;">{{this.selectedPharmacyNPI}}</span>
                                        </p>
                                    </div>
                                    <div class="col-sm-2 nopadding" style="vertical-align:middle;line-height:35px;"
                                        *ngIf="this.blnEditUser==true">
                                        <button type="button" class="btn btn-link"
                                            style="color:#c60f13; font-size:14px;font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;"
                                            (click)="removeNPISelected(item)">
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-sm-6"
                            style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';height:60px;vertical-align:middle;line-height:40px;padding:10px;max-width:435px;margin-bottom:8px;"
                            *ngIf="this.blnEditUser==false && this.filterAddedPharmacyList.length==1 && (this.selectedRole==11 || this.selectedRole==13)">
                            *Pharmacy users may only have 1 NPI assigned to their account.
                        </div>
                    
                    </div>
                </div>
            </div>
            <div class="card-footer" *ngIf="this.blnEditUser==true&&this.selectedRole!=12">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <button class="btn btn-link fontbold offset-9" (click)="cancelClick()">Cancel</button>
                        <button class="btn btn-primary" style="width:15%;float: right;" (click)="saveEdits()">Save
                            Edits</button>
                    </div>
                </div>

            </div>
        </div>

        <div class="content-card no-shadow" *ngIf="this.isoptumIdFound!=undefined && this.blnAddUserAdmin==false">
            <div class="card-content header container-fluid bg-white" style="border-bottom:2px solid #ddd">
                <div class="row">
                    <div class="col-1 checkbox" style="min-height: 25px;">
                        <input type="checkbox" class="form-check-input" id="checkbox" name="checkbox"
                            style="width: 1.2em; height: 1.2em;border:2px solid gray;border-radius:0px"
                            (click)="checkboxClick()">
                    </div>
                    <div class="col-8">
                        <p class="fontbold" style="margin: 0;">User: {{this.OptumIdvalue}}</p>
                        <span class="paragraph">Email: {{this.email}}</span>
                    </div>
                    <div class="col-3 float-end">
                        <button type="button" class="btn btn-link fontbold" style="color: #c60f13; font-size: 14px;"
                            (click)="removeUser()">Remove
                            User</button>
                    </div>
                </div>
            </div>
            <div class="card-content body bg-white">
                <div class="container-fluid nopadding">
                    <div class="row">
                        <div class="col-sm-6">
                            <span for="optumId">Assign a role</span><br><br>
                            <select class="drop-list" style="width: 100%;" (change)="onRoleChange(selectedRole)"
                                [(ngModel)]="selectedRole"
                                [ngClass]="{'inpError': (this.selectedRole==undefined && blncheckboxChecked==true)}">
                                <option value="-1" selected>-Select-</option>
                                <option value="10">Prescriber</option>
                                <option value="20">Admin</option>
                                <option value="12">Advisor</option>
                                <option value="11">Pharmacist</option>
                                <option value="13">Specialtypharmacist</option>
                            </select>
                        </div>
                        <div class="col-sm-6" style="margin-top: 40px;">
                            <button type="button" class="btn btn-secondary fontbold" style="width: 100%; height: 44px;"
                                disabled="disabled" *ngIf="this.selectedRole==undefined || this.selectedRole==-1">
                                Add NPIs or Pharmacy
                            </button>
                            <button type="button" class="btn btn-secondary fontbold" style="width: 100%; height: 44px;"
                                *ngIf="this.selectedRole==10 || this.selectedRole==20"
                                (click)="goto('/prescribers/add')">
                                Add NPIs or Pharmacy
                            </button>
                            <button type="button" class="btn btn-secondary fontbold" style="width: 100%; height: 44px;"
                                *ngIf="this.selectedRole==11 || this.selectedRole==13"
                                (click)="goto('/provision/addpharmacy')">
                                Add NPIs or Pharmacy
                            </button>
                        </div>
                        <div class="col-sm-12" style="margin-top: 18px;" *ngIf="this.selectedRole==undefined || this.selectedRole=='-1'">
                            <span class="custom_span_style">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"
                                    style="widows: 20px;height: 18px;font-size: 15px;"></i>
                                Role is required to add NPIs.
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="container-fluid manage_prescribers_container fadeout-widget ng-scope" style="margin-top: 30px;"
        *ngIf="this.isoptumIdFound!=undefined || this.blnAddUserAdmin==true">
        <div class="row ps-3">
            <div class="col-sm-6 nopadding">
                <button type="button" class="btn btn-secondary fontbold" style="width: 100%; height: 44px;"
                    disabled="disabled" *ngIf="this.blncheckboxChecked==false && this.selectedRole!=12">
                    Add Same NPIs or Pharmacy to Selected Users
                </button>
                <button type="button" class="btn btn-secondary fontbold" style="width: 100%; height: 44px;"
                    disabled="disabled"
                    *ngIf="(this.blncheckboxChecked==true || this.blncheckboxChecked==false) && this.selectedRole==12">
                    Add Same NPIs or Pharmacy to Selected Users
                </button>
                <button type="button" class="btn btn-secondary fontbold" style="width: 100%; height: 44px;"
                    *ngIf="this.blncheckboxChecked==true && (this.selectedRole!=12 && this.selectedRole!=11 && this.selectedRole!=13)" (click)="goto('/prescribers/add')">
                    Add Same NPIs or Pharmacy to Selected Users
                </button>
                <button type="button" class="btn btn-secondary fontbold" style="width: 100%; height: 44px;"
                    *ngIf="this.blncheckboxChecked==true && (this.selectedRole==11 || this.selectedRole==13)" (click)="goto('/provision/addpharmacy')">
                    Add Same NPIs or Pharmacy to Selected Users
                </button>
            </div>
            <div class="col-sm-2"></div>
            <div class="col-sm-2">
                <button type="button" class="btn btn-secondary fontbold" style="width: 100%; height: 44px;" (click)="goto('/provision')">
                    Cancel
                </button>
            </div>
            <div class="col-sm-2" style="padding-right: 0px;">
                <button type="button" class="btn btn-primary fontbold" style="width:92%;height: 44px;"
                    disabled="disabled" *ngIf="this.blncheckboxChecked==false">
                    Provision
                </button>
                <button type="button" class="btn btn-primary fontbold" style="width:92%;height: 44px;"
                    *ngIf="this.blncheckboxChecked==true" (click)="provisionClick()">
                    Provision
                </button>
            </div>
        </div>
    </div>
</div>



<ng-template #optumIdProvisionedModal let-modal>
    <div class="p-3">
        <div class="text-center progress-notification"><i class="fa fa-ban" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            OptumId you have entered is already provisioned in <br> Dashboard.
        </div>
        <div class="row p-2">
            <div class="col text-center">
                <button type="button" class="btn btn-secondary" (click)="dismiss();">Ok</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #provisionOptumIdModal let-modal>
    <div class="p-3">
        <div class="text-center progress-notification"><i class="fa fa-ban" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            Please save edits (or) add NPIs to the following Users:<br><br>
            <li style="text-transform:uppercase;">{{this.OptumIdvalue}}</li>
        </div>
        <br>
        <div class="row p-2">
            <div class="col text-center">
                <button type="button" class="btn btn-secondary" (click)="dismiss();">Ok</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #LoadingContent let-modal>
    <div class="modal-body">
        <h3 class="text-center text-capitalize">Searching...</h3>
    </div>
</ng-template>

<ng-template #SelectOnePrescriberModal let-modal>
    <div class="content-card no-shadow">
        <div class="card-content header container-fluid bg-white" style="border-bottom:2px solid #ddd">
            <div class="row">
                <h2 class="header nomargin" style="text-transform:capitalize;text-align:left;"></h2>
                <span class="heading_3 ng-binding">Choose NPI
                <i class="fa fa-close icon-close pull-right" role="button" aria-label="Close"
                    (click)="modal.dismiss()"></i></span>
            </div>
        </div>
    <div class="card-content body bg-white">
        <div class="container-fluid nopadding">
            <div class="row">
                <div class="col-sm-12" style="text-align:left;">
                    <span class="paragraph"
                        style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-size:16px;">
                        Prescriber users may only have 1 NPI assigned to their account.<br> Please choose one below:
                    </span>
                </div>
            </div>
            <div class="row" style="margin-top:10px;">
                <div class="col-sm-6 nopadding chooseNpiModalRadioBtn ng-scope" style="text-align:left;"
                    *ngFor="let item of filterAddedPrescribersList;let i=index">
                    <div  class="form-check"
                    style="display: flex;flex-direction: row;width:100%;padding-top: 5px;padding-left: 5px;flex-wrap: wrap;margin-bottom: 5px;">
                        <label (click)="filterStatus=item.npi" class="radioInput" style="cursor: pointer;">
                            <input type="radio" id="{{item.npi}}" value="{{item.npi}}" [checked]>
                            <i class="fa fa-circle-o" *ngIf="filterStatus!==item.npi"></i>
                            <i class="fa fa-dot-circle-o" *ngIf="filterStatus===item.npi"></i>&nbsp;&nbsp;
                            <span
                            style="font-family:'FrutigerLTStd-Bold', 'Frutiger LT Std 65 Bold', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:700;color:#333333;font-size:14px;">{{item.firstName}}
                            {{item.middleInitial}}.{{item.lastName}}
                            <span>,{{item.primaryDegree}}</span></span><br>
                            <span style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;color:#333333;font-size:14px;padding-left:30px;">NPI:</span>
                            <span style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;font-size:14px;color:#333333">{{item.npi}}</span>
                        </label>
                    </div>
                </div>
                    <div class="form-check"
                        style="padding-top: 15px;padding-bottom: 15px;padding-left: 0px; font-size: 18px; color: #050709;">
                        <label (click)="filterStatus='none'" class="radioInput">
                            <input type="radio" value=''>
                            <i class="fa fa-circle-o" *ngIf="filterStatus!=='none'"></i>
                            <i class="fa fa-dot-circle-o" *ngIf="filterStatus==='none'"></i>&nbsp;&nbsp;
                            <span style="font-family:'FrutigerLTStd-Roman', 'Frutiger LT Std 55 Roman', 'Frutiger LT Std';font-weight:400;font-size:16px;color:#333333;display:inline-block;vertical-align:middle;">Assign a different NPI.</span>
                        </label>
                    </div>
                
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-sm-12 text-right">
                <button class="btn btn-link fontbold offset-8" (click)="dismiss();this.selectedRole=this.oldRole;">Cancel</button>
                <button class="btn btn-primary" style="width:15%;float: right;" (click)="selectPresNpi(filterStatus)">Select</button>
            </div>
        </div>
        </div>
    </div>

</ng-template>


<ng-template #provisionConfirmModal let-modal>
    <div class="p-3">
        <div class="text-center progress-notification"><i class="fa fa-check-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            Process is initiated. Users provisioning is in progress and <br>might take up to 30 minutes.
        </div>
        <div class="row p-2">
            <div class="col text-center">
                <button type="button" class="btn btn-secondary" (click)="dismiss()">Ok</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #OnChgePresPharModal let-modal>
    <div class="p-3">
        <div class="text-center progress-notification"><i class="fa fa-ban" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            Atleast one NPI is required to provision the user. Please <br> click on add more NPIs (or) cancel the edit.
        </div>
        <div class="row p-2">
            <div class="col text-center">
                <button type="button" class="btn btn-secondary" (click)="dismiss();">Ok</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #maxPresbrlimitexceed let-modal>
    <div class="p-4">
      <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
      <div class="text-center mt-2 p-2">
       You have reached the maximum number of prescribers <br>
       that can be added.Please remove a prescriber in order <br>
       to add a new one.</div>
      <div class="text-center p-2"><button
          class="btn btn-secondary"
          (click)="modal.dismiss()">OK</button></div>
    </div>
  </ng-template>