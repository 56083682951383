import { Injectable } from '@angular/core';
import { DrugSearchItem } from 'src/app/modals/drugSearch/drugsearchwebresponse';

export class QtyCalculated{
       qtycaliculated:string="";
       qtydesc:string="";
       qtyEntered:string="";
       awp:string="";
}

@Injectable({
  providedIn: 'root'
})
export class CalculateQuantityService {

  constructor() { }

  quantityCalculate(Quantity:number,drugSearchItem:DrugSearchItem):QtyCalculated {
      debugger;   
      var quantity=0;
      var quantityCal="";
      var PackageSizeUnitOfMeasure="",PackageDesc="",PackageSize="",awp="";
      var quantityCalObj = new QtyCalculated();

       if(drugSearchItem!=undefined){
              if(drugSearchItem.additionalField.length>0){
                     for(var key in drugSearchItem.additionalField){
                            if(drugSearchItem.additionalField[key].fieldDescription.toLowerCase()=="packagesizeunitofmeasure"){
                                   PackageSizeUnitOfMeasure=drugSearchItem.additionalField[key].fieldStringValue;
                            }
                            if(drugSearchItem.additionalField[key].fieldDescription.toLowerCase()=="packagedesc"){
                                   PackageDesc=drugSearchItem.additionalField[key].fieldStringValue;
                            }
                            if(drugSearchItem.additionalField[key].fieldDescription.toLowerCase()=="packagesize"){
                                   PackageSize=drugSearchItem.additionalField[key].fieldDecimalValue;
                            }
                            if(drugSearchItem.additionalField[key].fieldDescription.toLowerCase()=="awp"){
                                   awp=drugSearchItem.additionalField[key].fieldDecimalValue;
                            }
                     }
              }else{
                            //Additional fields are mission in response object
              }
              debugger;
              quantity= Quantity;
              //console.log("quantity entered :"+quantity);
              quantityCalObj.qtyEntered=""+Quantity;//Added by Kumar
              if(drugSearchItem.breakableIndicator.toLowerCase()=="u"){
                     ////console.log("unbreakable drug:"+breakableInd);
                     ////console.log("package size:"+PackageSize);
                     Quantity=Math.ceil(Quantity);
                     quantity=parseFloat((Quantity*parseFloat(PackageSize)).toFixed(3));
                     //console.log("quantity calculated:"+quantity);
              }
              // else if(drugSearchItem.breakableIndicator.toLowerCase()=="b"){
              //        ////console.log("breakable drug:"+breakableInd);
              //        ////console.log("package size:"+PackageSize);
              //        Quantity=Math.floor(Quantity);
              //        quantity=parseFloat((Quantity*parseFloat(PackageSize)).toFixed(3));
              //        //console.log("quantity calculated:"+quantity);
              // }
              if(PackageSizeUnitOfMeasure.toLowerCase()=="gm" || PackageSizeUnitOfMeasure.toLowerCase()=="ml"||PackageSizeUnitOfMeasure.toLowerCase()=="oz"){
                     quantityCal=quantity + " "+PackageSizeUnitOfMeasure.toLowerCase();
              } else if(PackageSizeUnitOfMeasure.toLowerCase()=="ea" ){
                     if(drugSearchItem.breakableIndicator.toLowerCase()=="b"){
                            if(quantity>1 && drugSearchItem.dosageFormDesc.toLowerCase() == "tablet"){
                                   quantityCal=quantity + " "+drugSearchItem.dosageFormDesc.toLowerCase()+"s";
                            }
                            else{
                                   quantityCal=quantity + " "+drugSearchItem.dosageFormDesc.toLowerCase(); 
                            }      
                     }else{
                            quantityCal=quantity + " "+PackageDesc;      
                     }
              } else {      
                     if(quantity>1 && drugSearchItem.dosageFormDesc.toLowerCase() == "tablet"){
                            quantityCal=quantity + " "+drugSearchItem.dosageFormDesc.toLowerCase()+"s";
                     } else {
                            quantityCal=quantity + " "+drugSearchItem.dosageFormDesc.toLowerCase(); 
                     }
              }
       }      
       debugger;
       quantityCalObj.qtycaliculated=""+quantity;
       quantityCalObj.qtydesc=quantityCal;
       quantityCalObj.qtyEntered=""+Quantity;
       quantityCalObj.awp = awp;
       return quantityCalObj;

  }
}
