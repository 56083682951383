<div>
    <div Class="back1">
        <div class="container-fluid " class="back2">
            <div class="container-fluid c1">
                <div class="row" style="margin: 0px;">
                    <div class="col-2" style="padding-right: 0px;width: auto;">
                        <img class="header_pcms_logo responsive font_color" src="assets/images/u407.jpeg"
                            alt="HTML5 Icon">
                    </div>
                    <div class="col" style="padding: 0px;margin-top: 20px;margin-bottom: 10px;color: #fff;">
                        <div class="page_header header_font">PreCheck MyScript Registration</div>
                        <div style="padding-left: 20px;max-width:585px;line-height:1.5;margin-top:10px;font-size:16px;">
                            Use the form below to grant or deny access to your support staff in PreCheck MyScript.</div>
                        <h6 style="padding-left: 20px;max-width:630px;line-height:1.5;margin-top:20px;font-size:16px;">
                            Granting access to your support staff will allow them to use tools within PreCheck MyScript
                            on your behalf, including submitting prior authorizations.</h6>
                    </div>
                </div>
            </div>
            <div class="container c4" style="padding-top:12px;">
                <div class="tab-content justify-content-center">
                    <div class="tab-pane fade show active" role="tabpanel">
                        <div class="card card_size old_card">
                            <div class="col-md-12 error-Style" *ngIf="npipinmismatch">
                                <i class="fa fa-ban font-bold"
                                    style="margin-left:10px;font-size: 14px;"></i>&nbsp;&nbsp;<span
                                    class="ng-binding">Prescriber NPI and Verification PIN do not match</span>
                            </div>
                            <div class="card-body" style="padding: 30px;">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="paragraph fontbold nopadding ng-binding" style="margin-bottom: 5px;">
                                            Please enter your NPI and the verification PIN located on the fax form. This
                                            PIN will expire in 3 days.
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="paragraph nopadding ng-binding" style="margin-bottom: 20px;">This PIN
                                            is associated with the person who is requesting access. Reference the fax
                                            form for requestor details.</p>
                                    </div>
                                </div>
                                <div class="row" ng-init="npiFocus=false;pinFocus=false;">
                                    <div class="col-xs-12 col-sm-6">
                                        <form [formGroup]="NPI_Form" id="fax-verfication">
                                            <label class="inputFieldLabel ng-binding" style="margin-bottom:5px"
                                                for="npi">Prescriber NPI</label>
                                            <input [ngClass]="{'error_border':((NPI_Form.controls.NPI_Field.errors?.['minlength'] 
                                                || NPI_Form.controls.NPI_Field.errors?.['pattern']) && show) 
                                                || (NPI_Form.controls.NPI_Field.errors?.['required'] && npiRequired && show) 
                                                || (npipinmismatch && show)}" formControlName="NPI_Field"
                                                (focus)="insideTheBox()" (input)="npiFeildTouched()"
                                                (blur)="outsideTheBox()" type="text" maxlength="10" minlength="10"
                                                class="form-control npi_default">
                                            <div
                                                style="position: relative;height: 20px;align-items: center;display: flex;">

                                                <span class="error-msg"
                                                    *ngIf="NPI_Form.controls.NPI_Field.errors?.['required'] && npiRequired && show">NPI
                                                    is required.</span>
                                                <span class="error-msg"
                                                    *ngIf="(NPI_Form.controls.NPI_Field.errors?.['minlength'] || NPI_Form.controls.NPI_Field.errors?.['pattern']) && show">
                                                    Please enter a 10-digit number.
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="col-xs-12 col-sm-6">
                                        <form [formGroup]="PIN_Form" id="fax-verfication">
                                            <label class="inputFieldLabel ng-binding" style="margin-bottom:5px"
                                                for="pin">Verification PIN</label>
                                            <input [ngClass]="{'error_border':((PIN_Form.controls.PIN_Field.errors?.['minlength']  
                                                || PIN_Form.controls.PIN_Field.errors?.['pattern']) && show) 
                                                || (PIN_Form.controls.PIN_Field.errors?.['required'] && pinRequired && show) 
                                                || (npipinmismatch && show) || (invalidPinFormat && show)}"
                                                formControlName="PIN_Field" (focus)="insideTheBox()"
                                                (input)="pinFeildTouched();onInput()" (blur)="outsideTheBox()"
                                                type="text" maxlength="9" minlength="9"
                                                class="form-control npi_default">
                                            <div
                                                style="position: relative;height: 20px;align-items: center;display: flex;">

                                                <span class="error-msg"
                                                    *ngIf="PIN_Form.controls.PIN_Field.errors?.['required'] && pinRequired && show">Verification
                                                    PIN is required.
                                                </span>

                                                <span class="error-msg"
                                                    *ngIf="PIN_Form.controls.PIN_Field.errors?.['pattern'] && show;else invalid1">Please
                                                    Please enter 8 numeric characters.</span>

                                                <ng-template #invalid1>
                                                    <span class="error-msg" *ngIf="invalidPinFormat==true && show">
                                                        Invalid PIN. Refer to fax for correct PIN.</span>
                                                </ng-template>



                                            </div>
                                            <!-- <p style="position:absolute;margin:5px 0px;top:70px"
                                            class="error-message ng-binding ng-hide"
                                            ng-show="(VerifyProviderForm.pin.$error.required &amp;&amp; !pinFocus &amp;&amp; !VerifyProviderForm.pin.$pristine)||(fields.blnformsubmittedonce &amp;&amp; !pinFocus &amp;&amp; VerifyProviderForm.pin.$error.required)">
                                            Verification PIN is required.</p>
                                        <p style="position:absolute;margin:5px 0px;top:70px"
                                            class="error-message ng-binding ng-hide"
                                            ng-show="!pinFocus &amp;&amp; (!VerifyProviderForm.pin.$error.required &amp;&amp; VerifyProviderForm.pin.$error.pattern)">
                                            Please enter 8 numeric characters.</p>
                                        <p style="position:absolute;margin:5px 0px;top:70px"
                                            class="error-message ng-binding ng-hide"
                                            ng-show="(!pinFocus &amp;&amp; fields.blnwrongpinattempt &amp;&amp; !VerifyProviderForm.pin.$error.pattern &amp;&amp; !VerifyProviderForm.pin.$error.required)">
                                            Invalid PIN. Refer to fax for correct PIN.</p> -->
                                        </form>
                                    </div>
                                </div>
                                <!-- <form [formGroup]="PIN_Form" id="fax-verfication">
                                    <div class="form-group">

                                        <input
                                            [ngClass]="{'error_border':PIN_Form.controls.PIN_Field.errors?.['minlength'] && show || PIN_Form.controls.PIN_Field.errors?.['pattern']  ,'error_border_shadow':PIN_Form.controls.PIN_Field.errors?.['pattern']}"
                                            formControlName="PIN_Field" (focus)="insideTheBox()"
                                            (blur)="outsideTheBox()" type="text" maxlength="6" minlength="6"
                                            class="form-control"
                                            style="height: 44px;border: 2px solid #626f7d;background-color: #fff;color: #050709;width: 100%;padding: 10px;border-radius: 0 0 2px 2px;font-size: 20px;text-align: center;">
                                        <div style="position: relative;height: 20px;align-items: center;display: flex;">

                                            <span class="error-msg"
                                                *ngIf="(PIN_Form.controls.PIN_Field.errors?.['pattern']);else invalid0">Invalid
                                                character.</span>
                                            <ng-template #invalid0>
                                                <span class="error-msg"
                                                    *ngIf="PIN_Form.controls.PIN_Field.errors?.['minlength'] && show">Please
                                                    enter a
                                                    6-digit PIN.</span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </form> -->


                            </div>
                            <div class="card-footer" style="border: none;background-color: rgba(242, 246, 249, 1);">
                                <div class="container c5">
                                    <div class="container-fluid ng-scope">
                                        <div class="row" style="text-align: end;">
                                            <div class="col-9" style="padding:10px 0px">
                                                <span class=" card-link clear font_bold_no_color"
                                                    (click)="onDenyAccess()"
                                                    style="cursor: pointer;font-size: 16px;">Deny
                                                    access</span>
                                            </div>
                                            <div class="col-3">
                                                <button type="submit" (click)="onGrantAccess()"
                                                    class="btn primary btn_primary search_button font_bold_no_color"
                                                    style="max-width:175px;width: 100%;">Grant
                                                    access
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="container-fluid" style="position: absolute;top:0; padding: 0;margin: 0;left: 0; right: 0;">
                <div style="display:flex;flex-direction:row;justify-content: center;" *ngIf="showErrorPopup==true">
                    <div style="display:flex;color: #c60f13;;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px;background-color: #fff4f4;
          width: 76%;flex-direction:row;">

                        <i class="fa fa-ban" style="font-size: 25px;padding-right: 10px;"></i>
                        <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
            font-size: 16px;flex-grow: 1;">We're unable to complete your request at this time. Please try again
                            later.</span>
                        <div class="fa fa-remove" style="font-size: 16px;" (click)="closeErrorPopup()"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #otpExceededPopup let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>The request to grant access has expired. Please contact the requestor so they can send a new
                    PIN.</span>

            </p>
            <br />
            <button class="btn btn-primary" (click)="modal.dismiss();">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #alreadyDenied let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>You have already denied access for this requestor.</span>

            </p>
            <br />
            <button class="btn btn-primary" (click)="modal.dismiss();">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #alreadyGranted let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>You have already granted access to this requestor.</span>

            </p>
            <br />
            <button class="btn btn-primary" (click)="modal.dismiss();">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #cancelledRecord let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>The request to grant access has been cancelled by the person who initiated it. You no longer have to take any action and may close the browser window.</span>
            </p>
            <br />
            <button class="btn btn-primary" (click)="modal.dismiss();">Ok</button>
        </div>
    </div>
</ng-template>
<ng-template #error let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>We are unable to process your request. Please try again later  </span>
            </p>
            <br />
            <button class="btn btn-primary" (click)="modal.dismiss();">Ok</button>
        </div>
    </div>
</ng-template>



<div class="popup-greybackground" *ngIf="vefifydenied" ></div>
<div class= "popup-container" *ngIf="vefifydenied">
    <div class="popup" >
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12 text-center" style="padding-right: 15px;padding-left: 15px;">
                <h1 style="margin-top: 20px;margin-bottom: 10px;    line-height: 1.1;">You have sucessfully granted/denied access.</h1>
            </div>
        </div>
        <div class="row step_two">
            <div class="col-xs-12 text-center header_image_styels" style="height:275px;color:white;">
                <p style="color:white;margin:20px 30px;text-align: center;" class="paragraph ng-binding">Did you know
                    you can register for PreCheck MyScript? It only take a few moments to complete registration. In PCMS
                    you can:</p>
                <div style="text-align: left; margin: 10px 45px;">
                    <span style="border: 2px solid #fff; border-radius:30px;display: inline-block;">
                        <img src="assets/images/prescriptioncheck.png"
                            style="width: 40px;height: 40px;">
                    </span>
                    <span class="paragraph success_popup_text" >Check prescription
                        coverage for patients</span>
                </div>
                <div style="text-align: left; margin: 10px 45px;">
                    <span style="border: 2px solid #fff; border-radius:30px;display: inline-block;">
                        <img src="assets/images/ePA.png" style="width: 40px;height: 40px;">
                    </span>
                    <span class="paragraph success_popup_text" >Submit electronic
                        prior authorizations</span>
                </div>
                <div style="text-align: left; margin: 10px 45px;">
                    <span style="border: 2px solid #fff; border-radius:30px;display: inline-block;">
                        <img src="assets/images/memberSearch.png"
                            style="width: 40px;height: 40px;">
                    </span>
                    <span class="paragraph success_popup_text " >View patients' prior
                        authorization history</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 text-center">
                <button type="button" class="btn primary btn-primary font_bold_no_color "
                (click)="registerForPreCheckMyScript()"
                    style="width:270px;margin:25px 0px 10px; height: 44px;">Register for PreCheck MyScript</button>
                <button type="button" 
                class="btn secondary btn-secondary font_bold_no_color" 
                (click)="loginToPreCheckMyScript()"
                style="width:270px;margin:10px 0px;height: 44px;">Log in to PreCheck MyScript</button>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 text-center" style="margin: 10px 0 20px;">
                <span class="paragraph" style="font-size: 14px !important;">You may close your browser if you
                    are done.</span>
            </div>
        </div>
    </div>
    </div>


  </div>