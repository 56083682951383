import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router,Event, NavigationEnd, RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkcoverage',
  templateUrl: './checkcoverage.component.html',
  styleUrls: ['./checkcoverage.component.css']
})
export class CheckcoverageComponent implements OnInit, OnDestroy {
  routeVal:string="";
  routeData!:Subscription;
  blnProgressBar:boolean;
  
  @ViewChild("cancelSearchConfirmation")
  cancelSearchConfirmationModal?:ElementRef<HTMLElement>;

  constructor(private router:Router,private modalService: NgbModal) { 
    this.blnProgressBar=true;
    this.routeData=this.router.events.subscribe((event:Event)=>{
      this.blnProgressBar=true;

      if(this.router.url.toLowerCase().indexOf("summary")!=-1){
        this.blnProgressBar=false;
      }
      // if(event instanceof NavigationEnd){
      //   this.blnProgressBar=true;
      //   if(event!=undefined && event.urlAfterRedirects!=undefined 
      //     && event.urlAfterRedirects.toLowerCase().indexOf("summary")!=-1){
      //       this.blnProgressBar=false;
      //     } else {
      //       this.blnProgressBar=true;
      //     }
      // }
    });
  }
  
  ngOnDestroy():void{
    if(this.routeData!=undefined)
      this.routeData.unsubscribe();
  }

  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
  }
  
  cancelSearch(){
    this.modalService.open(this.cancelSearchConfirmationModal,{size:'md',windowClass:'cancelPAConfirmation',backdrop:'static'});
  }
  goto(routeUrl:string){
    this.router.navigateByUrl("/"+routeUrl);
  }
}
