import { PersonGender } from "src/app/constants/appconstants"

export class MemberDetailWebResponsehemi {
    searchOutputMetaData!: SearchOutputMetaData
    pagination!: Pagination
    memberItems!: MemberItem[]
  }
  
  export interface SearchOutputMetaData {
    respCode: string
    correlationId: string
    respMessage: string[]
  }
  
  export interface Pagination {
    maxResultsExceededInd: boolean
    resultCount: number
  }
  
  export interface MemberItem {
    sourceSystemInstance: string
    memberId: string
    carrierId: string
    carrierName: any
    accountId: string
    accountName: any
    groupId: string
    groupName: any
    firstName: string
    lastName: string
    middleName: any
    familyType: string
    familyIndicator: any
    familyId: string
    personCode: string
    relationshipCode: string
    benefitResetDate: string
    languageCode: string
    durKey: any
    durProcessFlag: string
    multipleBirthCode: number
    originalEffectiveDate: string
    erisa: any
    isEmployee: any
    memberType: any
    altInsuranceMbrId: any
    altInsuranceFlag: any
    listReferenceIndicator: any
    activeIndicator: string
    contact: Contact
    demographics: Demographics
    hicIdentifier: HicIdentifier
    medicarePartD: any
    plan: Plan
    careFacility: any
    primaryProvider: any
    memberEligibility: MemberEligibility[]
    groupEligibility: GroupEligibility[]
    memberAncillary: any
    groupAncillary: any
    bpgInfo: any
    createUser: string
    createDate: string
    createTime: string
    updateUser: string
    updateDate: string
    updateTime: string
    memberKey: any
    eid: any
  }
  
  export interface Contact {
    address1: string
    address2: string
    address3: string
    city: string
    state: string
    zip: string
    zip2: any
    zip3: any
    country: string
    phoneNumber: string
    email: any
  }
  
  export interface Demographics {
    gender: PersonGender
    dateOfBirth: string
    preferredLanguage: string
  }
  
  export interface HicIdentifier {
    medicareHic: any
    medicareEffectiveDate: string
    medicareCoverageType: any
    msiMedicareHic: any
  }
  
  export interface Plan {
    planCode: string
    planEffDate: string
    planName: string
    planTypeCode: string
    planQualifier: string
    productSchedule: string
    planSitusState: any
  }
  
  export interface MemberEligibility {
    eligibilitySequenceNumber: number
    eligibilityStatus: string
    eligibilityStartDate: string
    eligibilityEndDate: string
    copayBrand: number
    copayGeneric: number
    copay3: number
    copay4: number
    clientProductCode: any
    clientRiderCode: any
    spendDownAmount: string
    plan: Plan2[]
    createUser: any
    createDate: string
    createTime: any
    updateUser: string
    updateDate: string
    updateTime: string
  }
  
  export interface Plan2 {
    planCode: string
    planEffDate: string
    planName: string
    planTypeCode: string
    planQualifier: string
    productSchedule: string
    planSitusState: any
  }
  
  export interface GroupEligibility {
    eligibilitySequenceNumber: number
    eligibilityStatus: string
    eligibilityStartDate: string
    eligibilityEndDate: string
    copayBrand: number
    copayGeneric: number
    copay3: number
    copay4: number
    copay5: number
    copay6: number
    copay7: number
    copay8: number
    benefitCode: any
    createUser: any
    createDate: string
    createTime: any
    updateUser: string
    updateDate: string
    updateTime: string
    plan: Plan3
  }
  
  export interface Plan3 {
    planCode: string
    planEffDate: string
    planName: string
    planTypeCode: string
    planQualifier: string
    productSchedule: string
    planSitusState: any
  }
  