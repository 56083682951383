export class SteptwoRegisterUserRequest {
    role !: number
    linkRequestId !: string
    reActivateInd !: string
    user!: User
    prescriber!: Prescriber
  }
  
  export class User {
    firstName!: string
    lastName!: string
    streetAddress!: string
    city!: string
    state!: string
    zipCode!: string
    phoneNumber!: string
    faxNumber!: string
    email!: string
    npi!: string
    optumID!: string
    uuid!: string
    country!: string
    educationQualification!: string
    addrLine2!: string
    presbrQualTypeId!: string
    instanceId!: string
  }
  
  export class Prescriber {
    firstName !: string
    lastName !: string
    streetAddress !: string
    city !: string
    state !: string
    zipCode !: string
    phoneNumber !: string
    faxNumber !: string
    email !: string
    npi !: string
    optumID !: string
    uuid !: string
    country !: string
    educationQualification !: string
    addrLine2 !: string
    presbrQualTypeId !: string
    instanceId !: string
  }
  