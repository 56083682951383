<div class="container-fluid background1">
    <div class="row">
        <div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1 text-center nopadding">
            <h1 style="color: #fff;">
                Initiate Benefit Inquiry
            </h1>
        </div>
    </div>
    <div class="row" style="padding-top: 40px;">
        <div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1">
            <div class="container-fluid p-0 homepagetilesgroup">
                <div class="row" style="justify-content: center;">
                    <div class="col-xs-12 col-md-4 text-center" style="padding-left: 20px;
                    padding-right: 10px;
                    padding-top: 0px;
                    padding-bottom: 0px;">
                        <a href="javascript:void(0)" class="homepagetile" (click)="goto('benefitinquiry/manualflow')">
                            <div class="thumbnail">
                                <img src="assets/images/ePA.png" alt="Electronic Prior Authorization"/>
                                <h2>EMR 
                                    <br/>
                                    Manual Verification</h2>
                                <p>Manual verification flow to 
                                    <br/>
                                    trigger RTB/BI Service.</p>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-md-4 text-center" style="padding-right: 20px;
                    padding-left: 10px;
                    padding-top: 0px;
                    padding-bottom: 0px;">
                        <a href="javascript:void(0)" class="homepagetile" (click)="goto('benefitinquiry/regressionflow')">
                            <div class="thumbnail">
                                <img src="assets/images/prescriptioncheck.png" alt="Prescription Check"/>                                
                                <h2>EMR 
                                    <br/>Regression Flow</h2>
                                <p>Regression scenarios can be 
                                    <br/>
                                    executed and verified.</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <router-outlet></router-outlet> -->

