<ng-template #memberNameTemplate let-data="data">
    <p class="large">
        <span class="fontbold"  style="text-transform: capitalize !important;">{{data.lastName}}</span>
        <br/>
        {{data.firstName}}
    </p>
</ng-template>
 <ng-template #drugNameTemplate let-data="data">
    <p class="large">{{data.drugName}}</p>
</ng-template>
<ng-template #prescriberNameTemplate let-data="data">
    <p class="large"><span class="fontbold">{{data.prescriberName}}{{data.presbrLstName}}</span><br/>{{data.presbrFirstName}}</p>
</ng-template>
<ng-template #caseIdTemplate let-data="data">
    <p class="large" [innerHtml]="data.caseId"></p>
</ng-template>
<!--<ng-template #startDateTemplate let-data="data">
    <p class="large" aria-labelledby="startDateTemplate">{{data.fromDate | date: 'MM/dd/YYYY'}}</p>
</ng-template>-->
<ng-template #dateCreatedTemplate let-data="data">
    <p class="" aria-labelledby="dateCreatedTemplate">{{data.dateSubmitted | date: 'MM/dd/YYYY'}}</p>
</ng-template>

<ng-template #endDateTemplate let-data="data">
    <p class="large" *ngIf="data.thruDate.length>0" aria-labelledby="endDateTemplate">{{data.thruDate | date: 'MM/dd/YYYY'}}</p>
    <p class="large" *ngIf="data.thruDate.length==0" aria-labelledby="endDateTemplate">---</p>
</ng-template>


<ng-template #paCancelledTemplate let-data="data">
    <p><span class="alert alert-danger resultsflag">Cancelled</span></p>
</ng-template>
<ng-template #paApprovedTemplate let-data="data">
    <p><span class="alert alert-success resultsflag">Approved</span></p>
</ng-template>
<ng-template #paDeniedTemplate let-data="data">
    <p><span class="alert alert-danger resultsflag">Denied</span></p>
</ng-template>
<ng-template #paPendingTemplate let-data="data">
    <p><span class="alert alert-warning resultsflag">Pending</span></p>
</ng-template>
<ng-template #paPaidTemplate let-data="data">
    <p><span class="alert alert-success resultsflag">Paid</span></p>
</ng-template>
<ng-template #paReversedTemplate let-data="data">
    <p><span class="alert alert-danger resultsflag">Reversed</span></p>
</ng-template>

<ng-template #historyServerErrorTemplate let-data="data">
    <p class="large">
        <span>We were unable to retrieve this information. Please</span>
        <button class="btn btn-link invisiblebtn" enableAction="true" name="tryagain">Try again</button>
    </p>
</ng-template>

<ng-template #errorPopup let-modal>
 <div class="p-3">
        <div class="row text-center">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        </div>
       <div class="text-center mt-2">
                An error occured. Please try again.
       </div>
      <div class="row border-0 mt-4 p-3 ">
        <button class="col-2  position-absolute top-80 start-50 translate-middle btn btn btn-outline-primary" (click)="modal.dismiss()">OK</button>
      </div>
</div>
</ng-template>
   <div class="submitted_PA_progress text-center" style="margin-top: 30px;"
     *ngIf="blnLoading">
                    <div>
                        <i class="fa fa-circle-o-notch fa-spin"
                            style="color: #ef6c00;font-size: 30px;display: inline-block;vertical-align: middle;"></i>
                        <span style="padding-left: 5px;position: relative;top: 2px">Loading...</span>
                    </div>
                </div>
<!--<div class="text-center" *ngIf="blnLoading">
    <loader></loader>
</div>-->

    <!--<ng-container *ngIf="!blnLoading">-->
        <ng-container *ngIf="!blnLoading">
            <datatable [headers]="submittedPaHeaders" [rows]="submittedPaHistory"
            (onHeaderClicked)="onHeaderClick($event)" (onCellClicked)="onCellClick($event)" aria-labelledby="memberSubmittedPAs">
            </datatable>
            <div class="text-center" *ngIf="totalEntries>1">
                <pagination aria-label="Member submitted PA pagination"  [totalEntries]="totalEntries" [totalPages]="totalPages" (onChangeEntriesPerPage)="onChangeEntriesPerPage($event)" (onPageChange)="onPageChange($event)"></pagination>
                <!-- <pagination [totalPages]="totalPages" (onPageChange)="onPageChange($event)"></pagination> -->
            </div>
            <p class="large noresults p-3" [hidden]="submittedPaHistory.length>0">
                There are no submitted prior authorizations.
            </p>
        </ng-container>