import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-medication-claims',
  templateUrl: './search-medication-claims.component.html',
  styleUrls: ['./search-medication-claims.component.css']
})
export class SearchMedicationClaimsComponent implements OnInit,OnDestroy {
  routeVal:string="";
  routeData!:Subscription;
  @Input() blnInitiatePA!:boolean;
  pageType:string="SearchMedicationClaims"
  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  ngOnDestroy():void{
    if(this.routeData!=undefined)
      this.routeData.unsubscribe();
  }


}
