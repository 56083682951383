<!-- <th [ngClass]="cell.cssClass" [attr.data-sort]="cell.sortable" [attr.tabindex]="cell.sortable?0:null" [attr.data-sortselected]="cell.selectedForSort"
[attr.data-sortorder]="cell.sortAscending" *ngFor="let cell of cells;index as i" (click)="onCellClick($event,cell,i)">
    <span [innerHtml]="cell.content"></span>
    <i class="fa fa-sort" *ngIf="cell.sortable"></i>
    <div class="headerborder"></div>
</th> -->

<th [ngClass]="cell.cssClass" [attr.data-sort]="cell.sortable" [attr.tabindex]="cell.sortable?0:null" [attr.data-sortselected]="cell.selectedForSort"
[attr.data-sortorder]="cell.sortAscending" *ngFor="let cell of cells;index as i" (click)="onCellClick($event,cell,i)">
    <span [innerHtml]="cell.content"></span>
    <i class="fa fa-sort" *ngIf="cell.sortable"></i>
    <div class="headerborder"></div>
</th>
