<div style="min-height: 800px;">
    <div class="back1">
        <div class="container-fluid back2 ">
            <div class="provisioning_headerimg col-12 col-md-12">
                <div class="container-fluid manage_prescribers_container fadeout-widget">
                    <div class="col-12 col-md-12 col-lg-12 prescriberbook_headertext">
                        <div class="heading_mega">Search Users</div>
                        <p style="margin: 0 !important;color: #fff !important;">
                            Search users by entering their Optum ID. Once added, you can change their details.
                        </p>
                    </div>
                    <div class="col-sm-12 nopadding content-card-holder fadeout-widget" style="margin-top: 20px;">
                        <div @fade class="content-card no-shadow">
                            <h2 class="header nomargin"
                                style="border-bottom: 2px solid #ddd;border-radius: 4px 4px 0 0;padding: 26px">
                                <span class="heading_3 " style="color: #626f7d !important;">Search User</span>
                            </h2>
                            <form name="optumidsearchform" [formGroup]="userIDForm">
                                <div style="padding: 26px 26px 0px;">
                                    <div class="container-fluid nopadding">
                                        <div class="row">
                                            <div class="col-10" style="height: 95px;">
                                                <label class="input_FieldLabel " for="optumId">Optum
                                                    ID</label><br>
                                                <input class="input_box" type="text" name="optumId" id="optumId"
                                                    formControlName="userID" placeholder="" minlength="6" maxlength="50"
                                                    aria-describedby="optumIdError"
                                                    style="font-size: 16px;opacity: 1 !important;" (blur)="clickedout()"
                                                    (focus)="clickedin()" (input)="inputEntered()"
                                                    [ngClass]="{'error_border':(userIDForm.controls.userID.errors?.['pattern'] || userIDForm.controls.userID.errors?.['minlength'] || userIDForm.controls.userID.errors?.['required']) && clickOutside && searchSubmitted}">
                                            </div>
                                            <div class="col-2" style="margin-top: 34px;padding-right: 15px;
                                            padding-left: 15px; ">
                                                <button type="submit" (click)="onSubmit()"
                                                    class="btn btn_primary btn-primary ">Search</button>
                                            </div>
                                        </div>
                                        <div style="height: 26px;position: relative;top: -6px;">
                                            <span class="error-msg"
                                                *ngIf="userIDForm.controls.userID.errors?.['required'] && searchSubmitted">OptumId
                                                required.</span>
                                            <span class="error-msg"
                                                *ngIf="userIDForm.controls.userID.errors?.['pattern'] && show;else invalid1">Invalid
                                                character.</span>

                                            <ng-template #invalid1>
                                                <span class="error-msg"
                                                    *ngIf="userIDForm.controls.userID.errors?.['minlength'] && show">Please
                                                    enter at least 6 characters.</span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid manage_prescribers_container ng-scope" style="margin-top: 20px; margin-bottom: 100px;">
        <div *ngIf="!userNotFound && displayData!=undefined">
            <div class="row">
                <div class="col-12 col-sm-9 col-lg-9">
                    <h1 class="heading_mega label_name text-capitalize" style="color: #424249;;">
                        {{displayData.prescriber.firstName}}
                        {{displayData.prescriber.lastName}}</h1>
                </div>
            </div>
            <div class="content-card no-shadow memberdetail" style="margin-top: 20px; padding: 30px;">
                <div class="row">
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2">
                        <span class="data_label_header">Optum ID</span>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                        <span class="data_label_header push-left">Role</span>
                    </div>
                    <div class="col-4 col-sm-3 col-md-3 col-lg-3">
                        <span class="data_label_header push-right ">Email ID</span>
                    </div>
                    <div class="col-3 col-sm-4 col-md-5 col-lg-5">
                        <span class="data_label_header push-right ">Address</span>
                    </div>
                </div>
                <div class="row" style="margin-top: 5px;">
                    <div class="col-3 col-sm-3 col-md-2 col-lg-2">
                        <span class="label_content "
                            style="word-break: break-word;">{{displayData.prescriber.optumId}}</span>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                        <span class="label_content push-left ">{{role}}</span>
                    </div>
                    <div class="col-4 col-sm-3 col-md-3 col-lg-3">
                        <span class="label_content push-right "
                            style="word-break: break-word;">{{displayData.prescriber.email}}</span>
                    </div>
                    <div class="col-3 col-sm-4 col-md-5 col-lg-5">
                        <div
                            *ngIf="displayData.prescriber.addressLine1!=undefined && displayData.prescriber.addressLine1!='' &&  displayData.prescriber.addressLine1!=null">
                            <span class="label_content push-right text-capitalize "
                                style="display: inline-block;">{{displayData.prescriber.addressLine1}}</span>
                            <br>
                        </div>
                        <div
                            *ngIf="displayData.prescriber.addressLine2!=undefined && displayData.prescriber.addressLine2!='' &&  displayData.prescriber.addressLine2!=null">
                            <span class="label_content push-right text-capitalize "
                                style="display: inline-block;">{{displayData.prescriber.addressLine2}}</span>
                            <br>
                        </div>
                        <div>
                            <span class="label_content push-right text-capitalize "
                                style="display: inline-block;">{{addressLine3}}</span>

                        </div>

                    </div>
                </div>
                <div class="row" style="margin-bottom: 5px;margin-top: 30px;">
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3">
                        <span class="data_label_header push-left ">Registered Date</span>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3">
                        <span
                            class="label_content push-left ">{{displayData.prescriber.registerDate.toString()|timestamptostandarddate}}</span>
                    </div>
                </div>
            </div>
            <div class="content-card-holder fadeout-widget" style="margin-top: 20px;">
                <div class="content-card no-shadow">
                    <div class="row header nomargin" style="padding: 26px;     border-bottom: 2px solid #ddd;
                    border-radius: 4px 4px 0 0;">
                        <div class="col-sm-10 nopadding">
                            <div style="margin-top: 10px;color: #424249;
                            font-size: 20px;">
                                <span>Other Information</span>
                            </div>
                        </div>
                        <div *ngIf="!editClicked" class="col-sm-2 nopadding text-center">
                            <button type="button" class="btn primary pull-right btn-primary" (click)="editUserDetails()"
                                style="font-family: Frutiger-Bold, Helvetica, Arial, sans-serif;
                                height: 44px;width: 100%;">Edit Details</button>
                        </div>
                        <div *ngIf="editClicked" class="col-sm-2 nopadding text-center"
                            style="display: flex;align-items: center;justify-content: center;">
                            <div *ngIf="editClicked" class="cancel" (click)="onCancelClick()">Cancel</div>
                        </div>
                    </div>
                    <div class="body memberdetail" style="padding: 26px;">
                        <div class="row">
                            <div class="col-3 col-sm-3 col-md-3 col-lg-3">
                                <span class="data_label_header ">NPI</span>
                            </div>
                            <div class="col-3 col-sm-3 col-md-3 col-lg-3">
                                <span class="data_label_header push-left ">Internal</span>
                            </div>
                            <div class="col-4 col-sm-6 col-md-6 col-lg-6">
                                <span class="data_label_header push-right ">Status</span>
                            </div>
                        </div>
                        <form [formGroup]="editDetailsForm">
                            <div class="row" style="margin-top: 5px;margin-bottom: 30px;">
                                <div class="col-3 col-sm-3 col-md-3 col-lg-3">
                                    <span *ngIf="!editClicked || advisorRole"
                                        class="label_content">{{displayData.prescriber.npi}}</span>
                                    <input *ngIf="editClicked && !advisorRole" type="text" class="edit_input" formControlName="NPIFeild"
                                        (focus)="onFocus()" (blur)="onBlur()" style="opacity: 1 !important;"
                                        [ngClass]="{'error_border':(editDetailsForm.controls.NPIFeild.errors?.['pattern']
                                         || editDetailsForm.controls.NPIFeild.errors?.['maxlength'] || editDetailsForm.controls.NPIFeild.errors?.['minlength'] || editDetailsForm.controls.NPIFeild.errors?.['required']) && show2 && formSubmitted
                                        ,'error_border_shadow':(editDetailsForm.controls.NPIFeild.errors?.['pattern'] || editDetailsForm.controls.NPIFeild.errors?.['maxlength'] ||editDetailsForm.controls.NPIFeild.errors?.['required'] || editDetailsForm.controls.NPIFeild.errors?.['minlength'] ) && show3 && formSubmitted}">
                                </div>
                                <div class="col-3 col-sm-3 col-md-3 col-lg-3">
                                    <span *ngIf="!editClicked && displayData.prescriber.userType=='I'"
                                        class="label_content push-left ">Yes</span>
                                    <span *ngIf="!editClicked && displayData.prescriber.userType!='I'"
                                        class="label_content push-left ">No</span>
                                    <div *ngIf="editClicked" class="toggle-swicth">
                                        <label class="toggle-switch1">
                                            <input type="checkbox" formControlName="internalFeild">
                                            <span class="slider1">
                                                <div *ngIf="editDetailsForm.get('internalFeild')?.value"
                                                    style="display: flex;justify-content: start;width: 100%;height: 100%;align-items: center;">
                                                    <span style="padding-left: 20px;">Yes</span>
                                                </div>
                                                <div *ngIf="!editDetailsForm.get('internalFeild')?.value"
                                                    style="display: flex;justify-content: end;width: 100%;height: 100%;align-items: center;">
                                                    <span style="padding-right: 20px;">No</span>
                                                </div>

                                            </span>

                                        </label>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4" style="padding-left: 7px">
                                    <span *ngIf="!editClicked && displayData.prescriber.approveInd=='Y'"
                                        class="alert alert-success act_inct ">Active</span>
                                    <span *ngIf="!editClicked && displayData.prescriber.approveInd!='Y'"
                                        class="alert alert-danger act_inct">Inactive</span>
                                    <div *ngIf="editClicked" class="toggle-swicth">
                                        <label class="toggle-switch">
                                            <input type="checkbox" formControlName="statusFeild">
                                            <span class="slider">
                                                <div *ngIf="editDetailsForm.get('statusFeild')?.value"
                                                    style="display: flex;justify-content: start;width: 100%;height: 100%;align-items: center;">
                                                    <span style="padding-left: 17px;">Active</span>
                                                </div>
                                                <div *ngIf="!editDetailsForm.get('statusFeild')?.value"
                                                    style="display: flex;justify-content: end;width: 100%;height: 100%;align-items: center;">
                                                    <span style="padding-right: 8px;">Inactive</span>
                                                </div>

                                            </span>

                                        </label>
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 ng-scope" *ngIf="editClicked">
                                    <button type="button" class="btn primary pull-right btn-primary"
                                        style="height: 40px;width: 100%;" (click)="onSaveClick()">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="userNotFound" class="col-sm-12 noresults_box_style" style="margin-top: 40px;">
            <h2 style="margin-top: 10px;">No users found.</h2>
        </div>
    </div>
</div>

<ng-template #errorPopup let-modal>
    <div class="container-fluid favouriteActionError">
        <div class="row">
            <div class="col text-center">
                <i class="fa fa-ban" aria-hidden="true"></i>
            </div>
        </div>
        <div class="row">
            <div class="col paragraph large text-center">
                NPI details not found. Please try with a different one.
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Ok</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #successPopup let-modal>
    <div class="container-fluid favouriteActionError">
        <div class="row">
            <div class="col text-center">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
            </div>
        </div>
        <div class="row">
            <div class="col paragraph large text-center">
                User details updated successfully.
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Ok</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #LoadingContent1 let-modal>
    <div class="modal-body">
        <h3 class="text-center text-capitalize">Searching...</h3>
    </div>
</ng-template>