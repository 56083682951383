import { Pipe, PipeTransform } from '@angular/core';

import {DUR_LABEL, DUR, DUR_PROFESSIONAL_SERVICECODES, DUR_RESULTOF_SERVICECODES} from 'src/app/constants/appconstants';

@Pipe({
  name: 'durdescription'
})
export class DurdescriptionPipe implements PipeTransform {

  transform(value: unknown ,blnOnlyPrefix:boolean=false,...args: unknown[]): string | undefined {
    if(typeof value =="number" && DUR[value]!=undefined && DUR_LABEL.get(value)!=undefined){
      if(!blnOnlyPrefix)
        return DUR_LABEL.get(value);
      else
        return DUR_LABEL.get(value)?.split("-").splice(0,2).join("-");
    }
    return "";
  }

}

@Pipe({
  name: 'durprfsnlSrvcCd'
})
export class DurProfessionalServiceCodePipe implements PipeTransform {

  transform(value: string ,...args: string[]): string | undefined {
    if(value != undefined){
      if(DUR_PROFESSIONAL_SERVICECODES.get(value)!=undefined)
        return DUR_PROFESSIONAL_SERVICECODES.get(value);
      else
        return value;
    }
    return "";
  }
}

@Pipe({
  name: 'durrsltSrvcCd'
})
export class DurResultOfServiceCodePipe implements PipeTransform {

  transform(value: string ,...args: string[]): string | undefined {
    if(value != undefined){
      if(DUR_RESULTOF_SERVICECODES.get(value)!=undefined)
        return DUR_RESULTOF_SERVICECODES.get(value);
      else
        return value;
    }
    return "";
  }
}


