<!-- Success Popup -->
<div class="container-fluid" style="position: absolute;top:0; padding: 0;margin: 0;left: 0; right: 0;"
    *ngIf="addressChangeSuccessful==true" #vanish>
    <div style="display:flex;flex-direction:row;justify-content: center;position: relative;top: 8px;">
        <div
            style="position:absolute;;display:flex;flex-direction:row;justify-content: center;position: relative; top: 15px;top: 60px; width: 63%; z-index: 2;">
            <div
                style="display:flex;    background-color: #e4f3e4;color: #007a4b;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px; width: 76%;flex-direction:row; width: 100%;">

                <i class="fa fa-check" style="font-size: 25px;padding-right: 10px;"></i>
                <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
                font-size: 16px;flex-grow: 1;"> Preferred address for <span class="capitalize">
                        {{newAddressPrescriberName}} </span> has been successfully changed.
                </span>
            </div>
        </div>
    </div>
</div>

<!-- Failure Popup  -->
<div class="container-fluid" style="position: absolute;top:0; padding: 0;margin: 0;left: 0; right: 0;"
    *ngIf="addressChangeSuccessful==false" #vanishFailedScenario>
    <div style="display:flex;flex-direction:row;justify-content: center;position: relative;top: 8px;">
        <div
            style="position:absolute;;display:flex;flex-direction:row;justify-content: center;position: relative; top: 15px;top: 60px; width: 63%; z-index: 2;">
            <div class="text-danger"
                style="display:flex;background-color: #e4f3e4;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px; width: 76%;flex-direction:row; width: 100%;">
                <i class="fa fa-exclamation-circle" style="font-size: 25px;padding-right: 10px;"></i>
                <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
                font-size: 16px;flex-grow: 1;"> <span class="capitalize text-danger">{{newAddressPrescriberName}} </span>
                </span>
            </div>
        </div>
    </div>
</div>

<!-- Success Popup2  -->
<div class="container-fluid" style="position: absolute;top:0; padding: 0;margin: 0;left: 0; right: 0;"
    *ngIf="popup==false" #vanishSuccessScenario2>
    <div style="display:flex;flex-direction:row;justify-content: center;position: relative;top: 8px;">
        <div
            style="position:absolute;;display:flex;flex-direction:row;justify-content: center;position: relative; top: 15px;top: 60px; width: 63%; z-index: 2;">
            <div
                style="display:flex;    background-color: #e4f3e4;color: #007a4b;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px; width: 76%;flex-direction:row; width: 100%;">

                <i class="fa fa-check" style="font-size: 25px;padding-right: 10px;"></i>
                <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
                font-size: 16px;flex-grow: 1;"><span class="capitalize">{{newAddressPrescriberName}} </span>
                </span>
            </div>
        </div>
    </div>
</div>

<div class="col container-fluid mb-5 p-0" @explainerAnim>
    <div class="col d-print-none background2" style="width: 100%;">
        <div class="col-8 offset-2 timelinetitle pb-5 pt-5">
            <h3 class="timeline h3 text-light">Prescriber Profile</h3>
            <div class="row mb-3 mt-3">
                <div class="col-md-6"><span class="text-light mt-2 h6">View and change your preferred address, as well
                        as manage
                        support staff to whom you have granted access.</span></div>
            </div>
        </div>
    </div>
    <div class="col" style="position: relative; top:-50px;">
        <!-- Start of provider info -->
        <div class="mt-2 timelinetitle descriptionSection d-print-none">
            <div class="mt-2 col-8 offset-2 mb-5 d-print-none shadow">
                <div class="p-3 bg-white">
                    <h4 class="text-capitalize p-2">PROVIDER INFORMATION</h4>
                </div>
                <div class="m-0 p-3 bg-white border-2 border-top row">
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-4 row text-muted">
                                <label>First Name</label>
                                <label class="text-dark">{{this.firstName}}</label>
                            </div>
                            <div class="col-4 row text-muted">
                                <label>Last Name</label>
                                <label class="text-dark">{{this.lastName}}</label>
                            </div>
                            <div class="col-4 row text-muted">
                                <label>NPI</label>
                                <label class="text-dark">{{this.NPI}}</label>
                            </div>
                        </div>
                        <div class="row mt-3">

                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-4 row text-muted">
                                <label>Preferred Address</label>
                                <label class="text-dark">{{this.prefferedAddress1}}</label>
                                <label class="text-dark">{{this.prefferedAddress2}}</label>
                            </div>
                            <div class="col-4 row text-muted">
                            </div>
                            <div class="col-4 row text-muted">
                                <!-- <label>Phone</label> -->
                                <label class="text-dark"><i class="fa fa-phone" aria-hidden="true"></i> &nbsp;
                                    {{this.phone}}</label>
                                <label class="text-dark"><i class="fa fa-fax" aria-hidden="true"></i> &nbsp;
                                    {{this.fax}}</label>
                            </div>
                        </div>
                    </div>

                    <a *ngIf="blnShowAlternateAddress==false" (click)="blnShowAlternateAddress=true"
                        class="btn invisiblebtn btn-link fontbold mt-5"
                        style="display: block; text-align: left; width: auto;"><span>Change preferred address </span><i
                            aria-hidden="true" class="fa fa-angle-right"
                            style="font-size: 24px !important; vertical-align: bottom;"></i></a>

                </div>
                <div *ngIf="blnShowAlternateAddress==true">
                    <presbr-change-address [prescID]="this.NPI" [instanceID]="this.instancId"
                        (onSelectNewaddress)="storeNewAddress($event)"></presbr-change-address>
                    <div class="row m-0 bg-white">
                        <div class="col p-4">
                            <button class="btn btn-secondary float-end fontbold">
                                <span (click)="onSelectnewAddress()">Save</span>
                            </button>
                            <a class="btn invisiblebtn btn-link float-end fontbold me-2"
                                (click)="blnShowAlternateAddress=false">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End of Provider Info -->

            <!-- Start of staff info -->
            <div class="mt-2 timelinetitle descriptionSection d-print-none">
                <div class="mt-2 col-8 offset-2 shadow mb-5 d-print-none">
                    <div class="p-3 bg-white">
                        <h4 class="text-capitalize p-2">SUPPORT STAFF</h4>
                    </div>
                    <div class="m-0 p-3 bg-white border-2 border-top row">
                        <div class="row mb-3 mt-3">
                            <div class="col-md-6">
                                <span class="mt-2 h6">View support staff to whom you have granted access.</span>
                                <span>You can remove their access at any time.</span>
                            </div>
                        </div>


                        <div class="p-2">
                            <datatable [headers]="headers" [rows]="entries" (onHeaderClicked)="onHeaderClicked($event)"
                                (onCellClicked)="onCellClicked($event)">
                            </datatable>
                            <div class="col-sm-12 text-center" id="pharmacyLoader" *ngIf="blnLoader">
                                <div><i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i><span style="font-size: 16px;">
                                        &nbsp;Loading ...</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End of staff info -->

            <!-- Start of staff info -->
            <div class="mt-2 timelinetitle descriptionSection d-print-none">
                <div class="mt-2 col-8 offset-2 shadow mb-5 d-print-none">
                    <div class="p-3 bg-white">
                        <h4 class="text-capitalize p-2">ACCESS REQUESTS</h4>
                    </div>
                    <div class="m-0 p-3 bg-white border-2 border-top row">
                        <div class="row mb-3 mt-3">
                            <div class="col-md-10">
                                <span class="mt-2 h6">Approve or deny PCMS access requests submitted by support
                                    staff.</span>
                                <span>Requests expire after 72 hours, and will be removed after 24 hours of
                                    expiration.</span>
                            </div>
                        </div>
                        <div class="p-2">
                            <datatable [headers]="headers2" [rows]="entries2"
                                (onHeaderClicked)="onHeaderClicked($event)" >
                            </datatable>
                            <div class="col-sm-12 text-center" *ngIf="blnLoader">
                                <div><i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i><span style="font-size: 16px;">
                                        &nbsp;Loading ...</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End of staff info -->
        </div>
    </div>



    <ng-template #nameTemplate let-data="data">
        <div class="memberData">
            <span [innerHtml]="data.lastName" class="text-capitalize fontbold"></span>
            <br />
            <span [innerHtml]="data.firstName" class="text-capitalize"></span>
        </div>
    </ng-template>

    <ng-template #emailTemplate let-data="data">
        <div class="memberData">
            {{data.email}}
        </div>
    </ng-template>

    <ng-template #phoneTemplate let-data="data">
        <div class="memberData">
            {{data.primaryTelephoneNumber}}
        </div>
    </ng-template>

    <ng-template #dateGrantedTemplate let-data="data">
        <div class="memberData">
            {{data.dateGranted | date:'MM/dd/yyyy'}}
        </div>
    </ng-template>

    <ng-template #timeRemainingTemplate let-data="data">
        <div class="memberData">
            {{data.remainingHrs}} hrs {{data.remainingMins}} mins
        </div>
    </ng-template>

    <ng-template #removeTemplate let-data="data">
        <div class="memberData" enableAction="true">
            <a class="btn invisiblebtn btn-link fontbold text-danger" enableAction="true">
                <span>Remove </span>
            </a>
        </div>
    </ng-template>


    <ng-template #approveDenyTemplate let-data="data">
        <div class="memberData row ">
            <div class="approveDenyParent" style="width: 140px;">
                <p class="nopadding">
                    <button name="approveBtn" class="btn secondary pull-left approveBtn approvemouseleft"
                        [ngClass]="{'d-none':data.denybtnhover}" (mouseover)="data.approvebtnhover=true"
                        (mouseout)="data.approvebtnhover=false" (click)="this.approveRequest(data)">
                        <i name="approveBtn" class="fa fa-check" style="left:16px;position:static;"
                            aria-hidden="true"></i>
                        <span name="approveBtn">Approve</span>
                    </button>
                    <button name="denyBtn" class="btn secondary pull-right denyBtn" style="display: block;"
                        [ngClass]="{'d-none':data.approvebtnhover}" (mouseover)="data.denybtnhover=true"
                        (mouseout)="data.denybtnhover=false" (click)="this.denyRequest(data)">
                        &nbsp;<i name="denyBtn" class="fa fa-close" style="left:16px;position:static;"
                            aria-hidden="true"></i>
                        &nbsp;
                        <span name="denyBtn">Deny</span>
                    </button>
                </p>
            </div>

        </div>
    </ng-template>

    <ng-template #noSavedHistoryTemplate let-data="data">
        <p class="large">No saved members.</p>
    </ng-template>

    <ng-template #removeConfirmPopup let-modal>
        <!-- <div class="p-3">
        <div class="row text-center"><i class="fa fa-ban text-danger" aria-hidden="true"></i></div>
        <div class="text-center mt-2">Are you sure you want to remove access?<br>
            This individual will no longer be able to submit PAs on your behalf.</div>
        <div class="row border-0 mt-4 p-3 ">
            <button class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
            <button class="btn btn-primary rounded-pill" (click)="modal.dismiss();">Remove</button>
        </div>
    </div> -->
        <div class="modal-body text-center errorModal" aria-labelledby="removeConfirmModel">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large">
                Are you sure you want to remove access?<br />
                This individual will no longer be able to submit PAs on your behalf.
            </p>
        </div>
        <div style="background-color: rgba(242, 246, 249, 1);" class="p-3 text-center">
            <button class="btn btn-secondary me-2" (click)="modal.dismiss()">Cancel</button>
            <button class="btn btn-primary" (click)="modal.dismiss(); removeStaffmember()">Remove</button>
        </div>
    </ng-template>

    <ng-template #tryAgainPopup let-modal>
        <!-- <div class="p-3">
        <div class="row text-center"><i class="fa fa-ban text-danger" aria-hidden="true"></i></div>
        <div class="text-center mt-2">Are you sure you want to remove access?<br>
            This individual will no longer be able to submit PAs on your behalf.</div>
        <div class="row border-0 mt-4 p-3 ">
            <button class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
            <button class="btn btn-primary rounded-pill" (click)="modal.dismiss();">Remove</button>
        </div>
    </div> -->
        <div class="modal-body text-center errorModal" aria-labelledby="removeConfirmModel">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large">
                An error occurred when trying to remove access.<br />
                Please try again.
            </p>
        </div>
        <div style="background-color: rgba(242, 246, 249, 1);" class="p-3 text-center">
            <button class="btn btn-secondary me-2" (click)="modal.dismiss()">Cancel</button>
            <button class="btn btn-primary" (click)="modal.dismiss();this.tryAgain();">Try again</button>
        </div>
    </ng-template>