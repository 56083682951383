import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableHeaderCellData, TableClickEvent } from 'src/app/modals/datatable/datatable';

@Component({
  selector: '[th]',
  templateUrl: './tableheader.component.html',
  styleUrls: ['./tableheader.component.css']
})
export class TableheaderComponent implements OnInit{
  // @Input()
  // cells:TableHeaderCellData[]=[];

  // @Output()
  // onCellClicked:EventEmitter<TableClickEvent<any>> = new EventEmitter<TableClickEvent<any>>();

  // constructor() { }

  // ngOnInit(): void {
  // }

  // private resetSelectedColumnForSort(header:TableHeaderCellData):void{
  //   this.cells.forEach((cell:TableHeaderCellData)=>{
  //     cell.selectedForSort=false;
  //   });
  //   header.selectedForSort=true;
  // }

  // onCellClick(_event:any, header:TableHeaderCellData,_index:number):void{
  //   if(header.sortable){
  //     this.resetSelectedColumnForSort(header);
  //     header.sortAscending = !header.sortAscending;
  //     this.onCellClicked.emit({event:_event,cell:header,column:_index, row:-1});
  //   }
  // }

  @Input()
  cells:TableHeaderCellData[]=[];
 
  @Output()
  onCellClicked:EventEmitter<TableClickEvent<any>> = new EventEmitter<TableClickEvent<any>>();
 
  constructor() { }
 
  ngOnInit(): void {
  }
 
  private resetSelectedColumnForSort(header:TableHeaderCellData):void{
    this.cells.forEach((cell:TableHeaderCellData)=>{
      cell.selectedForSort=false;
    });
    header.selectedForSort=true;
  }
 
  onCellClick(_event:any, header:TableHeaderCellData,_index:number):void{
    if(header.sortable){
      this.resetSelectedColumnForSort(header);
      header.sortAscending = !header.sortAscending;
      this.onCellClicked.emit({event:_event,cell:header,column:_index, row:-1});
    }
  }
}


