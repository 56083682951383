import { DRUG_QUALIFIER, PersonGender } from 'src/app/constants/appconstants';
import { Status } from '../webresponsestatus';
import { environment } from 'src/environments/environment';

export class Drugsearchwebrequest {
    ndc!:string;
    drugName!:string;
    productNameAbbr!:string;
    genericName!:string;
    productIdQldr!:DRUG_QUALIFIER;
    instanceId!:string;
    excludeOnUnitDoseUnitUse:boolean=false;
    genericSubstitutionAllowed:boolean=false;
    forceGenericSubstitution:boolean=false;
    genericSubstitutionGPI!:string;
    genericSubstitutionOTCCode!:string;
    lob!:string;
    excludeOnNdcListNames:boolean=true;
}

export class Diagnosissearchwebrequest{
    diagnosisCode!:string;
    diagnosisDescription!:string;
}

export class FavoriteDrugsDetail {
    drugDescription!: string;
    drugCoded!: DrugCoded[];
    productIdQlfr!: string;
    drugInfoId!: string;
}

export class FavoriteDrugsDetailResponse {
    favoriteDrugsDetail!: FavoriteDrugsDetail[];
    loggedPresbrId!: string;
    loggedUuid!: string;
    status!: Status;
}

export class MedicationPrescribed {
    drugInfoId!: string;
}

export class RemoveFavRootObject {
    medicationPrescribed!: MedicationPrescribed;
    loggedPresbrId!: string;
    loggedUuid!: string;
}

export class DrugCoded {
    code!: string;
}

export class AddMedicationPrescribed {
    drugDescription!: string;
    drugCoded!: DrugCoded[];
    productIdQlfr!: string;
    drugInfoId!: string;
}

export class AddFavRootObject {
    medicationPrescribed!: AddMedicationPrescribed;
    loggedPresbrId!: string;
    loggedUuid!: string;
}

export class SelectedMedicine {
    drugSearchModel!: string;
    MedStrength!: string;
    Quantity!: string;
    Days!: string;
    DrugAdmin!: string;
    DiagnosisCode!: string;
}
export class MemberSearchItem {
    activeInd!:string;
    memberId!:string;
    firstName!:string;
    lastName!:string;
    carrierId!:string;
    accountId!:string;
    groupId!:string;
    instanceId!:string;
    dateOfBirth!:string;
    effectiveFromDate!:string;
    effectiveThruDate!:string;
    planId!:string;
    planEffectiveDate!:string;
    address!:Address;
    lob!:string;
    pdl!:string;
    relationshipCode!:string;
    personCode!:string;
    gender!:PersonGender;
     //Below properties doesn't come from server rest API
     isMedD:boolean=false;
     isFavorite!:boolean;
}

export class Address{
    address1!:string;
    address2!:string;
    address3!:string;
    city!:string;
    state!:string;
    zip1!:string;
    zip2!:string;
    zip3!:string;
    country!:string;
}