module.exports = {
    CONTEXT_PATH: '/',
    PORT: '8080',
    SSO_AUTHURL: 'https\://identity.onehealthcareid.com/oidc/authorize',
    SSO_TOKENURL:'https\://identity.onehealthcareid.com/oidc/token',
    SSO_CLIENTID:'PCMS101147',
    SSO_CLIENT_SECRET:"aa2668c8da38a22864242a116bf460ac9379eff87c54a3e1f1412e92e536c444a159e7b107ed9b8ba32e8fcd89042046",
    SSO_SCOPE:'openid profile email',
    SSO_STATE:'PreCheckMyScript',
    SSO_RESPONSETYPE:'code',
    BECKEND_HOST:'https://pcms-services-prod.optum.com/',
    PROVISIONING_BECKEND_HOST:'https://pcms-pro-services-ctc-prod-hcc.optum.com/'
}