export class MedDmemberRequest {
    searchInputMetaData!: SearchInputMetaData
    activeInd!: string
    memberId!: string
    carrierId!: string
    accountId!: string
    groupId!: string
  }
  
  export class SearchInputMetaData {
    rXClaimInstanceId!: string
  }
  