import { Component, OnInit } from '@angular/core';
import { USER_ROLE } from 'src/app/constants/appconstants';
import { ProfileService } from 'src/app/services/profile/profile.service';
import {Router} from '@angular/router';
import { PAGE_TYPE } from 'src/app/constants/routeconstants';
import { ViewAllRouteState } from 'src/app/constants/routeconstants';
import {Title} from "@angular/platform-browser";
import { LoggerService } from 'src/app/services/logger/logger.service';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';

@Component({
  selector: 'advisorhome',
  templateUrl: './advisorhome.component.html',
  styleUrls: ['./advisorhome.component.css']
})
export class AdvisorhomeComponent implements OnInit {
  username!:string;
  userrole!:USER_ROLE;
  maxHistoryCount:number=10;
  recentSearchSession:any = undefined;
  recentSearchDrugSession:any = undefined;

  constructor(private profileService:ProfileService,protected router:Router,private memberSearchHelper:MembersearchService){
    this.username="";
    if(this.profileService.loginuser!=undefined){
      this.username = this.profileService.loginuser.username.replace(',',"");
      this.userrole = this.profileService.USER_ROLE;
    }
   //for member search and drug search recent search session
   if(sessionStorage.getItem("recentMemberSearches")!=undefined)
    this.recentSearchSession= JSON.parse(""+sessionStorage.getItem("recentMemberSearches"));
    if(sessionStorage.getItem("recentDrugSearches")!=undefined)
    this.recentSearchDrugSession= JSON.parse(""+sessionStorage.getItem("recentDrugSearches"));
    sessionStorage.clear();
    if(this.recentSearchSession!=undefined)
    sessionStorage.setItem("recentMemberSearches",JSON.stringify(this.recentSearchSession));
    console.log(this.recentSearchSession);
    if(this.recentSearchDrugSession!=undefined)
      sessionStorage.setItem("recentDrugSearches",JSON.stringify(this.recentSearchDrugSession));
      console.log(this.recentSearchDrugSession);
  }
  
  ngOnInit(): void {
    
    
  }
  goto(routeUrl:string){
    debugger;
     // sessionStorage.setItem('',"true");
    this.router.navigateByUrl("/"+routeUrl);
    if(this.profileService.loginuser?.prescriber?.presbrId!=undefined){
      this.memberSearchHelper.getFavouriteMembers(this.profileService.loginuser.prescriber.presbrId).then((response:any)=>{  
      },(error:any)=>{});
    }
  }
}
