
<div class="container-fluid viewAll savedHistory">
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
            <h1 name="title" class="fs-3" style="font-weight: bold; text-align: left;">Search for a Member</h1>
            <h2>Enter the required fields to select a member.</h2>
        </div>
    </div>
    <span>
       <app-findmember [pageType]="pageType"></app-findmember>
    </span>
</div>   