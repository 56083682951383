import { Status } from "../webresponsestatus"

export class SubmitSingleUserProvisioningRequest {
    provisionId!: any
    presbrId!: string
    provisionUsers!: ProvisionUser[]
  }
  
  export class ProvisionUser {
    optumId!: string
    uuid!: string
    firstName!: string
    lastName!: string
    userType!: string
    note!: string
    email!: string
    prescriberSearchResponseList!: PrescriberSearchResponseList[]
    pharmacyDetailsResponseList!: any[]
    npiList!: string[]
    role!: string
  }
  
  export class PrescriberSearchResponseList {
    response!: Response
    status!: Status
  }
  
  export class Response {
    maxRowExceededInd!: boolean
    prescriberSearchItems!: PrescriberSearchItem[]
  }
  
  export class PrescriberSearchItem {
    identifiers!: Identifier[]
    lastName!: string
    firstName!: string
    middleInitial!: string
    address!: Address
    phone1!: string
    additionalFields!: AdditionalField[]
    instanceId!: string
  }
  
  export class Identifier {
    id!: string
    qualifier!: string
  }
  
  export class Address {
    address1!: string
    address2!: string
    city!: string
    state!: string
    zip1!: string
    zip2!: string
    zip3!: string
    country!: string
  }
  
  export class AdditionalField {
    fieldDescription!: string
    fieldStringValue!: string
  }
  