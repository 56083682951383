export class getRTBDetailsRequest {
    invocationContext!: InvocationContext
    benefitInquiryRequest!: BenefitInquiryRequest
  }
  
  export class InvocationContext {
    consumingApp: string="";
    invocationTimestamp!: string
  }
  
  export class BenefitInquiryRequest {
    userContext!: UserContext
    serviceContext!: ServiceContext
    transactionHeader!: TransactionHeader
    insuranceSegment!: InsuranceSegment
    patientSegment!: PatientSegment
    claimSegment!: ClaimSegment
    prescriberSegment!: PrescriberSegment
    durOrPpsSegment: DurOrPpsSegment |null=null;
  }
  
  export class UserContext {
    userId!: string
    userIdType!: string
  }
  
  export class ServiceContext {
    correlationId!: string
  }
  
  export class TransactionHeader {
    binNumber!: string
    processorControlNumber!: string
    serviceProviderIdQualifier!: string
    serviceProviderId!: string
    requestAlternateDrugs!: boolean
    requestAlternatePharmacies!: boolean
    identifyAlternatePharmaciesOnly!: boolean
    alternatePharmacies!: AlternatePharmacy[]
    pbmCarrier!: string
  }
  
  export class AlternatePharmacy {
    serviceProviderIdQualifier!: string
    serviceProviderId!: string
  }
  
  export class InsuranceSegment {
    cardholderId!: string
    groupId!: string
    personCode!: string
    patientRelationshipCode!: string
  }
  
  export class PatientSegment {
    patientId!: string
    dateOfBirth!: string
    patientGenderCode!: string
    patientLastName!: string
    patientFirstName!: string
    patientZipCode!: string
    placeOfService!: string
    patientResidence!: string
  }
  
  export class ClaimSegment {
    productORServiceIdQualifier!: string
    productORServiceId!: string
    quantityDispensed!: string
    daysSupply!: string
    dawOrProductSelectionCode!: string
  }
  
  export class PrescriberSegment {
    prescriberIdQualifier!: string
    prescriberId!: string
  }
  
  export class DurOrPpsSegment {
    reasonForServiceCode!: string
    professionalServiceCode!: string
    resultOfServiceCode!: string
  }
  