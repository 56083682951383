import { Component, OnInit, Input, ViewChild, TemplateRef, HostListener } from '@angular/core';

import { MemberPAHistorywebrequest, PAHistorywebrequest } from 'src/app/modals/pahistory/pahistorywebrequest';
import { PAHistorywebresponse, PAResponseHistory } from 'src/app/modals/pahistory/pahistorywebresponse';
import { TableCellData, TableRowData, TableHeaderCellData, TableClickEvent, DataTableType} from 'src/app/modals/datatable/datatable';
import { PahistoryService } from 'src/app/services/pahistory/pahistory.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { VIEWALL_SUBMITTEDPA_STATUS, VIEWALL_SUBMITTEDPA_STATUS_LABELS, APPCONSTANTS} from 'src/app/constants/appconstants';
import { DatetoDisplayString } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { FilterobjectsByTextPipe } from 'src/app/pipes/filterobjects/filterobjects.pipe';
import { StringToSentenceCase } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { EpaInitiationResponse, SavedPAResponse } from 'src/app/modals/epa/priorauthresponse';
import { QuestionSet, Question, QuestionType, AnswerValue, UserGivenAnswer } from 'src/app/modals/epa/questionsetviewmodal';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { MemberDetailService } from 'src/app/services/memberdetail/memberdetail.service';

@Component({
  selector: 'submittedpahistory',
  templateUrl: './submittedpahistory.component.html',
  styleUrls: ['./submittedpahistory.component.css']
})
export class SubmittedpahistoryComponent implements OnInit {

  submittedPaHeaders: TableHeaderCellData[];
  submittedPaHistory: TableRowData<PAResponseHistory>[];
  private sourceData:PAResponseHistory[];
  datatableType:DataTableType = DataTableType.TRANSPARENT;
  blnLoading:boolean;
  blnServerError:boolean;
  quesArry:any;
  questionArry:any;
  answArry:any;
  attachmentArry:any;
  role!: number;
  totalPages:number;
  currentPage:number;
  totalEntries: number=0;
  recordsPerPage:number = environment.paginationRecordsPerPage;
  
  private firstHeader:TableHeaderCellData = new TableHeaderCellData();
  private secondHeader:TableHeaderCellData = new TableHeaderCellData();
  private thirdHeader:TableHeaderCellData = new TableHeaderCellData();
  private forthHeader:TableHeaderCellData = new TableHeaderCellData();
  private fifthHeader:TableHeaderCellData = new TableHeaderCellData();
  //private sixthHeader:TableHeaderCellData = new TableHeaderCellData();


  @ViewChild("memberNameTemplate",{static:true})
  memberNameTemplateRef?:TemplateRef<HTMLElement>;

  @ViewChild("drugNameTemplate",{static:true})
  drugNameTemplateRef?:TemplateRef<HTMLElement>

  @ViewChild("prescriberNameTemplate",{static:true})
  prescriberNameTemplateRef?:TemplateRef<HTMLElement>;

  @ViewChild("caseIdTemplate",{static:true})
  caseIdTemplateRef?:TemplateRef<HTMLElement>;

  @ViewChild("dateCreatedTemplate",{static:true})
  dateCreatedTemplateRef?:TemplateRef<HTMLElement>;

  @ViewChild("paCancelledTemplate",{static:true})
  paCancelledTemplateRef?:TemplateRef<HTMLElement>;

  @ViewChild("paApprovedTemplate",{static:true})
  paApprovedTemplateRef?:TemplateRef<HTMLElement>;

  @ViewChild("paDeniedTemplate",{static:true})
  paDeniedTemplateRef?:TemplateRef<HTMLElement>;

  @ViewChild("paPendingTemplate",{static:true})
  paPendingTemplateRef?:TemplateRef<HTMLElement>;

  @ViewChild("paPaidTemplate",{static:true})
  paPaidTemplateRef?:TemplateRef<HTMLElement>;

  @ViewChild("paReversedTemplate",{static:true})
  paReversedTemplateRef?:TemplateRef<HTMLElement>;

  @ViewChild("historyServerErrorTemplate",{static:true})
  historyServerErrorTemplateRef?:TemplateRef<HTMLElement>;

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;

  @Input()
  memberId!:string;

  @Input()
  searchText:string="";
  private fieldsToConsiderForFilteration:string[];

  @Input()
  filterByStatus:VIEWALL_SUBMITTEDPA_STATUS=VIEWALL_SUBMITTEDPA_STATUS.ALL;
  
  private _fromDate: Date;
  public get fromDate() : Date {
    return this._fromDate;
  }
  @Input()
  public set fromDate(v : Date) {
    this._fromDate = v;
  }

  
  private _toDate: Date;
  public get toDate() : Date {
    return this._toDate;
  }
  @Input()
  public set toDate(v : Date) {
    this._toDate = v;
  }

  @Input()
  maxResults!:number;
  
  constructor(private pahistory:PahistoryService, 
    private loginProfile:ProfileService,private router: Router,
    private dateToDisplayStringConversion: DatetoDisplayString,
    private filterobjectsByTextPipe:FilterobjectsByTextPipe,
    private epaServiceHelper:MemberDetailService,
    private stringToSentenceCase:StringToSentenceCase,private modalService: NgbModal, private logger: LoggerService) {
    this.role=this.loginProfile.USER_ROLE;
    this.firstHeader.content = "Name";
    this.firstHeader.sortable=true;
    this.secondHeader.content = "Medication";
    this.thirdHeader.content = "Case ID";
    this.forthHeader.content = "Date created";
    this.forthHeader.sortable = true;
    this.fifthHeader.selectedForSort = true;
    this.fifthHeader.content = "Status";
    
    this.submittedPaHeaders = [];
    this.submittedPaHeaders.push(this.firstHeader);
    this.submittedPaHeaders.push(this.secondHeader);
    this.submittedPaHeaders.push(this.thirdHeader);
    this.submittedPaHeaders.push(this.forthHeader);
    this.submittedPaHeaders.push(this.fifthHeader);
    
    this.submittedPaHistory=[];



    //Following dates are used to fetch history from server.
    let currentDate:Date = new Date();
    //Represent 12AM of next date. Meaning, It considers complete 24h of Today.
    currentDate.setDate(currentDate.getDate()+1); 
    this._toDate = currentDate;

    //Resenting to represent today's date.
    currentDate = new Date();
    //This history will be defaulted to last 30 days from now if no dates are supplied.
    currentDate.setDate(currentDate.getDate()-183);
    this._fromDate = currentDate;

    this.totalPages=1;
    this.currentPage=1
    this.blnLoading=false;
    this.blnServerError=false;
    this.sourceData=[];
    this.fieldsToConsiderForFilteration=[];
    this.fieldsToConsiderForFilteration.push("lastName");
    this.fieldsToConsiderForFilteration.push("firstName");
    this.fieldsToConsiderForFilteration.push("drugName");
    this.fieldsToConsiderForFilteration.push("presbrLstName");
    this.fieldsToConsiderForFilteration.push("presbrFirstName");
    this.fieldsToConsiderForFilteration.push("caseId");
    this.fieldsToConsiderForFilteration.push("dateSubmitted");
    this.fieldsToConsiderForFilteration.push("paStatus");
  }
  
  ngOnInit(): void {
    
      this.reload();
    
  }

  reload(blnHardReload:boolean=true):void{
    
    if(blnHardReload){
      if(this.role==13||this.role==10){
        let webrequest:PAHistorywebrequest = new PAHistorywebrequest();
        webrequest.fromDate = this.dateToDisplayStringConversion.transform(this.fromDate);
        webrequest.toDate = this.dateToDisplayStringConversion.transform(this.toDate);
        webrequest.maxResults = this.maxResults;
        //webrequest.adminPresbrID = this.loginProfile.loginuser.prescriber?.presbrId;
        webrequest.providerNPI = this.loginProfile.loginuser.prescriber?.npi;
        debugger;
        webrequest.providerId = this.loginProfile.loginuser.prescriber?.uuid;
        this.blnLoading=true;
        this.blnServerError=false;
        this.sourceData=[];
        this.resetPaginationDefaults();
        this.pahistory.getSubmittedPAHistory(webrequest).then((response:PAHistorywebresponse)=>{
          this.blnLoading=false;
          if(this.memberId!=undefined){
            this.sourceData = response.paResponseHistory.filter((item)=>item.memberId==this.memberId);
          }else{
            this.sourceData = response.paResponseHistory;
          }
          this.loadHistory();      
        },(error:any)=>{
          this.submittedPaHistory=[];
          this.blnLoading=false;
          this.blnServerError=true;
          this.loadHistory();
        }).catch((reason:any)=>{
          this.submittedPaHistory=[];
          this.blnLoading=false;
          this.blnServerError=true;
          this.loadHistory();
        });
      } 
      else{
      let webrequest:PAHistorywebrequest = new PAHistorywebrequest();
      webrequest.fromDate = this.dateToDisplayStringConversion.transform(this.fromDate);
      webrequest.toDate = this.dateToDisplayStringConversion.transform(this.toDate);
      webrequest.maxResults = this.maxResults;
      webrequest.adminPresbrID = this.loginProfile.loginuser.prescriber?.presbrId;
      webrequest.providerId = this.loginProfile.loginuser.prescriber?.uuid;
      this.blnLoading=true;
      this.blnServerError=false;
      this.sourceData=[];
      this.resetPaginationDefaults();
      this.pahistory.getSubmittedPAHistory(webrequest).then((response:PAHistorywebresponse)=>{
        this.blnLoading=false;
        if(this.memberId!=undefined){
          this.sourceData = response.paResponseHistory.filter((item)=>item.memberId==this.memberId);
        }else{
          this.sourceData = response.paResponseHistory;
        }
        this.loadHistory();      
      },(error:any)=>{
        this.submittedPaHistory=[];
        this.blnLoading=false;
        this.blnServerError=true;
        this.loadHistory();
      }).catch((reason:any)=>{
        this.submittedPaHistory=[];
        this.blnLoading=false;
        this.blnServerError=true;
        this.loadHistory();
      });
    } 
  }else {
      this.loadHistory();
    }
  }
  private filterSourceDataByStatus():PAResponseHistory[]{
    
    return this.sourceData.filter((history:PAResponseHistory)=>{
        if(parseInt(this.filterByStatus.toString()) == VIEWALL_SUBMITTEDPA_STATUS.ALL)
          return true;
        if(history.paStatus!=undefined && history.paStatus.length>0)
          return VIEWALL_SUBMITTEDPA_STATUS_LABELS.get(parseInt(this.filterByStatus.toString())) == history.paStatus 
          || VIEWALL_SUBMITTEDPA_STATUS_LABELS.get(parseInt(this.filterByStatus.toString())) == this.stringToSentenceCase.transform(history.paStatus);
        else
          return false;
    });
  }
  private resetPaginationDefaults():void{
    this.currentPage=1;
    this.totalPages=1;
  }
  private readCurrentPagePaHistory():PAResponseHistory[]{
    
    const filteredSourceData:PAResponseHistory[]=this.filterobjectsByTextPipe.transform(this.filterSourceDataByStatus(),this.searchText,this.fieldsToConsiderForFilteration);
    this.totalEntries=filteredSourceData.length;
    if(this.maxResults==undefined){
      const startIndex:number = (this.currentPage-1) * this.recordsPerPage;
      const endIndex:number = (this.currentPage * this.recordsPerPage);
      this.totalPages=(filteredSourceData.length%this.recordsPerPage)!=0?Math.floor(filteredSourceData.length/this.recordsPerPage)+1: Math.floor(filteredSourceData.length/this.recordsPerPage);
      
      return filteredSourceData.slice(startIndex, endIndex);
    } else {
      return filteredSourceData;
    }
    
  }
  public loadHistory():void{
    
    this.submittedPaHistory=[];
    if(!this.blnServerError){
      this.readCurrentPagePaHistory().forEach( (history:PAResponseHistory) => {
        
        const row:TableRowData<PAResponseHistory> = new TableRowData<PAResponseHistory>();
        
        const firstCell:TableCellData<PAResponseHistory> = new TableCellData();
        firstCell.data = history;
        firstCell.sortable = true;
        firstCell.template = this.memberNameTemplateRef;
        row.cells.push(firstCell);
 
        const secondCell:TableCellData<PAResponseHistory> = new TableCellData();
        secondCell.data=history;
        secondCell.template=this.drugNameTemplateRef;
        row.cells.push(secondCell);
 
        const thirdCell:TableCellData<PAResponseHistory> = new TableCellData();
        thirdCell.data=history;
        thirdCell.template=this.caseIdTemplateRef;
        row.cells.push(thirdCell);
 
        const fourCell:TableCellData<PAResponseHistory> = new TableCellData();
        fourCell.data=history;
        fourCell.template=this.dateCreatedTemplateRef;
        row.cells.push(fourCell);
 
        const fifthCell:TableCellData<PAResponseHistory> = new TableCellData();
        fifthCell.data=history;
        
        switch(history.paStatus?.toLowerCase())
        {
         case "cancelled":
         case "closed":
           fifthCell.template=this.paCancelledTemplateRef;
           break;
         case "approved":
          fifthCell.template=this.paApprovedTemplateRef;
           break;
         case "denied":
          fifthCell.template=this.paDeniedTemplateRef;
           break;
         case "pending":
          fifthCell.template=this.paPendingTemplateRef;
           break;
         case "paid":
          fifthCell.template=this.paPaidTemplateRef;
           break;
         case "reversed":
          fifthCell.template=this.paReversedTemplateRef;
           break;
        }
        row.cells.push(fifthCell);
 
        this.submittedPaHistory.push(row);
      });
    } else {
      this.datatableType = DataTableType.TRANSPARENT;
      const row:TableRowData<PAResponseHistory> = new TableRowData<PAResponseHistory>();
      row.disabled=true;
      row.cssClass="norow";

      const firstCell:TableCellData<PAResponseHistory> = new TableCellData<PAResponseHistory>(); 
      firstCell.template = this.historyServerErrorTemplateRef;
      firstCell.clickable=false;
      firstCell.colspan = this.submittedPaHeaders.length;
      row.cells.push(firstCell);
      this.submittedPaHistory.push(row);
    }
    
  }
  onHeaderClick(headerData:TableClickEvent<TableHeaderCellData>){
  }
  onCellClick(clickEvent:TableClickEvent<PAResponseHistory>){
    // 
    if(clickEvent.event.target.getAttribute("name")=="tryagain"){
      this.reload();
    }
    // if(clickEvent.column == 0 || clickEvent.column == 1 || clickEvent.column == 2 || clickEvent.column == 3 || clickEvent.column == 4 || clickEvent.column == 5){
     
     this.logger.userAction("PA Selected");
      sessionStorage.setItem("pAdetails",JSON.stringify(clickEvent.cell?.data));
      this.epaServiceHelper.resumeCellClickPA(""+clickEvent.cell?.data?.caseId).then((response:SavedPAResponse) => {
        
        if(response.paInitiationResponseWeb.body==undefined){
          
          this.quesArry = [];
        }
        else {
     this.quesArry= response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet.question;
       }
     //console.log("data",response);
     if(response.paResumeDetails!=undefined){
       
     this.attachmentArry = response.paResumeDetails.paAttachment;
     //console.log("attachments",this.attachmentArry);
     }
     else{
       this.attachmentArry=[];
     }
     //console.log("quesArry",this.quesArry);
     sessionStorage.setItem("Array",JSON.stringify(this.quesArry));
    //sessionStorage.setItem("Modal",JSON.stringify(response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet));
     sessionStorage.setItem("Attachements",JSON.stringify(this.attachmentArry));
     this.router.navigateByUrl("/PriorAuthorizationDetails");
      
  });
  // }
  
}

onRowClick(clickEvent:TableClickEvent<PAResponseHistory>){
  // 
  this.onCellClick(clickEvent);
}

  onPageChange(pageNumber:any){
    this.currentPage=pageNumber;
    this.loadHistory();
  }

  onChangeEntriesPerPage(entriesPerPage: any){
    this.recordsPerPage = entriesPerPage;
    this.loadHistory();
  }
}
