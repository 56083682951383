import { PRESCRIBER_QUALIFIER } from "src/app/constants/appconstants";

export class PrescriberSearchWebRequest {
    lastName: string='';
    firstName: string='';
    prescriberState: string='';
    prescriberCity: string='';
    prescriberId: string='';
    prescriberIdQualifier:PRESCRIBER_QUALIFIER = PRESCRIBER_QUALIFIER.Empty;
}

export class PrescriberDetailWebRequest{
    instanceId:string="";
    prescriberId:string="";
    prescriberIdQualifier:PRESCRIBER_QUALIFIER = PRESCRIBER_QUALIFIER.Empty;
}

/**************************Prescriber Favorite*******************/

export class PrescriberFavoriteWebRequest {
    lastName:string="";
	firstName:string="";
    address:string="";
    npi: string = "";
    presbrId: string="";
    instcId: string="";
    uuid: string="";
}

/**********************verify provider request*************************/
export class VerifyProviderRequest {
    adminPresbrID: string = "";
    uuid: string = "";
  }
  
  /******************Manage Prescribers*********************************/
  export class PrescriberAddressBookRequest {
    adminPresbrId!: string
  }

  /***************Favorite Prescriber**********************************/
  export class changeDefaultPrescriberRequest {
    adminPrescriberId!: string
    prescriberIdNew!: string
    prescriberIdOld!: string
  }
  

  export class supportStaffRequest{
    isPendingAccessRqst!:string;
    npi!:string;
  }

  export class changeAdminRequest{
    presbrPriSecRoleId!: number|undefined;
    adminUpdateStatus!: number|undefined;
    adminPresbrId!: number|undefined;
  }