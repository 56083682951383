import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BenefitinquiryhomepageComponent } from 'src/app/components/benefitinquiryhomepage/benefitinquiryhomepage.component';
import { BenefitinquirymanualflowComponent } from 'src/app/components/benefitinquirymanualflow/benefitinquirymanualflow.component';
import { RouterModule, Routes } from '@angular/router';
import { BenefitinquiryregressionflowComponent } from 'src/app/components/benefitinquiryregressionflow/benefitinquiryregressionflow.component';
import { BenefitinquiryComponent } from 'src/app/components/benefitinquiry/benefitinquiry.component';
import { DrugsearchComponent } from 'src/app/components/drugsearch/drugsearch.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// export const benefitinquiryhomepageroutes:Routes=
// [
//   {
//     path:'', 
//   redirectTo:'/benefitinquiry/homepage',
//   pathMatch:'full'
// },
//   {path:'manualflow', component:BenefitinquirymanualflowComponent, data:{breadcrumb:{label:'EMR Manual Verification'}}, pathMatch:'full'},  
//   {path:'regressionflow', component: BenefitinquiryregressionflowComponent, data:{breadcrumb:{label:'EMR Regression FLow'}},pathMatch:'full'},
// ];

export const benefitinquiryroutes: Routes = [
  {path:'',redirectTo:'/benefitinquiry/homepage',pathMatch:'full'},
  {path:'homepage', component: BenefitinquiryhomepageComponent, data:{breadcrumb:{label:'Benefit Inquiry',step:1}},pathMatch:'full'},
  {path:'manualflow', component:BenefitinquirymanualflowComponent, data:{breadcrumb:{label:'EMR Manual Verification',step:2}}, pathMatch:'full'},  
  {path:'regressionflow', component: BenefitinquiryregressionflowComponent, data:{breadcrumb:{label:'EMR Regression FLow',step:3}},pathMatch:'full'},
];

@NgModule({
  declarations: [   
    BenefitinquiryComponent,
    BenefitinquiryhomepageComponent,
    BenefitinquirymanualflowComponent,
    BenefitinquiryregressionflowComponent,
    DrugsearchComponent

  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule.forChild(benefitinquiryroutes),
    NgbModule
  ],
  exports:[    
    BenefitinquiryhomepageComponent,
    BenefitinquirymanualflowComponent,
    BenefitinquiryregressionflowComponent,
    BenefitinquiryComponent,
    
  ]
})
export class BenefitinquiryModule { }