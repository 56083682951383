import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrescriberfilterComponent } from 'src/app/components/prescriberfilter/prescriberfilter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [PrescriberfilterComponent],
  imports: [    
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports:[PrescriberfilterComponent,FormsModule]
})
export class FilterforprescriberModule { }
