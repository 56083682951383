import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { APPCONSTANTS, BOOLEAN_FLAG, PRESCRIBER_QUALIFIER, ROLES, USER_ROLE } from 'src/app/constants/appconstants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationhelperService } from 'src/app/services/registrationhelper/registrationhelper.service';
import { Router } from '@angular/router';
import { registrationService } from 'src/app/services/registrationSteps/registration.service';
import { SteponePharmacyDetailsRequest } from 'src/app/modals/registration/SteponePharmacyDetailsRequest';
import { SteponePharmacyDetailsResponse } from 'src/app/modals/registration/SteponePharmacyDetailsResponse';
import { CONTEXT_PATH } from 'config/stage';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  @ViewChild("reactivate", { static: true })
  reactivateTemplateRef?: TemplateRef<HTMLElement>;

  constructor(private profileService: ProfileService, private modalService: NgbModal, private registrationHelper: RegistrationhelperService, private router: Router, private registrationservice: registrationService) {
  }
  deactivated: boolean = false;
  npi: string = '';
  optID: string = '';
  uuid: string = '';
  role: number = 0;
  loginuserdata!: any;
  pharmacyName!: string;
  prescrole: boolean = true;

  ngOnInit() {
    debugger;
    var temp = sessionStorage.getItem('loginuserdata');
    if (temp != null || temp != undefined) {
      this.loginuserdata = JSON.parse(temp);
      this.npi = this.loginuserdata.prescribers[0].npi;
      this.optID = this.loginuserdata.prescribers[0].optumId;
      this.uuid = this.loginuserdata.prescribers[0].uuid;
      this.role = this.loginuserdata.prescribers[0].roleTypeId;
      if (this.role == USER_ROLE.ADMIN || this.role == USER_ROLE.PRESCRIBER) {
        this.prescrole = true;
      }
      else {
        this.prescrole = false;
        var webrequest = new SteponePharmacyDetailsRequest();
        webrequest.pharmacyId = this.npi;
        webrequest.pharmacyIdQualifier = PRESCRIBER_QUALIFIER.NPI;
        webrequest.instanceId = this.loginuserdata.prescribers[0].instanceId;
        this.registrationservice.postPharmacyDetails(webrequest).then((data: SteponePharmacyDetailsResponse) => {
          if (data.status.statusCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
            this.pharmacyName = data.pharmacyDetailItems[0].pharmacyName;
            sessionStorage.setItem('deactPharmaDetails', JSON.stringify(data));
          }
        });

      }
      if (this.loginuserdata.reActivateInd = BOOLEAN_FLAG.YES) {
        this.deactivated = true;
      }

    }
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);



  }
  loginToPreCheckMyScript() {
    // window.open("https://precheckmyscript-stage.uhcprovider.com");
    window.location.href = '/';
    sessionStorage.removeItem("lastPageBeforeReload");
    // this.router.navigateByUrl('/');
  }
  reactivateAccount() {
    this.modalService.open(this.reactivateTemplateRef, { size: 'sm',backdrop:'static' });
  }
  continueNPI() {
    this.modalService.dismissAll();
    sessionStorage.setItem("selfregistration", JSON.stringify({ uuid: this.uuid, ohid: this.optID }));
    // this.registrationHelper.getRegistrationStatus();  
    this.router.navigateByUrl('/register');


  }
  changeNPI() {
    this.modalService.dismissAll();
    sessionStorage.setItem("selfregistration", JSON.stringify({ uuid: this.uuid, ohid: this.optID }));
    // this.registrationHelper.getRegistrationStatus();
    this.router.navigateByUrl('/register');
  }
}
