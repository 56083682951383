import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HomeComponent} from 'src/app/components/routecontent/home/home.component';
import {ProviderhomeComponent} from 'src/app/components/routecontent/providerhome/providerhome.component';
import {PharmacyhomeComponent} from 'src/app/components/routecontent/pharmacyhome/pharmacyhome.component';
import {AdvisorhomeComponent} from 'src/app/components/routecontent/advisorhome/advisorhome.component';
import { ViewallnotificationsComponent } from 'src/app/components/viewallnotifications/viewallnotifications.component';
import { ViewalldatatableModule } from '../viewalldatatable/viewalldatatable.module';
import { FilterforprescriberModule } from '../filterforprescriber/filterforprescriber.module';
import { BenefitinquiryhomepageComponent } from 'src/app/components/benefitinquiryhomepage/benefitinquiryhomepage.component';
import { HistoryModule } from '../history/history.module';
import { MemberViewallComponent } from 'src/app/components/memberviewall/memberviewall.component';
import { SpecialtyPharmacyhomeComponent } from 'src/app/components/routecontent/specialty-pharmacyhome/specialty-pharmacyhome.component';

@NgModule({
  declarations: [
    HomeComponent,
    ProviderhomeComponent,
    PharmacyhomeComponent,
    AdvisorhomeComponent,
    ViewallnotificationsComponent,    
    //MemberViewallComponent
    SpecialtyPharmacyhomeComponent,
    ViewallnotificationsComponent,
  ],
  imports: [
    CommonModule,
    ViewalldatatableModule,
    FilterforprescriberModule,
    HistoryModule,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,

 ],
})
export class HomeModule { }
