import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BenefitInquiryRequest, ClaimSegment, DurOrPpsSegment, InsuranceSegment, InvocationContext, PatientSegment, PrescriberSegment, ServiceContext, TransactionHeader, UserContext, getRTBDetailsRequest } from 'src/app/modals/benefitinquiry/getRTBDetailsRequest';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { getRTBDetailsResponse } from 'src/app/modals/benefitinquiry/getRTBDetailsResponse';
import { BenefitinquiryService } from 'src/app/services/benefitinquiryservices/benefitinquiry.service';

@Component({
  selector: 'benefitinquirymanualflow',
  templateUrl: './benefitinquirymanualflow.component.html',
  styleUrls: ['./benefitinquirymanualflow.component.css'],
  animations: [
    trigger('fade',
      [
        state('void', style({
          opacity: 0,
          //transform:'translateX(20px)'//
        })),
        transition(':enter', [
          animate(2000)
        ])
      ]),
    trigger('slideInOut', [
      state('in', style({
        height: '*',
        opacity: 1,
        overflow: 'hidden'
      })),
      state('out', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden',
        padding: 0
      })),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ]),
  ]
})
export class BenefitinquirymanualflowComponent implements OnInit {
  // requestAlternateDrugs: boolean = true;
  // requestAlternatePharmacies: boolean = false;
  // dentifyAlternatePharmaciesOnly: boolean = true;
  // MedDMember: boolean = false;
  constructor(private router: Router, private loggerService: LoggerService, private profileService: ProfileService, private fb: FormBuilder, private biservice: BenefitinquiryService) {
    console.log(this.profileService);
    this.GeneralForm.get('ConsumingApp')?.valueChanges.subscribe(value => {
      this.GeneralForm.get('GroupID')?.setValue(value, { emitEvent: false });
    });
  }
  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
  }
  goto(routeUrl: string) {
    this.loggerService.userAction("Going to " + routeUrl);

    this.router.navigateByUrl("/" + routeUrl);

  }

  //animation part
  slidedownupstate1 = 'in';
  slidedownupstate2 = 'out';
  slidedownupstate3 = 'out';
  slidedownupstate4 = 'out';
  slidedownupstate5 = 'out';
  slidedownupstate6 = 'out';
  slidedownupstate7 = 'out';

  //boolean flags
  show: boolean = false;
  submitted: boolean = false;
  errorinform: boolean = false;
  DurPpsFormcheck: boolean = true;
  showJSON: boolean = false;
  submitsuccess: boolean = false;

//response form feilds
  InstanceID: string = "";
  CorrelationID: string = "";
  ProviderStatus: string = "";
  ESBStatus: string = "";
  biresponsestring: string = "";
  birequeststring:string="";


  toggleInOut1() {
    this.slidedownupstate1 = this.slidedownupstate1 === 'in' ? 'out' : 'in';
    // console.log('1'+this.slidedownupstate1);
  }
  toggleInOut2() {
    this.slidedownupstate2 = this.slidedownupstate2 === 'in' ? 'out' : 'in';
    // console.log('2 '+this.slidedownupstate2);
  }
  toggleInOut3() {
    this.slidedownupstate3 = this.slidedownupstate3 === 'in' ? 'out' : 'in';
    // console.log('3 '+this.slidedownupstate3);
  }
  toggleInOut4() {
    this.slidedownupstate4 = this.slidedownupstate4 === 'in' ? 'out' : 'in';
  }
  toggleInOut5() {
    this.slidedownupstate5 = this.slidedownupstate5 === 'in' ? 'out' : 'in';
  }
  toggleInOut6() {
    this.slidedownupstate6 = this.slidedownupstate6 === 'in' ? 'out' : 'in';
  }
  toggleInOut7() {
    this.slidedownupstate7 = this.slidedownupstate7 === 'in' ? 'out' : 'in';
  }

  //BI request form groups
  GeneralForm = this.fb.group({
    ConsumingApp: ['RXLINK'],
    GroupID: [{ value: 'RXLINK', disabled: true }],
    UserID: [{ value: this.profileService.loginuser?.prescriber?.optumId, disabled: true }],
    UserIDType: [{ value: 'USER_ID', disabled: true }],
    BINNumber: [{ value: '999999', disabled: true }],
    PersonalControlNumber: [{ value: 'ESB', disabled: true }]
  });
  MemberForm = this.fb.group({
    PatientLastName: ['', [Validators.required]],
    PatientFirstName: [''],
    DateOfBirth: ['', [Validators.required]],
    PatientZipCode: ['', [Validators.required]],
    CardholderID: ['', [Validators.required]],
    PatientGenderCode: ['1'],
    CarrierID: [''],
    PersonCode: [''],
    PersonRelationshipCode: [''],
    PlaceOfService: [''],
    PatientResidence: ['']
  });
  PrescriberForm = this.fb.group({
    PrescriberIDQualifier: [{ value: '01', disabled: true }],
    PrescriberID: [this.profileService.loginuser?.prescriber?.npi, [Validators.required]]
  });
  DrugForm = this.fb.group({
    ProductServiceIDQualifier: ['03'],
    ProductServiceID: ['', [Validators.required]],
    QuantityDispensed: ['', [Validators.required]],
    DaysSupply: ['', [Validators.required]],
    DAWProductSelectionCode: ['0'],
    RequestAlternateDrugs: [true],

  });
  PharmacyForm = this.fb.group({
    ServiceProviderIDQualifier: [{ value: '01', disabled: true }],
    ServiceProviderID: ['', [Validators.required]]
  });
  AlternatePharmacyForm = this.fb.group({
    RequestAlternatePharmacies: [false],
    IdentifyAlternatePharmaciesOnly: [true],
  });
  MailOrderPharmacyForm = this.fb.group({
    ServiceProviderIDQualifier: ['01'],
    ServiceProviderID: [''],
    ServiceProviderName: [''],
    ServiceProviderPhone: [''],
  });
  SpecialtyPharmacyForm = this.fb.group({
    MedDMember: [false],
    ServiceProviderIDQualifier1: ['01'],
    ServiceProviderID1: [''],
    ServiceProviderName1: [''],
    ServiceProviderPhone1: [''],
    ServiceProviderIDQualifier2: ['01'],
    ServiceProviderID2: [''],
    ServiceProviderName2: [''],
    ServiceProviderPhone2: [''],
    ServiceProviderIDQualifier3: ['01'],
    ServiceProviderID3: [''],
    ServiceProviderName3: [''],
    ServiceProviderPhone3: [''],
  });
  DurPpsForm = this.fb.group({
    ReasonForServiceCode: ['', [Validators.required]],
    ProfessionalServiceCode: ['', [Validators.required]],
    ResultOfServiceCode: ['', [Validators.required]]
  });

  //get current
  getcurrentTime() {
    var now = new Date();
    var hours = now.getHours();
    var minutes = now.getMinutes();
    var seconds = now.getSeconds();
    var time: string = hours + ":" + minutes + ":" + seconds;
    return time;
  }
  
  onSubmit() {

    this.submitted = true;

    //check if DurPpsForm value are all set to something or nothing
    if ((this.DurPpsForm.get('ReasonForServiceCode')?.value == "" && this.DurPpsForm.get('ProfessionalServiceCode')?.value == "" && this.DurPpsForm.get('ResultOfServiceCode')?.value == "") || (this.DurPpsForm.get('ReasonForServiceCode')?.value != "" && this.DurPpsForm.get('ProfessionalServiceCode')?.value != "" && this.DurPpsForm.get('ResultOfServiceCode')?.value != "")) {
      this.DurPpsFormcheck = true;
    }
    else {
      this.DurPpsFormcheck = false;
    }

    //check overall forms 
    if (this.GeneralForm.valid && this.MemberForm.valid && this.PrescriberForm.valid && this.DrugForm.valid
      && this.PharmacyForm.valid && this.AlternatePharmacyForm.valid && this.MailOrderPharmacyForm.valid
      && this.SpecialtyPharmacyForm.valid && this.DurPpsFormcheck == true) {

      this.errorinform = false;

      var randomString = Math.random().toString(36).replace('0.', '');

      var webrequest = new getRTBDetailsRequest();

      webrequest.invocationContext = new InvocationContext();

      webrequest.benefitInquiryRequest = new BenefitInquiryRequest();

      webrequest.benefitInquiryRequest.userContext = new UserContext();
      webrequest.benefitInquiryRequest.serviceContext = new ServiceContext();
      webrequest.benefitInquiryRequest.transactionHeader = new TransactionHeader();
      webrequest.benefitInquiryRequest.insuranceSegment = new InsuranceSegment();
      webrequest.benefitInquiryRequest.patientSegment = new PatientSegment();
      webrequest.benefitInquiryRequest.claimSegment = new ClaimSegment();
      webrequest.benefitInquiryRequest.prescriberSegment = new PrescriberSegment();
      webrequest.benefitInquiryRequest.durOrPpsSegment = new DurOrPpsSegment();

      webrequest.invocationContext.consumingApp = "" + this.GeneralForm.get('ConsumingApp')?.value;
      webrequest.invocationContext.invocationTimestamp = this.getcurrentTime();

      webrequest.benefitInquiryRequest.userContext.userId = "" + this.GeneralForm.get('UserID')?.value;
      webrequest.benefitInquiryRequest.userContext.userIdType = "" + this.GeneralForm.get('UserIDType')?.value;
      webrequest.benefitInquiryRequest.serviceContext.correlationId = "BenefitInquiry-" + randomString;
      webrequest.benefitInquiryRequest.transactionHeader.binNumber = "" + this.GeneralForm.get('BINNumber')?.value;
      webrequest.benefitInquiryRequest.transactionHeader.processorControlNumber = "" + this.GeneralForm.get('PersonalControlNumber')?.value;
      webrequest.benefitInquiryRequest.transactionHeader.serviceProviderIdQualifier = "" + this.PharmacyForm.get('ServiceProviderIDQualifier')?.value;
      webrequest.benefitInquiryRequest.transactionHeader.serviceProviderId = "" + this.PharmacyForm.get('ServiceProviderID')?.value;
      webrequest.benefitInquiryRequest.transactionHeader.requestAlternateDrugs = this.DrugForm.get('RequestAlternateDrugs')?.value ?? true;
      webrequest.benefitInquiryRequest.transactionHeader.requestAlternatePharmacies = this.AlternatePharmacyForm.get('RequestAlternatePharmacies')?.value ?? false;
      webrequest.benefitInquiryRequest.transactionHeader.identifyAlternatePharmaciesOnly = this.AlternatePharmacyForm.get('IdentifyAlternatePharmaciesOnly')?.value ?? true;

      webrequest.benefitInquiryRequest.transactionHeader.alternatePharmacies = [];
      if (this.MailOrderPharmacyForm.get('ServiceProviderID')?.value != "") {
        var altpharmacy = {
          serviceProviderIdQualifier: "01",
          serviceProviderId: "" + this.MailOrderPharmacyForm.get('ServiceProviderID')?.value
        }
        webrequest.benefitInquiryRequest.transactionHeader.alternatePharmacies.push(altpharmacy);
      }
      if (this.SpecialtyPharmacyForm.get('ServiceProviderID1')?.value != "") {
        var altpharmacy = {
          serviceProviderIdQualifier: "01",
          serviceProviderId: "" + this.SpecialtyPharmacyForm.get('ServiceProviderID1')?.value
        }
        webrequest.benefitInquiryRequest.transactionHeader.alternatePharmacies.push(altpharmacy);
      }
      if (this.SpecialtyPharmacyForm.get('ServiceProviderID2')?.value != "") {
        var altpharmacy = {
          serviceProviderIdQualifier: "01",
          serviceProviderId: "" + this.SpecialtyPharmacyForm.get('ServiceProviderID2')?.value
        }
        webrequest.benefitInquiryRequest.transactionHeader.alternatePharmacies.push(altpharmacy);
      }
      if (this.SpecialtyPharmacyForm.get('ServiceProviderID3')?.value != "") {
        var altpharmacy = {
          serviceProviderIdQualifier: "01",
          serviceProviderId: "" + this.SpecialtyPharmacyForm.get('ServiceProviderID3')?.value
        }
        webrequest.benefitInquiryRequest.transactionHeader.alternatePharmacies.push(altpharmacy);
      }
      webrequest.benefitInquiryRequest.transactionHeader.pbmCarrier = ""+this.MemberForm.get('CarrierID')?.value;

      webrequest.benefitInquiryRequest.insuranceSegment.cardholderId = "" + this.MemberForm.get('CardholderID')?.value;
      webrequest.benefitInquiryRequest.insuranceSegment.groupId = "" + this.GeneralForm.get('GroupID')?.value;
      webrequest.benefitInquiryRequest.insuranceSegment.personCode = "" + this.MemberForm.get('PersonCode')?.value;
      webrequest.benefitInquiryRequest.insuranceSegment.patientRelationshipCode = "" + this.MemberForm.get('PersonRelationshipCode')?.value;

      webrequest.benefitInquiryRequest.patientSegment.patientId = "" + this.MemberForm.get('PatientLastName')?.value;
      webrequest.benefitInquiryRequest.patientSegment.dateOfBirth = "" + this.MemberForm.get('DateOfBirth')?.value;
      webrequest.benefitInquiryRequest.patientSegment.patientGenderCode = "" + this.MemberForm.get('PatientGenderCode')?.value;
      webrequest.benefitInquiryRequest.patientSegment.patientLastName = "" + this.MemberForm.get('PatientLastName')?.value;
      webrequest.benefitInquiryRequest.patientSegment.patientFirstName = "" + this.MemberForm.get('PatientFirstName')?.value;
      webrequest.benefitInquiryRequest.patientSegment.patientZipCode = "" + this.MemberForm.get('PatientZipCode')?.value;
      webrequest.benefitInquiryRequest.patientSegment.placeOfService = "" + this.MemberForm.get('PlaceOfService')?.value;
      webrequest.benefitInquiryRequest.patientSegment.patientResidence = "" + this.MemberForm.get('PatientResidence')?.value;

      webrequest.benefitInquiryRequest.prescriberSegment.prescriberIdQualifier = "" + this.PrescriberForm.get('PrescriberIDQualifier')?.value;
      webrequest.benefitInquiryRequest.prescriberSegment.prescriberId = "" + this.PrescriberForm.get('PrescriberID')?.value;

      webrequest.benefitInquiryRequest.claimSegment.productORServiceIdQualifier = "" + this.DrugForm.get('ProductServiceIDQualifier')?.value;
      webrequest.benefitInquiryRequest.claimSegment.productORServiceId = "" + this.DrugForm.get('ProductServiceID')?.value;
      webrequest.benefitInquiryRequest.claimSegment.quantityDispensed = "" + this.DrugForm.get('QuantityDispensed')?.value;
      webrequest.benefitInquiryRequest.claimSegment.daysSupply = "" + this.DrugForm.get('DaysSupply')?.value;
      webrequest.benefitInquiryRequest.claimSegment.dawOrProductSelectionCode = "" + this.DrugForm.get('DAWProductSelectionCode')?.value;

      webrequest.benefitInquiryRequest.prescriberSegment.prescriberIdQualifier = "" + this.PrescriberForm.get('PrescriberIDQualifier')?.value;
      webrequest.benefitInquiryRequest.prescriberSegment.prescriberId = "" + this.PrescriberForm.get('PrescriberID')?.value;
      if(this.DurPpsForm.get('ReasonForServiceCode')?.value == "" && this.DurPpsForm.get('ProfessionalServiceCode')?.value == "" && this.DurPpsForm.get('ResultOfServiceCode')?.value == "" ){
        webrequest.benefitInquiryRequest.durOrPpsSegment=null;
      }
      else {
        webrequest.benefitInquiryRequest.durOrPpsSegment.reasonForServiceCode = "" + this.DurPpsForm.get('ReasonForServiceCode')?.value;
        webrequest.benefitInquiryRequest.durOrPpsSegment.professionalServiceCode = "" + this.DurPpsForm.get('ProfessionalServiceCode')?.value;
        webrequest.benefitInquiryRequest.durOrPpsSegment.resultOfServiceCode = "" + this.DurPpsForm.get('ResultOfServiceCode')?.value;
  
      }


      console.log(webrequest);
      
      this.submitBenefitInquiry(webrequest);
    }
    else {
      this.errorinform = true;
      if (!this.MemberForm.valid) {
        this.slidedownupstate1 = 'out';
        this.slidedownupstate2 = 'in';
        this.slidedownupstate3 = 'out';
        this.slidedownupstate4 = 'out';
        this.slidedownupstate5 = 'out';
        this.slidedownupstate6 = 'out';
        this.slidedownupstate7 = 'out';
      }
      else if (!this.PrescriberForm.valid) {
        this.slidedownupstate1 = 'out';
        this.slidedownupstate2 = 'out';
        this.slidedownupstate3 = 'in';
        this.slidedownupstate4 = 'out';
        this.slidedownupstate5 = 'out';
        this.slidedownupstate6 = 'out';
        this.slidedownupstate7 = 'out';
      }
      else if (!this.DrugForm.valid) {
        this.slidedownupstate1 = 'out';
        this.slidedownupstate2 = 'out';
        this.slidedownupstate3 = 'out';
        this.slidedownupstate4 = 'in';
        this.slidedownupstate5 = 'out';
        this.slidedownupstate6 = 'out';
        this.slidedownupstate7 = 'out';
      }
      else if (!this.PharmacyForm.valid) {
        this.slidedownupstate1 = 'out';
        this.slidedownupstate2 = 'out';
        this.slidedownupstate3 = 'out';
        this.slidedownupstate4 = 'out';
        this.slidedownupstate5 = 'in';
        this.slidedownupstate6 = 'out';
        this.slidedownupstate7 = 'out';
      }
      else if (this.DurPpsFormcheck == false) {
        this.slidedownupstate1 = 'out';
        this.slidedownupstate2 = 'out';
        this.slidedownupstate3 = 'out';
        this.slidedownupstate4 = 'out';
        this.slidedownupstate5 = 'out';
        this.slidedownupstate6 = 'out';
        this.slidedownupstate7 = 'in';
      }

    }
  }

  insideTheBox() {
    this.show = true;
  }
  outsideTheBox() {
    this.show = false;
  }
  applyRedErrorShadow(elementId: string, error: boolean) {
    this.show = true;
    const element = document.getElementById(elementId);
    if (this.submitted == true && error == true) {
      element?.classList.add('error_border_shadow');
    }
  }
  removeRedErrorShadow(elementId: string) {
    this.show = false;
    const element = document.getElementById(elementId);
    element?.classList.remove('error_border_shadow');
  }
  checkerror(elementId: string, error: boolean) {
    const element = document.getElementById(elementId);
    if (this.show = true && this.submitted == true && error == true) {
      element?.classList.add('error_border_shadow');
    }
    else {
      element?.classList.remove('error_border_shadow');
    }
  }

  submitBenefitInquiry(webrequest: getRTBDetailsRequest) {
    this.biservice.postGetRTBDetails(webrequest).then((response: getRTBDetailsResponse) => {
      debugger
      this.submitsuccess = true;
      this.slidedownupstate1 = 'in';
      this.biresponsestring = JSON.stringify(response.benefitCheckResponse,null,3);
      this.birequeststring = JSON.stringify(webrequest,null,3);
      if (response.benefitCheckResponse.invocationContext != undefined) {
        this.InstanceID = response.benefitCheckResponse.invocationContext.instanceId;
      }
      if (response.benefitCheckResponse.serviceContext != undefined) {
        this.CorrelationID = response.benefitCheckResponse.serviceContext.correlationId;
      }
      if (response.benefitCheckResponse.integrationStatus.providerStatus != undefined) {
        this.ProviderStatus = response.benefitCheckResponse.integrationStatus.providerStatus.responseMessage;
      }
      if (response.benefitCheckResponse.integrationStatus.esbStatus != undefined) {
        this.ESBStatus = response.benefitCheckResponse.integrationStatus.esbStatus.responseMessage;
      }
      console.log(response);
      console.log(this.biresponsestring);
      this.errorinform = false
    }).catch((error: any) => {
      console.log(error);
    });
  }
  onBackToBIRequestClick() {
    this.clearResponse();
    this.resetdropdown();
    this.submitsuccess = false;
  }
  clearResponse() {
    this.InstanceID = "";
    this.CorrelationID = "";
    this.ProviderStatus = "";
    this.ESBStatus = "";
    this.biresponsestring = "";
  }
  resetdropdown() {
    this.slidedownupstate1 = 'out';
    this.slidedownupstate2 = 'out';
    this.slidedownupstate3 = 'out';
    this.slidedownupstate4 = 'out';
    this.slidedownupstate5 = 'out';
    this.slidedownupstate6 = 'out';
    this.slidedownupstate7 = 'out';
  }
  onViewJsonClick(){
    this.showJSON=true;
  }
  closeJsonClick(){
    this.showJSON=false;
  }
}

