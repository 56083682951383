import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { ErrorComponent } from 'src/app/components/error/error.component';
import { GlobalmenuComponent } from 'src/app/components/globalmenu/globalmenu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    GlobalmenuComponent,
    // LoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule
  ],
  exports:[GlobalmenuComponent]
})
export class CommonModule { }
