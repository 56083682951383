import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationspageComponent } from 'src/app/components/notificationspage/notificationspage.component';
import { Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatefieldComponent } from 'src/app/components/datefield/datefield.component';
import { SharedModule } from '../shared/shared.module';
import { ViewalldatatableModule } from '../viewalldatatable/viewalldatatable.module';
import { FilterforprescriberModule } from '../filterforprescriber/filterforprescriber.module';
import { MemberViewallComponent } from 'src/app/components/memberviewall/memberviewall.component';
import { MembernotificationsComponent } from 'src/app/components/membernotifications/membernotifications.component';
import { MemberdeletedComponent } from 'src/app/components/memberdeleted/memberdeleted.component';
import { DeletednotificationpageComponent } from 'src/app/components/deletednotificationpage/deletednotificationpage.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const notificationsRoutes: Routes = [
  { path: '', redirectTo: '/notifications/ViewAll', pathMatch: 'full' },
  { path: 'ViewAll',component: NotificationspageComponent,data: { breadcrumb: { label: 'View All Notifications', step:1 } }, pathMatch: 'full' },
  { path: 'ViewDeleted', component: DeletednotificationpageComponent, data: { breadcrumb: { label: 'Recently Deleted Notifications', step:2 } }, pathMatch: 'full' },
];

@NgModule({
  declarations: [
    NotificationspageComponent,
    MembernotificationsComponent,
    MemberdeletedComponent,
    DeletednotificationpageComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ViewalldatatableModule,
    FilterforprescriberModule,
    BrowserAnimationsModule
  ],
  exports: [
    NotificationspageComponent,
    MembernotificationsComponent,
    MemberdeletedComponent,
    DeletednotificationpageComponent
  ],
  schemas: [NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class NotificationsModule { }
