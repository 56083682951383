export class SteptwoUpdateStatusRequest {
    presQualTypeID !: string
    linkReqID !: string
    optumID !: string
    npi !: string
    uuid !: string
    instcID !: string
    reActivateInd !: string
    presbrId !: string
    role !: string
  }