<div class="container showallNotifications">
    <div class="row notificationHeader">
        <div style="margin-left: 8.33333333%;padding: 0 !important;width: 83.33333333%;display: flex;">           
            <div class="col-sm-7 col-md-7 col-lg-7 text-left nopadding">
                <h1 class="heading_1 ng-binding">View All Notifications</h1>
                <span class="paragraph ng-binding" style="display: block;">
                    Find all notifications here. Narrow your results by
                    <br>using the filter or search options.
                </span>
            </div>
            <div class="col-sm-5 col-md-5 col-lg-5 nopadding" *ngIf="role==20">
                <div class="pull-right prescribersdropdown" style="width: 100%;
                padding-top: 20px;">
                    <prescriberfilter (presbrId)="changePrescriber($event)">

                    </prescriberfilter>

                </div>
            </div>
        </div>
    </div>
    <div class="row notificationBody home_dashboard">
        <div class="col-sm-12 col-md-12 col-lg-10 nopadding column" style="margin-left: 8.33333333%;float: left;">
            <div class="content-card-holder">
                <div class="content-card no-shadow nopadding">
                    <div class="header">
                        <div class="container-fuild nopadding notificationsfilters">
                            
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12"
                                        style="margin-top:10px;margin-bottom:10px">
                                        <label for="dateRange" class="inputFieldLabel"
                                            style="margin: 0;padding-right: 5px;">Date Range: </label>
                                        <!-- <select id="dateRange" class="drop-list" formControlName="dateRange">
                                            <option label="Last 30 days" value="30">Last 30 days</option>
                                            <option label="Last 60 days" value="60">Last 60 days</option>
                                            <option label="Last 6 months" value="180">Last 6 months</option>
                                            <option label="Last year" value="365">Last year</option>
                                            <option label="Custom date" value="custom">Custom date</option>
                                        </select> -->
                                        <select id="dateRange" class="drop-list" [(ngModel)]="submittedHistory.selectedDate">
                                        <option *ngFor="let option of submittedHistory.dateRange | keyvalue"
                                            [innerHtml]="option.value" [value]="option.key" (input)="removecustomdate()"></option>
                                    </select>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 nopadding"
                                        style="margin-top:10px;margin-bottom:10px;">
                                        <label for="statusRange" class="inputFieldLabel"
                                            style="margin: 0;padding-right: 5px;">Status: </label>
                                        <!-- <select id="statusRange" class="drop-list" formControlName="status">
                                            <option label="All Statuses" value="status">All Statuses</option>
                                            <option label="Approved" value="Approved">Approved</option>
                                            <option label="Denied" value="Denied">Denied</option>
                                            <option label="Cancelled" value="Cancelled">Cancelled</option>
                                            <option label="Saved PA Expiring" value="Expiring">Saved PA Expiring
                                            </option>
                                        </select> -->
                                        <select id="statusRange" class="drop-list" [(ngModel)]="submittedHistory.selectedStatus">
                                            <option *ngFor="let option of submittedHistory.statuses | keyvalue" [innerHtml]="option.value" [value]="option.key"></option>
                                        </select>
                                    </div>
                                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12"
                                        style="margin-top:10px;margin-bottom:10px;float: left;">
                                        <input type="text" [(ngModel)]="submittedHistory.searchText"
                                            style="font-size: 16px;height: 44px;border: 2px solid #626f7d;background-color: #fff;color: #050709;padding: 10px;width: 70%;">
                                        <button type="button" (click)="filterHistory()" style="width:auto" class="btn btn-primary push-right-sm"
                                            style="width: auto;height: 44px;padding-left: 20px;padding-right: 20px;margin-left: 10px;position: relative;top: -2px;font-family: Frutiger-Bold, Helvetica, Arial, sans-serif;">
                                            Search</button>
                                    </div>
                                </div>
                            
                        </div>
                    </div>
                    <div class="footer ng-scope" style="padding: 28px;" *ngIf="submittedHistory.selectedDate==5">
                        <div class="container-fluid nopadding">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="inputFieldLabel " for="customDateFrom"
                                        style="display:inline-block;padding-top:10px;padding-right:0px">Search by
                                        Date:</label>
                                    &nbsp;
                                    <!-- <input type="text" id="inputFieldDOB" placeholder="MM/DD/YYYY" name="inputFieldDOB"
                                        minlength="1" maxlength="10" required="" value="" aria-required="true" style="margin: 0;font-size: 16px;height: 44px;border: 2px solid #626f7d;background-color: #fff;color: #050709;padding: 10px;width: 150px!important;"> -->
                                    <datefield style="width: 150px !important;height: 44px;" #FromDate [validationMode]="submittedHistory.fromDateValidationMode"
                                        [content]="submittedHistory.fromDateValue"
                                        (onUpdate)="submittedHistory.onFromDateUpdate($event)"
                                        [emptyValid]="!submittedHistory.blnCustomDateFormSubmitted"
                                        [toDateComponent]="ToDateComponent"
                                        [placeHolder]="submittedHistory.dateFormatPlaceHolder">
                                    </datefield>
                                </div>
                                <div class="col-md-2 nopadding" style="width:200px;">
                                    <label for="customDateTo" class="inputFieldLabel "
                                        style="display:inline-block;padding-top:10px;padding-right:0px">To</label>
                                    &nbsp;
                                    <datefield style="width: 150px !important;height: 44px;" #ToDate [validationMode]="submittedHistory.toDateValidationMode"
                                        [content]="submittedHistory.toDateValue"
                                        (onUpdate)="submittedHistory.onToDateUpdate($event)"
                                        [emptyValid]="!submittedHistory.blnCustomDateFormSubmitted"
                                        [placeHolder]="submittedHistory.dateFormatPlaceHolder">
                                    </datefield>

                                </div>
                                <div class="col-md-5 text-left" style="padding-left: 0;">
                                    <button type="button" (click)="filterHistory()" style="width:auto" class="btn secondary btn-secondary "
                                        style="padding-left: 20px;padding-right: 20px;text-align: center;height: 40px;">Update</button>
                                    <span *ngIf="showDates" class="paragraph_small "
                                        style="display:inline-block;position:absolute;right:-15px;margin:10px 0px;">
                                        Viewing from {{userGivenFromDate}} to {{userGivenToDate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="body tab-pane" style="padding: 10px;padding-bottom: 0px;">
                        <!-- <div style="margin-left:20px"><br>We were unable to retrieve this information. Refresh the page to try again. <a href="javascript:void(0)">Refresh page</a></div> -->
                        <div class="submitted_PA_results fadeout-widget">
                            <div class="submitted_PA_progress text-center" style="margin-top: 30px;"
                                *ngIf="notificationCount==undefined">
                                <div>
                                    <i class="fa fa-circle-o-notch fa-spin"
                                        style="color: #ef6c00;font-size: 30px;display: inline-block;vertical-align: middle;"></i>
                                    <span style="padding-left: 5px;position: relative;top: 2px">Loading...</span>
                                </div>
                            </div>
                            <div style="padding-left: 30px;padding-top: 30px;" *ngIf="unabletofetchdata; else nexterrormsg" >
                                <span class="paragraph">We were unable to retrieve this information. Refresh the page to try again. 
                                    <span class="refreshPage">Refresh page</span>
                                </span>
                            </div>
                            <ng-template #nexterrormsg>
                            <div style="padding-left: 30px;padding-top: 30px;" *ngIf="(staticNotifications!=undefined && staticNotifications.length ==0) && ( allNotifications!=undefined && allNotifications.length==0)">
                                <span class="paragraph">There are no notifications for this prescriber.</span>
                            </div>
                        </ng-template>
                            <div style="padding-left: 30px;padding-top: 30px;" *ngIf="(allNotifications!=undefined && allNotifications.length==0) && (staticNotifications!=undefined && staticNotifications.length != 0)">
                                <span class="paragraph">There are no matching notifications.</span>
                            </div>

                            <div class="info-report-border-separate">
                               
                                <viewalldatatable [tabledata]="allNotifications" [action]="dltNtfyType" (viewPa)="onViewPAClick($event)">
            
                                </viewalldatatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-card-holder" style="margin-top:20px">
                <div class="container-fluid nopadding backToSearch">
                    <div class="row">
                        <div class="col-md-6 text-left"
                            style="position: relative;min-height: 1px;padding-right: 15px;padding-left: 15px;">
                            <span class="back_to_home" (click)="onBackClick()" href="javascript:void(0)">
                                <i class="fa fa-angle-left"
                                    style="padding-right: 3px;font-size: 24px !important;vertical-align: bottom;"
                                    aria-hidden="true"></i>&nbsp; Back to PCMS homepage
                            </span>
                        </div>
                        <div class="col-md-6 text-right"
                            style="position: relative;min-height: 1px;padding-right: 15px;padding-left: 15px;">
                            <span class="go_to_deleted" (click)="onDeletedNotificationsClick()"
                                href="javascript:void(0)">
                                Recently deleted notifications&nbsp;
                                <i class="fa fa-angle-right"
                                    style="padding-right: 3px;font-size: 24px !important;vertical-align: bottom;"
                                    aria-hidden="true"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>