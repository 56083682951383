<div class="dropdown navbar-brand globalmenu">
    <button class="btn removestyle" type="button" id="globalmenuoptions" data-bs-toggle="dropdown" aria-expanded="false" 
    [ngClass]="{'dropdown-toggle':blnMenuRequired}" (click)="blnOpened=!blnOpened">
        <img src="assets/images/pcms_home_logo.svg" width="30" height="30" class="d-inline-block align-top" alt="">
    </button>
    <!-- <a class="dropdown-toggle" href="javascript:void(0)" id="globalmenuoptions" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="assets/images/pcms_home_logo.svg" width="30" height="30" class="d-inline-block align-top" alt="">
    </a> -->
    <ul class="dropdown-menu" aria-labelledby="globalmenuoptions" *ngIf="blnMenuRequired">
      <li *ngIf="features.checkcoverageflow">
        <button type="button" class="btn removestyle dropdown-item" (click)="goto('PriorAuthChecker')">
        <div class="d-flex">
            <div class="p-3 flex-fill">
                <h2>Check Prescription Coverage</h2>
                <p>See if medications are covered by member's plan.</p>
            </div>
            <div class="p-3 flex-fill text-end">
                <img src="assets/images/prescriptioncheck.png" alt="Prescription Check">
            </div>
        </div>
        <hr/>
        </button>
      </li>
      <li *ngIf="features.epaflow">
        <button type="button" class="btn removestyle dropdown-item" (click)="goto('ePA')">
            <div class="d-flex">
                <div class="p-3 flex-fill">
                    <h2>Initiate a prior authorization</h2>
                    <p>Easy 3-step process to Initiate a prior authorization.</p>
                </div>
                <div class="p-3 flex-fill text-end">
                    <img src="assets/images/ePA.png" alt="Prescription Check">
                </div>
            </div>
        <hr/>
        </button>
      </li>
      <li *ngIf="features.membersearch">
        <button type="button" class="btn removestyle dropdown-item" (click)="goto('/searchMedicationClaims/MemberSearch')">
            <div class="d-flex">
                <div class="p-3 flex-fill">
                    <h2>Search for a member</h2>
                    <p>View member information and prior auth history.</p>
                </div>
                <div class="p-3 flex-fill text-end">
                    <img src="assets/images/memberSearch.png" alt="Prescription Check">
                </div>
            </div>
            <hr/>
        </button>
      </li>
      <li *ngIf="features.provisioning">
        <button type="button" class="btn removestyle dropdown-item" (click)="goto('provision')">
            <div class="d-flex">
                <div class="p-3 flex-fill">
                    <h2>Provisioning</h2>
                    <p>Add users one at a time or in bulk.</p>
                </div>
                <div class="p-3 flex-fill text-end">
                    <img src="assets/images/ePA.png" alt="Prescription Check">
                </div>
            </div>
        </button>
        <hr/>
      </li>
      <li *ngIf="features.manageprescribers">
          <button type="button" class="btn removestyle dropdown-item notitle" (click)="goto('prescribers/manage')">
              <div class="d-flex">
                  <div class="p-3 flex-fill">
                      <p>Manage Prescribers</p>
                  </div>
              </div>
              <hr/>
          </button>
      </li>
      <li *ngIf="features.benefitinquiry">
        <button type="button" class="btn removestyle dropdown-item notitle" (click)="goto('benefitinquiry')">
            <div class="d-flex">
                <div class="p-3 flex-fill">
                    <p>Initiate Benefit Inquiry</p>
                </div>
            </div>
            <hr/>
        </button>
    </li>
      <li *ngIf="features.prescriberprofile">
        <button type="button" class="btn removestyle dropdown-item notitle" (click)="goto('prescriber/profile')">
            <div class="d-flex">
                <div class="p-3 flex-fill">
                    <p>Prescriber Profile</p>
                </div>
            </div>
            <hr/>
        </button>
      </li>
      <li *ngIf="features.controlpannel">
        <button type="button" class="btn removestyle dropdown-item notitle" (click)="goto('ControlPanel')">
            <div class="d-flex">
                <div class="p-3 flex-fill">
                    <p>Internal Control Panel</p>
                </div>
            </div>
            <hr/>
        </button>
      </li>
      <li>
        <button type="button" class="btn removestyle dropdown-item notitle" (click)="goto('contactus')">
            <div class="d-flex">
                <div class="p-3 flex-fill">
                    <p>Contact us</p>
                </div>
            </div>
        </button>
      </li>
    </ul>
</div>
<div class="backdrop" *ngIf="blnOpened && blnMenuRequired" (click)="blnOpened=false"></div>