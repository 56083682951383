import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { downloadRTBRegressionSheetRequest } from 'src/app/modals/benefitinquiry/downloadRTBRegressionSheetRequest';
import { downloadRTBRegressionSheetResponse } from 'src/app/modals/benefitinquiry/downloadRTBRegressionSheetResponse';
import { BenefitinquiryService } from 'src/app/services/benefitinquiryservices/benefitinquiry.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'benefitinquiryregressionflow',
  templateUrl: './benefitinquiryregressionflow.component.html',
  styleUrls: ['./benefitinquiryregressionflow.component.css']
})
export class BenefitinquiryregressionflowComponent implements OnInit {

  @ViewChild("UploadProgress")
  uploadProgressElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("UploadError")
  uploadErrorElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("FileSizeExceeded")
  fileSizeExceededElmRef?: ElementRef<HTMLElement>;

  @ViewChild("FileTypeError")
  fileTypeErrorElmRef?: ElementRef<HTMLElement>;
  

  selectedFile: File | null = null;
  progress!: number;
  errorMessage!: string;
  presbrId: string = "" + this.profileService.loginuser?.prescriber?.presbrId;
  maxFileSize:number = APPCONSTANTS.ATTACHMENT_SIZE;
  fileTypesAllowed= APPCONSTANTS.BI_UPLOAD_TYPE;

  constructor(private router: Router, private loggerService: LoggerService,
    private profileService: ProfileService, private fb: FormBuilder,
    private biservice: BenefitinquiryService, private modalService: NgbModal,) {
    console.log(this.profileService);
    this.GeneralForm.get('ConsumingApp')?.valueChanges.subscribe(value => {
      this.GeneralForm.get('GroupID')?.setValue(value, { emitEvent: false });
    });
  }
  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
  }
  goto(routeUrl: string) {
    this.loggerService.userAction("Going to " + routeUrl);

    this.router.navigateByUrl("/" + routeUrl);

  }
  GeneralForm = this.fb.group({
    ConsumingApp: ['RXLINK'],
    GroupID: [{ value: 'RXLINK', disabled: true }],
    UserID: [{ value: this.profileService.loginuser?.prescriber?.optumId, disabled: true }],
    UserIDType: [{ value: 'USER_ID', disabled: true }],
    BINNumber: [{ value: '999999', disabled: true }],
    PersonalControlNumber: [{ value: 'ESB', disabled: true }],
    RequestAlternateDrugs: [true],
    RequestAlternatePharmacies: [true],
    IdentifyAlternatePharmaciesOnly: [true]

  });

  onDownloadTemplateClick() {
    debugger;
    var webrequest = new downloadRTBRegressionSheetRequest();
    webrequest.isEmptySheet = 'Y';

    this.biservice.postDownloadRTBRegressionSheet(webrequest).then((data: downloadRTBRegressionSheetResponse) => {
      debugger;
      this.downloadExcelFileFromString(data.fileBytes, 'sheet.xlsx');
    });

  }

  downloadExcelFileFromString(fileString: string, fileName: string): void {
    debugger
    // Decode the base64 string to a binary array
    const binaryString = window.atob(fileString);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Convert the binary array to a Blob
    const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a link element
    const link = document.createElement('a');

    // Create an object URL from the Blob
    const url = window.URL.createObjectURL(blob);
    link.href = url;

    // Set the download attribute with a filename
    link.download = fileName;

    // Append the link to the document body (required for some browsers)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link after triggering the download
    document.body.removeChild(link);

    // Release the object URL to free up memory
    window.URL.revokeObjectURL(url);
  }


  // onSelectClick(event: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     this.selectedFile = file;
  //   }
  // }
  onSelectClick(event:any):void{
    debugger
    this.onDeleteFileClick();
    const selectedFiles:FileList = event.target.files;
    if(selectedFiles!=undefined && selectedFiles.item!=undefined && selectedFiles.item(0)!=undefined){
      const file:File|null= selectedFiles.item(0);
      if(file!=null && this.isFileValid(file) 
      && this.uploadProgressElmtRef!=undefined
      && this.uploadErrorElmtRef!=undefined){
        this.selectedFile = file;
        // this.transferFile();
      }
    }
    //reset file upload element after capturing the file bytes.
    event.target.value="";
  }
  triggerFileInput() {
    document.getElementById('uploadbutton')?.click();
  }
  onDeleteFileClick() {
    this.selectedFile = null;
  }


  onUploadClick(): void {
    debugger
    if (this.selectedFile != undefined) {
      this.progress = 1;
      this.modalService.open(this.uploadProgressElmtRef, { size: "md" ,backdrop:'static'});
      this.biservice.postRTBRegressionUpload(this.presbrId, this.selectedFile).subscribe({
        next: (event: any) => {
          debugger
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            if (event.status.toString() == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE
              && event.body != undefined && event.body.statusCode != undefined
              && event.body.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
              this.progress = 0;
              this.errorMessage = event.body.statusDesc;
              this.modalService.dismissAll();
            } else {
              this.onDeleteFileClick();
              this.modalService.dismissAll();
              if (event.status == -1) {
                this.errorMessage = event.statusText;
              } else {
                this.errorMessage = "An error occurred while trying to upload the file.<br/>Please try again.";
              }
              this.modalService.open(this.uploadErrorElmtRef, { size: 'md',backdrop:'static' });
            }
          }
        },
        error: (err: any) => {
          this.onDeleteFileClick();
          this.modalService.dismissAll();
          if (err.error && err.error.message) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = 'An error occurred while trying to upload the file.<br/>Please try again.';
          }
          this.modalService.open(this.uploadErrorElmtRef, { size: 'md',backdrop:'static' });
        }
      });
    }
  }

  private isFileValid(file: File): boolean {
    debugger;
    const fileType: string = file.type;
    const fileSize: string = this.getFileSize(file);
    if (fileType != this.fileTypesAllowed) {
      this.modalService.open(this.fileTypeErrorElmRef,{size:'md',backdrop:'static'});
      return false;
    }
    else {
      if (this.maxFileSize < parseFloat(fileSize)) {
        this.modalService.open(this.fileSizeExceededElmRef, { size: 'md',backdrop:'static' });
        return false;
      }
    }
    return true;
  }


  getFileSize(file?: File): string {
    debugger
    if (file != undefined)
      return (file.size / 1048576).toFixed(2);
    else
      return "0";
  }

}
