import { NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { FavouritemembersComponent } from '../../../components/favouritemembers/favouritemembers.component';



@NgModule({
  declarations: [FavouritemembersComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[FavouritemembersComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,

 ],
})
export class FavouritesModule { }
