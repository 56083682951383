<div class="col container-fluid mb-5">
    <form action="/MemberDetail" method="post">
    <div class="col-8 offset-2 timelinetitle row mt-3">
        <div class="col p-0">
            <p class="fs-2 fw-lighter text-capitalize">{{this.memberSearchResult!.firstName.toLocaleLowerCase()}}
                {{this.memberSearchResult!.lastName.toLocaleLowerCase()}}</p>
        </div>
        <div class="col mb-2 p-0">
            <button class="btn btn-primary fontbold mt-3 float-end" (click)="goto('/PriorAuthChecker/DrugSearch')">
                Check Coverage   
            </button> &nbsp;&nbsp;&nbsp;
            <button class="btn btn-primary fontbold mt-3 float-end me-3" (click)="goto('/ePA/DrugSearch')" *ngIf="this.userRole==10 || this.userRole==13 || this.userRole==20" >
                Initiate PA Request 
            </button> 
        </div>
        <!--<div class>
            <style>
                .btn-spacing {
                    margin-right:0px
                }
            </style>
            <button class="btn btn-primary fontbold mt-3 btn-spacing" (click)="goto('/PriorAuthChecker/DrugSearch')">
                Checkcoverage   
             </button>&nbsp;
             <button class="btn btn-primary fontbold mt-3 btn-spacing" (click)="goto('/ePA/DrugSearch')" >
                 Initiate PA Request 
             </button>     
        </div>-->
        
    </div>
    <div class="col-8 offset-2 shadow mb-5 row p-3 bg-white" *ngIf="this.memberSearchResult!=undefined">
        <div class="col row text-muted">
            <label class="fontbold p-2">Date Of Birth</label>
            <label class="text-dark">{{this.memberSearchResult!.dateOfBirth}}</label>
        </div>
        <div class="col row text-muted">
            <label class="fontbold p-2">Gender</label>
            <label class="text-dark">{{this.gender}}</label>
        </div>
        <div class="col row text-muted">
            <label class="fontbold p-2">Phone Number</label>
            <label class="text-dark" *ngIf="this.memberDetailResult!=undefined">{{this.memberDetailResult!.memberItems[0].contact.phoneNumber == 0 ? 'N/A' : this.memberDetailResult!.memberItems[0].contact.phoneNumber}}</label>
        </div>
        <div class="col row text-muted">
            <label class="fontbold p-2">Address</label>
            <label class="text-dark text-capitalize">{{this.memberSearchResult!.address.address1.toLocaleLowerCase()}},
                {{this.memberSearchResult!.address.address2.toLocaleLowerCase()}}{{this.memberSearchResult!.address.address3.toLocaleLowerCase()}}
                {{this.memberSearchResult!.address.city.toLocaleLowerCase()}},
                {{this.memberSearchResult!.address.state}} {{this.memberSearchResult!.address.zip1}}</label>
        </div>
        <div class="col"></div>
    </div>

    <div class="mt-2 col-8 offset-2 mb-5 shadow">
        <div class="p-3 bg-white">
            <div class="row">
                <div class="col">
                    <h4 class="text-capitalize mt-3 mb-3 col">INSURANCE INFORMATION</h4>
                </div>
                <div class="col">
                    <button class="btn btn-link mt-3 mb-3 float-end fontbold col" (click)="this.refreshMemberDetails()" (keyup.enter)="refreshMemberDetails()">
                        <i class="fa fa-refresh fs-4 text-primary" aria-hidden="true" [ngClass]="{'rotateRefresh':(this.refreshData)}"></i>&nbsp;&nbsp;
                        Refresh eligibility status
                    </button>
                </div>
            </div>
        </div>
        <div class="m-0 p-3 bg-white border-2  border-top row">
            <div class="row">
                <div class="col-4 row text-muted">
                    <label class="fontbold p-2">Policy Status</label>
                    <label class="text-dark"><span _ngcontent-vyo-c126=""
                            *ngIf="this.memberSearchResult!.activeInd=='A'"
                            class="alert alert-success text-capitalize resultsflag">Active</span>
                        <span _ngcontent-vyo-c126="" *ngIf="this.memberSearchResult!.activeInd=='I'"
                            class="alert alert-warning text-capitalize resultsflag">Active</span>
                    </label>
                </div>
                <div class="col-4 row text-muted">
                    <label class="fontbold p-2">Dates Eligible</label>
                    <label class="text-dark">{{this.eligibilityDates}}</label>
                </div>
                <div class="col-4 row text-muted">
                    <label class="fontbold p-2">Last Updated</label>
                    <label class="text-dark">{{this.lastUpdated}}</label>
                </div>
            </div>
        </div>

        <div class="m-0 p-3 border-1 border-top row" style="background-color: rgba(242,246,249,1);">
            <div class="row">
                <div class="col-4 row text-muted">
                    <label class="fontbold p-2">MemberId</label>
                    <label class="text-dark">{{this.memberSearchResult?.memberId}}</label>
                </div>
                <div class="col row text-muted">
                    <label class="fontbold p-2">Group ID</label>
                    <label class="text-dark">{{this.memberSearchResult?.groupId}}</label>
                </div>
                <div class="col row text-muted">
                    <label class="fontbold p-2">Relationship</label>
                    <label class="text-dark" style="text-transform: capitalize;">{{this.relationshipCode}}</label>
                </div>
                <div class="col row text-muted">
                    <label class="fontbold p-2">Person Code</label>
                    <label class="text-dark">{{this.memberSearchResult?.personCode}}</label>
                </div>
                <div class="col"></div>
            </div>
        </div>
        </div></form>
    </div>
    <div class="mt-5 col-8 offset-2 mb-5 shadow" *ngIf="this.userRole==10 || this.userRole==13 || this.userRole==20">
        <!-- <div class="col-lg-2 .d-md-none .d-sm-none"></div> -->
            <div class="row p-3 mt-5">
                <!-- <div class="col-lg-2 .d-md-none .d-sm-none"></div> -->
                <div class="col-md-12 col-sm-12 col-xs-12 p-0">
               <!----<div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1">-->
                    <ul class="nav nav-tabs nav-justified" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link text-center" [ngClass]="{'active':activeTab==1}" aria-controls="PA_Notifications" href="javascript:void(0)" (click)="activeTab=1">Notifications</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-center" [ngClass]="{'active':activeTab==2}" aria-controls="PA_History" href="javascript:void(0)" (click)="activeTab=2">Prior Authorizations History</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-center" [ngClass]="{'active':activeTab==3}" aria-controls="Saved_PA" href="javascript:void(0)" (click)="activeTab=3">Saved Prior Authorizations</a>
                        </li>
                    </ul>
                </div>
            <div class="col-12 bg-white">
                <!-- <div class="col-lg-2 .d-md-none .d-sm-none"></div> -->
                <div>
                    <div class="tab-content">
                        <div *ngIf="activeTab==1" role="tabpanel" aria-labelledby="PA_Notifications">
                            
                           <!--<button class="btn btn-primary btn-primary-link float-end" (click)="generateMemberSpecificPANotification()">ViewallNotifications</button>-->
                           <section>
                            <memberviewall [memberId]="this.memberSearchResult!.memberId" [maxResults]="maxHistoryCount" (newNotifications)="getnewNotificationsCount($event)"></memberviewall>
                            </section>
                            <!--- <p class="large">You have 0 new notifications for this member, which are indicated
                                by the icon in the corner Up to ten of the most recent notifications for this member are shown below.</p>-->
                                <!--<section class="info-report-border-separate" ng-show="items.length>0 && !hide">-->
                                <!--<section>
                                    <memberviewall>

                                  </memberviewall>
                                    <memberdatatable [tabledata]="allNotifications">
                
                                    </memberdatatable>                   
                                </section>-->
                        </div>
                        <div class="tab-content" *ngIf="activeTab == 2" role="tabpanel"
                        aria-labelledby="PA_History">
                            <div class="row" style="margin: 2%;">
                                
                            <div class="col-md-7 col-xs-6">
                                <div>
                                <h2>Prior Authorization History</h2>
                                </div>
                                <span>Up to ten of the most recent prior authorization requests for this member<br> are shown below.</span>
                                </div>
                                <div class="col-md-5 col-xs-6" style="float:right">
                                    <button class="btn btn-primary btn-primary-link float-end" (click)="gotoViewAllSubmittedPA()">View All PA History</button>
                                </div>
                            
                            <section  class="card-content">
                                <!-- <submittedpahistory [memberId]="this.memberSearchResult!.memberId" [maxResults]="maxHistoryCount"></submittedpahistory> -->
                                <membersubmittedpahistory [memberId]="this.memberSearchResult!.memberId" [maxResults]="maxHistoryCount"></membersubmittedpahistory>
                            </section>
                        </div>
                        </div>

                        <div *ngIf="activeTab==3" role="tabpanel" aria-labelledby="Saved_PA">
                            <div class="row" style="margin: 2%;">
                                <div class="col-md-7 col-xs-6">
                                        <h2>Saved Prior Authorizations</h2>
                                  <span>Up to ten of the most recent saved prior authorizations for this member are<br>shown below.
                                        Select a saved prior authorization to resume your work,or <br>
                                        resubmit an expired prior authorization request.</span>
                                    </div>
                                    <div class="col-md-5 col-xs-6" style="float:right">
                                        <button class="btn btn-primary btn-primary-link float-end" (click)="gotoViewAllSavedPA()">View All Saved PA</button>
                                    </div>
                                
                                    <section class="pb-4">
                                        <savedmemberpahistory [memberId]="this.memberSearchResult!.memberId" [pageType]="this.memberSpecific" [maxResults]="maxHistoryCount"></savedmemberpahistory>
                                    </section>
                            </div>
                            </div>
                        </div>   
                    </div>    
                    </div>   
                    </div> 
                    </div>               
    <div class="row">
    <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 text-start backToHome">
        <i class="fa fa-angle-left"></i>
        &nbsp;
        <a class="btn btn-link backToHome" (click)="goto('/searchMedicationClaims/MemberSearch')" tabindex="-1">
            Back to Member Search
        </a>
    </div>
</div>
    <ng-template #errorPopup let-modal>
        <div class="p-3">
            <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
            <div class="text-center mt-2">
                An error occured. Please try again.
            </div>
            <div class="row border-0 mt-4 p-3 ">
                    <button class="col-2  position-absolute top-80 start-50 translate-middle btn btn btn-outline-primary" (click)="modal.dismiss()">OK</button>
            </div>
        </div>
    </ng-template>
