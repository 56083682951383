<div class="col-10 offset-1">
    <div class="container-fluid noresultsinfo" *ngIf="memberResultType==1">
        <div class="row">
            <div class="col nopadding">
                <div class="alert alert-primary shadow" role="alert">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                    <span>Info: No Search Results found</span>
                    <i class="fa fa-remove" aria-label="Close" (click)="memberResultType=0"></i>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container-fluid servererror" *ngIf="memberResultType==2 || memberResultType==3">
        <div class="row">
            <div class="col nopadding">
                <div class="alert alert-danger shadow" role="alert">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                    <span>We're unable to complete your request at this time. Please try again later.</span>
                    <i class="fa fa-remove" aria-label="Close" (click)="memberResultType=0"></i>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container-fluid timeout" *ngIf="memberResultType==4">
        <div class="row alert alert-danger shadow" role="alert">
            <div class="col-10 nopadding">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                <span>We were unable to retrieve this information. Refresh the page to try again.</span>
            </div>
            <div class="col-2 text-right nopadding">
                <div class="row">
                    <button class="col-10 btn btn-danger default" (click)="findMember()">Refresh page</button>
                    <i class="col-2 mt-2 fa fa-remove" aria-label="Close" (click)="memberResultType=0"></i>
                </div>
            </div>
        </div>
    </div>  
    <div class="row" *ngIf="!this.blnMember==false">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
            <h1 name="title" class="fs-3">Search for a Member</h1>
            <h2>Enter the required fields to select a member.</h2>
        </div>
    </div>
    <form name="membersearchform" *ngIf="blnSearchPanelOpened" [formGroup]="membersearchform" (ngSubmit)="this.findMember()">
        <div class="container-fluid bg-white content-card">
            <div class="row">
                <div class="col card-header">
                    <h3 class="float-start" *ngIf="!this.blnInitiatePA && pageType!='SearchMedicationClaims'">STEP 1 OF 3: SELECT A MEMBER</h3>
                    <h3 class="float-start" *ngIf="this.blnInitiatePA">STEP 1 OF 4: SELECT A MEMBER</h3>
                    <h3 class="float-start fontbold" *ngIf="pageType=='SearchMedicationClaims'" style="color: #626f7d !important;">SEARCH BY:</h3>
                    <span class="btn btn-link float-end clearAll" *ngIf="blnValidFormSubmitted"
                        (click)="clearAll()">Clear All</span>
                    <span class="float-end">
                        <a class="btn invisiblebtn btn-link fontbold" (click)="openRecentMembers($event)">Recent
                            Searches</a>
                        &nbsp;
                        |
                        &nbsp;
                        <a class="btn invisiblebtn btn-link fontbold" (click)="openSavedMembers($event)">Saved Searches</a>
                    </span>
                </div>
            </div>
            <div class="row" *ngIf="blnInSuffientSearchCriteria">
                <div class="col nopadding">
                    <div class="alert alert-danger">Please enter all required fields.</div>
                </div>
            </div>
            <div class="row searchcriteria pulldown">
                <div class="col-6">
                    <div class="form-group">
                        <label for="Lastname" class="form-label">Member Last Name</label>
                        <input type="text" maxlength="25" name="Lastname" formControlName="Lastname"
                            class="rounded-0 form-control inp" aria-required="true" (focus)="blnLastNameFocus=true"
                            (blur)="blnLastNameFocus=false"
                            [ngClass]="{'inpError': (blnFormSubmitted && !membersearchform.controls['Lastname'].valid) || 
                        (!blnFormSubmitted && !membersearchform.controls['Lastname'].pristine && membersearchform.controls['Lastname'].errors && 
                        (membersearchform.controls['Lastname'].errors['pattern'] || (!blnLastNameFocus && membersearchform.controls['Lastname'].errors['minlength']) ))}" />
                    </div>
                    <div class="text-danger">
                        <ng-container
                            *ngIf="membersearchform.controls['Lastname'] && membersearchform.controls['Lastname'].errors && !membersearchform.controls['Lastname'].errors['pattern'] && 
                        membersearchform.controls['Lastname'].errors['minlength'] && (blnFormSubmitted || !blnLastNameFocus)">
                            Please enter atleast two characters.
                        </ng-container>
                        <ng-container
                            *ngIf="membersearchform.controls['Lastname'] && membersearchform.controls['Lastname'].errors && membersearchform.controls['Lastname'].errors['pattern']">
                            Invalid Character.
                        </ng-container>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="Firstname" class="form-label">Member First Name (optional)</label>
                        <input type="text" maxlength="25" name="Firstname" formControlName="Firstname"
                            class="rounded-0 form-control inp" aria-required="false"
                            [ngClass]="{'inpError': (!membersearchform.controls['Firstname'].valid && !membersearchform.controls['Firstname'].pristine)}" />
                    </div>
                    <div class="text-danger">
                        <ng-container
                            *ngIf="membersearchform.controls['Firstname'] && membersearchform.controls['Firstname'].errors && membersearchform.controls['Firstname'].errors['pattern']">
                            Invalid Character
                        </ng-container>
                    </div>
                </div>
            </div>
    
            <div class="row searchcriteria">
                <div class="col-4">
                    <div class="form-group">
                        <label for="DateofBirth" class="form-label">Date of Birth (mm/dd/yyyy)</label>
                        <datefield [validationMode]="dobValidationMode" [content]="dobValue"
                            (onUpdate)="onDobUpdate($event)" [emptyValid]="!blnFormSubmitted">
                        </datefield>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <label for="inputZip" class="form-label">ZIP Code</label>
                        <input type="text" maxlength="5" name="inputZip" formControlName="inputZip"
                            class="rounded-0 form-control inp" aria-required="true" (focus)="blnZipCodeFocus=true"
                            (blur)="blnZipCodeFocus=false"
                            [ngClass]="{'inpError': (blnFormSubmitted && !membersearchform.controls['inputZip'].valid) || 
                        (!membersearchform.controls['inputZip'].pristine && membersearchform.controls['inputZip'].errors && 
                        (membersearchform.controls['inputZip'].errors['pattern'] || (!blnZipCodeFocus && membersearchform.controls['inputZip'].errors['minlength'])))}" />
                    </div>
                    <div class="text-danger">
                        <ng-container
                            *ngIf="membersearchform.controls['inputZip'] && membersearchform.controls['inputZip'].errors && membersearchform.controls['inputZip'].errors['pattern']">
                            Invalid Character
                        </ng-container>
                        <ng-container
                            *ngIf="membersearchform.controls['inputZip'] && membersearchform.controls['inputZip'].errors && !membersearchform.controls['inputZip'].errors['pattern'] && 
                        membersearchform.controls['inputZip'].errors['minlength'] && (blnFormSubmitted || !blnZipCodeFocus)">
                            Please enter a valid ZIP code.
                        </ng-container>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="MemberId" class="form-label">Member ID (Optional)</label>
                        <input type="text" maxlength="18" class="rounded-0 form-control inp" formControlName="MemberId"
                            name="MemberId" (focus)="blnMemberIdFocus=true" (blur)="blnMemberIdFocus=false" [ngClass]="{'inpError': membersearchform.controls['MemberId'].errors && !membersearchform.controls['MemberId'].pristine && ( (!blnMemberIdFocus && membersearchform.controls['MemberId'].errors['minlength']) || 
                        membersearchform.controls['MemberId'].errors['pattern'])}" />
                    </div>
                    <div class="text-danger">
                        <ng-container
                            *ngIf="!blnMemberIdFocus && membersearchform.controls['MemberId'].errors && membersearchform.controls['MemberId'].errors['minlength']">
                            Please enter at least 6 characters.
                        </ng-container>
                        <ng-container
                            *ngIf="membersearchform.controls['MemberId'].errors && !membersearchform.controls['MemberId'].pristine && membersearchform.controls['MemberId'].errors['pattern']">
                            Invalid character.
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col card-footer">
                    <button class="btn btn-primary float-end fontbold" type="submit">
                        <span *ngIf="!blnValidFormSubmitted">Search</span>
                        <!-- <span *ngIf="!blnValidFormSubmitted && pageType=='SearchMedicationClaims'" (click)="goto('MemberDetail')">Search</span> -->
                        <span *ngIf="blnValidFormSubmitted">Update</span>
                    </button>
                    <a class="btn invisiblebtn btn-link float-end fontbold" *ngIf="!blnValidFormSubmitted" (click)="back($event)">Back</a>
                    <a class="btn invisiblebtn btn-link float-end fontbold" *ngIf="blnValidFormSubmitted" (click)="close()">Close</a>
                </div>
            </div>
    
        </div>
    </form>
    
    <div class="container-fluid bg-white closedsearchcriteria content-card" *ngIf="!blnSearchPanelOpened">
        <div class="row">
            <div class="col-2">
                You Searched:
            </div>
            <div class="col-9">
                [
                LN:&nbsp;{{getLastName()}}
                <span *ngIf="getFirstName().length>0">&nbsp;/&nbsp;FN:&nbsp;{{getFirstName()}}&nbsp;</span>
                /&nbsp;DOB:&nbsp;{{dobValue}}
                /&nbsp;ZIP:&nbsp;{{getZipcode()}}&nbsp;
                <span *ngIf="getMemberId().length>0">/&nbsp;ID:&nbsp;{{getMemberId()}}&nbsp;</span>
                ]
            </div>
            <div class="col-1">
                <a class="btn btn-link invisiblebtn btn-link fontbold" (click)="blnSearchPanelOpened=true">Edit</a>
            </div>
        </div>
    </div>
    <br />
    <div class="container-fluid" *ngIf="blnShowResultsPannel">
        <!-- <div class="container-fluid"> -->
        <div class="row">
            <div class="col nopadding" *ngIf="memberResults.length>0">
                <memberresult [members]="memberResults" 
                [pageType]="pageType"></memberresult>
            </div>
            <div class="col nopadding noresults" *ngIf="memberResults.length==0 && this.blnHideonClearAll==false;">
                No search results found.
            </div>
        </div>
    </div>
</div>


<ng-template #savedmembersmodaltemplate let-modal>
    <favouritemembers (onMemberSelected)="findFavouriteMember($event)"></favouritemembers>
</ng-template>

<ng-template #recentMembers let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Search History</h4>
       <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
  
    <div class="modal-body">
      <datatable [headers]="recentMembersHeaders" [rows]="recentMembersHistory"
        (onHeaderClicked)="onRecentMembersHeaderClick($event)" (onCellClicked)="onRecentMembersCellClick($event)">
      </datatable>
    </div>
    <div class="modal-body" *ngIf="this.recentMembersHistory.length==0">
        <h6 class="p-3">{{this.message}}</h6>
      </div>

      <div class="container-fluid" *ngIf="totalPagesCount>1 && this.recentSearches.length>5">
        <div class="row">
          <div class="col text-center p-2 offset-4">
            <pagination [totalPages]="totalPagesCount" (onPageChange)="onPageChange($event)"></pagination>
          </div>
        </div>
      </div>
    
  </ng-template>

  <ng-template #NameTemplate let-data="data">
    <div class="p-2">
      <p class="large">
        <span class="fontbold">{{data.lastName}}</span>
        <br />
        {{data.firstName}}
      </p>
    </div>
  </ng-template>
  <ng-template #AddressTemplate let-data="data">
    <p class="large">
      <span class="fontbold">{{data.address.address1}}</span>
      <br />
      {{data.address.city}},{{data.address.state}}&nbsp;{{data.address.zip1}}
    </p>
  </ng-template>
  <ng-template #DateofBirthTemplate let-data="data">
    <div class="p-3">
    <p class="large" >{{data.dateOfBirth}}</p>
    </div>
  </ng-template>
  <ng-template #MemberIdTemplate let-data="data">
    <p class="large">{{data.memberId}}</p>
  </ng-template>
  
  
<!-- Loading.... -->
<ng-template #LoadingContent let-modal>
    <div class="modal-body">
        <h3 class="text-center text-capitalize">{{modalContent}}</h3>
    </div>
</ng-template>