<div class="content-card bg-white">
    <div class="card-content">
        <div class="container-fluid nopadding">
            <div class="row">
                <div class="col-12">
                    <h2 class="fontbold">Add an attachment (Optional)</h2>
                    <p class="large">
                        Please attach a file. Accepted file types: TIFF, PNG, JPEG, PDF. The attachment cannot exceed more than {{maxFileSize}}MB in size.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="text-center uploader" *ngIf="!paAttachment">
                        <div>
                            <label class="btn btn-primary fontbold rounded-pill" for="uploadbutton">
                                <i class="fa fa-folder-open" aria-hidden="true"></i>
                                Select file from my computer
                            </label>
                            <input type="file" id="uploadbutton" #uploaderelement [accept]="fileTypesAllowed"
                            (change)="uploadFile($event)"/>
                        </div>
                    </div>
                    <div class="text-start uploaded" *ngIf="paAttachment">
                        <div>
                            <i class="fa fa-file fileicon"></i>
                            <span class="filename">
                                {{paAttachment.fileName}}
                                <span class="filename" *ngIf="paAttachment.fileType">.{{paAttachment.fileType}}</span>
                            </span>                            
                            <span class="filesize">
                                <span [innerHtml]="getFileSize()"></span>
                                MB
                            </span>
                            <div class="float-end deletefile" (click)="deleteUploadedFile()">
                                <button class="btn btn-link">
                                    Remove file
                                </button>
                                <i class="fa fa-trash-o deleteicon"></i>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #FileSizeExceeded let-modal>
    <div class="modal-body text-center errorModal" aria-labelledby="FileSizeExceeded">
        <i class="fa fa-exclamation-circle"></i>
        <p class="large">
            The file size exceeds the amount permitted for attachments.
            <br/>
            Please upload a smaller file
        </p>
        <button class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
        <button class="btn btn-primary rounded-pill" (click)="modal.dismiss();browseAnotherFile()">Select new file</button>
    </div>
</ng-template>

<ng-template #FileTypeError let-modal>
    <div class="modal-body text-center errorModal" aria-labelledby="FileTypeError">
        <i class="fa fa-exclamation-triangle error"></i>
        <p class="large">
            The file failed to upload.
            <br/>
            Only these types of files are supported: TIFF, PNG, JPEG, PDF
        </p>
        <button class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
        <button class="btn btn-primary rounded-pill" (click)="modal.dismiss();browseAnotherFile()">Select new file</button>
    </div>
</ng-template>

<ng-template #UploadProgress let-modal>
    <div class="modal-body text-center" aria-labelledby="UploadProgress">
        <p class="large">
            {{currentFile?.name}}
        </p>
        <div class="uploadprogress">
            <div [style.width]="progress+'%'"></div>
        </div>
        <br/>
        <div>Uploading attachment...</div>
    </div>
</ng-template>

<ng-template #UploadError let-modal>
    <div class="modal-body text-center" aria-labelledby="UploadError">
        <i class="fa fa-exclamation-circle"></i>
        <p class="large" [innerHtml]="errorMessage">
        </p>
        <button class="btn btn-secondary" (click)="modal.dismiss()">Ok</button>
    </div>
</ng-template>