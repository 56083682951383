import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { USER_ROLE } from 'src/app/constants/appconstants';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'pharmacyhome',
  templateUrl: './pharmacyhome.component.html',
  styleUrls: ['./pharmacyhome.component.css']
})
export class PharmacyhomeComponent {
  username!:string;
  userrole!:USER_ROLE;
  recentSearchSession:any = undefined;
  recentSearchDrugSession:any = undefined;

  constructor(private profileService:ProfileService,protected router:Router){
    this.username="";
    if(this.profileService.loginuser!=undefined){
      this.username = this.profileService.loginuser.username.replace(',',"");
      this.userrole = this.profileService.USER_ROLE;
    }
    //for member search and drug search recent search session
    if(sessionStorage.getItem("recentMemberSearches")!=undefined)
      this.recentSearchSession= JSON.parse(""+sessionStorage.getItem("recentMemberSearches"));
      if(sessionStorage.getItem("recentDrugSearches")!=undefined)
      this.recentSearchDrugSession= JSON.parse(""+sessionStorage.getItem("recentDrugSearches"));
      sessionStorage.clear();
      if(this.recentSearchSession!=undefined)
      sessionStorage.setItem("recentMemberSearches",JSON.stringify(this.recentSearchSession));
      console.log(this.recentSearchSession);
      if(this.recentSearchDrugSession!=undefined)
        sessionStorage.setItem("recentDrugSearches",JSON.stringify(this.recentSearchDrugSession));
        console.log(this.recentSearchDrugSession);
      
  }
  ngOnInit(): void {
   
  }
  goto(routeUrl:string){
    this.router.navigateByUrl("/"+routeUrl);
  }

  
}
