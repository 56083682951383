import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DatatableComponent} from 'src/app/components/datatable/datatable.component';
import { TableheaderComponent} from 'src/app/components/datatable/tableheader/tableheader.component';
import { TablerowComponent } from 'src/app/components/datatable/tablerow/tablerow.component';
import { TablecellComponent } from 'src/app/components/datatable/tablecell/tablecell.component';
import { DatefieldComponent } from 'src/app/components/datefield/datefield.component';
import { TimeremaininghoursPipe , TimeremainingDescPipe, TimeremainingminutesPipe} from "src/app/pipes/timeremaining/timeremaining.pipe";
import { StandardDateToServertime, DatetoDisplayString, ServertimeToStandardDate, TimeStampToStandardDate, TimestamptoServerTime, ServertimeToDisplayString } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { FilterobjectsByTextPipe } from 'src/app/pipes/filterobjects/filterobjects.pipe';
import { StringToSentenceCase, FormatPhoneOrFax } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { NdcdisplayformatPipe } from 'src/app/pipes/ndcdisplayformat/ndcdisplayformat.pipe';
import { DatatableModule } from 'src/app/modules/datatable/datatable.module';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { GlobalmenuComponent } from 'src/app/components/globalmenu/globalmenu.component';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
// import { PaginationComponent } from 'src/app/components/pagination/pagination.component';

//import { PaginationComponent } from 'src/app/components/pagination/pagination/pagination.component';
import { DrugnamesortPipe } from 'src/app/pipes/drugnamesort/drugnamesort.pipe';
import { EparunnerComponent } from 'src/app/components/epa/eparunner/eparunner.component';
import { DurdescriptionPipe, DurProfessionalServiceCodePipe, DurResultOfServiceCodePipe } from 'src/app/pipes/durdescription/durdescription.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    TimeremainingDescPipe,
    TimeremaininghoursPipe,
    TimeremainingminutesPipe,
    DrugnamesortPipe,
    DurdescriptionPipe,
    DurProfessionalServiceCodePipe,
    DurResultOfServiceCodePipe,
    StandardDateToServertime,
    ServertimeToStandardDate,
    ServertimeToDisplayString,
    DatetoDisplayString,
    TimeStampToStandardDate,
    TimestamptoServerTime,
    FilterobjectsByTextPipe,
    StringToSentenceCase,
    NdcdisplayformatPipe,
    FormatPhoneOrFax,
    DatefieldComponent,
    PaginationComponent,
    DrugnamesortPipe,
    EparunnerComponent,
    LoaderComponent,


  ],
  imports: [
    CommonModule,
    DatatableModule,
    FormsModule, 
    ReactiveFormsModule,
    NgbModule,
    CommonModule,
    BrowserAnimationsModule,
  ],
  exports: [
    TimeremainingDescPipe,
    TimeremaininghoursPipe,
    TimeremainingminutesPipe,
    DrugnamesortPipe,
    DurdescriptionPipe,
    DurProfessionalServiceCodePipe,
    DurResultOfServiceCodePipe,
    StandardDateToServertime,
    ServertimeToDisplayString,
    DatetoDisplayString,
    ServertimeToStandardDate,
    TimeStampToStandardDate,
    TimestamptoServerTime,
    FilterobjectsByTextPipe,
    NdcdisplayformatPipe,
    StringToSentenceCase,
    FormatPhoneOrFax,
    DatatableComponent,
    TableheaderComponent,
    TablerowComponent,
    TablecellComponent,
    DatefieldComponent,
    PaginationComponent,
    DrugnamesortPipe,
    EparunnerComponent,
    LoaderComponent,



  ],
  providers: [
    TimeremainingDescPipe,
    TimeremaininghoursPipe,
    TimeremainingminutesPipe,
    DrugnamesortPipe,
    DurdescriptionPipe,
    DurProfessionalServiceCodePipe,
    DurResultOfServiceCodePipe,
    StandardDateToServertime,
    ServertimeToDisplayString,
    DatetoDisplayString,
    ServertimeToStandardDate,
    TimeStampToStandardDate,
    TimestamptoServerTime,
    FilterobjectsByTextPipe,
    NdcdisplayformatPipe,
    StringToSentenceCase,
    FormatPhoneOrFax,
    DatatableComponent,
    TableheaderComponent,
    TablerowComponent,
    TablecellComponent,
    DatefieldComponent,
  ]
})
export class SharedModule { }
