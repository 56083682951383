<div class="container-fluid" style="position: absolute;top:0; padding: 0;margin: 0;left: 0; right: 0;"
    *ngIf="cancelSuccessful==true" #vanish>
    <div style="display:flex;flex-direction:row;justify-content: center;position: relative;top: 8px;">
        <div style="position:absolute;;display:flex;flex-direction:row;justify-content: center;position: relative; top: 15px;top: 60px; width: 63%; z-index: 2;"
            >
            <div
                style="display:flex;    background-color: #e4f3e4;color: #007a4b;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px; width: 76%;flex-direction:row; width: 100%;">

                <i class="fa fa-check" style="font-size: 25px;padding-right: 10px;"></i>
                <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
                    font-size: 16px;flex-grow: 1;">
                    The request for access has been successfully cancelled.</span>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid" style="position: absolute;top:0; padding: 0;margin: 0;left: 0; right: 0;"
    *ngIf="removeSuccessful==true"  #vanish>
    <div style="display:flex;flex-direction:row;justify-content: center;position: relative;top: 8px;">
        <div style="position:absolute;;display:flex;flex-direction:row;justify-content: center;position: relative; top: 15px;top: 60px; width: 63%; z-index: 2;"
           >
            <div
                style="display:flex;    background-color: #e4f3e4;color: #007a4b;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px; width: 76%;flex-direction:row; width: 100%;">

                <i class="fa fa-check" style="font-size: 25px;padding-right: 10px;"></i>
                <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
                    font-size: 16px;flex-grow: 1;">
                    <span class="capitalize">
                        {{cancelPrescriberName}}
                    </span> has been successfully removed.</span>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid" style="position: absolute;top:0; padding: 0;margin: 0;left: 0; right: 0;"
*ngIf="addressChangeSuccessful==true" #vanish>
<div style="display:flex;flex-direction:row;justify-content: center;position: relative;top: 8px;">
    <div style="position:absolute;;display:flex;flex-direction:row;justify-content: center;position: relative; top: 15px;top: 60px; width: 63%; z-index: 2;"
        >
        <div
            style="display:flex;    background-color: #e4f3e4;color: #007a4b;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px; width: 76%;flex-direction:row; width: 100%;">

            <i class="fa fa-check" style="font-size: 25px;padding-right: 10px;"></i>
            <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
                font-size: 16px;flex-grow: 1;"> Preferred address for <span class="capitalize"> {{cancelPrescriberName}} </span> has been successfully changed.
            </span>
        </div>
    </div>
</div>
</div>


<div class="container-fluid addressbook">
    <div class="row addressbook_headerrow back1">
        <div class="addressbook_headerimg col-xs-12 col-md-12">
            <div class="container-fluid manage_prescribers_container fadeout-widget" style="display: flex;">
                <div class="col-xs-6 col-md-6 col-lg-7 addressbook_headertext ng-scope">
                    <div class="heading_mega ng-binding">Manage Prescribers</div>
                    <p style="max-width: 470px;">Here you can add up to 20 prescribers to your account.</p>
                    <p style="max-width: 470px;">Adding a prescriber allows you to submit electronic prior
                        authorizations on their behalf, as well as view their submitted prior authorization history and
                        saved prior authorizations.</p>
                </div>
                <div class="col-xs-6 col-md-5 col-lg-4 addressbook_prescriberimgs ng-scope">
                    <div class="addressbook_prescriberimg1">
                        <img class="prescriberimg1" src="assets/images/u21522.png">
                    </div>
                    <div class="addressbook_prescriberimg2">
                        <img class="prescriberimg2" src="assets/images/u21526.png">
                    </div>
                    <div class="addressbook_prescriberimg3">
                        <img class="prescriberimg3" src="assets/images/u21517.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="row addressbook_contentrow" style="padding-bottom: 100px;">
            <div class="col-xs-12 col-md-12 ng-scope" ui-view>

                <div class="container-fluid manage_prescribers_container ng-scope">
                    <div class="row fadeout-widget " *ngIf="deactivatedPrescriberDetails.length>0">
                        <div class="col-md-9" style="margin-top: 10px;">
                            <h2 class="pendinglabel">Deactivated</h2>
                        </div>
                        <div class="col-md-3 ng-hide" style="margin-top: 35px;text-align: end;"
                            *ngIf="deactivatedPrescriberDetails.length>1">
                            <button type="button" class="btn primary btn-primary " style="height: 40px;width:100%"
                                (click)="reActivateAllPrescribers()">Reactivate All</button>
                        </div>
                    </div>
                    <div class="row " *ngIf="deactivatedPrescriberDetails.length>0">
                        <div class="col-md-12" style="padding: 5px 0px;">
                            <div class="row">
                                <div class="col-sm-12 managedivs ng-scope"
                                    *ngIf="deactivatedPrescriberDetails.length>0">
                                    <div class="tileopened-1 box_style" style="width:320px !important;"
                                        *ngFor="let item of deactivatedPrescriberDetails;index as i">
                                        <app-presbr-book-details [item]="deactivatedPrescriberDetails[i]"
                                            (onReactivatePrescriber)="reActivatePrescribers($event)"></app-presbr-book-details>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row fadeout-widget "
                        *ngIf="expiredPrescriberDetails.length>0 || pendingPrescriberDetails.length>0 || deniedPrescriberDetails.length>0">
                        <div class="col-md-9" style="margin-top: 10px;">
                            <h2 class="pendinglabel">Prescribers Pending Verification</h2>
                        </div>
                    </div>
                    <div class="row"
                        *ngIf="pendingPrescriberDetails.length>0 || expiredPrescriberDetails.length>0 || deniedPrescriberDetails.length>0">
                        <div class="col-md-12" style="padding: 5px 0px;">
                            <div class="row">
                                <div class="col-sm-12 managedivs ng-scope">
                                    <div class="tileopened-1 box_style " style="width:320px !important;"
                                        *ngFor="let item of pendingPrescriberDetails;index as i">
                                        <app-presbr-book-details [item]="pendingPrescriberDetails[i]"
                                            (onCancelRequest)="onPendingCancel($event)"
                                            (onResendNewPINRequest)="onResendPin($event)"></app-presbr-book-details>
                                    </div>
                                    <div class="tileopened-1 box_style" style="width:320px !important;"
                                        *ngFor="let item of expiredPrescriberDetails;index as i">
                                        <app-presbr-book-details [item]="expiredPrescriberDetails[i]"
                                            (onCancelRequest)="onExpiredRemove($event)"
                                            (onRequestNewPINRequest)="onRequestPin($event)"></app-presbr-book-details>
                                    </div>
                                    <div class="tileopened-1 box_style" style="width:320px !important;"
                                        *ngFor="let item of deniedPrescriberDetails;index as i">
                                        <app-presbr-book-details (onCancelRequest)="onDeniedRemove($event)"
                                            [item]="deniedPrescriberDetails[i]"></app-presbr-book-details>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row fadeout-widget ">
                        <div class="col-md-12">
                            <h2 style="margin: 40px 0 20px 0;">My Prescribers</h2>
                            <span class="paragraph ng-binding">View the prescribers that have access to, or add a
                                prescriber. You can select your preferred prescriber by selecting the star icon in the
                                top right corner of their prescriber card. You can only have one preferred prescriber at
                                a time.</span>
                        </div>
                    </div>
                    <!-- <div class="row ">
                        <div class="col-md-12" style="padding: 5px 0px;">
                            <div class="row">
                                <div class="col-sm-12 managedivs" [ngClass]="{'open':blnShowAltAddress}">
                                    <div class="tileopened-1 box_style">
                                        <div class="default_box small_box" style="position: relative"
                                            (click)="goto('prescribers/add')">
                                            <div
                                                style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding: 25px;">
                                                <img id="image-1" alt src="assets/images/add-prescribers-icon-01.svg"
                                                    style="width:40%; margin:40px 10px 30px 30px;height:89px">
                                                <br>
                                                <span>Add a Prescriber</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tileopened-1 box_style"
                                        *ngFor="let item of prescriberDetails;index as i">
                                        <app-presbr-book-details [item]="prescriberDetails[i]"                                            
                                            (onChangeSelected)="changeAddress(item.presbrId,i)"
                                            (onChangeDefaultPrescriber)="changeDefaultPrescriber(item.presbrId)">
                                            <app-presbr-change-address 
                                            *ngIf="this.blnShowAltAddress==true"
                                                [item]="prescriberDetails[this.index]">
                                            </app-presbr-change-address>
                                        </app-presbr-book-details>

                                    </div>
                                </div>

                            </div>


                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-md-12" style="padding: 5px 0px;">
                            <div class="row">
                                <div class="col-sm-12 managedivs grid_pattern"
                                    *ngFor="let row of addedPrescriberscopy;index as i">
                                    <div class="tileopened-1 box_style_added shadow" *ngIf="i==0 && this.totalPrescriberCount<20">
                                        <div class="default_box small_box" style="position: relative"
                                            (click)="goto('prescribers/add')">
                                            <div
                                                style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding: 25px;">
                                                <img id="image-1" alt src="assets/images/add-prescribers-icon-01.svg"
                                                    style="width:40%; margin:40px 10px 30px 30px;height:89px">
                                                <br>
                                                <span class="underline-on-hover">Add a Prescriber</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tileopened-1 box_style_added" *ngIf="i==0 && this.totalPrescriberCount==20">
                                        <div class="default_box default_dummy_box small_box ng-scope" style="position: relative">
                                            <div
                                                style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding: 25px;">
                                                <img id="image-1" alt src="assets/images/max-prescribers-icon.svg"
                                                style="width:50%; margin-bottom:10px;height:89px">
                                                <br>
                                                <span>You have reached the maximum number of prescribers that can be added. Please remove a prescriber in order to add a new one.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tileopened-1 box_style_added" *ngFor="let item of row;index as j"
                                        [ngClass]="{'itembig': i == selectedTilerow && j== selectedTilecol,
                                        'item3': j%3 == 1 && i == selectedTilerow && j== selectedTilecol && addedPrescriberscopy[i].length == 2,
                                        'item4': j%3 == 2 && i == selectedTilerow && j== selectedTilecol && addedPrescriberscopy[i].length != 2}">
                                        <app-presbr-book-details [item]="addedPrescriberscopy[i][j]"
                                            (onChangeSelected)="changeAddress(item.presbrId,j);selectedTile(i,j)"
                                            (onChangeDefaultPrescriber)="changeDefaultPrescriber(item.presbrId)"
                                            [addressChangeID]="selectedChangeaddressID"
                                            (onRemoveAddedPresc)="onAddedRemove($event)"
                                            (onResetTileSize)="resetSelectedTile()"
                                            (onAddressChangeSuccess)="onNewAddressChange($event)">
                                            <!-- <app-presbr-change-address *ngIf="this.blnShowAltAddress==true"
                                                [item]="addedPrescriberscopy[i][j]">
                                            </app-presbr-change-address> -->
                                        </app-presbr-book-details>

                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>
                </div>

            </div>

        </div>

    </div>
</div>

<ng-template #successPINResendPopup let-modal>
    <div class="container-fluid favouriteActionError">
        <div class="row">
            <div class="col text-center">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
            </div>
        </div>
        <div class="row">
            <div class="col paragraph large text-center">
                The PIN has been resent to
                <span *ngIf="!pinResentToAll" class="capitalize" style="font-family: Frutiger-Bold, Helvetica, Arial, sans-serif;">
                    {{resendPrescName}}. 
                </span> 
                <span *ngIf="pinResentToAll"> all. </span> If the prescriber has not received the PIN at the fax number
                you selected, please review the fax number to ensure it is correct. The prescriber can complete the
                request once they have received the PIN or you can call
                <span style="font-family: Frutiger-Bold, Helvetica, Arial, sans-serif;"> 1-866-842-3278 </span>.
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <button type="button" class="btn btn-primary" (click)="modal.dismiss();clearData();">Ok</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #cancelRequestPopup let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>Are you sure you want to cancel this access request?</span>
                <br />
                <span class="capitalize"
                    style="color: #050709 !important;padding-bottom: 3px !important;padding-top: 10px;font-family: Frutiger-Bold, Helvetica, Arial, sans-serif; font-size: 18px;">
                    {{cancelPrescriberName}}
                </span>
                <br />
                <span>NPI: {{cancelprescriberNPI}}</span>

            </p>
            <br />
            <button class="btn btn-secondary" (click)="modal.dismiss();">No</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" (click)="modal.dismiss();cancelPrescriberRequest()">Yes</button>
        </div>
    </div>
</ng-template>
<ng-template #canceladdedprescPopup let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>Are you sure you want to remove access to this prescriber?</span>
                <br />
                <span class="capitalize"
                    style="color: #050709 !important;padding-bottom: 3px !important;padding-top: 10px;font-family: Frutiger-Bold, Helvetica, Arial, sans-serif; font-size: 18px;">
                    {{cancelPrescriberName}}
                </span>
                <br />
                <span>NPI: {{cancelprescriberNPI}}</span>

            </p>
            <br />
            <button class="btn btn-secondary" (click)="modal.dismiss();">No</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" (click)="modal.dismiss();cancelPrescriberRequest()">Yes</button>
        </div>
    </div>
</ng-template>
<ng-template #removedeniedprescPopup let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>Are you sure you want to remove this denied request?</span>
                <br />
                <span class="capitalize"
                    style="color: #050709 !important;padding-bottom: 3px !important;padding-top: 10px;font-family: Frutiger-Bold, Helvetica, Arial, sans-serif; font-size: 18px;">
                    {{cancelPrescriberName}}
                </span>
                <br />
                <span>NPI: {{cancelprescriberNPI}}</span>

            </p>
            <br />
            <button class="btn btn-secondary" (click)="modal.dismiss();">No</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" (click)="modal.dismiss();cancelPrescriberRequest()">Yes</button>
        </div>
    </div>
</ng-template>
<ng-template #noprescriber let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>The prescriber has approved/denied this request.</span>
                <br />
                <span> Click OK to update the page.</span>
            </p>
            <br />
            <button class="btn btn-primary" (click)="modal.dismiss();removeNotFoundPrescriber()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToActivate let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>An error occurred.</span>
                <br />
                <span>
                    We are unable to submit your request.
                </span>
                <br />
                <span>Please try again.</span>

            </p>
            <br />
            <button class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" style="font-family: Frutiger-Bold;"
                (click)="modal.dismiss();cancelPrescriberRequest()">Try again</button>
        </div>
    </div>
</ng-template>

<ng-template #unableTocancel let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>An error occurred.</span>
                <br />
                <span>
                    We are unable to cancel your request.
                </span>
                <br />
                <span>Please try again.</span>

            </p>
            <br />
            <button class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" style="font-family: Frutiger-Bold;"
                (click)="modal.dismiss();cancelPrescriberRequest()">Try again</button>
        </div>
    </div>
</ng-template>

<ng-template #unableToResendPIN let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>An error occurred.</span>
                <br />
                <span>
                    We are unable to process your PIN request.
                </span>
                <br />
                <span>Please try again.</span>

            </p>
            <br />
            <button class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" style="font-family: Frutiger-Bold;"
                (click)="modal.dismiss();onRequestPinCall()">Try again</button>
        </div>
    </div>
</ng-template>

<ng-template #resendlimitexceeded let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>You have resent your request three times.</span>
                <br />
                <span class="link_text" (click)="modal.dismiss();onRequestPinCall()"> Request a new PIN </span>
                <span> or call 1-866-842-3278 for assistance. </span>

            </p>
            <br />
            <button class="btn btn-secondary bold_text" (click)="modal.dismiss();">OK</button>
        </div>
    </div>
</ng-template>

<ng-template #resendlimitexceededFull let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>You have reached the limit (3) for requesting a new PIN.</span>
                <br />
                <span> Please start a new request or call 1-866-842-3278. </span>
            </p>
            <br />
            <button class="btn btn-secondary bold_text" (click)="modal.dismiss();">OK</button>
        </div>
    </div>
</ng-template>

<!-- <div class="row" *ngIf="prescriberDetails.length>0">
                                <div class="col-md-12" style="padding: 5px 0px;">
                                    <div class="row" *ngFor="let item of prescriberDetails">
                                        <div class="col-sm-12 managedivs" *ngFor="key in item" [ngClass]="{'open':(useractivity.rowselected == key)}">
                                            <div *ngFor="let prscbr of value" class="tileopened{{useractivity.titleselected}} box_style" >
                                                <addresslist-item [index]="index" [item]="prscbr" [name]="prscbr.objkey" [type]="prscbr.type" [event]="prscbr.onclick(event)" [translation-options]="prscbr.translations"></addresslist-item>					
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->