<div class="container-fluid nopadding ng-scope">
    <div class="container-fluid maindiv" style="height: 325px;">
        <div class="row">
            <div class="col-md-12 text-center" style="padding: 95px 0 85px 0;">
                <div class="contactus" style="display: inline-block;">
                    <p class="heading_mega" style="color:#ffffff;">Contact PreCheck MyScript</p>
                    <p class="paragraph" style="color:#ffffff;word-wrap: break-word;line-height: 24px;">If you have any
                        questions, please contact us with questions using the information below.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid" style="position: relative; top: -125px;">
        <div class="row">
            <div class="col-md-12 text-center" style="padding: 0px;">
                <div class="container-fluid nopadding content-card contactus">
                    <div class="row nomargin contactUsFirstDiv">
                        <div class="col-sm-7 col-md-8 bg-white" style="padding:25px;">
                            <p class="heading_3">UnitedHealthcare Provider Tech Support</p>
                            <p class="paragraph" style="color: #333333; line-height: normal;">If you are a
                                UnitedHealthcare Provider and need assistance with the online tools listed below, please
                                contact us.</p>
                            <div class="container-fluid nopadding">
                                <div class="row" style="padding-top: 15px;">
                                    <div class="col-sm-6 col-md-5 nopadding contactbox">
                                        <ul style="padding-left: 30px;">
                                            <li>
                                                <p class="paragraph">Member Eligibility</p>
                                            </li>
                                            <li>
                                                <p class="paragraph" style="white-space: nowrap;">Electronic Prior
                                                    Authorization</p>
                                            </li>
                                            <li>
                                                <p class="paragraph">Preferred Alternatives</p>
                                            </li>
                                            <li>
                                                <p class="paragraph">Trial Claim Submittal</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-sm-6 col-md-7 nopadding contactbox">
                                        <ul>
                                            <li>
                                                <p class="paragraph">Drug Lookup</p>
                                            </li>
                                            <li>
                                                <p class="paragraph">Saved Prior Authorization Records</p>
                                            </li>
                                            <li>
                                                <p class="paragraph">Prior Authorization Request History</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div x-ms-format-detection="none" class="col-sm-5 col-md-4"
                            style="background-color: rgba(242, 246, 249, 1);padding:29px 15px 41px 20px;">
                            <p class="heading_2 ng-binding" style="padding-top: 20px;padding-bottom: 25px;">
                                <i class="fa fa-phone" aria-hidden="true"></i> 1-866-842-3278.
                            </p>
                            <p class="paragraph" style="color: #333333;white-space: nowrap;margin-left: 0.5em;">Hours of Operation (Central
                                Time)</p>
                            <div class="row" style="margin-left: 0.5em;">
                            <div class="col-sm-6 col-md-6 nopadding" style="width:40%;">
                                <p class="label_cu"><span style="color: #626F7D;">Monday - Friday</span>
                                </p>
                                <p class="label_cu"><span style="color: #626F7D;">Saturday - Sunday</span>
                                </p>
                            </div>
                            <div class="col-sm-6 col-md-6 nopadding" style="width:40%;margin-left: 1em;">
                                <p class="label_cu"><span>7:00 a.m. - 9:00 p.m.</span>
                                </p>
                                <p class="label_cu"><span>closed</span>
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top:25px">
            <div class="col-md-12 text-center" style="padding: 0px;">
                <div class="container-fluid content-card contactus">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="heading_3">One Healthcare ID Support</p>
                            <p class="paragraph ng-binding">
                                If you'd like assistance with your One Healthcare ID account, please contact One
                                Healthcare ID Support Help Desk.
                                <br><br>
                                Phone: <i x-ms-format-detection="none" class="fa fa-phone" aria-hidden="true"></i>&nbsp;
                                1-855-819-5909
                                <br>
                                Email: <a class ="btn invisiblebtn btn-link" href="mailto: OptumSupport@optum.com">OptumSupport&#64;optum.com</a>
                                <br>
                                Hours: 6am - 10pm CST, Monday - Friday
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="footer" class="d-print-none">
    <nav class="navbar navbar-expand-lg bg-light">
        <div class="container-fluid nav-footer text-center">
            <div class="row">
                <div class="col">
                    <ul>
                        <li aria-label="2024 Optum, Inc. All rights reserved">&#169; 2024 Optum, Inc. All rights
                            reserved</li>
                        &nbsp;|&nbsp;
                        <li><a routerLink="/privacy" aria-label="Link to Privacy Policy">Privacy policy</a></li>
                        &nbsp;|&nbsp;
                        <li><a routerLink="/terms" aria-label="Link to Terms of Use">Terms of use</a></li>
                        &nbsp;|&nbsp;
                        <li><a routerLink="/contactus" aria-label="Link to Contact Us">Contact us</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</section>