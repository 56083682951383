<div class="container-fluid col-md-8 alert alert-success shadow  mt-3 mb-3" style="position: absolute;  left: 16%;"
    role="alert" *ngIf="this.isSavedNotify==true">
    <i class="fa fa-check" aria-hidden="true" style="color: #1D781D;"></i>
    <span style="color: #222222;">Case ID: {{this.isCancelPAparam}} has been successfully saved.</span>
</div>
<div class=" container-fluid col-md-8 alert alert-success shadow  mt-3 mb-3"  style="position: absolute; left: 16%;" role="alert"
    *ngIf="this.isCancelled==true && iscancelPA!=null">
    <i class="fa fa-check" aria-hidden="true" style="color: #1D781D;"></i>
    <span style="color: #222222;">Case ID: {{this.isCancelPAparam}} has been successfully cancelled.</span>
</div>
<div class="container-fluid background1">
    <div class="row">
        <div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1 pagetitle">
            <h2>Welcome {{username}}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1 text-center">
            <h1>
                <img src="assets/images/pcms_global_logo.svg" alt="PreCheck MyScript"/>
            </h1>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1">
            <div class="container-fluid p-0 homepagetilesgroup">
                <div class="row">
                    <div class="col-xs-12 col-md-4 text-center">
                        <a href="javascript:void(0)" class="homepagetile" (click)="goto('PriorAuthChecker')" (keyup.enter)="goto('PriorAuthChecker')">
                            <div class="thumbnail">
                                <img src="assets/images/prescriptioncheck.png" alt="Prescription Check"/>
                                <h2>Check Prescription Coverage</h2>
                                <p>Check to see if medications are covered by a member's plan and get pricing options.</p>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-md-4 text-center">
                        <a href="javascript:void(0)" class="homepagetile" (click)="goto('ePA')">
                            <div class="thumbnail">
                                <img src="assets/images/ePA.png" alt="Electronic Prior Authorization"/>
                                <h2>Initiate a Prior Authorization</h2>
                                <p>Use our three-step process to initiate a prior authorization.</p>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-md-4 text-center">
                        <a href="javascript:void(0)" class="homepagetile" (click)="goto('/searchMedicationClaims/MemberSearch')">
                            <div class="thumbnail">
                                <img src="assets/images/memberSearch.png" alt="Search For A Member"/>
                                <h2>Search for a Member</h2>
                                <p>View member's insurance, demographics, prior authorization history and renewals.</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>