<div class="col-xs-12 col-md-12">
    <div class="container-fluid nopadding">
        <div class="container-fluid maindiv">
            <div class="row">
                <div class="col-md-8 col-xs-12 offset-md-2 pt-5">
                    <div class="smapcontactus" style="display:inline-block;">
                        <p class="heading_mega" style="color: #ffffff;">User Provisioning</p>
                        <p class="paragraph" style="color: #ffffff; word-wrap: break-word;line-height: 24px;">
                            Add users via their Optum ID, or use the bulk provisioning to add large volumes of
                            users.<br />
                            Once added, the Optum IDs will be visible in the table below.<br /><br />
                            <button type="button" aria-label="Add users to PreCheck MyScript" class="btn default_style"
                                (click)="goto('/provision/addusers')">Add users to PreCheck MyScript</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid nopadding" style="margin-top: 30px;">
        <div class="row">
            <!-- <div class="col-xs-12 col-md-8 offset-md-2">
                <div class="col-md-6" data-toggle="buttons">
                    <label for="pcmsProvisioning" class="btn active" ng-click="isPcmsProvisioning=true;recentUploads();">
                        <input type="radio" id="pcmsProvisioning" name="filter" ng-focus="checkRadio($event)" [checked]="isPcmsProvisioning" class="ng-pristine ng-untouched ng-valid ng-not-empty" value="true" style="display: none;">
                        <i class="fa fa-circle-o"></i><i class="fa fa-dot-circle-o"></i><span class="text"> PCMS Provisioning</span>
                    </label>
                    <label for="smmProvisioning" class="btn" ng-click="isPcmsProvisioning=false;recentUploads();">
                        <input type="radio" id="smmProvisioning" name="filter" ng-focus="checkRadio($event)" [checked]="!isPcmsProvisioning"  class="ng-pristine ng-untouched ng-valid ng-not-empty" value="false" style="display: none;">
                        <i class="fa fa-circle-o"></i><i class="fa fa-dot-circle-o"></i><span class="text"> SMAP Provisioning</span>
                    </label>
                </div>
            </div> -->
            <div class="col-xs-12 col-md-8 offset-md-2">
                <div class="container-fluid bg-white content-card nopadding">
                    <div class="card-header pb-5">
                        <h3 class="float-start">Bulk Provisioning</h3>
                        <span class="float-end">
                            <a class="btn invisiblebtn btn-link fontbold" (click)="downloadTemplate()">
                                <i class="fa fa-file-text" style="color: #0470c5"></i>
                                Download Template
                                <img src="assets/images/microsoft-excel.png" alt="excel image"
                                    style="width: 25px; height: 25px; position: relative; top: -3px;">
                            </a>
                        </span>
                    </div>
                </div>
                <div class="content-card">
                    <h2>Add an attachment (optional)</h2>
                    <p class="mt-2">Please attach an Excel file. Download the template to start.</p>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding ng-scope" *ngIf="selectedFile==null"
                        style="background-color:rgba(242, 246, 249, 1);border:1px solid lightgray">
                        <div style="margin-top:40px;margin-bottom:40px;text-align:center">
                            <input type="file" id="uploadbutton" #uploaderelement (change)="onSelectClick($event)"
                                style="display:none;">
                            <button type="button" class="btn primary btn_primary ng-binding" for="uploadbutton"
                                (click)="triggerFileInput()"
                                style="height:60px;font-size:18px;width:auto;border-radius:0px"><i
                                    class="fa fa-folder-open" aria-hidden="true"></i> Select file from my
                                computer</button>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding files" *ngIf="selectedFile!=null"
                        style="background-color:rgba(242, 246, 249, 1);border:1px solid lightgray;border-radius:4px;">
                        <div class="filenames">
                            <div class="container-fluid" style="padding:25px 15px">
                                <div class="row">
                                    <div class="col-xs-8 col-sm-10 col-md-10">
                                        <i class="fa fa-file fileicon" aria-hidden="true" style="color: #424249;"></i>
                                        &nbsp;
                                        <div
                                            style="max-width:80%;padding-right:10px;overflow:hidden;text-overflow:ellipsis;display:inline-block;vertical-align:middle;white-space:nowrap;color: #424249;">
                                            {{selectedFile.name}}
                                        </div>
                                        <div style="display:inline-block;vertical-align:middle;opacity:0.8">
                                            {{selectedFile.size/(1024*1024) | number:'1.2-2'}} MB
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-2 col-md-2 deleteatta" style="text-align: right;">
                                        <div style="cursor: pointer;" (click)="onDeleteFileClick()">
                                            <span class="push-left" style="color: #C60F13;">Remove
                                                file</span>&nbsp;
                                            <i class="fa fa-trash-o deleteicon" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-md-8 offset-md-2 mt-4 ">
                <button type="button" class="btn btn-primary float-end fontbold ps-3 pe-3"
                    style="font-size:18px;width:auto;border-radius:0px" (click)="uploadExcelFile()">Upload</button>
            </div>
            <div class="col-xs-12 col-md-8 offset-md-2 mt-4">
                <p class="heading_mega">Recent Uploads</p>
                <span class="paragraph">Check status of recent uploads</span>
            </div>
            <div class="col-xs-12 col-md-8 offset-md-2 mt-4 mb-5">
                <div class="content-card">
                    <div class="offset-5" *ngIf="this.historyLoader==true">
                        <i class="fa fa-circle-o-notch fa-spin"
                            style="color: #ef6c00;font-size: 30px;display: inline-block;vertical-align: middle;"></i>
                        <span style="padding-left: 5px;position: relative;top: 2px">Loading...</span>
                    </div>
                    <div class="paragraph" *ngIf="this.blnUploadHistory==true && this.historyLoader==false">
                        <label>We were unable to retrieve this information. Refresh the page to try again.<a class="btn btn-link" (click)="refreshPage()">Refresh Page</a></label>
                    </div>
                    <datatable [headers]="headers" [rows]="entries" (onHeaderClicked)="onHeaderClicked($event)"
                        (onCellClicked)="onCellClicked($event)" *ngIf="this.historyLoader==false && this.blnUploadHistory==false">
                    </datatable>
                </div>
            </div>
        </div>
    </div>
</div>



<ng-template #dateTemplate let-data="data">
    <div class="paragraph" enableAction="true">
        {{data.dateSubmitted}}
    </div>
</ng-template>

<ng-template #timeTemplate let-data="data">
    <div class="paragraph" enableAction="true">
        {{data.timeSubmitted}}
    </div>
</ng-template>

<ng-template #recordTemplate let-data="data">
    <div class="paragraph" enableAction="true">
        {{data.recordsCount}}
    </div>
</ng-template>

<ng-template #statusTemplate let-data="data">
    <div class="paragraph" *ngIf="data.uploadStatus=='Completed'">
        <span class="alert alert-success text-capitalize resultsflag">Complete</span>
    </div>
    <div class="paragraph" *ngIf="data.uploadStatus=='Needs Review'">
        <span class="alert alert-warning text-capitalize resultsflag">Needs Review</span>
    </div>
    <div class="paragraph" *ngIf="data.uploadStatus=='Pending'">
        <span class="alert alert-warning text-capitalize resultsflag">Pending</span>
    </div>
</ng-template>

<ng-template #noSavedHistoryTemplate let-data="data">
    <p class="large">No saved members.</p>
</ng-template>

<ng-template #FileSizeExceeded let-modal>
    <div class="modal-body text-center errorModal" aria-labelledby="FileSizeExceeded">
        <i class="fa fa-exclamation-circle"></i>
        <p class="large">
            The file size exceeds the amount permitted for attachments.
            <br />
            Please upload a smaller file
        </p>
        <button class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
        <button class="btn btn-primary rounded-pill" (click)="modal.dismiss();">Select new file</button>
    </div>
</ng-template>

<ng-template #FileTypeError let-modal style="margin-top: 6.75rem;">
    <div class="modal-body text-center errorModal" aria-labelledby="FileTypeError">
        <i class="fa fa-exclamation-triangle"></i><br>
        <p class="large">
            The file failed to upload.
            <br />
            Only these types of files are supported: xlsx
        </p>
    </div>
    <div class="p-4 text-center">
        <button class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>&nbsp;&nbsp;
        <button class="btn btn-primary" (click)="modal.dismiss();">Select new file</button>
    </div>
</ng-template>

<ng-template #UploadProgress let-modal>
    <div class="modal-body text-center" aria-labelledby="UploadProgress">
        <p class="large">
            {{selectedFile?.name}}
        </p>
        <div class="uploadprogress">
            <div [style.width]="progress+'%'"></div>
        </div>
        <br />
        <div>Uploading attachment...</div>
    </div>
</ng-template>

<ng-template #UploadError let-modal>
    <div class="modal-body text-center" aria-labelledby="UploadError">
        <i class="fa fa-exclamation-circle"></i>
        <p class="large" [innerHtml]="errorMessage">
            <a (click)="reloadHistory()" *ngIf="this.errorMessage=='Unable to upload history.'">Refresh the page.</a>
        </p>
        <button class="btn btn-secondary" (click)="modal.dismiss()">Ok</button>
    </div>
</ng-template>

<ng-template #norecordsToProvision let-modal>
    <!-- <div class="input-modal container-fluid nopadding"> -->
    <div class="row">
        <div class="col-sm-12">
            <div class="content-card no-shadow" style="border-bottom:2px solid #ddd">
                <div class="header nomargin">
                    <h3 class="ng-binding">{{ currentDate | date:'MM/dd/yyyy HH:mm' }} PROVISIONING UPLOAD</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="content-card no-shadow" style="border-bottom:2px solid #ddd">
                <div class="body">
                    <div class="col-sm-12">
                        <span class="paragraph">There are no valid records.</span>
                    </div>
                    <div class="row">
                        <div class="col-xs-6 col-md-9"></div>
                        <div class="col-xs-3 col-md-1 text-center" style="padding-top:12px;padding-bottom:12px;">
                            <button type="button" class="btn btn_link fontbold" aria-label="Cancel provisioning"
                                name="cancel" (click)="dismiss()">
                                <span aria-hidden="true">Cancel</span><span class="visually-hidden">Cancel
                                    provisioning</span></button>
                        </div>
                        <div class="col-xs-3 col-md-2">
                            <button type="button" aria-label="Provision users"
                                class="btn primary btn_primary text-capatilize disabled">
                                <span class="visually-hidden">Provision users</span>
                                <span aria-hidden="true">Provision</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #recordsToProvision let-modal>
    <!-- <div class="input-modal container-fluid nopadding"> -->
    <div class="row">
        <div class="col-sm-12">
            <div class="content-card no-shadow" style="border-bottom:2px solid #ddd">
                <div class="header nomargin">
                    <h3 class="ng-binding">{{ currentDate | date:'MM/dd/yyyy HH:mm' }} PROVISIONING UPLOAD</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="content-card no-shadow" style="border-bottom:2px solid #ddd">
                <div class="body">
                    <div class="col-sm-12">
                        <span class="paragraph">Records from Excel.</span>
                        <span class="float-end">
                            <span class="paragraph">{{this.excelrecordsDisplayedCount}} of
                                {{this.excelNoOfRecords}}</span>&nbsp;
                            <a (click)="loadPreviousExcelPageRecords()"><i class="fa fa-angle-left"
                                    [ngClass]="{arrowDisabled: currentPage==1, arrowActive: currentPage>1}"
                                    aria-hidden="true" *ngIf="this.excelrecordentries!=[]"></i></a>
                            &nbsp;&nbsp;&nbsp;
                            <a (click)="loadNextExcelPageRecords()"><i class="fa fa-angle-right"
                                    [ngClass]="{arrowDisabled: currentPage==noOfPages, arrowActive: currentPage<noOfPages}"
                                    aria-hidden="true" *ngIf="this.excelrecordentries!=[]"></i>
                            </a>
                        </span>
                        <datatable [headers]="excelrecordheaders" [rows]="excelrecordentries"></datatable>

                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <span class="float-end">
                                <span class="paragraph">{{this.excelrecordsDisplayedCount}} of
                                    {{this.excelNoOfRecords}}</span>&nbsp;
                                <a (click)="loadPreviousExcelPageRecords()"><i class="fa fa-angle-left"
                                        [ngClass]="{arrowDisabled: currentPage==1, arrowActive: currentPage>1}"
                                        aria-hidden="true" *ngIf="this.excelrecordentries!=[]"></i></a>
                                &nbsp;&nbsp;&nbsp;
                                <a (click)="loadNextExcelPageRecords()"><i class="fa fa-angle-right"
                                        [ngClass]="{arrowDisabled: currentPage==noOfPages, arrowActive: currentPage<noOfPages}"
                                        aria-hidden="true" *ngIf="this.excelrecordentries!=[]"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-6 col-md-9"></div>
                        <div class="col-xs-3 col-md-1 text-center" style="padding-top:42px;padding-bottom:12px;">
                            <button type="button" class="btn btn_link fontbold" aria-label="Cancel provisioning"
                                name="cancel" (click)="dismiss()">
                                <span aria-hidden="true">Cancel</span><span class="visually-hidden">Cancel
                                    provisioning</span></button>
                        </div>
                        <div class="col-xs-3 col-md-2" style="padding-top:42px;padding-bottom:12px;">
                            <button type="button" aria-label="Provision users"
                                class="btn primary btn_primary text-capatilize" (click)="provisionUser()">
                                <span class="visually-hidden">Provision users</span>
                                <span aria-hidden="true">Provision</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #optumIDTemplate let-data="data">
    <div class="paragraph" enableAction="true" *ngIf="data.editMode==false">
        {{data.excelProvisionUser.optumId}}
    </div>
    <div class="paragraph" *ngIf="data.editMode==true">
        <input type="text" [(ngModel)]="data.excelProvisionUser.optumId" [ngClass]="{'error': data.excelProvisionUser.optumId==''}"
            style="height:50px;width:60%;border: 2px solid #626f7d;">
    </div>
</ng-template>

<ng-template #npiTemplate let-data="data">
    <div class="paragraph" enableAction="true" *ngIf="data.editMode==false">
        {{data.excelProvisionUser.npiList}}
    </div>
    <div class="paragraph" *ngIf="data.editMode==true">
        <input type="text" [(ngModel)]="data.excelProvisionUser.npiList" [ngClass]="{'error': data.excelProvisionUser.npiList==''}"
            style="height:50px;width:60%;border: 2px solid #626f7d;">
    </div>
</ng-template>

<ng-template #roleTemplate let-data="data">
    <div class="paragraph" enableAction="true" *ngIf="data.editMode==false">
        {{data.excelProvisionUser.role}}
    </div>
    <div class="paragraph" *ngIf="data.editMode==true">
        <select [(ngModel)]="data.excelProvisionUser.role" class="rounded-0 inp form-select dropdown">
            <option value="Prescriber">Prescriber</option>
            <option value="Admin">Admin</option>
            <option value="Advisor">Advisor</option>
            <option value="Pharmacist">Pharmacist</option>
            <option value="Specialtypharmacist">Specialtypharmacist</option>
        </select>
    </div>
</ng-template>

<ng-template #internalTemplate let-data="data">
    <div class="label" *ngIf="data.editMode==true">
        <div class="toggle-switch">
            <label class="toggle-switch">
                <input type="checkbox" [(ngModel)]="data.isInternalUser">
                <span class="slider">
                    <div style="display: flex;justify-content: start;width: 100%;height: 100%;align-items: center;">
                        <span style="padding-left: 17px;">Yes</span>
                    </div>
                    <div style="display: flex;justify-content: end;width: 100%;height: 100%;align-items: center;">
                        <!-- <span style="padding-right: 8px;" >No</span> -->
                    </div>
                </span>
            </label>
        </div>
    </div>
    <div class="label" *ngIf="data.editMode==false">
        <div class="label" *ngIf="data.excelProvisionUser.userType=='I'">
            <span class="paragraph">Yes</span>
        </div>
        <div class="label" *ngIf="data.excelProvisionUser.userType=='E'">
            <span class="paragraph">No</span>
        </div>
    </div>

</ng-template>

<ng-template #emailTemplate let-data="data">
    <div class="paragraph" enableAction="true" *ngIf="data.editMode==false">
        {{data.excelProvisionUser.email}}
    </div>
    <div class="paragraph" *ngIf="data.editMode==true">
        <input type="text" [(ngModel)]="data.excelProvisionUser.email" [ngClass]="{'error': data.excelProvisionUser.email==''}"
            style="height:50px;width:100%;border: 2px solid #626f7d;">
    </div>
</ng-template>

<ng-template #editTemplate let-data="data">
    <button class="btn btn-link fontbold" *ngIf="data.editMode==false" (click)="edit(data)">
        Edit
    </button>
    <button class="btn btn-link fontbold" *ngIf="data.editMode==true" (click)="save(data)">
        Save
    </button>
</ng-template>

<ng-template #provisionConfirmModal let-modal>
    <div class="p-3">
        <div class="text-center progress-notification"><i class="fa fa-check-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            File upload process is initiated and users will be <br> provisioned.
        </div>
        <div class="row p-2">
            <div class="col text-center">
                <button type="button" class="btn btn-secondary" (click)="dismiss()">Ok</button>
            </div>
        </div>
    </div>
</ng-template>