export class SteponePharmacyDetailsResponse {
  status!: Status
  pharmacyDetailItems !: PharmacyDetailItem[]
}

export interface Status {
  statusCode: string
  statusDesc: string
}

export interface PharmacyDetailItem {
  status: string
  pharmacyName: string
  storeAddress: StoreAddress
  primaryPhone: string
  primaryExtension: string
  fax: string
  contactName: string
  contactTitle: string
  contactPhone: string
  contactExtension: string
  contactEMail: string
  acceptsEPrescribing: boolean
  paymentCenterStatus: string
}

export interface StoreAddress {
  address1: string
  address2: string
  city: string
  state: string
  zip1: string
  zip2: string
  zip3: string
  country: string
}
