<div class="container-fluid background2">
    <div class="row">
        <div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1">
            <div class="container-fluid p-0 mt-3 mb-5">
                <div class="row mt-0">
                    <div class="col-12 text-start pagetitle">
                        <h2>Welcome {{username}},</h2>
                        <span>to PreCheck MyScript</span>
                    </div>
                </div>
                <div class="row homepagetilesgroup">
                    <div class="col-xs-12 col-md-6 text-center pt-3">
                        <a href="javascript:void(0)" class="homepagetile" (click)="goto('PriorAuthChecker')">
                            <div class="thumbnail horizontal d-flex align-items-center">
                                <div class="d-flex p-2 align-items-center">
                                    <img src="assets/images/prescriptioncheck.png" alt="Prescription Check"/>
                                    <div class="text-start">
                                        <h2>Check Prescription Coverage</h2>
                                        <p>Check to see if medications are covered by a member's plan and get pricing options.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-md-6 text-center pt-3">
                        <a href="javascript:void(0)" class="homepagetile" (click)="goto('/searchMedicationClaims/MemberSearch')">
                            <div class="thumbnail horizontal d-flex align-items-center">
                                <div class="d-flex p-2 align-items-center">
                                    <img src="assets/images/memberSearch.png" alt="Search For A Member"/>
                                    <div class="text-start">
                                        <h2>Search for a Member</h2>
                                        <p>View member's insurance and demographics.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>