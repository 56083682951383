import { Component, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_ROLE } from 'src/app/constants/appconstants';
import { SavedPARequest } from 'src/app/modals/epa/priorauthrequest';
import { EpaCancellationWebResponse, PARefIDWebResponse } from 'src/app/modals/epa/priorauthresponse';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'providerhome',
  templateUrl: './providerhome.component.html',
  styleUrls: ['./providerhome.component.css']
})
export class ProviderhomeComponent {
  username!:string;
  userrole!:USER_ROLE;
  isSavedNotify!:boolean;
  isCancelled!:boolean;
  isSavedParam!:SavedPARequest;
  isCancelPAparam!:string;
  iscancelPA!:EpaCancellationWebResponse;
  recentSearchSession:any = undefined;
  recentSearchDrugSession:any = undefined;
  // @ViewChild("errorModalTemplate")
  // errorModalTemplateRef!:ElementRef<HTMLElement>;
  // errorMessage:string="";
  // PaREfID:any;
  // paResponse!: PARefIDWebResponse;

  constructor(private logger: LoggerService,private titleService:Title,private epaProcessor:EpaprocessorService,private modalService: NgbModal,private profileService:ProfileService,private memberSearchHelper: MembersearchService,private loggerService:LoggerService,protected router:Router){
    this.username="";
    if(this.profileService.loginuser!=undefined){
      this.username = this.profileService.loginuser.username.replace(',',"");
      this.userrole = this.profileService.USER_ROLE;
      console.log(this.userrole);
    }
    this.isSavedParam = JSON.parse(""+sessionStorage.getItem("savelater"));
    this.isCancelPAparam = JSON.parse(""+sessionStorage.getItem("paCaseID"));
    this.iscancelPA = JSON.parse(""+sessionStorage.getItem("iscancelPA"));
    console.log(this.iscancelPA);
     
    if(this.isSavedParam!=undefined){
      this.isSavedNotify = true;
    }
    else if(this.isCancelPAparam!=undefined||this.isCancelPAparam!=null){
      this.isCancelled = true;
    }
    
    setTimeout(()=>{
      sessionStorage.removeItem("savelater");
      sessionStorage.removeItem("paCaseID");
      this.isSavedNotify = false;
      this.isCancelled = false;
      // this.epaSavedResponse =JSON.parse(""+sessionStorage.getItem("savelater"));
    },7000);
    //for member search and drug search recent search session
    if(sessionStorage.getItem("recentMemberSearches")!=undefined)
    this.recentSearchSession= JSON.parse(""+sessionStorage.getItem("recentMemberSearches"));
    if(sessionStorage.getItem("recentDrugSearches")!=undefined)
    this.recentSearchDrugSession= JSON.parse(""+sessionStorage.getItem("recentDrugSearches"));
    sessionStorage.clear();
    if(this.recentSearchSession!=undefined)
    sessionStorage.setItem("recentMemberSearches",JSON.stringify(this.recentSearchSession));
    console.log(this.recentSearchSession);
    if(this.recentSearchDrugSession!=undefined)
      sessionStorage.setItem("recentDrugSearches",JSON.stringify(this.recentSearchDrugSession));
      console.log(this.recentSearchDrugSession);
    
  }
  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    console.log(this.isSavedNotify,this.isSavedParam,this.iscancelPA);

  }
  goto(routeUrl:string){
    debugger;
    this.loggerService.userAction("Going to " +routeUrl);
    if(routeUrl=='ePA')
      sessionStorage.setItem('IntiatePA',"true");
    this.router.navigateByUrl("/"+routeUrl);
    if(this.profileService.loginuser?.prescriber?.presbrId!=undefined){
      this.memberSearchHelper.getFavouriteMembers(this.profileService.loginuser.prescriber.presbrId).then((response:any)=>{  
      },(error:any)=>{});
    }
  }
  // generatePARefID():void{
  //   debugger;
  //   this.epaProcessor.generatePaRefId(""+this.profileService.loginuser?.prescriber?.uuid).then((response: PARefIDWebResponse)=>{
  //      this.PaREfID = response.paRefPrefix+response.paRefID;
  //      this.paResponse = response;
  //      console.log(this.PaREfID);
  //      sessionStorage.setItem("paRefID",this.PaREfID);
  //      sessionStorage.setItem("PAResponse",""+this.paResponse);
  //    }, (error:any)=>{
  //      this.modalService.open(this.errorModalTemplateRef,{size:'md'});
  //      if(error!=undefined && error.status!=undefined && error.status.statusDesc!=undefined){
  //        this.errorMessage = error.status.statusDesc;
  //      } else {
  //        this.logger.log("Error Occured while Initiating PA.");
  //        this.errorMessage = "An error occured. Please try again.";
  //      }
  //    }).catch((error:any)=>{
  //      this.logger.error("Error Occured while Initiating PA.");
  //      this.modalService.open(this.errorModalTemplateRef,{size:'md'});
  //      this.errorMessage = "An error occured. Please try again.";
  //    });
  //    sessionStorage.setItem("errorMsg",this.errorMessage);
  //  }
  }

