<div class="container-fluid background1">
    <div class="row">
        <div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1 pagetitle">
            <h2>Welcome {{username}}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1 text-center">
            <h1>
                <img src="assets/images/pcms_global_logo.svg" alt="PreCheck MyScript" />
            </h1>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-10 col-xl-8 offset-xl-5 offset-lg-1">
            <div class="container-fluid p-0 homepagetilesgroup">
                <div class="row">
                    <div class="col-xs-12 col-md-4 text-center">
                        <a href="javascript:void(0)" class="homepagetile" (click)="goto('ePA')">
                            <div class="thumbnail">
                                <img src="assets/images/ePA.png" alt="Electronic Prior Authorization" />
                                <h2>
                                    Initiate a
                                    <br>
                                    Prior Authorization
                                </h2>
                                <p>Use our three-step process to initiate a prior authorization.</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>