<table class="table table-border-seperated header_row_report" aria-describedby>
    <tr class="ng-scope contentcard" id="{{data.notificationId}}" style="box-shadow: none"
        *ngFor="let data of tabledata" [ngClass]="{'display_none':data.readInd!='N'}">
        <td class="ng-scope clickdisabled">
            <div class="dashboard_notifications" style="padding:0">
                <span *ngIf="data.readInd=='N'" style="display:block;position: absolute;left: -5px;right: 0;top: 6px;"
                    class="arrow-up notification_new"></span>
                <div style="display: flex;justify-content: space-between;">
                    <div style="display: inline-block;margin-left: 10px;width: 85%;" *ngIf="data.paStatus=='SavedPA'">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"
                            style="font-size: 16px;color: #846732;"></i>
                        <span class="pa_status" [ngClass]="{'pa_color_yellow':data.paStatus=='SavedPA'}">&nbsp;Saved PA
                            Request Expiring</span>
                        <span class="paragraph " style="margin: 3px 0 6px 0;display: block;text-transform: capitalize;">
                            <span style="text-transform: none">A saved prior authorization
                                request for </span>
                            {{data.firstName}}
                            {{data.lastName}}'s
                            <span style="text-transform: none"> for {{data.drugName}} is
                            </span>
                            <b *ngIf="data.paStatus=='SavedPA'" class="pa_color_yellow"
                                style="text-transform: lowercase;">
                                expiring</b>
                            <span *ngIf="data.paStatus=='SavedPA'" style="text-transform: none"> soon.</span>
                        </span>
                        <button class="btn_removestyle btn_tertiary btn_tertiary_focus PADetails text_underline"
                            id="{{data.caseId}}" (click)="onViewPriorAuthclick(data)"
                            *ngIf="data.paStatus=='Denied'|| data.paStatus=='Cancelled' || data.paStatus=='Approved'">
                            <span>View prior auth details </span>
                            <i class="fa fa-angle-right" aria-hidden="true"
                                style="font-size: 24px !important;vertical-align: bottom;"></i></button>
                        <button class="btn_removestyle btn_tertiary btn_tertiary_focus PADetails text_underline"
                            id="{{data.caseId}}" (click)="resumeSavedPA(data.referenceId,data.notificationId)"
                            *ngIf="data.paStatus=='SavedPA'">
                            <span>Resume </span>
                            <i class="fa fa-angle-right" aria-hidden="true"
                                style="font-size: 24px !important;vertical-align: bottom;"></i></button>
                    </div>
                    <div style="display: inline-block;margin-left: 10px;width: 85%;"
                        *ngIf="data.paStatus=='Denied'|| data.paStatus=='Cancelled' || data.paStatus=='Approved'">
                        <i *ngIf="data.paStatus=='Denied'||data.paStatus=='Cancelled'" class="fa fa-exclamation-circle"
                            aria-hidden="true" style="font-size: 16px;color: #c60f13"> </i>
                        <i *ngIf="data.paStatus=='Approved'" class="fa fa-check-circle" aria-hidden="true"
                            style="font-size: 16px;color: green;"></i>
                        <span class="pa_status"
                            [ngClass]="{'pa_color_red':data.paStatus=='Denied'|| data.paStatus=='Cancelled','pa_color_green':data.paStatus=='Approved'}">&nbsp;PA
                            Request <span>{{data.paStatus}}</span>
                        </span>
                        <span class="paragraph " style="margin: 3px 0 6px 0;display: block;text-transform: capitalize;">
                            {{data.firstName}}
                            {{data.lastName}}'s
                            <span style="text-transform: none">prior authorization request for {{data.drugName}}
                                <span> has been&nbsp;</span>
                            </span>
                            <b [ngClass]="{'pa_color_red':data.paStatus=='Denied'|| data.paStatus=='Cancelled','pa_color_green':data.paStatus=='Approved'}"
                                style="text-transform: lowercase;"> {{data.paStatus}}.</b>
                        </span>
                        <button class="btn_removestyle btn_tertiary btn_tertiary_focus PADetails text_underline"
                            id="{{data.caseId}}" (click)="onViewPriorAuthclick(data)">
                            <span>View prior auth details </span>
                            <i class="fa fa-angle-right" aria-hidden="true"
                                style="font-size: 24px !important;vertical-align: bottom;"></i></button>
                    </div>
                    <div style="display: inline-block;width: 10%;margin-right: 10px;">
                        <span style="float: right;" class="ng-binding">{{data.convertedTimestamp}}</span><br>
                        <button class="btn_removestyle"
                            style="position:absolute;top:35%;right:20px;font-size: 24px;float: right;color: #424249;"
                            custom-class="fa fa-times" (click)="onDeleteclick(data.notificationId)"><i
                                class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </td>        
    </tr>
    <tr class="ng-scope contentcard" id="{{data.notificationId}}" style="box-shadow: none"
        *ngFor="let data of tabledata" [ngClass]="{'display_none':data.readInd=='N'}">
        <td class="ng-scope clickdisabled">
            <div class="dashboard_notifications" style="padding:0">
                <span *ngIf="data.readInd=='N'" style="display:block;position: absolute;left: -5px;right: 0;top: 6px;"
                    class="arrow-up notification_new"></span>
                <div style="display: flex;justify-content: space-between;">
                    <div style="display: inline-block;margin-left: 10px;width: 85%;" *ngIf="data.paStatus=='SavedPA'">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"
                            style="font-size: 16px;color: #846732;"></i>
                        <span class="pa_status" [ngClass]="{'pa_color_yellow':data.paStatus=='SavedPA'}">&nbsp;Saved PA
                            Request Expiring</span>
                        <span class="paragraph " style="margin: 3px 0 6px 0;display: block;text-transform: capitalize;">
                            <span style="text-transform: none">A saved prior authorization
                                request for </span>
                            {{data.firstName}}
                            {{data.lastName}}'s
                            <span style="text-transform: none"> for {{data.drugName}} is
                            </span>
                            <b *ngIf="data.paStatus=='SavedPA'" class="pa_color_yellow"
                                style="text-transform: lowercase;">
                                expiring</b>
                            <span *ngIf="data.paStatus=='SavedPA'" style="text-transform: none"> soon.</span>
                        </span>
                        <button class="btn_removestyle btn_tertiary btn_tertiary_focus PADetails text_underline"
                            id="{{data.caseId}}" (click)="onViewPriorAuthclick(data)"
                            *ngIf="data.paStatus=='Denied'|| data.paStatus=='Cancelled' || data.paStatus=='Approved'">
                            <span>View prior auth details </span>
                            <i class="fa fa-angle-right" aria-hidden="true"
                                style="font-size: 24px !important;vertical-align: bottom;"></i></button>
                        <button class="btn_removestyle btn_tertiary btn_tertiary_focus PADetails text_underline"
                            id="{{data.caseId}}" (click)="resumeSavedPA(data.referenceId,data.notificationId)"
                            *ngIf="data.paStatus=='SavedPA'">
                            <span>Resume </span>
                            <i class="fa fa-angle-right" aria-hidden="true"
                                style="font-size: 24px !important;vertical-align: bottom;"></i></button>
                    </div>
                    <div style="display: inline-block;margin-left: 10px;width: 85%;"
                        *ngIf="data.paStatus=='Denied'|| data.paStatus=='Cancelled' || data.paStatus=='Approved'">
                        <i *ngIf="data.paStatus=='Denied'||data.paStatus=='Cancelled'" class="fa fa-exclamation-circle"
                            aria-hidden="true" style="font-size: 16px;color: #c60f13"> </i>
                        <i *ngIf="data.paStatus=='Approved'" class="fa fa-check-circle" aria-hidden="true"
                            style="font-size: 16px;color: green;"></i>
                        <span class="pa_status"
                            [ngClass]="{'pa_color_red':data.paStatus=='Denied'|| data.paStatus=='Cancelled','pa_color_green':data.paStatus=='Approved'}">&nbsp;PA
                            Request <span>{{data.paStatus}}</span>
                        </span>
                        <span class="paragraph " style="margin: 3px 0 6px 0;display: block;text-transform: capitalize;">
                            {{data.firstName}}
                            {{data.lastName}}'s
                            <span style="text-transform: none">prior authorization request for {{data.drugName}}
                                <span> has been&nbsp;</span>
                            </span>
                            <b [ngClass]="{'pa_color_red':data.paStatus=='Denied'|| data.paStatus=='Cancelled','pa_color_green':data.paStatus=='Approved'}"
                                style="text-transform: lowercase;"> {{data.paStatus}}.</b>
                        </span>
                        <button class="btn_removestyle btn_tertiary btn_tertiary_focus PADetails text_underline"
                            id="{{data.caseId}}" (click)="onViewPriorAuthclick(data)">
                            <span>View prior auth details </span>
                            <i class="fa fa-angle-right" aria-hidden="true"
                                style="font-size: 24px !important;vertical-align: bottom;"></i></button>
                    </div>
                    <div style="display: inline-block;width: 10%;margin-right: 10px;">
                        <span style="float: right;" class="ng-binding">{{data.convertedTimestamp}}</span><br>
                        <button class="btn_removestyle"
                            style="position:absolute;top:35%;right:20px;font-size: 24px;float: right;color: #424249;"
                            custom-class="fa fa-times" (click)="onDeleteclick(data.notificationId)"><i
                                class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </td>        
    </tr>
</table>


<div *ngIf="showPopup && dontSHowAgain" class="popup-greybackground"></div>
<div *ngIf="showPopup && dontSHowAgain" class="popup-container">
    <div class="popup">
        <div style="
    padding: 25px 30px 30px;">
            <div style="display:flex;flex-direction:row;justify-content: center;"><i class="fa fa-exclamation-circle"
                    aria-hidden="true"></i></div>
            <div class="popup-text">
                Are you sure you want to delete this notification?
            </div>
            <div style="display:flex;flex-direction:row;justify-content: center;">
                <button class="btn" (click)="onNoClick()"
                    style="border-radius: 2px;border: 2px solid #0470c5;font-size: 16px; color: #0470c5!important;background-color: transparent;font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;margin: 0 10px;padding: 10px 20px;width: auto;height: 40px;display: flex;align-items: center;">
                    No
                </button>
                <button class="btn btn-primary" (click)="onYesClick()"
                    style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;border-radius: 2px;background: #0470c5;font-size: 16px;color: #fff;margin: 0 10px;padding: 10px 20px;width: auto;height: 40px;display: flex;align-items: center;">
                    Yes
                </button>
            </div>
        </div>
        <div style="background-color: rgba(242, 246, 249, 1);border: none;border-radius: 0;box-shadow: none;">
            <div class="row" style="padding: 25px">
                <div class="col-md-12 checkbox">
                    <input type="checkbox" style="height: 20px;width: 20px;" name="remove" id="remove"
                        [(ngModel)]="isChecked">
                    <label class="checkbox_label" for="remove" style="text-transform:none;padding-left: 5px;"><span
                            class="paragraph ng-binding">Don't show me this again</span></label>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="showErrorPopup" class="popup-greybackground"></div>
<div *ngIf="showErrorPopup" class="popup-container">
    <div class="popup">
        <div style="
    padding: 25px 30px 30px;">
            <div style="display:flex;flex-direction:row;justify-content: center;"><i class="fa fa-exclamation-circle"
                    aria-hidden="true"></i></div>
            <div class="popup-text">
                Server error. This notification can't be deleted.
            </div>

        </div>
        <div *ngIf="dontSHowAgain"
            style="background-color: rgba(242, 246, 249, 1);border: none;border-radius: 0;box-shadow: none;">
            <div class="row" style="padding: 25px">
                <div class="col-md-12 checkbox">
                    <div style="display:flex;flex-direction:row;justify-content: center;">
                        <button class="btn" (click)="onNoClick()"
                            style="border-radius: 2px;border: 2px solid #0470c5;font-size: 16px; color: #0470c5!important;background-color: transparent;font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;margin: 0 10px;padding: 10px 20px;width: auto;height: 40px;display: flex;align-items: center;">
                            Close
                        </button>
                        <button class="btn btn-primary" (click)="onYesClick()"
                            style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;border-radius: 2px;background: #0470c5;font-size: 16px;color: #fff;margin: 0 10px;padding: 10px 20px;width: auto;height: 40px;display: flex;align-items: center;">
                            Try again
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #errorsavedpa let-modal>
    <div class="container-fluid content-card" aria-modal="true" aria-labelledby="errorsavedpa" role="dialog"
        style="padding: 36px 30px 10px;">
        <div class="row">
            <div class="col paragraph large text-center">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <br />
                An error occured. Please try again.
            </div>
        </div>
        <div class="row">
            <div class="col text-center card-footer" style="background-color: #fff;">
                <button type="button" class="btn btn-secondary" style="font-family: 'Frutiger-Bold';"
                    (click)="modal.dismiss()">Close</button>
                &nbsp;&nbsp;
                <button type="button" style="font-family: 'Frutiger-Bold';" class="btn btn-primary"
                    (click)="rerunsavedPA()">Try again</button>
            </div>
        </div>
    </div>
</ng-template>