import { Component } from '@angular/core';
import { TableHeaderCellData, TableRowData } from 'src/app/modals/datatable/datatable';
import { ProvisionUser } from 'src/app/modals/provisioning/submitUserProvisioningrequest';

@Component({
  selector: 'app-provisioning',
  templateUrl: './provisioning.component.html',
  styleUrls: ['./provisioning.component.css']
})
export class ProvisioningComponent {


}
