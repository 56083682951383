<!-- For QA automation purpose
<div class="row" id="targetDrugRejectCode" style="display:none;">
    <h1 codeintargetdrug="{{targetrejectcode}}" aria-hidden="true">{{targetrejectcode}}</h1>
  </div>

For QA automation purpose
<div class="row" id="targetDrugTransitionRejectCode" style="display:none;">
    <h1 transitioncodeintargetdrug="{{targetTransitionRejectCode}}" aria-hidden="true">{{targetTransitionRejectCode}}</h1>
  </div> -->



<div *ngIf="this.updateScenarioClaimsList()" class="">
    <div class="mt-2 col-8 offset-2 timelinetitle shadow descriptionSection d-print-none">
        <div class="row">
            <div class="col-6" *ngIf="this.claimsummaryDetailsrequest.scenariopharmacyIndex==0">
                <p class="h5 mt-4">{{this.claimsummaryDetailsrequest.ContractedNetworkProvider}}</p>
            </div>
            <div class="col-6" *ngIf="this.claimsummaryDetailsrequest.scenariopharmacyIndex!=0">
                <p class="h5 mt-4">{{this.claimresponse.trailadjudicationwebresponse.prefAlternativesWebResponse.alternatePharmacies[this.claimsummaryDetailsrequest.scenariopharmacyIndex-1].serviceProviderName}}</p>
            </div>
            <div *ngIf="blnChgQtyDOS==0" class="rounded border col-6 mt-3">
                <div class="row">
                    <p class="col-5 mt-3 text-center"><span>Quantity Entered:
                            <strong>{{claimsummaryDetailsrequest.QuantityEntered}}</strong></span></p>
                    <p class="col-4 mt-3 text-center"><span>Days Supply:
                            <strong>{{claimsummaryDetailsrequest.DaysSupply}}</strong></span></p>
                    <button class="col-3 btn btn-link float-end"
                        *ngIf="claimsummaryDetailsrequest.claimStsTypId<3 || claimsummaryDetailsrequest.claimStsTypId==6 || claimsummaryDetailsrequest.claimStsTypId==8"
                        (click)="chgQtyDOS()">Change</button>
                </div>
            </div>
            <div *ngIf="blnChgQtyDOS==1" class="rounded border col-6 mt-3">
                <form [formGroup]="changeQTYDOSForm">
                    <div class="row ">
                        <div class="col-5 mt-2">
                            <p class="row"><span>Quantity Entered: </span></p>
                            <div class="col mb-2">
                                <input type="text" [ngClass]="{'inpError': (!Quantity?.valid && !Quantity?.pristine)}"
                                    class=" rounded-0 form-control inp h-70 " maxlength="11" formControlName="Quantity">
                            </div>
                        </div>
                        <div class="col-4 mt-2">
                            <p class="row"><span>Days Supply: </span></p>
                            <div class="col mb-2">
                                <input type="text" [ngClass]="{'inpError': (!Days?.valid && !Days?.pristine)}"
                                    class=" rounded-0 form-control inp h-70" maxlength="3" formControlName="Days">
                            </div>
                        </div>
                        <!-- <p class="col-3 mt-3 text-center"><span>Days Supply: <strong>{{claimsummaryDetailsrequest.DaysSupply}}</strong></span></p> -->
                        <div class="col-3 row">
                            <button class="col btn btn-link float-end text-danger"
                                (click)="cancelQtyDOS()">Cancel</button>
                            <button *ngIf="claimresponse.clmgroupId==undefined" class="col btn btn-link float-end"
                                (click)="upadateOrReexecute()">Update</button>
                            <!-- <button *ngIf="claimresponse.clmgroupId!=undefined"
                            class="col-2 btn btn-link float-end"
                            (click)="upadateOrReexecute(true)">Re-execute</button> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!--Green Payble Claim  -->
    <div class="col-8 offset-2 text-light d-print-none">
        <div class="p-2 bg-success" *ngIf="claimsummaryDetailsrequest.bannerType==3">
            <!-- <i aria-hidden="true" class="fa fa-check h3 ps-2"
            style="position: relative; top: 2px; margin-right: 20px;"></i>
        <label class="fs-6">{{claimsummaryDetailsrequest.bannerMessage}}</label> -->
            <div class="row p2">
                <i aria-hidden="true" class="fa fa-check h3 m-2 ms-4 col-1 bannerIcon text-light"></i>
                <label class="fs-5 col-10 mt-2 mb-4 bannerText">{{claimsummaryDetailsrequest.bannerMessage}}</label>
            </div>
        </div>

        <!--Yellow Prior authorization is required on this medication.  -->
        <div class="p-2 bg-warning text-dark" *ngIf="claimsummaryDetailsrequest.bannerType==2">
            <div class="row p2">
                <i aria-hidden="true" class="fa fa-exclamation-triangle h3 m-2 ms-4 col-1 bannerIcon"></i>
                <label class="fs-5 col-10 mt-2 mb-4 bannerText">{{claimsummaryDetailsrequest.bannerMessage}}</label>
            </div>
        </div>

        <!--Red The medication has exceeded the plan limits allowed  -->
        <div class="p-2 bg-danger text-light" *ngIf="claimsummaryDetailsrequest.bannerType==1">
            <div class="row p2">
                <i aria-hidden="true" class="fa fa-exclamation-circle h3 m-2 ms-4 col-1 bannerIcon text-light"></i>
                <label class="fs-5 col-10 mt-2 mb-4 bannerText">{{claimsummaryDetailsrequest.bannerMessage}}</label>
            </div>
        </div>

        <div class="p-2 text-light bg-custom" *ngIf="claimsummaryDetailsrequest.bannerType==4">
            <div class="row p2">
                <i aria-hidden="true" class="fa fa-check h3 m-2 ms-4  col-1 bannerIcon text-light"></i>
                <label class="fs-5 col-10 mt-2 mb-4 bannerText">{{claimsummaryDetailsrequest.bannerMessage}}</label>
            </div>
        </div>
        <div [hidden]="!claimsummaryDetailsrequest.durReject.isDurReject || claimsummaryDetailsrequest.durReject.durSoftRejects.length>0"
            [ngClass]="{'bg-danger':claimsummaryDetailsrequest.bannerType==1, 'bg-warning':claimsummaryDetailsrequest.bannerType==2, 'bg-success':claimsummaryDetailsrequest.bannerType==3}">
            <div *ngFor="let msg of claimsummaryDetailsrequest.durReject.durMessages" class="text-dark fs-6"
                [innerHtml]="msg"></div>
        </div>
        <div *ngIf="claimsummaryDetailsrequest.durReject.durSoftRejects.length>0" class="text-dark fs-6"
            [ngClass]="{'bg-danger':claimsummaryDetailsrequest.bannerType==1, 'bg-warning':claimsummaryDetailsrequest.bannerType==2, 'bg-success':claimsummaryDetailsrequest.bannerType==3}">
            <div style="display: inline-block;vertical-align: top;">
                <i style="visibility: hidden;" aria-hidden="true"
                    class="fa fa-exclamation-triangle h3 ps-4 col-1 bannerIcon"></i>
            </div>
            <div style="display: inline-block;vertical-align: top;">
                <div *ngFor="let softReject of claimsummaryDetailsrequest.durReject.durSoftRejects; first as isFirst"
                    class="container-fluid dursoftcontainer">
                    <div class="row" *ngIf="softReject.durMessage.length>0">
                        <div class="col">
                            <span [innerHtml]="softReject.durMessage"></span>
                        </div>
                    </div>
                    <div [hidden]="!isFirst" class="row">
                        <div class="col">
                            Please choose Professional and Result of Service Codes to override and proceed with the
                            trial.
                        </div>
                    </div>
                    <div [hidden]="isFirst" class="row">
                        <div class="col">Another DUR message goes here.</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>Professional Service Code</label>
                            <select [(ngModel)]="softReject.prfsnlSrvcCd" class="rounded-0 inp form-select">
                                <option value="">Select a code</option>
                                <option *ngFor="let code of durProfessionalCodes" [value]="code"
                                    [innerHtml]="code |durprfsnlSrvcCd"></option>
                            </select>
                        </div>
                        <div class="col">
                            <label>Result of Service Code</label>
                            <select [(ngModel)]="softReject.rsltSrvcCd" class="rounded-0 inp form-select">
                                <option value="">Select a code</option>
                                <option *ngFor="let code of durResultCodes" [value]="code"
                                    [innerHtml]="code|durrsltSrvcCd"></option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-2 bg-white container-fluid durinvalidcontainer"
            *ngIf="claimsummaryDetailsrequest.durReject.isDurReject">
            <div class="row p2"
                *ngFor="let invalidReject of claimsummaryDetailsrequest.durReject.durInvalidSoftRejects; first as isFirst">
                <div class="col-12 durtitle" *ngIf="isFirst">
                    <div style="border-bottom: 1px solid lightgrey;padding-top:10px; padding-bottom: 10px;">
                        <h3>DUR Override Codes That Will be Added to This Claim</h3>
                    </div>
                </div>
                <div class="col-12" style="border-bottom: 1px solid lightgrey"
                    [hidden]="invalidReject.prfsnlSrvcCd.length==0 || invalidReject.rsltSrvcCd.length==0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <label class="text-muted">Professional Service Code</label>
                                <br />
                                <div [innerHtml]="invalidReject.prfsnlSrvcCd | durprfsnlSrvcCd" class="text-dark fs-5">
                                </div>
                            </div>
                            <div class="col">
                                <label class="text-muted">Result of Service Code</label>
                                <br />
                                <div [innerHtml]="invalidReject.rsltSrvcCd | durrsltSrvcCd" class="text-dark fs-5">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid shadow p-3 mb-4"
            [ngClass]="{'lightGreenBG':claimsummaryDetailsrequest.bannerType==3, 'lightYellowBG':claimsummaryDetailsrequest.bannerType==2,'lightRedBG':claimsummaryDetailsrequest.bannerType==1, 'lightCream':claimsummaryDetailsrequest.bannerType==4}">
            <div>
                <p class="fs-6 text-danger">{{claimsummaryDetailsrequest.claimSubmitCustomErrorMessage}}</p>
            </div>
            <div class="row">
                <div class="col-7 pt-3 pe-5">
                    <div style="border-bottom: 1px solid lightgrey;">
                        <div class="row">
                            <h4 class="mb-2 col-8"><strong>{{claimsummaryDetailsrequest.MedicationName}}</strong></h4>
                            <div class="col-4 row">

                                <label *ngIf="claimsummaryDetailsrequest.brandPreferred"
                                    class="mb-1 p-2 row rounded-pill text-center"
                                    style="color: #000; background-color: lightblue;">
                                    <span class="col-12">Brand</span>
                                    <span class="col-12">Preferred</span>
                                </label>

                                <label *ngIf="claimsummaryDetailsrequest.requiresPA" class="mb-1"
                                    style="color: #846732;"><i aria-hidden="true" class="fa fa-exclamation-triangle ps-2"
                                    style="position: relative; margin-right: 0px; font-size: 1em;"></i><span class="text-center  ms-2">PA Required</span></label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <p class="mt-2 fs-6 text-secondary fw-light"><span>
                                    Quantity Calculated: {{claimsummaryDetailsrequest.QuantityDesc}}
                                </span></p>
                        </div>
                        <div class="col-6" *ngIf="this.claimsummaryDetailsrequest.isMedD==true && claimsummaryDetailsrequest.endPhase!=undefined">
                            <p class="mt-2 fs-6 text-secondary fw-light"><span>
                                    End Phase: {{claimsummaryDetailsrequest.endPhase}}
                                </span></p>
                        </div>
                    </div>
                </div>

                <div class="col-5 row">
                    <div class="col-4">
                        <p class="fs-6 text-secondary fw-light">Patient Amount Due</p>
                        <p class="fs-4 text-dark">{{claimsummaryDetailsrequest.PatientPayAmount}}</p>
                    </div>
                    <div class="col-4 p-0">
                        <p class="fs-6 text-secondary fw-light">Plan Amount Due</p>
                        <p class="fs-4 text-dark">{{claimsummaryDetailsrequest.ProviderTotalAmountDue}}</p>
                    </div>
                    <div class="col-4" *ngIf="claimsummaryDetailsrequest.initiatePALnk">
                        <button class="btn btn-link fs-6 ms-3 mt-3 fw-bold" (click)="editProvider()">Initiate
                            PA</button>.
                        <!-- <eparunner></eparunner> -->
                    </div>
                    <!-- *ngIf="this.claimsummaryDetailsrequest.claimSubmitted" -->
                    <div class="col-4" *ngIf="claimsummaryDetailsrequest.claimStsTypId==3">
                        <!-- <div class="col-4"> -->
                        <button class="btn btn-primary fs-6  fw-bold">Reverse Claim</button>
                    </div>

                    <!-- <div class="col-4" *ngIf="this.claimsummaryDetailsrequest.claimSubmitFailed"> -->
                    <div class="col-4"
                        *ngIf="claimsummaryDetailsrequest.claimStsTypId==6 || claimsummaryDetailsrequest.claimStsTypId==8">
                        <button class="btn btn-primary fs-6  fw-bold">Remove</button>
                    </div>

                    <!-- <button class="btn btn-primary float-end fontbold mt-1 col-5 offset-1" (click)="submitClaims()">Submit Claim
                </button> -->
                </div>
            </div>

            <div class="rounded border p-3 mb-2" *ngIf="claimsummaryDetailsrequest.localMessages!=undefined && claimsummaryDetailsrequest.localMessages.length>0">
                <span class="text-secondary fs-6"><strong>Additional information:</strong></span>
                <ul class="text-secondary fw-light mb-0 ps-3"
                    *ngFor="let item of claimsummaryDetailsrequest.localMessages">
                    <li>{{item.localMessageDescription}}</li>
                </ul>
            </div>
        </div>
    </div>
    <!--End Payble Claim -->

    <!-- Alternate pharmacies -->
    <div class="mt-2 col-8 offset-2 mb-5 bg-white d-print-none">
        <div class="p-3 bg-white">
            <h4 class="text-capitalize mt-3 mb-3">ALTERNATIVES FOR {{claimsummaryDetailsrequest.MedicationName.substring(0,claimsummaryDetailsrequest.MedicationName.indexOf(" "))}}</h4>
        </div>
        <div class="m-0 p-3 bg-white border-3 border-top" *ngIf="this.scenarioClaimsList.length==0">
            <div class="row">
                <p>There are no alternatives to display.</p>
            </div>
        </div>
        <div class="m-0 p-3 bg-white border-3 border-top" *ngFor="let item of this.scenarioClaimsList;index as i">
            <div class="row">
                <div class="col-7 pt-3 pe-5">
                    <div style="border-bottom: 1px solid lightgrey;">
                        <div class="row">
                            <h4 class="mb-2 col-8"><strong>{{item.responseClaimSegment.productLabelName}}</strong></h4>
                            <div class="col-4 row">

                                <!-- <label *ngIf="claimsummaryDetailsrequest.brandPreferred"
                                    class="mb-1 p-2 row rounded-pill text-center"
                                    style="color: #000; background-color: lightblue;">
                                    <span class="col-12">Brand</span>
                                    <span class="col-12">Preferred</span>
                                </label> -->

                                <!-- <label *ngIf="claimsummaryDetailsrequest.requiresPA" class="mb-1"
                                    style="color: #846732;"><span class="text-center  ms-2">PA
                                        Required<i aria-hidden="true" class="fa fa-exclamation-triangle ps-2"
                                            style="position: relative; top: 4px; margin-right: 5px;"></i></span></label> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <p class="mt-2 fs-6 text-secondary fw-light"><span>
                                    Quantity Calculated: {{this.quantityService.quantityCalculate(item.responseClaimSegment.quantityDispensed, this.claimresponse.drugSearchItem).qtydesc}}
                                </span></p>
                        </div>
                        <!-- <div class="col-6" *ngIf="this.claimsummaryDetailsrequest.isMedD==true">
                            <p class="mt-2 fs-6 text-secondary fw-light"><span>
                                    End Phase: {{claimsummaryDetailsrequest.endPhase}}
                                </span></p>
                        </div> -->
                    </div>
                </div>

                <div class="col-5 row">
                    <div class="col-4">
                        <p class="fs-6 text-secondary fw-light">Patient Amount Due</p>
                        <p class="fs-4 text-dark">{{item.responsePricingSegment.patientPayAmount!="0"?"$"+item.responsePricingSegment.patientPayAmount:"N/A"}}</p>
                    </div>
                    <div class="col-4 p-0">
                        <p class="fs-6 text-secondary fw-light">Plan Amount Due</p>
                        <p class="fs-4 text-dark">{{item.responsePricingSegment.planPayAmount!="0"?"$"+item.responsePricingSegment.planPayAmount:"N/A"}}</p>
                    </div>
                    
                    <!-- <div class="col-4" *ngIf="claimsummaryDetailsrequest.initiatePALnk">
                        <button class="btn btn-link fs-6 ms-3 mt-3 fw-bold" (click)="editProvider()">Initiate
                            PA</button>.
                        <eparunner></eparunner>
                    </div> -->
                    <div class="col-4">
                        <button class="btn btn-primary fs-6  mt-4 ms-4 fw-bold" (click)="selectAlternateDrugInfo(item)">Select</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End Alternate Pharmices  -->
    <!--Start Member Details -->
    <div class="mt-2 col-8 offset-2 mb-5 d-print-none">
        <div class="p-3 bg-white">
            <h4 class="text-capitalize mt-3 mb-3">PRESCRIPTION COVERAGE SUMMARY</h4>
        </div>
        <div class="m-0 p-3 bg-white border-3 border-top">
            <h4 class="text-capitalize mt-3 mb-3">MEMBER INFORMATION</h4>
            <div class="m-2 mt-3 p-3 bg-light border-3 border-top row">
                <div class="col-6">
                    <div class="row">
                        <div class="col-6 row text-muted">
                            <label>Name</label>
                            <label class="text-dark">{{claimsummaryDetailsrequest.Name}}</label>
                        </div>
                        <div class="col-6 row text-muted">
                            <label>Member ID</label>
                            <label class="text-dark">{{claimsummaryDetailsrequest.MemberID}}</label>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 row text-muted">
                            <label>Address</label>
                            <label class="text-dark">{{claimsummaryDetailsrequest.Address}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-4 row text-muted">
                            <label>Date of Birth</label>
                            <label class="text-dark">{{claimsummaryDetailsrequest.DateOfBirth}}</label>
                        </div>
                        <div class="col-4 row text-muted">
                            <label>Phone</label>
                            <label class="text-dark">{{claimsummaryDetailsrequest.Phone == '0' ? 'N/A' :claimsummaryDetailsrequest.Phone}}</label>
                        </div>
                        <div class="col-4 row text-muted">
                            <label>Gender</label>
                            <label class="text-dark">{{claimsummaryDetailsrequest.Gender}}</label>
                        </div>
                    </div>
                    <!-- <div class="row mt-3">
                    <div class="col-12 row text-muted">
                        <label>Bin/PCN/Group</label>
                        <label class="text-dark">{{claimsummaryDetailsrequest.BinPCNGroup}}</label>
                    </div>
                </div> -->
                </div>

            </div>
        </div>
        <div class="m-0 p-3 bg-white border-3">
            <h4 class="text-capitalize mt-3 mb-3">MEDICATION INFORMATION</h4>
            <div class="bg-light border-3 border-top p-3">
                <div class="row">
                    <div class="col-6 p-3">
                        <div class="row">
                            <label class="text-muted">Medication Name</label>
                            <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.MedicationName}}</label>
                        </div>

                        <div class="row mt-3">
                            <label class="text-muted">Diagnosis Code</label>
                            <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.DiagnosisCode}}</label>
                        </div>
                        
                        <div class="row mt-3">
                            <div class="col-6 row text-muted">
                                <label>Quantity Entered</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.QuantityEntered}}</label>
                            </div>
                            <div class="col-6 row text-muted">
                                <label>Quantity Calculated</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.QuantityDesc}} </label>
                            </div>
                        </div>
                        
                        <div class="row mt-3">
                            <label class="text-muted">Contracted Network Provider</label>
                            <label
                                class="fs-6 text-dark">{{claimsummaryDetailsrequest.ContractedNetworkProvider}}</label>
                        </div>
                    </div>
                    <div class="col-6 p-3">
                        <div class="row">
                            <div class="col-6 row text-muted">
                                <label>Product ID</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.ProductID |
                                    ndcdisplayformat}}</label>
                            </div>
                            <div class="col-6 row text-muted">
                                <label>Date of Service</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.DateofService}}</label>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <label class="text-muted">Days of Supply</label>
                            <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.DaysSupply}}</label>
                        </div>
                        <div class="row mt-3">
                            <label class="text-muted">Contracted Network Provider</label>
                            <label
                                class="fs-6 text-dark">{{claimsummaryDetailsrequest.ContractedNetworkProviderAddress}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End Member Details  -->

        <!-- Print div template -->
    <div class="mt-6 col-8 offset-2 text-dark d-none d-print-block" id="submissionDetails1">
        <div class="img mt-6 pt-4 d-none d-print-block" style="align-content: top-left;" id='logo' hidden>
            <img id="u11482.PNG" class="img" style="padding-top: 30px; height: 40%; width: 40%;" src="assets/images/u11482.png" aria-hidden="true" focusable="false">
        </div>
        <div class="p-3 bg-white  mt-6 d-none d-print-block">
            <div class="row">
                <div class="col">
                    <h4 class="text-capitalize mt-3 col">CHECK COVERAGE SUMMARY</h4>
                </div>
            </div>
        </div>

        <!-- Quantity -->
        
        <div class="row" *ngIf="printAlternative==false">
            <p class="col-5 mt-3 text-center"><span>Quantity Entered:
                    <strong>{{claimsummaryDetailsrequest.QuantityEntered}}</strong></span></p>
            <p class="col-4 mt-3 text-center"><span>Days Supply:
                    <strong>{{claimsummaryDetailsrequest.DaysSupply}}</strong></span></p>
            <button class="col-3 btn btn-link float-end"
                *ngIf="claimsummaryDetailsrequest.claimStsTypId<3 || claimsummaryDetailsrequest.claimStsTypId==6 || claimsummaryDetailsrequest.claimStsTypId==8"
                (click)="chgQtyDOS()">Change</button>
        </div>
        

        <!-- Banner -->

        <div class="p-2 bg-success" *ngIf="claimsummaryDetailsrequest.bannerType==3 && printAlternative==false">
            <div class="row p2">
                <i aria-hidden="true" class="fa fa-check h3 m-2 ms-4 col-1 bannerIcon text-light"></i>&nbsp; &nbsp;
                <label class="fs-5 col-10 mt-2 mb-4 bannerText">{{claimsummaryDetailsrequest.bannerMessage}}</label>
            </div>
        </div>

        <!--Yellow Prior authorization is required on this medication.  -->
        <div class="p-2 bg-warning text-dark" *ngIf="claimsummaryDetailsrequest.bannerType==2 && printAlternative==false">
            <div class="row p2">
                <i aria-hidden="true" class="fa fa-exclamation-triangle h3 m-2 ms-4 col-1 bannerIcon"></i>&nbsp; &nbsp;
                <label class="fs-5 col-10 mt-2 mb-4 bannerText">{{claimsummaryDetailsrequest.bannerMessage}}</label>
            </div>
        </div>

        <!--Red The medication has exceeded the plan limits allowed  -->
        <div class="p-2 bg-danger text-light" *ngIf="claimsummaryDetailsrequest.bannerType==1 && printAlternative==false">
            <div class="row p2">
                <i aria-hidden="true" class="fa fa-exclamation-circle h3 m-2 ms-4 col-1 bannerIcon text-light"></i>&nbsp; &nbsp;
                <label class="fs-5 col-10 mt-2 mb-4 bannerText">{{claimsummaryDetailsrequest.bannerMessage}}</label>
            </div>
        </div>

        <!-- Drug Details -->
        <div class="row mt-3 p-2" *ngIf="printAlternative==false">
            <div class="col-7 pt-3 pe-5">
                <div style="border-bottom: 1px solid lightgrey;">
                    <div class="row">
                        <h4 class="mb-2 col-8"><strong>{{claimsummaryDetailsrequest.MedicationName}}</strong></h4>
                        <div class="col-4 row">

                            <label *ngIf="claimsummaryDetailsrequest.brandPreferred"
                                class="mb-1 p-2 row rounded-pill text-center"
                                style="color: #000; background-color: lightblue;">
                                <span class="col-12">Brand</span>
                                <span class="col-12">Preferred</span>
                            </label>

                            <label *ngIf="claimsummaryDetailsrequest.requiresPA" class="mb-1"
                                style="color: #846732;"><i aria-hidden="true" class="fa fa-exclamation-triangle ps-2"
                                style="position: relative; margin-right: 0px;"></i><span class="text-center  ms-2">PA
                                    Required</span></label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <p class="mt-2 fs-6 text-secondary fw-light"><span>
                                Quantity Calculated: {{claimsummaryDetailsrequest.QuantityDesc}}
                            </span></p>
                    </div>
                    <div class="col-6" *ngIf="this.claimsummaryDetailsrequest.isMedD==true">
                        <p class="mt-2 fs-6 text-secondary fw-light"><span>
                                End Phase: {{claimsummaryDetailsrequest.endPhase}}
                            </span></p>
                    </div>
                </div>
            </div>

            <div class="col-5 row">
                <div class="col-4">
                    <p class="fs-6 text-secondary fw-light">Patient Amount Due</p>
                    <p class="fs-4 text-dark">{{claimsummaryDetailsrequest.PatientPayAmount}}</p>
                </div>
                <div class="col-4 p-0">
                    <p class="fs-6 text-secondary fw-light">Plan Amount Due</p>
                    <p class="fs-4 text-dark">{{claimsummaryDetailsrequest.ProviderTotalAmountDue}}</p>
                </div>
                <div class="col-4" *ngIf="claimsummaryDetailsrequest.initiatePALnk">
                    <button class="btn btn-link fs-6 ms-3 mt-3 fw-bold" (click)="editProvider()">Initiate
                        PA</button>.
                    <eparunner></eparunner>
                </div>
                <!-- *ngIf="this.claimsummaryDetailsrequest.claimSubmitted" -->
                <div class="col-4" *ngIf="claimsummaryDetailsrequest.claimStsTypId==3">
                    <!-- <div class="col-4"> -->
                    <button class="btn btn-primary fs-6  fw-bold">Reverse Claim</button>
                </div>

                <!-- <div class="col-4" *ngIf="this.claimsummaryDetailsrequest.claimSubmitFailed"> -->
                <div class="col-4"
                    *ngIf="claimsummaryDetailsrequest.claimStsTypId==6 || claimsummaryDetailsrequest.claimStsTypId==8">
                    <button class="btn btn-primary fs-6  fw-bold">Remove</button>
                </div>

                <!-- <button class="btn btn-primary float-end fontbold mt-1 col-5 offset-1" (click)="submitClaims()">Submit Claim
            </button> -->
            </div>
        </div>


        <!-- Alternatives print -->

        <div class=" mt-3 p-3 bg-white" *ngIf="printAlternative==false">
            <h4 class="text-capitalize mt-3 mb-3">ALTERNATIVES FOR {{claimsummaryDetailsrequest.MedicationName.substring(0,claimsummaryDetailsrequest.MedicationName.indexOf(" "))}}</h4>
        </div>
        <div class="m-0 p-3 bg-white border-3 border-top" *ngIf="this.scenarioClaimsList.length==0 && printAlternative==false">
            <div class="row">
                <p>There are no alternatives to display.</p>
            </div>
        </div>
        <div class="m-0 p-3 bg-white border-3 border-top" *ngFor="let item of this.scenarioClaimsList;index as i">
            <div class="row"  *ngIf="printAlternative==false">
                <div class="col-7 pt-3 pe-5">
                    <div style="border-bottom: 1px solid lightgrey;">
                        <div class="row">
                            <h4 class="mb-2 col-8"><strong>{{item.responseClaimSegment.productLabelName}}</strong></h4>
                            <div class="col-4 row">

                                <!-- <label *ngIf="claimsummaryDetailsrequest.brandPreferred"
                                    class="mb-1 p-2 row rounded-pill text-center"
                                    style="color: #000; background-color: lightblue;">
                                    <span class="col-12">Brand</span>
                                    <span class="col-12">Preferred</span>
                                </label> -->

                                <!-- <label *ngIf="claimsummaryDetailsrequest.requiresPA" class="mb-1"
                                    style="color: #846732;"><span class="text-center  ms-2">PA
                                        Required<i aria-hidden="true" class="fa fa-exclamation-triangle ps-2"
                                            style="position: relative; top: 4px; margin-right: 5px;"></i></span></label> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <p class="mt-2 fs-6 text-secondary fw-light"><span>
                                    Quantity Calculated: {{this.quantityService.quantityCalculate(item.responseClaimSegment.quantityDispensed, this.claimresponse.drugSearchItem).qtydesc}}
                                </span></p>
                        </div>
                        <!-- <div class="col-6" *ngIf="this.claimsummaryDetailsrequest.isMedD==true">
                            <p class="mt-2 fs-6 text-secondary fw-light"><span>
                                    End Phase: {{claimsummaryDetailsrequest.endPhase}}
                                </span></p>
                        </div> -->
                    </div>
                </div>

                <div class="col-5 row">
                    <div class="col-4">
                        <p class="fs-6 text-secondary fw-light">Patient Amount Due</p>
                        <p class="fs-4 text-dark">{{item.responsePricingSegment.patientPayAmount!="0"?"$"+item.responsePricingSegment.patientPayAmount:"N/A"}}</p>
                    </div>
                    <div class="col-4 p-0">
                        <p class="fs-6 text-secondary fw-light">Plan Amount Due</p>
                        <p class="fs-4 text-dark">{{item.responsePricingSegment.planPayAmount!="0"?"$"+item.responsePricingSegment.planPayAmount:"N/A"}}</p>
                    </div>
                    
                    <!-- <div class="col-4" *ngIf="claimsummaryDetailsrequest.initiatePALnk">
                        <button class="btn btn-link fs-6 ms-3 mt-3 fw-bold" (click)="editProvider()">Initiate
                            PA</button>.
                        <eparunner></eparunner>
                    </div> -->
                    <div class="col-4">
                        <button class="btn btn-primary fs-6  mt-4 ms-4 fw-bold" (click)="selectAlternateDrugInfo(item)">Select</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="m-0 col">
            <div class="m-0 p-4 ps-1 bg-white">
                <h4 class="text-capitalize mb-3">MEMBER INFORMATION</h4>
                <div class="m-2 mt-3 p-1 border-3 border-top row" style="background-color: rgba(242, 246, 249, 1);">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-6 row text-muted">
                                <label>Name</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.Name}}</label>
                            </div>
                            <div class="col-6 row text-muted">
                                <label>Member ID</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.MemberID}}</label>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 row text-muted">
                                <label>Address</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.Address}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-6 row text-muted">
                                <label>Date of Birth</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.DateOfBirth}}</label>
                            </div>
                            <div class="col-6 row text-muted">
                                <label>Phone</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.Phone == '0' ? 'N/A' :claimsummaryDetailsrequest.Phone}}</label>
                            </div>
                            <div class="col-6 mt-3 row text-muted">
                                <label>Gender</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.Gender}}</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="m-0 p-4 ps-1 bg-white border-3">
                <h4 class="text-capitalize mt-3 mb-3">MEDICATION INFORMATION</h4>
                <div class="border-3 border-top p-1" style="background-color: rgba(242, 246, 249, 1);">
                    <div class="row">
                        <div class="col-6 p-3">
                            <div class="row">
                                <label class="text-muted">Medication Name</label>
                                <label class="fs-6 text-dark">{{this.selectedAlternateDrug.responseClaimSegment.productLabelName}}</label>
                            </div>
                            <div class="row mt-3">
                                <label class="text-muted">Diagnosis Code</label>
                                <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.DiagnosisCode}}</label>
                            </div>
                            <div class="row mt-3">
                                <div class="col-6 row text-muted">
                                    <label>Quantity Entered</label>
                                    <label class="text-dark">{{claimsummaryDetailsrequest.QuantityEntered}}</label>
                                </div>
                                <div class="col-6 row text-muted">
                                    <label class="text-muted">Days of Supply</label>
                                    <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.DaysSupply}}</label>
                                </div>
                            </div>
                            
                            <div class="row mt-3">
                                <label class="text-muted">Pharmacy</label>
                                <label *ngIf="this.claimsummaryDetailsrequest.scenariopharmacyIndex==0"
                                    class="fs-6 text-dark">{{claimsummaryDetailsrequest.ContractedNetworkProvider}}</label>
                                <label *ngIf="this.claimsummaryDetailsrequest.scenariopharmacyIndex!=0"
                                    class="fs-6 text-dark">{{this.claimresponse.trailadjudicationwebresponse.prefAlternativesWebResponse.alternatePharmacies[this.claimsummaryDetailsrequest.scenariopharmacyIndex-1].serviceProviderName}}</label>
                            </div>
                        </div>
                        <div class="col-6 p-3">
                            <div class="row">
                                <div class="col-6 row text-muted">
                                    <label>Product ID</label>
                                    <label class="text-dark">{{this.selectedAlternateDrug.responseClaimSegment.productOrServiceId|
                                        ndcdisplayformat}}</label>
                                </div>
                                <div class="col-6 row text-muted">
                                    <label>Cost Per fill</label>
                                    <label class="text-dark">$&nbsp;{{this.selectedAlternateDrug.responsePricingSegment.patientPayAmount}}</label>
                                </div>
                            </div>
                            
                            <div class="row mt-5">
                                <label class="text-muted">Contracted Network Provider</label>
                                <label
                                    class="fs-6 text-dark">{{claimsummaryDetailsrequest.ContractedNetworkProviderAddress}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-1 border-2 border-top">
                <label class="mt-2">Prices are estimates. Prices on the medication(s) listed may vary depending on the days of supply, directions for use or the manufacturers' prices at this time and at the time of the transaction. Please note: Not all manufacturers for specified medications may be priced with this tool. Please refer to the member's covered medications list (formulary) for more information.</label>
            </div>
        </div>
    </div>
    <!-- End Print div template -->
</div>


<ng-template #claimSummaryProgress let-modal>
    <div style="height:200px">
        <div class="modal-body mt-2">
            <div class="p-3">
                <div *ngIf="counter==10" class="row text-center"><i class="fa fa-ban  text-danger fs-1"
                        aria-hidden="true"></i></div>
                <div @slideDown *ngIf="counter==10" class="text-center mt-3 fs-6">
                    {{trailAdjuducicationError}}</div>
                <div *ngIf="counter==10" @slideDown class="modal-footer border-0 mt-5">
                    <button class="btn btn-secondary position-absolute top-80 start-50 translate-middle"
                        (click)="dismissAll()">ok</button>
                </div>
                <div *ngIf="counter==3" class="circle">
                    <div class="checkMark"></div>
                </div>
                <div *ngIf="counter<3" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div @slideDown *ngIf="counter==3" class="text-center fs-6">Check coverage complete</div>

                <div @slideDown *ngIf="counter==2" class="text-center fs-6">Retrieving results...</div>

                <div @slideDown *ngIf="counter==1" class="text-center fs-6">Analyzing information…</div>

            </div>
        </div>

    </div>

</ng-template>

<ng-template #editProviderTemplate let-modal>
    <div class="content-card editprovider">
        <div class="card-header text-start">
            <h2>Verify Provider Information</h2>
        </div>
        <div class="alert alert-danger text-end" *ngIf="editProviderStatus==2">
            <div class="float-start servererror">
                <i class="fa fa-ban"></i>An error occurred.
            </div>
            <button class="btn btn-danger" (click)="editProviderStatus=0">Refresh page</button>
        </div>
        <div class="alert alert-danger text-start" *ngIf="editProviderStatus==3">
            All fields are required.
        </div>
        <div>
            <!-- <editprovider *ngIf="editProviderStatus!=2" [prescriberDemographs]="claimresponse.selectedPrescriberDetail" (isValid)="isEditProviderValid($event)"></editprovider> -->
        <app-verify-provider [isProviderPage]="isProviderPage"></app-verify-provider>
        </div>
        <div class="card-footer text-end">
            <button class="btn btn-link fontbold" (click)="modal.dismiss()">Cancel</button>
            <button class="btn btn-primary" 
                (click)="modal.dismiss();initiatePA()">Submit</button>
        </div>
    </div>
</ng-template>

<ng-template #errorModalTemplate let-modal>
    <div class="content-card errorModal">
        <div class="card-content text-center">
            <i class="fa fa-ban"></i>
            <br /><br />
            <div [innerHtml]="errorMessage"></div>
            <br />
            <button class="btn btn-primary" (click)="modal.dismiss()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #overwriteConfirmModal let-modal>
    <div class="p-3">
        <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            All drugs in the transaction will process again.<br>Results may vary depending on if there were plan
            changes.
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <div class="col-4 ">
                <button class="col-2 position-absolute top-80 start-50 translate-middle btn btn btn-outline-primary"
                    (click)="chgQtyDOS()">okay</button>
            </div>
        </div>
    </div>
</ng-template>

<!-- Template for alternate pharmacy selected to print -->
<ng-template #selectAlternateDrug let-modal>
    <div class="p-4 d-print-none">
        <p class="h4">E-prescribing is not currently supported through PreCheck MyScript. If you would like to prescribe this alternative, use the electronic medical records application.</p>
    </div>
    <div class="mt-2 ps-4 border-2 border-bottom d-print-none">
        <p class="m-0">Print this page for your reference below.</p>
    </div>
    <div class="mt-2 col d-print-none">
        <div class="p-4 bg-white">
            <h4 class="text-capitalize mt-3">PRESCRIPTION COVERAGE SUMMARY</h4>
        </div>
        <div class="m-0 p-4 bg-white">
            <h4 class="text-capitalize mb-3">MEMBER INFORMATION</h4>
            <div class="m-2 mt-3 p-3 border-3 border-top row  d-print-none" style="background-color: rgba(242, 246, 249, 1);">
                <div class="col-6">
                    <div class="row">
                        <div class="col-6 row text-muted">
                            <label>Name</label>
                            <label class="text-dark">{{claimsummaryDetailsrequest.Name}}</label>
                        </div>
                        <div class="col-6 row text-muted">
                            <label>Member ID</label>
                            <label class="text-dark">{{claimsummaryDetailsrequest.MemberID}}</label>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 row text-muted">
                            <label>Address</label>
                            <label class="text-dark">{{claimsummaryDetailsrequest.Address}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-4 row text-muted">
                            <label>Date of Birth</label>
                            <label class="text-dark">{{claimsummaryDetailsrequest.DateOfBirth}}</label>
                        </div>
                        <div class="col-4 row text-muted">
                            <label>Phone</label>
                            <label class="text-dark">{{claimsummaryDetailsrequest.Phone == '0' ? 'N/A' :claimsummaryDetailsrequest.Phone}}</label>
                        </div>
                        <div class="col-4 row text-muted">
                            <label>Gender</label>
                            <label class="text-dark">{{claimsummaryDetailsrequest.Gender}}</label>
                        </div>
                    </div>
                    <!-- <div class="row mt-3">
                    <div class="col-12 row text-muted">
                        <label>Bin/PCN/Group</label>
                        <label class="text-dark">{{claimsummaryDetailsrequest.BinPCNGroup}}</label>
                    </div>
                </div> -->
                </div>

            </div>
        </div>
        <div class="m-0 p-4 bg-white border-3 d-print-none">
            <h4 class="text-capitalize mt-3 mb-3">MEDICATION INFORMATION</h4>
            <div class="border-3 border-top p-3  d-print-none" style="background-color: rgba(242, 246, 249, 1);">
                <div class="row">
                    <div class="col-6 p-3">
                        <div class="row">
                            <label class="text-muted">Medication Name</label>
                            <label class="fs-6 text-dark">{{this.selectedAlternateDrug.responseClaimSegment.productLabelName}}</label>
                        </div>
                        <div class="row mt-3">
                            <div class="col-6 row text-muted">
                                <label>Quantity Entered</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.QuantityEntered}}</label>
                            </div>
                            <div class="col-6 row text-muted">
                                <label>Quantity Calculated</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.QuantityDesc}} </label>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <label class="text-muted">Diagnosis Code</label>
                            <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.DiagnosisCode}}</label>
                        </div>
                        <div class="row mt-3">
                            <label class="text-muted">Contracted Network Provider</label>
                            <label *ngIf="this.claimsummaryDetailsrequest.scenariopharmacyIndex==0"
                                class="fs-6 text-dark">{{claimsummaryDetailsrequest.ContractedNetworkProvider}}</label>
                            <label *ngIf="this.claimsummaryDetailsrequest.scenariopharmacyIndex!=0"
                                class="fs-6 text-dark">{{this.claimresponse.trailadjudicationwebresponse.prefAlternativesWebResponse.alternatePharmacies[this.claimsummaryDetailsrequest.scenariopharmacyIndex-1].serviceProviderName}}</label>
                        </div>
                    </div>
                    <div class="col-6 p-3">
                        <div class="row">
                            <div class="col-6 row text-muted">
                                <label>Product ID</label>
                                <label class="text-dark">{{this.selectedAlternateDrug.responseClaimSegment.productOrServiceId|
                                    ndcdisplayformat}}</label>
                            </div>
                            <div class="col-6 row text-muted">
                                <label>Date of Service</label>
                                <label class="text-dark">{{claimsummaryDetailsrequest.DateofService}}</label>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <label class="text-muted">Days of Supply</label>
                            <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.DaysSupply}}</label>
                        </div>
                        <div class="row mt-3">
                            <label class="text-muted">Contracted Network Provider</label>
                            <label
                                class="fs-6 text-dark">{{claimsummaryDetailsrequest.ContractedNetworkProviderAddress}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="this.claimsummaryDetailsrequest.claimStsTypId<3" class="border-2 border-bottom p-3 pb-5 mt-5 d-print-none">
            <button class="btn btn-link m-2" (click)="modal.dismiss();
            this.selectedAlternateDrug=this.claimresponse.trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims[0];">Close</button>
            <button class="btn btn-primary float-end m-2" (click)="printFun('submissionDetails1'); printAlternative==true">
                <i class="fa fa-print" aria-hidden="true"></i> &nbsp;Print summary</button>
            <button class="btn btn-outline-primary border border-primary border-2 float-end m-2" type="submit" role="button"
                (click)="goToSelectMember()">Go to homepage</button>
        </div>
        <div class="p-4 d-print-none ">
            <p>Prices are estimates. Prices on the medication(s) listed may vary depending on the days of supply, directions for use or the manufacturers' prices at this time and at the time of the transaction. Please note: Not all manufacturers for specified medications may be priced with this tool. Please refer to the member's covered medications list (formulary) for more information.</p>
            <p>Medications that are listed as preferred alternatives may not be covered by the member's benefits plan. Please refer to the member's covered medications list (formulary) to determine coverage.</p>
        </div>
    </div>
</ng-template>

