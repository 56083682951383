/*export class PharmacySearchRequest {
    zipCode!: string
    distance!: string
    dispenserType!: string[]
    dispenserClass!: string[]
    plan!: Plan
    pharmacyName!: string
    instanceId!: string
  }
  
  export class Plan {
    id!: string
    effectiveDate!: string
  }*/

  export class PharmacySearchRequest {
    address!: Address
    searchRadius!: string
    primaryProviderTypeCode!: string[]
    dispenserClassCode!: string[]
    pharmacyName!: string
    sourceSystemInstance!: string
    planSearch!: PlanSearch
  }
  
  export class Address {
    zip!: string
  }
  
  export class PlanSearch {
    planCode!: string
    planEffectiveDate!: string
  }
  