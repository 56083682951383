import { NgModule ,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewalldatatableComponent } from 'src/app/components/viewalldatatable/viewalldatatable.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { HistoryModule } from '../history/history.module';
import { MemberdatatableComponent } from 'src/app/components/memberdatatable/memberdatatable.component';



@NgModule({
  declarations: [ViewalldatatableComponent,
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
   // HistoryModule,
  ]
  ,exports:[ViewalldatatableComponent,FormsModule],
  schemas: [NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ]

})
export class ViewalldatatableModule { }
