<div class="col-10 offset-1">
  <div class="container-fluid noresultsinfo" *ngIf="this.nomatchesFound=='true'">
    <div class="row">
        <div class="col nopadding">
            <div class="alert alert-primary shadow" role="alert">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                <span>Info: No Search Results found</span>
                <i class="fa fa-remove" aria-label="Close" (click)="dismiss()"></i>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid servererror" *ngIf="this.timeoutError=='true'">
  <div class="row alert alert-danger shadow" role="alert">
    <div class="col nopadding">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        <span>We were unable to retrieve this information. Refresh the page to try again.</span>
        <i class="fa fa-remove" aria-label="Close"  (click)="dismiss()"></i>
    </div>
</div>
</div>

  <div class="container-fluid bg-white content-card">

    <div class="row">
      <div class="col card-header tabs-header noborder">
        <ul class="nav nav-tabs text-center">
          <li class="nav-item">
            <p><a class="btn invisiblebtn nav-link" [ngClass]="{'active':activeTab==1}" (click)="activeTab=1"
              aria-current="page" id="claimhistory" role="tab">
              Search by Prescriber NPI
            </a></p>
          </li>
          <li class="nav-item">
            <p><a class="btn invisiblebtn nav-link" [ngClass]="{'active':activeTab==2}" (click)="activeTab=2"
              href="javascript:void(0)" id="pahistory" role="tab">
              Search by Prescriber Name
           </a></p>
          </li>
        </ul>
      </div>
    </div>


    <div class="row" *ngIf="activeTab==1">
      <div class="tab-content col">
        <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="claimhistory">
          <form [formGroup]="searchPrescriberNPIForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <label for="text" class="form-label float-left">Prescriber NPI</label>
                  <div class="search">
                    <i class="fa fa-search"></i>
                    <input type="text" (focus)="onFocusNPI()" (blur)="onFocusNPI()" class="form-control inp rounded-0"
                      [ngClass]="{'inpError':((NPI && NPI.invalid && NPI.dirty) || (NPI && NPI.invalid && submittedNPIForm))}"
                      formControlName="NPI">
                  </div>
                  <div *ngIf="NPI && NPI.invalid && NPI.dirty">
                    <div class="text-danger" *ngIf=" NPI && NPI.errors?.['minlength'] && !focussedNPI">
                      <small>Please enter min 10 characters.</small>
                    </div>
                    <div class="text-danger"
                      *ngIf="NPI && NPI.errors?.['required'] && !submittedNPIForm && !focussedNPI">
                      <small>NPI is required</small>
                    </div>
                  </div>
                  <div class="text-danger" *ngIf="NPI && NPI.errors?.['required'] && submittedNPIForm && !focussedNPI">
                    <small>NPI is required</small>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="activeTab==2">
      <div class="col">
        <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="pahistory">
          <form [formGroup]="searchPrescriberNameForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3 ">
                    <label class="form-label">Last Name</label>
                    <input type="text" class="rounded-0 form-control inp" aria-describedby="emailHelp" maxlength="25"
                      [ngClass]="{'inpError':((lastName && lastName.invalid && lastName.dirty) || (lastName && lastName.invalid && submittedNPINameForm))}"
                      formControlName="lastName" (focus)="onFocusLastName()" (blur)="onFocusLastName()">
                    <div *ngIf="lastName && lastName.invalid && lastName.dirty">
                      <div class="text-danger" *ngIf="lastName && lastName.errors?.['minlength'] && !focussedLastName">
                        <small>Please enter min 2 characters.</small>
                      </div>
                      <div class="text-danger"
                        *ngIf="lastName && lastName.errors?.['required'] && !submittedNPINameForm && !focussedLastName">
                        <small>Last Name is required</small>
                      </div>
                    </div>
                    <div class="text-danger"
                      *ngIf="lastName && lastName.errors?.['required'] && submittedNPINameForm && !focussedLastName">
                      <small>Last Name is required</small>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="mb-3">
                    <label for="firstName" class="form-label">First Name (optional)</label>
                    <input type="text" class=" rounded-0 form-control inp" id="firstName" aria-describedby="firstName"
                      name="firstName" maxlength="25" formControlName="firstName">
                    <div class="text-danger" *ngIf="firstName && firstName.invalid && firstName.dirty">
                      <small>Invalid Character</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8">
                  <!-- <div class="mb-3"> -->
                  <label for="city" class="form-label">City (optional)</label>
                  <input type="text" class="rounded-0 form-control inp" id="city" aria-describedby="city" name="city" formControlName="city">
                </div>

                <div class="col-sm-4">
                  <!-- <div class="mb-3"> -->
                  <label class="form-label">State</label>
                  <select class="rounded-0 inp form-select" aria-label="Default select example" name="DrugAdmin"
                    formControlName="state"
                    [ngClass]="{'inpError':((state && state.invalid && state.dirty) || (state && state.invalid && submittedNPINameForm))}"
                    (focus)="onFocussedState()" (blur)="onFocussedState()">
                    <option selected value="">&nbsp;Select</option>
                    <option *ngFor="let item of states" value="{{item.code}}">&nbsp;{{item.code}}</option>
                  </select>
                  <div class="text-danger"
                    *ngIf="state && state.invalid && state.errors?.['required'] && submittedNPINameForm && !focussedState">
                    <small>State is required.</small>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--  NPI Seach Buttons-->
    <div class="row" *ngIf="activeTab==1">
      <div class="col card-footer text-end">
        <button (click)="clearNPIForm()" class="btn btn-link fontbold">Clear</button>
        <button class="btn btn-primary text-white shadow" role="button" (click)="submitNPIForm()">Search</button>
        <button  class="btn btn-link float-start ps-0 fontbold" (click)="goto()">Cancel</button>
      </div>
    </div>
    <!--  NPI Seach Buttons-->
    <div class="row" *ngIf="activeTab==2">
      <div class="col card-footer text-end">
        <!-- <div class="col-md-9 col-xs-8 p-4">
        <button class="btn btn-link fontbold">Cancel</button></div> -->
        <button type="button" (click)="clearNPINameForm()" class="btn btn-link fontbold">Clear</button>
        <button class="btn btn-primary text-white shadow" role="button" (click)="submitNPINameForm()">Search</button>
      </div>
    </div>
  </div>
</div>


<div class="col-10 offset-1">
  <div class="container-fluid" *ngIf="this.prescriberSearchHistory.length>0">
    <div *ngIf="prescriberList.length>0" class="row" style="margin-bottom: 30px; color:#424249">
      <div class="row form-label">
        <label class="display-4 col-1" style=" position: relative; top: 20%;" *ngIf="prescriberList.length<10">{{prescriberList.length}}</label>
        <label class="display-4 col-2" style=" position: relative; top: 20%;" *ngIf="prescriberList.length>10">{{prescriberList.length}}</label>
        <div class="col-10">
          <label class="offset-0" style=" position: relative; top: 30%;" *ngIf="prescriberList.length<10"> Prescriber found</label>
          <label class="offset-1" style=" position: relative; top: 30%;" *ngIf="prescriberList.length>10"> Prescribers found</label>
          <div class="offset-5" style="position: relative; top: 20%; border-bottom: 1.5px solid lightgray; height: 1px;">
          </div>
          <label class="offset-0" *ngIf="prescriberList.length<10" style=" position: relative; top: 40%; color: grey;">Showing 
            {{((currentPageNumber-1)*recordsPerPage)+1}} of {{prescriberList.length}} prescriber</label>
          <label class="offset-1" *ngIf="prescriberList.length>10" style=" position: relative; top: 30%; color: grey;">Showing 
            {{(recordsPerPage)}} of {{prescriberList.length}} prescribers</label>
        </div>
      </div>
    </div>
  </div>
  <datatable *ngIf="prescriberList.length>0" [headers]="prescriberSearchHeaders" [rows]="prescriberSearchHistory"
    [type]="datatableType" (onHeaderClicked)="onPrescriberHeaderClick($event)"
    (onCellClicked)="onPrescriberCellClick($event)">
  </datatable>

  <div class="container-fluid" *ngIf="totalPagesCount>1&&this.prescriberSearchHistory.length>0">
    <div class="row">
      <div class="col text-center">
        <pagination [totalPages]="totalPagesCount" (onPageChange)="onPageChange($event)"></pagination>
      </div>
    </div>
  </div>

  <div class="col mt-3 nopadding noresults"
    *ngIf="totalPagesCount==0 && (submittedNPINameForm==true || submittedNPIForm==true) && (searchPrescriberNPIForm.valid || searchPrescriberNameForm.valid)">
    No search results found.
  </div>

  <div class="row" style="margin:15px !important;margin-top:15px;" *ngIf="this.nomatchesFound=='true'">
    <div class="col-xs-12 noresults">
        No search results found.
    </div>
</div>
</div>

<!-- Templates for DataTable -->
<ng-template #NameTemplate let-data="data">
  <div class="p-2">
    <p class="large">
      <span class="fontbold">{{data.lastName | stringtosentencecase }}</span>
      <br />
      {{data.firstName | stringtosentencecase }}
    </p>
  </div>
</ng-template>
<ng-template #AddressTemplate let-data="data">
  <p class="large">
    <span>{{data.address.address1 | stringtosentencecase}}</span>
    <br />
    {{data.address.city | stringtosentencecase }},{{data.address.state}}&nbsp;{{data.address.zip1}}
  </p>
</ng-template>
<ng-template #NPITemplate let-data="data">
  <p class="large">{{data.identifiers[0].id}}</p>
</ng-template>

<ng-template #SNameTemplate let-data="data">
  <div class="p-2">
    <p class="large">
      <span class="fontbold">{{data.lastName}}</span>
      <br />
      {{data.firstName}}
    </p>
  </div>
</ng-template>
<ng-template #SAddressTemplate let-data="data">
  <p class="large">
    <span class="fontbold">{{data.address}}</span>
    <br />
  </p>
</ng-template>
<ng-template #SNPITemplate let-data="data">
  <p class="large">{{data.npi}}</p>
</ng-template>


<ng-template #RNameTemplate let-data="data">
  <div class="p-2">
    <p class="large">
      <span class="fontbold">{{data.lastName}}</span>
      <br />
      {{data.firstName}}
    </p>
  </div>
</ng-template>
<ng-template #RAddressTemplate let-data="data">
  <div style="text-transform: capitalize;">
  <p class="large">
    <span>{{data.address.address1}}</span>
    <br />
    {{data.address.city}},{{data.address.state}}&nbsp;{{data.address.zip1}}
  </p>
  </div>
</ng-template>
<ng-template #RNPITemplate let-data="data">
  <p class="large">{{data.identifiers[0].id}}</p>
</ng-template>


<ng-template #actionPrescriberSearchSelect let-data="data">
  <div class="row">
    <div class="col-8">
      <button class="btn btn-link" style="color: #0470c5;" enableAction="true">Select</button>
    </div>
  </div>
</ng-template>

<ng-template #actionRemove let-data="data">
  <div class="row">
    <div class="col-8">
      <a class="large underline text-danger" enableAction="true">Remove</a>
    </div>
  </div>
</ng-template>

<ng-template #actionSelect let-data="data">
  <div class="row">
    <div class="col-4 p-2">
      <i class="fa fa-angle-right" style="font-size: 32px!important; color: #0470c5;"></i>
    </div>
  </div>
</ng-template>

<!-- Loading.... -->
<ng-template #LoadingContent let-modal>
  <div class="modal-body">
    <h3 class="text-center text-capitalize">Searching...</h3>
  </div>
</ng-template>



<ng-template #errorPopup let-modal>
  <div class="p-3">
    <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
    <div class="text-center mt-2">
     An error occured. Please try again.</div>
    <div class="row border-0 mt-4 p-3 "><button
        class="col-2 position-absolute top-80 start-50 translate-middle btn btn btn-outline-primary"
        (click)="modal.dismiss()">OK</button></div>
  </div>
</ng-template>



<ng-template #claimSummaryProgress let-modal>
  <div style="height:200px" role="dialog" aria-labelledby="claimSummaryProgress">
      <div class="modal-body mt-2">
          <div class="p-3">
              <div *ngIf="this.counter==10" class="row text-center"><i class="fa fa-ban  text-danger fs-1"
                      aria-hidden="true"></i></div>
              <div @slideDown *ngIf="this.counter==10" class="text-center mt-3 fs-6">
                  {{this.trailAdjuducicationError}}</div>
              <div *ngIf="this.counter==10" @slideDown class="modal-footer border-0 mt-5">
                  <button class="btn btn-secondary position-absolute top-80 start-50 translate-middle"
                      (click)="modal.dismiss()">ok</button>
              </div>
              <div *ngIf="this.counter==3" class="circle">
                  <div class="checkMark"></div>
              </div>
              <div *ngIf="this.counter<3" class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
              </div>
              <div @slideDown *ngIf="this.counter==3" class="text-center fs-6" tabindex="-1" aria-modal="true">Check coverage complete</div>

              <div @slideDown *ngIf="this.counter==2" class="text-center fs-6" tabindex="-1" aria-modal="true">Retrieving results...</div>

              <div @slideDown *ngIf="this.counter==1" class="text-center fs-6" tabindex="-1" aria-modal="true">Analyzing information…</div>

          </div>
      </div>

  </div>

</ng-template>

<!-- EPA processing Template -->
<ng-template #paInitiationTemplate let-modal>
  <div class="content-card bg-white" aria-labelledby="paInitiationTemplate">
      <div class="card-content painitiation" *ngIf="epaState<5">
          <div class="text-center">
              <!-- <loader [loaderText]="loaderText" [cssClass]="loaderClass"></loader> -->
              <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i><span style="font-size: 16px;"></span>
          </div>
          <div class="step" [ngClass]="{'active':epaState>0}">
              <i class="fa fa-check" aria-hidden="true"></i>
              <span class="paragraph large inputFieldLabel">Verifying information.</span>
          </div>
          <div class="step" [ngClass]="{'active':epaState>1}">
              <i class="fa fa-check" aria-hidden="true"> </i>
              <span class="paragraph large inputFieldLabel">Submitting PA request form.</span>
          </div>
          <div class="step" [ngClass]="{'active':epaState>2}">
              <i class="fa fa-check" aria-hidden="true"> </i>
              <span class="paragraph large inputFieldLabel">Retrieving PA evaluation questions.</span>
          </div>
          <div class="step" [ngClass]="{'active':epaState>3}">
              <i class="fa fa-check" aria-hidden="true"> </i>
              <span class="paragraph large inputFieldLabel">Questions received.</span>
          </div>
          <div class="painitiationfooter text-center">
              <!-- <span class="paragraph" style="line-height: normal;">This process may take up to 3 minutes.</span> -->
              <span class="paragraph large inputFieldLabel">Thank you and we've received your request. Your
                  question set will
                  typically be returned in less than 1 minute.</span>
          </div>
      </div>
      <div class="card-content text-center timeline" *ngIf="epaState==5">
          <i class="fa fa-check-circle"></i>
          <br />
          <span x-ms-format-detection="none" class="paragraph large inputFieldLabel">
              The questions have been received. You have...
          </span>
          <br />
          <div class="text-center">
              <div class="timerblock">
                  <div class="inputFieldLabel">{{epaHoursRemaining}}</div>
                  <div>Hours</div>
              </div>
              <div class="timerblock">
                  <div class="inputFieldLabel">{{epaMinutesRemaining}}</div>
                  <div>Minutes</div>
              </div>
              <div class="timerblock">
                  <div class="inputFieldLabel">{{epaSecondsRemaining}}</div>
                  <div>Seconds</div>
              </div>
          </div>
          <br />
          <span x-ms-format-detection="none" class="paragraph large inputFieldLabel">
              ...to complete the questions before this request expires.
          </span>
          <br /><br />
      </div>
      <div class="card-footer text-center" *ngIf="epaState==5">
          <button class="btn btn-primary" style="width:40%" (click)="goToEPAQuestions()">View PA questions</button>
      </div>
  </div>
</ng-template>

<!--End EPA processing Template -->


<ng-template #paInitiationFaxFormTemplate let-modal>
  <div class="content-card bg-white" aria-labelledby="paInitiationFaxFormTemplate">
      <div class="card-content text-center">
          <i class="fa fa-ban"></i>
          <br />
          <span x-ms-format-detection="none" class="paragraph large inputFieldLabel"
              [innerHtml]="errorMessage"></span>
          <br /><br />
          <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
          &nbsp;&nbsp;
          <button type="button" class="btn btn-primary rounded-pill" (click)="openfaxforms()">View fax
              forms</button>
      </div>
  </div>
</ng-template>

<ng-template #paInitiationErrorTemplate let-modal>
  <div class="content-card bg-white" aria-labelledby="paInitiationErrorTemplate">
      <div class="card-content text-center">
          <i class="fa fa-ban"></i>
          <br />
          <span x-ms-format-detection="none" class="paragraph large inputFieldLabel"
              [innerHtml]="errorMessage"></span>
          <br /><br />
          <button type="button" class="btn btn-secondary" (click)="modal.dismiss();goToHome()">Ok</button>
      </div>
  </div>
</ng-template>
<ng-template #paInitiationFaxFormTemplate let-modal>
<div class="content-card bg-white" aria-labelledby="paInitiationFaxFormTemplate"> 
<div class="card-content text-center">
  <i class="fa fa-ban"></i>
  <br />
  <span x-ms-format-detection="none" class="paragraph large inputFieldLabel" [innerHtml]="errorMessage"></span>
  <br /><br />
  <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
  &nbsp;&nbsp;
  <button type="button" class="btn btn-primary rounded-pill" (click)="openfaxforms()">View fax forms</button>
</div>
</div>
</ng-template>

