<div class="container-fluid questionnarie">
    <div *ngIf="routedfromnotifications || routedfromSummaryPage " class="row">
        <div class="col-md-8 offset-md-1 col-xs-12">
            <h1>You're Almost There!</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 offset-md-1 col-xs-12 epadetails">
            <!-- <router-outlet></router-outlet> -->
            <epadetails></epadetails>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 offset-md-1 col-xs-12 epadetails">
            <questionset [disabled]="blnquestionnariedisabled" (onUpdate)="autoSave()" (onCompleted)="onQuestionSetCompleted($event)"></questionset>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 offset-md-1 col-xs-12 epadetails">
                <div class="container-fluid nopadding">
                    <div class="row">
                        <div class="col-lg-3 col-sm-4 text-start">
                            <button class="btn btn-link" (click)="cancelPA(false)">Cancel request</button>
                        </div>
                        <div class="col-lg-6 col-sm-4 text-center">
                            <div class="deadline">
                                <div>
                                    <i class="fa fa-clock-o"></i>
                                    {{timeRemainingDesc}}
                                </div>
                                <div class="autosaveprogress" [ngSwitch]="autoSaveStatus">
                                    <ng-container *ngSwitchCase="0">
                                        <i class="fa fa-circle-o-notch fa-spin"></i>
                                        &nbsp;Saving ...
                                    </ng-container>
                                    <ng-container *ngSwitchCase="1">
                                        <i class="fa fa-check"></i>
                                        &nbsp;Progress saved.
                                    </ng-container>
                                    <ng-container *ngSwitchCase="2">
                                        <span class="text-danger autosavefailed">
                                            <i class="fa fa-exclamation-circle"></i>
                                            &nbsp;Failed to save.
                                            <button class="btn text-danger" (click)="autoSave(false)">Try again</button>
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-4 text-end">
                            <button class="btn btn-link" (click)="saveForLater()">Save for later</button>
                            <button class="btn btn-primary btnsubmit" [hidden]="!blnQSEntirelyAnswered" (click)="submitPARequest()">Submit</button>
                        </div>
                    </div>
                </div>

        </div>
    </div>
</div>

<ng-template #paExpiredModal let-modal>
    <div class="content-card paexpired">
        <div class="card-content">
            <div class="text-center">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <br/><br/>
                <p class="large">
                    Your PA request has been cancelled because the submission time has expired.
                    <br/><br/>
                    You must start a new prior authorization request.
                </p>
            </div>
            <div class="text-center">
                <button type="button" class="btn btn-secondary" (click)="goToHome()">Go to homepage</button>
                <button type="button" class="btn btn-primary">Start a new PA</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #paExpiringModal let-modal>
    <div class="content-card paexpiring">
        <div class="card-content">
            <div class="text-center">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <br/><br/>
                <p class="large">
                    Your PA request will expire in {{ deadlineForReply | timeremainingdesc }}. If the request is not submitted by then, it will be cancelled.
                    <br/><br/>
                </p>
            </div>
            <div class="text-center">
                <button type="button" class="btn btn-primary" (click)="modal.dismiss()">OK</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #saveForLaterFailure let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <div *ngIf="errorMessage.length==0">
                <i class="fa fa-exclamation-circle"></i>
                <p class="large">
                    <span>An error occurred.</span>
                    <br/>
                    <span>Progress for Case ID: {{paCaseID}} could not be saved.</span>
                </p>
                <br/>
                <button class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
                &nbsp;&nbsp;
                <button class="btn btn-primary" (click)="modal.dismiss();saveForLater();">Try again</button>
            </div>
            <div *ngIf="errorMessage.length>0">
                <i class="fa fa-exclamation-circle"></i>
                <p class="large">
                    <span>An error occurred. Please call customer service at</span>
                    <br/>
                    <span>{{errorMessage}}.</span>
                </p>
                <br/>
                <button class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
                &nbsp;&nbsp;
                <button class="btn btn-primary" (click)="modal.dismiss();saveForLater();">Try again</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #saveForLaterConfirmation let-modal>
    <div class="content-card text-center saveforlaterconfirmation">
        <div class="card-content"> 
            <p class="large">
                Are you sure you want to save and resume later?
                <br/>
                <br/>
                Case ID: {{paCaseID}}
            </p>
            <p class="large alert alert-warning">
                NOTE: This request will be cancelled after {{timeRemainingDesc}}.
            </p>
            <button class="btn btn-link" (click)="modal.dismiss();">Answer questions</button>
            <button class="btn btn-primary" (click)="modal.dismiss();saveForLater(true)">Save for later</button>
        </div>
    </div>
</ng-template>

<ng-template #cancelPAConfirmation let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <br/><br/>
            <p class="large">
                Are you sure you want to cancel this PA request? Once it is cancelled, you will no longer be able to retrieve it.
                <br/><br/>
                Case ID: {{paCaseID}}
            </p>
            <br/><br/>
            <button class="btn btn-secondary" (click)="modal.dismiss();">No</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" (click)="modal.dismiss();cancelPA(true)">Yes</button>
        </div>
    </div>
</ng-template>
<ng-template #cancelPAProgressModel let-modal>
    <div class="content-card text-center">
        <loader [loaderText]="loaderText"></loader>
        <br/><br/>
        Your cancellation request is being processed.<br/> Please wait up to 45 seconds for a response...
        <br/><br/>
    </div>
</ng-template>
<ng-template #cancelPAInternalErrorModal let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <br/><br/>
            An error occurred.
            <br/><br/>
            Case ID: {{paCaseID}} could not be cancelled.
            <br/><br/>
            Try again or call 1-800-711-4555 for assistance.
            <br/><br/>
            <button class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" (click)="modal.dismiss();cancelPA(true)">Try again</button>
        </div>
    </div>
</ng-template>
<ng-template #cancelPAErrorModal let-modal>
    <div class="content-card text-center">
        <div class="card-content inputFieldLabel">
            <i class="fa fa-exclamation-circle"></i>
            <br/><br/>
            <div *ngIf="errorMessage.length==0">
                An error occurred.
                <br/><br/>
                Case ID: {{paCaseID}} could not be cancelled.
                <br/><br/>
                We are experiencing a technical error. Please contact <br/> OptumRx at 1-800-711-4555 to initiate a new prior <br/> authorization request
            </div>
            <div *ngIf="errorMessage.length>0">
                <span [innerHtml]="errorMessage"></span>
            </div>
            <br/><br/>
            <button class="btn btn-primary" (click)="modal.dismiss(); goToHome()">Go to homepage</button>
        </div>
    </div>
</ng-template>

<ng-template #paGenericErrorTemplate let-modal>
    <div class="content-card bg-white">
        <div class="card-content text-center">
            <i class="fa fa-ban"></i>
            <br/>
            <span x-ms-format-detection="none" class="paragraph large inputFieldLabel" *ngIf="errorMessage.length==0">An error occurred. Please call customer service at<br/>1-866-842-3278.
            </span>
            <span x-ms-format-detection="none" class="paragraph large inputFieldLabel" *ngIf="errorMessage.length>=0" [innerHtml]="errorMessage"></span>
            <br/><br/>
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss();goToHome()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #paSubmissionTemplate let-modal>
    <div class="content-card bg-white text-center">
        <div class="card-content" *ngIf="summarySubmissionStatus<2">
            <!-- <loader [loaderText]="loaderText"></loader> -->
            <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i><span style="font-size: 16px;"></span>
            <br/><br/>
            <div>Your answers are being submitted. This process may take up to 1 minute.</div>
        </div>
        <div class="card-content" *ngIf="summarySubmissionStatus>=2">
            <i class="fa fa-circle" aria-hidden="true">
                <div></div>
                <div></div>
                <div></div>
            </i>
            <br/><br/>
            <p class="large">Your questions have been received.</p>
            <br/>
            <p class="large">OptumRx is reviewing your request.<br/>You will receive a determination in 24 to 72 hours.</p>
        </div>
        <br/>
        <div class="card-footer">
            <button type="button" class="btn btn-primary" [ngClass]="{'disabled':summarySubmissionStatus==1}" (click)="modal.dismiss();goToHome()">
                Return to PCMS homepage
            </button>
        </div>
    </div>
</ng-template>

<ng-template #paStatusTemplate let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i [ngClass]="paStatusIcon"></i>
            <br/><br/>
            <p class="large" [innerHtml]="paStatusText"></p>
            <br/><br/>
            <button class="btn btn-secondary" (click)="modal.dismiss();goToHome()">Ok</button>
        </div>
    </div>
</ng-template>