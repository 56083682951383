<ng-template #memberNameTemplate let-data="data">
    <p class="large">
        <span class="fontbold">{{data.lastName}}</span>
        <br/>
        {{data.firstName}}
    </p>
</ng-template>
<ng-template #drugNameTemplate let-data="data">
    <p class="large" aria-labelledby="drugNameTemplate">{{data.drugName}}</p>
</ng-template>
<ng-template #prescriberNameTemplate let-data="data">
    <p class="large" aria-labelledby="prescriberNameTemplate"><span class="fontbold">{{data.presbrLstName}}</span><br/>{{data.presbrFirstName}}</p>
</ng-template>
<ng-template #caseIdTemplate let-data="data">
    <p class="large" [innerHtml]="data.caseId" aria-labelledby="caseIdTemplate"></p>
</ng-template>
<ng-template #timeRemainingTemplate let-data="data">
    <p class="large" aria-labelledby="timeRemainingTemplate">{{data.deadlineForReply | timeremainingdesc}}</p>
</ng-template>
<ng-template #timeRemainingElapsedTemplate let-data="data">
    <p><span class="alert alert-danger resultsflag" aria-labelledby="timeRemainingElapsedTemplate">Expired</span></p>
</ng-template>
<!--(enableAction)=>this attribute makes html controls inside a cell clickable within data table.
User actions on a given table cell is turned off or on in script by using clickable property in tablecelldata -->
<ng-template #resumePaTemplate let-data="data">
    <a aria-describedby='SavedPA' class="fontbold" enableAction="true" aria-labelledby="resumePaTemplate" tabindex="0">Resume</a>
</ng-template>
<ng-template #resubmitPaTemplate let-data="data">
    <a aria-describedby='SavedPA' class="fontbold" enableAction="true" aria-labelledby="resubmitPaTemplate" tabindex="0">Resubmit</a>
</ng-template>
<div class="submitted_PA_progress text-center" style="margin-top: 30px;"
     *ngIf="blnLoading">
                    <div>
                        <i class="fa fa-circle-o-notch fa-spin"
                            style="color: #ef6c00;font-size: 30px;display: inline-block;vertical-align: middle;"></i>
                        <span style="padding-left: 5px;position: relative;top: 2px">Loading...</span>
                    </div>
                </div>
<ng-container *ngIf="!blnLoading">
    <!--<datatable [headers]="savedPaHeaders" [rows]="savedPaHistory" (onCellClicked)="onSavedPASelected($event)" aria-labelledby="savedPAs">
    </datatable>-->
    <datatable [headers]="savedPaHeaders" [rows]="savedPaHistory"  (onCellClicked)="onSavedPASelected($event)" aria-labelledby="savedPAs">
    </datatable>
    <div class="text-center offset-3" *ngIf="totalEntries>1">
        <pagination aria-label="Saved PA history pagination"  [totalEntries]="totalEntries" [totalPages]="totalPages" (onChangeEntriesPerPage)="onChangeEntriesPerPage($event)" (onPageChange)="onPageChange($event)"></pagination>
        <!-- <pagination [totalPages]="totalPages" (onPageChange)="onPageChange($event)"></pagination> -->
    </div>
    <p class="large noresults" [hidden]="savedPaHistory.length>0">
        There are no saved prior authorizations.
    </p>
</ng-container>
<ng-template #historyServerErrorTemplate let-data="data">
    <p class="large" aria-labelledby="historyServerErrorTemplate">
        <span>We were unable to retrieve this information. Please</span>
        <button class="btn btn-link invisiblebtn" enableAction="true" name="tryagain">Try again</button>
    </p>
</ng-template>

<ng-template #errorPopup let-modal>
 <div class="p-3" aria-modal="true" aria-labelledby="errorPopup" role="dialog">
        <div class="row text-center">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        </div>
    <div class="text-center mt-2">
                An error occured. Please try again.
    </div>
      <div class="row border-0 mt-4 p-3 ">
        <button class="col-2  position-absolute top-80 start-50 translate-middle btn btn btn-primary" (click)="modal.dismiss()">OK</button>
      </div>
</div>
</ng-template>
