import { Component } from '@angular/core';

import { ProfileService } from 'src/app/services/profile/profile.service';
import { Router } from '@angular/router';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {

  constructor(private profileService: ProfileService, private router: Router, private route: ActivatedRoute) {
    profileService.appLogin = APPCONSTANTS.APP_REGISTRATION_LOADED;
    const selfregistration: any = sessionStorage.getItem('selfregistration');
    if (selfregistration != null) {
      const selfregistrationdetails: any = JSON.parse(selfregistration);
      if (typeof selfregistrationdetails.currentpage == 'object') {
        let step = parseInt(selfregistrationdetails.currentpage.status.dbStatusCode);
        if (step == 1) {
          this.router.navigateByUrl('/register/stepone');
        } else {
          if (step == 4)
            this.router.navigateByUrl('/register/stepthree');
          else {
            step = parseInt(selfregistrationdetails.currentpage.rgstStsTypId);
            switch (step) {
              case 1:
                this.router.navigateByUrl('/register/stepone');
                break;
              case 2:
                this.router.navigateByUrl('/register/steptwo');
                break;
              case 3:
                this.router.navigateByUrl('/register/stepthree');
                break;
              case 4:
                sessionStorage.setItem('pinverified','true');
                this.router.navigateByUrl('/register/stepthree');
                break;

            }
          }
        }
      } else {
        this.router.navigateByUrl('/error');
      }
    } else {
      this.router.navigateByUrl('/error');
    }
  }

}
