<div style="width: 100%;">
  <div *ngIf="noAdditionalAddresses==undefined"
    style="padding: 180px 0;text-align: center;border-top: 2px solid #dfdfdf;background-color: rgba(242, 246, 249, 1);">
    <h4><i class="fa fa-circle-o-notch fa-spin" style="color: #ef6c00;font-size: 30px;"></i>&nbsp;Finding additional
      addresses ...</h4>
  </div>
  <div *ngIf="noAdditionalAddresses==true"
    style="padding: 180px 0;text-align: center;border-top: 2px solid #dfdfdf;background-color: rgba(242, 246, 249, 1);">
    <p>
      <span ng-if="notifications.blnnodetails" class="ng-binding ng-scope">There are no additional locations for
        prescriber.</span>
    </p>
  </div>
  <div *ngIf="noAdditionalAddresses==false"
    style="overflow-y: hidden;height:400px;padding-right: 10px;border-top: 2px solid #dfdfdf;background-color: rgba(242, 246, 249, 1);padding: 0px 30px 20px 30px;">
    <div class="row" style="margin: 0;padding: 0;">
      <div class="col-md-12 column nopadding" style="margin-top:20px;">
        <p style="font-size:16px;font-family:'Frutiger-bold'">Select a new preferred address below.</p>
      </div>
      <div class="pagination_border" *ngIf="addresses.length>2">
        <div style="margin-top:5px;margin-bottom:5px;">
          <p style="margin:0px">Showing 
            <span *ngIf="currentpageindex%2==0"> {{currentpageindex-1}} </span>
            <span *ngIf="currentpageindex%2==1"> {{currentpageindex}} </span> 
            - <span>{{currentpageindex}}</span> of 
            <span>{{addresses.length}}</span> locations</p>
        </div>
        <div style="display: flex;">
            <div style="padding-right: 10px !important;">
              <i *ngIf="currentpageindex > minpageindex" (click)="decrpage()" class="fa fa-angle-left pagination_arrow_enabled"  aria-hidden="true"></i>
              <i *ngIf="currentpageindex <= minpageindex" class="fa fa-angle-left pagination_arrow_disabled" aria-hidden="true"></i>
            </div>
            <div style="padding-left: 10px !important;">
              <i *ngIf="currentpageindex < maxpageindex" (click)="incrpage()" class="fa fa-angle-right pagination_arrow_enabled"  aria-hidden="true"></i>
              <i *ngIf="currentpageindex == maxpageindex"class="fa fa-angle-right pagination_arrow_disabled" style="font-size: 35px !important;" aria-hidden="true"></i>
            </div>
        </div>
      </div>
      <div class="col-md-12 column nopadding alternativeaddresses" *ngFor="let item of addresses;index as i">
        <!--condition to print 1)2 addresses and not to print last but second- in last page when  address count is odd -->
        <div *ngIf="(i==currentpageindex-2 && currentpageindex%2!=1)  || i==currentpageindex-1" class="address_style addresslines_borderbox " (click)="selectAddress(i,item)"
          [ngClass]="{'addresslines_borderbox_active':i==selectedAddressIndex}"
          style="margin-top: 20px;display: flex;justify-content: space-between;">
          <div style="display: flex;flex-direction: column;line-height: 21px;">
            <span class="paragraph_small static_text" style="color: #626F7D !important;">Address #{{i+1}}</span>
            <span class="paragraph capitalize">{{item.address.address1}}</span>
            <span class="paragraph capitalize">{{item.address.address2}}</span>
            <span class="paragraph capitalize">{{item.address.city}}, {{item.address.state}}
              {{item.address.zip1}}</span>
          </div>
          <div class="alt_addrs" style="position: relative;display: inline-block; float:right;margin-top:20px;">
            <div>
              <i class="fa fa-phone fax_img_big"></i><span
                class="paragraph ">{{faxFormat1(item.contact.phone1)}}</span><br>
            </div>
            <div style="padding-top: 10px;">
              <i class="fa fa-fax fax_img_big"></i>
              <span class="paragraph" *ngIf="item.contact.fax=='' || item.contact.fax=='0'"> –&nbsp;–&nbsp;– </span>
              <span class="paragraph"
                *ngIf="item.contact.fax!='' && item.contact.fax!='0'">{{faxFormat1(item.contact.fax)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




</div>