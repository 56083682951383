import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { PharmacySearchCriteria } from 'src/app/modals/registration/pharmacyviewmodal';
import { PrescriberSearchCriteria } from 'src/app/modals/registration/prescriberviewmodal';
import { Pharmacy, SteponePharmacyResponsehemi } from 'src/app/modals/registration/SteponePharmacyResponse';
import { PrescriberSearchItem, SteponeNPISearchResponse } from 'src/app/modals/registration/SteponeNPISearchResponse';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { StepthreeInitiateFaxRequest } from 'src/app/modals/registration/StepthreeInitiateFaxRequest';
import { StepthreeInitiateFaxResponse } from 'src/app/modals/registration/StepthreeInitiateFaxResponse';
import { rightFaxPinVerificationRequest } from 'src/app/modals/registration/rightFaxPinVerificationRequest';
import { rightFaxPinVerificationResponse } from 'src/app/modals/registration/rightFaxPinVerificationResponse';
import { SteponeNPISearchRequest } from 'src/app/modals/registration/SteponeNPISearchRequest';
import {  SteponePharmacyRequesthemi } from 'src/app/modals/registration/SteponePharmacyRequest';
import { SteponeInitiationResponse } from 'src/app/modals/registration/SteponeInitiationResponse';
import { SteponeInitiationRequest } from 'src/app/modals/registration/SteponeInitiationRequest';
import { SteponeLinkProvisionResponse } from 'src/app/modals/registration/SteponeLinkProvisionResponse';
import { SteponeLinkProvisionRequest } from 'src/app/modals/registration/SteponeLinkProvisionRequest';
import { SteponePrescriberDetailsResponse } from 'src/app/modals/registration/SteponePrescriberDetailsResponse';
import { SteponePrescriberDetailsRequest } from 'src/app/modals/registration/SteponePrescriberDetailsRequest';
import { SteponePharmacyDetailsResponse } from 'src/app/modals/registration/SteponePharmacyDetailsResponse';
import { SteponePharmacyDetailsRequest } from 'src/app/modals/registration/SteponePharmacyDetailsRequest';
import { cancelRegistrationRequest } from 'src/app/modals/registration/cancelRegistrationRequest';
import { cancelRegistrationResponse } from 'src/app/modals/registration/cancelRegistrationResponse';
import { SteptwoUpdateStatusRequest } from 'src/app/modals/registration/SteptwoUpdateStatusRequest';
import { SteptwoUpdateStatusResponse } from 'src/app/modals/registration/SteptwoUpdateStatusResponse';
import { SteptwoRegisterUserResponse } from 'src/app/modals/registration/SteptwoRegisterUserResponse';
import { SteptwoRegisterUserRequest } from 'src/app/modals/registration/SteptwoRegisterUserRequest';

@Injectable({
  providedIn: 'root'
})
export class registrationService {

  constructor(private http: HttpClient) { }
  url = environment.proxyurl;

  postInitiateFax(webrequest: StepthreeInitiateFaxRequest): Promise<StepthreeInitiateFaxResponse> {
    const promise: Promise<StepthreeInitiateFaxResponse> = new Promise<StepthreeInitiateFaxResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.postInitiateFaxUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postRightFaxPinVerification(webrequest: rightFaxPinVerificationRequest): Promise<rightFaxPinVerificationResponse> {
    const promise: Promise<rightFaxPinVerificationResponse> = new Promise<rightFaxPinVerificationResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.rightFaxPinVerificationUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postPrescriberNPI(webrequest: SteponeNPISearchRequest): Promise<SteponeNPISearchResponse> {
    const promise: Promise<SteponeNPISearchResponse> = new Promise<SteponeNPISearchResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.prescriberSearchUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  /*postPharmacy(webrequest: SteponePharmacyRequest): Promise<SteponePharmacyResponse> {
    const promise: Promise<SteponePharmacyResponse> = new Promise<SteponePharmacyResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getPharmacySearchDetailsUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if (response.response!=undefined &&response.status != undefined && response.pharmacySearchResponse!=undefined && response.pharmacySearchItems!=undefined 
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }*/
  postPharmacyhemi(webrequest: SteponePharmacyRequesthemi): Promise<SteponePharmacyResponsehemi> {
    const promise: Promise<SteponePharmacyResponsehemi> = new Promise<SteponePharmacyResponsehemi>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getPharmacySearchDetailshemiUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if (response.response!=undefined &&response.status != undefined && response.pharmacySearchResponse!=undefined && response.pharmacySearchItems!=undefined 
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postgetRegistrationInitiation(webrequest: SteponeInitiationRequest): Promise<SteponeInitiationResponse> {
    const promise: Promise<SteponeInitiationResponse> = new Promise<SteponeInitiationResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.registrationInitiationUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postLinkProvision(webrequest: SteponeLinkProvisionRequest): Promise<SteponeLinkProvisionResponse> {
    const promise: Promise<SteponeLinkProvisionResponse> = new Promise<SteponeLinkProvisionResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.linkProvisionDetailsUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postPrescriberDetails(webrequest: SteponePrescriberDetailsRequest): Promise<SteponePrescriberDetailsResponse> {
    const promise: Promise<SteponePrescriberDetailsResponse> = new Promise<SteponePrescriberDetailsResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.prescriberDetailUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postPharmacyDetails(webrequest: SteponePharmacyDetailsRequest): Promise<SteponePharmacyDetailsResponse> {
    const promise: Promise<SteponePharmacyDetailsResponse> = new Promise<SteponePharmacyDetailsResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getPharmacyDetailsUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postCancelRegistration(webrequest: cancelRegistrationRequest): Promise<cancelRegistrationResponse> {
    const promise: Promise<cancelRegistrationResponse> = new Promise<cancelRegistrationResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.cancelRegistrationUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }
  postUpdateStatus(webrequest: SteptwoUpdateStatusRequest): Promise<SteptwoUpdateStatusResponse> {
    const promise: Promise<SteptwoUpdateStatusResponse> = new Promise<SteptwoUpdateStatusResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.updateStatusUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postRegisterUser(webrequest: SteptwoRegisterUserRequest): Promise<SteptwoRegisterUserResponse> {
    const promise: Promise<SteptwoRegisterUserResponse> = new Promise<SteptwoRegisterUserResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.registerUserUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }
  
  
  storePrescriberSearchCriteria(prescriber:PrescriberSearchCriteria):void{
    if(prescriber!=undefined){
      sessionStorage.setItem(this.SEARCH_PRESCRIBER_CRITERIA_KEY, JSON.stringify(prescriber));
    }
  }

  storePharmacySearchCriteria(pharmacy:PharmacySearchCriteria):void{
    if(pharmacy!=undefined){
      sessionStorage.setItem(this.SEARCH_PHARMACY_CRITERIA_KEY, JSON.stringify(pharmacy));
    }
  }

  private _SEARCH_PRESCRIBER_CRITERIA_KEY : string="prescribersearchcriteria";
  public get SEARCH_PRESCRIBER_CRITERIA_KEY() : string {
    return this._SEARCH_PRESCRIBER_CRITERIA_KEY;
  }

    private _SEARCH_PHARMACY_CRITERIA_KEY : string="pharmacysearchcriteria";
  public get SEARCH_PHARMACY_CRITERIA_KEY() : string {
    return this._SEARCH_PHARMACY_CRITERIA_KEY;
  }

  storePrescriberResults(results:PrescriberSearchItem[]):void{
    if(Array.isArray(results)){
      sessionStorage.setItem(this.PRESCRIBER_RESULTS,JSON.stringify(results));
    }
  }

  storePharmacyResults(results:Pharmacy[]):void{
    if(Array.isArray(results)){
      sessionStorage.setItem(this.PHARMACY_RESULTS,JSON.stringify(results));
    }
  }

  private _PRESCRIBER_RESULTS : string = "prescribersearchresults";
  public get PRESCRIBER_RESULTS() : string {
    return this._PRESCRIBER_RESULTS;
  }

  private _PHARMACY_RESULTS : string = "pharmacysearchresults";
  public get PHARMACY_RESULTS() : string {
    return this._PHARMACY_RESULTS;
  }


//end brace  
}
