// import { BOOLEAN_FLAG, EPA_STATUS } from 'src/app/constants/appconstant';
import { Patient, QuestionSet, BenefitsCoordination, Prescriber } from 'src/app/modals/epa/questionsetviewmodal';
// import { SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { PARefIDWebResponse } from './priorauthresponse';
import { BOOLEAN_FLAG, EPA_STATUS } from 'src/app/constants/appconstants';
import { SelectedMedicine } from '../drugSearch/drugsearchwebresponse';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';

/********************************PA Initiation Request************************************ */
export class PARequestMedication{
    drugDescription:string="";
    daysSupply:string="";
    drugCoded: PADrugCoded[]=[];
    quantity: PADrugQuantity[]=[];
    productIdQlfr:string="";
}
export class PADrugQuantity{
    value:string="";
    codeListQualifier:string="";
    code:string="";
}
export class PADrugCoded{
    code:string="";
    qualifier:string="";
}
export class PASubmissionWebRequest{
    pAReferenceID:string="";
	benefitsCoordination:BenefitsCoordination[] = [];
	patient = new Patient();
    prescriber = new Prescriber();
    changedPrescriber = new Prescriber();
	medicationPrescribed = new PARequestMedication();
}
export class PAInitiationWebRequest extends PASubmissionWebRequest{
    renewPaFlow:BOOLEAN_FLAG = BOOLEAN_FLAG.NO;
    renewPaCaseId:string="";
}

export class EpaRunnerInitiationEvent{
    claimresponse!:SelectedMedicine;
    paRefId!:PARefIDWebResponse;
    status: EPA_STATUS = EPA_STATUS.PA_NOT_STARTED;
}

/************************Request structure for PA Save For Later ************************/
export class SavedPARequest{
    pAReferenceID:string="";
    benefitsCoordination:BenefitsCoordination[]=new Array<BenefitsCoordination>();
    patient!:Patient;
    prescriber!:Prescriber;
    medicationPrescribed!:SavedPAMedicationPrescribed;
    request!:SavedPAQSRequest;
    isAttachmentExists!:boolean;
    isSaveForLater!:boolean;
    isAutoSave!:boolean;
    saveIndicator!:BOOLEAN_FLAG | string
}
/************************Request structure for PA Save For Later ************************/

export class SavedPAMedicationPrescribed{
    drugDescription:string="";
    drugCoded:SavedPADrugCoded[]=[];
    quantity:SavedPADrugQuantity[]=[];
    daysSupply:string="";
}

export class SavedPADrugCoded{
    code:string="";
    qualifier:string="";
}

export class SavedPADrugQuantity{
    value:string="";
    codeListQualifier:string="";
    code:string="";
}

export class SavedPAQSRequest{
    solicitedModel!:SavedPASolicitedModel;
}

export class SavedPASolicitedModel{
    pacaseID:string="";
    questionSet!:QuestionSet;
}
/***********************PA Question set Pooling******************/
export class EPAPoolWebRequest{
    paReferenceID:string="";
}
export class EPACancellationWebRequest extends EPAPoolWebRequest{
    uuid:string="";
}