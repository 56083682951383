import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BOOLEAN_FLAG, USER_ROLE,  VIEWALLPA_DATERANGE,  VIEWALLPA_DATERANGE_LEBELS,  VIEWALL_DATERANGE, VIEWALL_DATERANGE_LEBELS, VIEWALL_NOTIFICATIONS_STATUS, VIEWALL_NOTIFICATIONS_STATUS_LABELS, VIEWALL_SUBMITTEDPA_STATUS, VIEWALL_SUBMITTEDPA_STATUS_LABELS } from 'src/app/constants/appconstants';
import { DateFieldEvent, DateFieldResultType, DateFieldValidationMode } from 'src/app/modals/datefield/datefield';
import { viewAllNotificationsRequest } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsRequest';
import { NotificationsService } from 'src/app/services/viewAllNotificationsServices/notifications.service';
import { DatefieldComponent } from '../datefield/datefield.component';
import { DatetoDisplayString ,StandardDateToServertime, TimeStampToStandardDate} from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { ProfileService } from 'src/app/services/profile/profile.service';
//import { MemberdatatableComponent } from '../memberdatatable/memberdatatable.component';
import { updateNotificationStatusRequest } from 'src/app/modals/viewallnotificationscalls/updateNotificationStatusRequest';
import { ViewalldatatableComponent } from '../viewalldatatable/viewalldatatable.component';
import { MemberdatatableComponent } from '../memberdatatable/memberdatatable.component';
import { NotificationResponse, viewAllNotificationsResponse } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsResponse';
import { StringToSentenceCase } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { FilterobjectsByTextPipe } from 'src/app/pipes/filterobjects/filterobjects.pipe';
import { MemberSearchV5Item } from 'src/app/modals/membersearch/membersearchwebresponse';
import { Drugsearchwebrequest, MemberSearchItem } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { SavedPAResponse, SavedPAResumeDetails } from 'src/app/modals/epa/priorauthresponse';
import { memberNotificationsRequest } from 'src/app/modals/pahistory/notificationhistorywebrequest';
import { PahistoryService } from 'src/app/services/pahistory/pahistory.service';
import { MemberDetailService } from 'src/app/services/memberdetail/memberdetail.service';
import { Drugsearchwebresponse, MemberNtfyResponse } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { MemberPAHistorywebrequest } from 'src/app/modals/pahistory/pahistorywebrequest';
import { PAResponseHistory } from 'src/app/modals/pahistory/pahistorywebresponse';
import { PAHistorywebresponse } from 'src/app/modals/pahistory/pahistorywebresponse';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { environment } from 'src/environments/environment';
import { memberNtofyResponse } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionsetViewmodal } from 'src/app/modals/epa/questionsetviewmodal';
class NotificationHistory {
  searchText: string = "";

  savedStatus: Map<number, string> = VIEWALL_SUBMITTEDPA_STATUS_LABELS;
  blnStatusChanged: boolean = false;

  private _selectedStatus: number = VIEWALL_SUBMITTEDPA_STATUS.ALL;
  public get selectedStatus(): number {
    return this._selectedStatus;
  }
  public set selectedStatus(v: number) {
    this.blnStatusChanged = true;
    this._selectedStatus = v;
  }


}
class CustomDate {

  blnShowFromDate!: boolean;
  private _fromDateValue: string = "";
  public get fromDateValue(): string {
    return this._fromDateValue;
  }
  public set fromDateValue(v: string) {
    this._fromDateValue = v;
    if ((new Date(v)).toString() != "Invalid Date")
      this.fromDate = new Date(v);
  }
  fromDate: Date = new Date();//Different from fromDateValue. This fromDate is used to bind to history component where fromDateValue is used for validation on datefield.
  fromDateResult: DateFieldResultType = DateFieldResultType.EMPTY;
  fromDateValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONBLUR;
  onFromDateUpdate(event: DateFieldEvent): void {
    this.fromDateValue = event.value;
    this.fromDateResult = event.valid;
  }

  blnCustomDateFormSubmitted: boolean = false;
  dateFormatPlaceHolder: string = "MM/DD/YYYY";


  private _toDateValue: string = "";
  public get toDateValue(): string {
    return this._toDateValue;
  }
  public set toDateValue(v: string) {
    this._toDateValue = v;
    if ((new Date(v)).toString() != "Invalid Date")
      this.toDate = new Date(v);
  }
  toDate: Date = new Date();//Different from toDateValue. This toDate is used to bind to history component where toDateValue is used for validation on datefield.
  toDateResult: DateFieldResultType = DateFieldResultType.EMPTY;
  toDateValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONBLUR;
  onToDateUpdate(event: DateFieldEvent): void {
    this.toDateValue = event.value;
    this.toDateResult = event.valid;
  }

  dateRange: Map<number, string> = VIEWALLPA_DATERANGE_LEBELS;

  blnDateChanged!: boolean;
  private _selectedDate !: number;
  public get selectedDate(): number {
    return this._selectedDate;
  }
  public set selectedDate(v: number) {
    this.blnShowFromDate = false;
    this._selectedDate = v;
    this.blnDateChanged = true;
    if (parseInt(v.toString()) != VIEWALLPA_DATERANGE.CUSTOM_DATE) {
      //Today should represent either 11:59:59PM of today or 12AM of next day - 12AM of next day would be easy to code.
      let today: Date = new Date();
      today.setDate(today.getDate() + 1);
      this.toDate = today;

      this.fromDate = new Date();
      switch (parseInt(v.toString())) {
        case VIEWALLPA_DATERANGE.DAYS_THIRTY:
          this.fromDate.setDate(this.fromDate.getDate() - 30);
          break;
        case VIEWALLPA_DATERANGE.DAYS_SIXTY:
          this.fromDate.setDate(this.fromDate.getDate() - 60);
         break;
          case VIEWALLPA_DATERANGE.DAYS_NINETY:
          this.fromDate.setDate(this.fromDate.getDate() - 90);
          break;
          case VIEWALLPA_DATERANGE.DAYS_ONE_EIGHTY:
          this.fromDate.setDate(this.fromDate.getDate() - 180);
          break;
       /* case VIEWALL_DATERANGE.MONTHS_SIX:
          this.fromDate.setMonth(this.fromDate.getMonth() - 6);
          break;*/
      }

    } else {
      this.fromDateValue = "";
      this.toDateValue = "";
      this.fromDateResult = DateFieldResultType.EMPTY;
      this.toDateResult = DateFieldResultType.EMPTY;
      this.blnCustomDateFormSubmitted = false;
      setTimeout(() => {
        this.updateCustomSelectedDate();
        this.blnShowFromDate = true;
      }, 200);

    }
  }

  updateCustomSelectedDate(): void {
    if (this.fromDateResult == DateFieldResultType.SUCCESS && this.fromDateValue != undefined && this.fromDateValue.length > 0) {
      this.fromDate = new Date(this.fromDateValue);
    }
    if (this.toDateResult == DateFieldResultType.SUCCESS && this.toDateValue != undefined && this.toDateValue.length > 0) {
      this.toDate = new Date(this.toDateValue);
    }
  }
  statuses: Map<number, string> = VIEWALL_NOTIFICATIONS_STATUS_LABELS;
  selectedStatus: number = VIEWALL_NOTIFICATIONS_STATUS.ALL;
  searchText: string = "";

  reload(blnHardReload: boolean = true): void {
    if (blnHardReload) { }
  }

  constructor() {
    this.selectedDate = VIEWALLPA_DATERANGE.DAYS_ONE_EIGHTY;
    this.blnDateChanged = false;
    this.blnShowFromDate = false;
  }
}
@Component({
  selector: 'membernotifications',
  templateUrl: './membernotifications.component.html',
  styleUrls: ['./membernotifications.component.css']
})

export class MembernotificationsComponent implements OnInit {
  resumeDetails?: SavedPAResumeDetails;
  memberSearchResult!: MemberSearchV5Item | MemberSearchItem | undefined;
  drugsearchvalue!:Drugsearchwebrequest|undefined
  private _fromDate!: Date;
  showPopup: boolean = false;
  dontSHowAgain: boolean = true;
  deleteId: string = '';
  showErrorPopup: boolean = false
  @Input() tabledata!: any;
  @Input() action!: any;

  @ViewChild("errorsavedpa", { static: false })
  errorsavedpaErrorElementRef?: ElementRef<HTMLElement>;

  @Output() updateondelete = new EventEmitter<string>();
  @Output() viewPa = new EventEmitter<any>();
  isChecked: boolean = false;
  recentsavedPaID!:number;
  recentsavedPanotificationId!:number;

  public get fromDate(): Date {
    return this._fromDate;
  }
  @Input()
  public set fromDate(v: Date) {
    this._fromDate = v;
  }

  private _toDate!: Date;
  public get toDate(): Date {
    return this._toDate;
  }
  @Input()
  public set toDate(v: Date) {
    this._toDate = v;
  }

  filterByStatus!: VIEWALL_NOTIFICATIONS_STATUS;
  filterByTextArry!: string[];


  @Input()
  memberId!: string;

  blnLoading!: boolean;
  blnServerError!: boolean;
  // sourceData!: NotificationResponse[];
  //filterByStatusArray!: drugSearchResponse[];
  filterByStatusArray!: PAResponseHistory[];
  unabletofetchdata: boolean = false;
  userRole!: string;
  userID!: string;
  useruuid!: string;
  sourceData!: PAResponseHistory[];
  notificationlist: string[] = [];
  notificationCount!: any;
  userGivenFromDate!: string;
  userGivenToDate!: string;
  allNotifications!: any;
  staticNotifications: PAResponseHistory[]=[];
  role!: USER_ROLE;
  dltNtfyType: any = 'delete';
  notifcationFinalText: Array<any> = [];
  notifrespponse :PAResponseHistory[]=[];
  quesArry: any;
  questionArry: any;
  answArry: any;
  attachmentArry: any;
  showDates:boolean=false;

  constructor(private stringToSentenceCase: StringToSentenceCase,private epaservice: EpaprocessorService,private modalService: NgbModal,private memberSearchHelper: MembersearchService, private standardDateToServertime:StandardDateToServertime,private pahistory: PahistoryService,  private router: Router, private notificaionsService: MemberDetailService, private fb: FormBuilder, private dateToDisplayStringConversion: DatetoDisplayString, private profileService: ProfileService, private epaServiceHelper: EpaprocessorService

  ,private timestampTostandardDate: TimeStampToStandardDate) {
    if (this.profileService.loginuser != undefined) {

    }
    let currentDate: Date = new Date();
    //Represent 12AM of next date. Meaning, It considers complete 24h of Today.
    currentDate.setDate(currentDate.getDate() + 1);
    this._toDate = currentDate;

    //Resenting to represent today's date.
    currentDate = new Date();
    //This history will be defaulted to last 189 days from now if no dates are supplied.
    currentDate.setDate(currentDate.getDate() - 179);
    this._fromDate = currentDate;
    this.submittedHistory = new CustomDate();
    this.notificationHistory = new NotificationHistory();
  }


  submittedHistory!: CustomDate;
  notificationHistory!: NotificationHistory;


  @ViewChild("FromDate", { static: false })
  private FromDateComponent!: DatefieldComponent;

  @ViewChild("ToDate", { static: false })
  ToDateComponent!: DatefieldComponent;

 /* @ViewChild(ViewalldatatableComponent, { static: false })
  private submittedHistoryRef!: ViewalldatatableComponent;*/

  @ViewChild(ViewalldatatableComponent, { static: false })
  private membersubmittedmemberHistoryRef!: ViewalldatatableComponent;

  ngOnInit(): void {
    this.memberSearchResult = this.memberSearchHelper.getSelectedMember();
    this.getNotifications();
  }
  onViewPriorAuthclick(data: any) {
    debugger;
    this.viewPa.emit(data);
  }

  getNotifications() {
    //reset error boolean flags
    debugger;
    this.unabletofetchdata = false;
    let webrequest: MemberPAHistorywebrequest = new MemberPAHistorywebrequest();
      webrequest.fromDate = this.dateToDisplayStringConversion.transform(this.fromDate);
      webrequest.toDate = this.dateToDisplayStringConversion.transform(this.toDate);
      webrequest.memberDOB = this.memberSearchResult?.dateOfBirth.indexOf('/')!=-1?this.standardDateToServertime.transform(""+this.memberSearchResult?.dateOfBirth):this.memberSearchResult?.dateOfBirth;
      webrequest.memberFirstName = this.memberSearchResult?.firstName;
      webrequest.memberLastName = this.memberSearchResult?.lastName;
      webrequest.memberId = this.memberSearchResult?.memberId;
      webrequest.instanceId = this.memberSearchResult?.instanceId;
      webrequest.carrierId = this.memberSearchResult?.carrierId;
      webrequest.groupId = this.memberSearchResult?.groupId;
      webrequest.accountId = this.memberSearchResult?.accountId;
      webrequest.resultsFromPAS = true;
      webrequest.providerId = this.profileService.loginuser.prescriber?.uuid;
      //webrequest.providerNPI= this.profileService.loginuser.prescriber?.npi;
      if(this.profileService.loginuser.prescriber?.roleTypeId==20){
        webrequest.adminPresbrID= this.profileService.loginuser.prescriber?.presbrId; 
  }
      
    this.sourceData = [];
    this.pahistory.getMemberSubmittedPAHistory(webrequest).then((data: PAHistorywebresponse) => {
      debugger;
      this.blnLoading = false;
      if (this.memberId != undefined) {
        this.sourceData = data.paResponseHistory.filter((item) => item.memberId == this.memberId);
      } else {
        this.sourceData = data.paResponseHistory;
      }
      for(let i=0;i<this.sourceData.length;i++) {
        debugger;
        let request: memberNotificationsRequest = new memberNotificationsRequest();
        request.memberId = this.sourceData[i].memberId;
        request.presbrId = this.profileService.loginuser?.prescriber?.presbrId;
        request.paStatus = this.sourceData[i].paStatus;
        request.paCaseId = this.sourceData[i].caseId;
        request.drugSearchRequired = "Y";
        request.notificationRequired = "Y";
        request.uuid=this.profileService.loginuser?.prescriber?.uuid;
        request.npi = this.profileService.loginuser?.prescriber?.npi;
        let drugrequest: Drugsearchwebrequest = new Drugsearchwebrequest();
        request.drugSearchRequest = drugrequest;
        drugrequest.ndc = this.sourceData[i].productId;
        drugrequest.lob=''+this.memberSearchResult?.lob;

        drugrequest.instanceId =''+ this.memberSearchResult?.instanceId;
        this.notificaionsService.getMemberNotification(request).then((data: MemberNtfyResponse) => {
          this.sourceData[i].drugName =''+data.drugSearchResponse.response.drugSearchItems[0].detailedDrugName;
          this.sourceData[i].firstName=''+this.memberSearchResult?.firstName.toLowerCase();
          this.sourceData[i].lastName=''+this.memberSearchResult?.lastName.toLowerCase();
          this.sourceData[i].notificationId = data.memberNtfyResponse.notificationId;
          this.sourceData[i].readInd =''+data.memberNtfyResponse.readInd;
          this.sourceData[i].convertedTimestamp = this.timestampTostandardDate.transform(this.sourceData[i].date.toString());
          this.sourceData[i].dateSubmitted = this.timestampTostandardDate.transform(this.sourceData[i].date.toString());
          this.sourceData[i].presbrLstName = this.sourceData[i].prescriberName;
          if(this.sourceData[i].paStatus=='Resolved-Denied'){
            this.sourceData[i].paStatus='Denied';
          }
        }
        );
        
      //this.allNotifications = this.sourceData.length;
      //this.loadHistory();
      }
        this.allNotifications = data.paResponseHistory;
        this.staticNotifications = data.paResponseHistory;
        this.notificationCount = data.paResponseHistory.length;
        console.log(this.notificationCount);
        console.log(this.allNotifications);
 
  

        //to add search text to all notifications 
        for (let i = 0; i < this.staticNotifications.length; i++) {
          if (this.staticNotifications[i].paStatus == 'SavedPA') {
            this.sourceData[i].msg = "Saved PA Request Expiring A saved prior authorization request for " + this.staticNotifications[i].firstName + ' ' + this.staticNotifications[i].lastName + "'s for " + this.staticNotifications[i].drugName + " is expiring soon. Resume"
          }
          else if (this.staticNotifications[i].paStatus == 'Cancelled' || this.staticNotifications[i].paStatus == 'Denied' || this.staticNotifications[i].paStatus == 'Approved') {
            this.sourceData[i].msg = " PA Request " + this.staticNotifications[i].paStatus + " " + this.staticNotifications[i].firstName + ' ' + this.staticNotifications[i].lastName + "'s prior authorization request for " + this.staticNotifications[i].drugName + " has been " + this.staticNotifications[i].paStatus + ". View prior auth details"

          }
        }

        debugger;
        if (this.submittedHistory.selectedStatus == 5) {
          this.allNotifications = undefined;
          this.filterByStatusArray = this.staticNotifications.filter((item) => item.paStatus == "SavedPA");
          if (this.submittedHistory.searchText != '') {
            this.filterByStatusArray = this.filterByStatusArray.filter(item => item.msg.toLowerCase().includes(this.submittedHistory.searchText.toLowerCase()))
          }
          this.allNotifications = this.filterByStatusArray;

        }
        else if (this.submittedHistory.selectedStatus == 4) {
          this.allNotifications = undefined;
          this.filterByStatusArray = this.staticNotifications.filter((item) => item.paStatus == "Cancelled");
          if (this.submittedHistory.searchText != '') {
            this.filterByStatusArray = this.filterByStatusArray.filter(item => item.msg.toLowerCase().includes(this.submittedHistory.searchText.toLowerCase()))
          }
          this.allNotifications = this.filterByStatusArray;

        }
        else if (this.submittedHistory.selectedStatus == 3) {
          this.allNotifications = undefined;
          this.filterByStatusArray = this.staticNotifications.filter((item) => item.paStatus == "Denied"||item.paStatus=="Resolved-Denied");
         if (this.submittedHistory.searchText != '') {
            this.filterByStatusArray = this.filterByStatusArray.filter(item => item.msg.toLowerCase().includes(this.submittedHistory.searchText.toLowerCase()))
          }
          this.allNotifications = this.filterByStatusArray;

        }
        else if (this.submittedHistory.selectedStatus == 2) {
          this.allNotifications = undefined;
          this.filterByStatusArray = this.staticNotifications.filter((item) => item.paStatus == "Approved");
        if (this.submittedHistory.searchText != '') {
            this.filterByStatusArray = this.filterByStatusArray.filter(item => item.msg.toLowerCase().includes(this.submittedHistory.searchText.toLowerCase()))
          }
          this.allNotifications = this.filterByStatusArray;

        }
        else {
          this.filterByStatusArray = this.staticNotifications;
          if (this.submittedHistory.searchText != '') {
            this.filterByStatusArray = this.filterByStatusArray.filter(item => item.msg.toLowerCase().includes(this.submittedHistory.searchText.toLowerCase()))
          }
          this.allNotifications = this.filterByStatusArray;
        }
     

       if(this.submittedHistory.searchText!='')
         this.allNotifications=this.allNotifications.filter((item:any)=>""+item.presbrId==this.profileService.loginuser?.prescriber?.presbrId).includes(this.submittedHistory.searchText);
       // console.log(this.allNotifications);


     /* console.log(this.staticNotifications);
      console.log(this.staticNotifications.length);
      console.log(this.allNotifications);
      console.log(this.allNotifications.length);*/
      
    });
   // });
  }
  textSearch(){
  this.allNotifications=undefined;
       this.allNotifications=this.staticNotifications.filter(item =>item.msg.toLowerCase().includes(this.submittedHistory.searchText.toLowerCase()))               

  }

  staticFilter = this.fb.group({
    dateRange: ['30'],
    status: ['status'],
    search: ['']

  });

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;

  }
  getTodate(): Date {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;

  }
  getDate30DaysBack(): Date {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }
  changeTimeformat(time: string) {
    var year = time.slice(0, 4);
    var month = time.slice(5, 6);
    var day = time.slice(7, 8);
    return month + '/' + day + '/' + year;
  }

  onDeletedNotificationsClick() {
    this.router.navigateByUrl('MemberDetailsViewDeleted');
  }

  onBackClick() {
    this.router.navigateByUrl('MemberDetail');
  }

  removecustomdate(){
    if(this.submittedHistory.selectedDate!=5){
      this.showDates=false;
    }
  }
  onDeleteclick(elementID: string) {

    this.showPopup = true;
    this.deleteId = elementID;
    if (this.dontSHowAgain == false) {
      this.onYesClick();
    }
  }
  resumeSavedPA(notificationId:number) {
    debugger;
    //this.recentsavedPaID=referenceId;
    this.recentsavedPanotificationId
    let uuid = '' + this.profileService.loginuser?.prescriber?.uuid;
    this.epaservice.resumeNtfsSavedPA(this.recentsavedPaID, uuid,notificationId).then((response: SavedPAResponse) => {
      debugger;
      const epaQuestions: QuestionsetViewmodal = this.epaservice.extactQuestionSetModalFromSavedPA(response.paInitiationResponseWeb.body, response.paResumeDetails);
      this.epaservice.storeQuestionSetViewModal(epaQuestions);
      this.router.navigateByUrl("/ePA/ePAquestions");
      sessionStorage.setItem('routedfrom','notifications');
      sessionStorage.setItem('disableanchortag','true');
    }, (error: any) => {
      this.modalService.open(this.errorsavedpaErrorElementRef, { modalDialogClass: 'errorsavedpa', size: 'xs' ,backdrop:'static'});
    }).catch((reason: any) => {
      this.modalService.open(this.errorsavedpaErrorElementRef, { modalDialogClass: 'errorsavedpa', size: 'xs' ,backdrop:'static'});
    });
  }
  rerunsavedPA(){
    debugger;
    this.resumeSavedPA(this.recentsavedPaID)
  }
  onYesClick() {

    var apiparams = new updateNotificationStatusRequest();
    apiparams.notificationId = parseInt(this.deleteId);
    if (this.action == 'permanent') {
      apiparams.action = 13;
    }
    else if (this.action == 'delete') {
      apiparams.action = 11;
    }
    let request:MemberPAHistorywebrequest = new MemberPAHistorywebrequest();
    this.pahistory.getMemberSubmittedPAHistory(request).then((data:PAHistorywebresponse)=>{
    debugger;
    if(data.status.statusCode=='Success'){
    for(let i=0;i<this.sourceData.length;i++){
    let webrequest:memberNotificationsRequest = new memberNotificationsRequest();
    this.notificaionsService.getMemberNotification(webrequest).then((res: MemberNtfyResponse) => {
    debugger;
      if (res.status.statusCode == 'Success') {
        this.updateNotificationRequest();
      }
      else {
        this.showPopup = false
        this.showErrorPopup = true;
      }
    });
}
    }
});
  }
updateNotificationRequest() {

  this.showPopup = false
  this.showErrorPopup = false;
  console.log(this.tabledata);
  var notificationArray: Array<any> = this.tabledata;
  this.tabledata = undefined;
  for (let i = 0; i < notificationArray.length; i++) {
    if (notificationArray[i].notificationId == this.deleteId) {
      notificationArray.splice(i, 1);
      i = i - 1;
    }
  }
  debugger
  console.log(notificationArray);
  this.updateondelete.emit('true');
  this.tabledata = notificationArray
  this.deleteId = '';
}
  
filterHistory(): void { 
  debugger;   
  this.submittedHistory.blnCustomDateFormSubmitted = false;
  if (parseInt(this.submittedHistory.selectedDate.toString()) == VIEWALL_DATERANGE.CUSTOM_DATE) {
    this.submittedHistory.blnCustomDateFormSubmitted = true;
    this.ToDateComponent.onFocusOut();
    this.FromDateComponent.onFocusOut();
    setTimeout(() => {
      if (this.submittedHistory.fromDateResult == DateFieldResultType.SUCCESS
        && this.submittedHistory.toDateResult == DateFieldResultType.SUCCESS) {
        this.reload(this.submittedHistory.blnDateChanged);
        this.submittedHistory.blnDateChanged = false;
      }

    }, 250);
  }
  else {
    if (this.notificationHistory.blnStatusChanged == false) {
      console.log(this.notificationHistory.selectedStatus);
      this.reload(this.notificationHistory.blnStatusChanged);
      this.notificationHistory.blnStatusChanged = false;
    }
    else {
      this.reload(this.notificationHistory.blnStatusChanged);
      this.notificationHistory.blnStatusChanged = true;
    }
  }
}

  onDelete($event: String) {
    if ($event == 'true')
      this.notificationCount = parseInt(this.notificationCount) - 1;
  }
  
  reload(blnHardReload: boolean = true): void {
    if (blnHardReload) {
      this.blnLoading = true;
      this.blnServerError = false;
      this.staticNotifications = [];
      this.notificationCount = undefined;
      this.getNotifications();
    } else {
      this.getNotifications();
    }
  }
  goto($event: String) {
   

    this.router.navigateByUrl("/PriorAuthorizationDetails");
  }

  onViewPAClick($event: PAResponseHistory) {
    var paResponseHistory = new PAResponseHistory;
        paResponseHistory = $event;
   
    if ($event.caseId != undefined) {

      this.epaServiceHelper.resumeCellClickPA("" + $event.caseId).then((response: SavedPAResponse) => {
        console.log(response);

        if (response.paInitiationResponseWeb.body == undefined) {

          this.quesArry = [];
        }
        else {
          this.quesArry = response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet.question;
        }
        //console.log("data",response);
        if (response.paResumeDetails != undefined) {

          this.attachmentArry = response.paResumeDetails.paAttachment;
          //console.log("attachments",this.attachmentArry);
        }
        else {
          this.attachmentArry = [];
        }

        sessionStorage.setItem("pAdetails", JSON.stringify(paResponseHistory));
        //console.log("quesArry",this.quesArry);
        sessionStorage.setItem("Array", JSON.stringify(this.quesArry));
        //sessionStorage.setItem("Modal",JSON.stringify(response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet));
        sessionStorage.setItem("Attachements", JSON.stringify(this.attachmentArry));
        this.router.navigateByUrl("/PriorAuthorizationDetails");

      });
    }
    else {
      sessionStorage.setItem("pAdetails", JSON.stringify(paResponseHistory));
      this.router.navigateByUrl("/PriorAuthorizationDetails");
    }
  }

  //end brace
}




