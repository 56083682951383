<div class="col container-fluid mb-5">
    <div class="col-8 offset-2 timelinetitle row mt-3 d-print-none">
        <div class="col p-0">
            <h1>Prior Authorization Details</h1>
            <p class="mt-2 large">View details about a prior authorization, such as member and drug information,
                answers<br />
                to the prior authorization request evaluation questions and more.
            </p>
        </div>
    </div>
    &nbsp; &nbsp; &nbsp;
    <div class="col-8  offset-2 row d-print-none">
        <div class="col-6 text-start position-relative backToHome">
            <i class="fa fa-angle-left" style="position: relative; width: 2px; height: 20px;top: 5px;"></i>
            <a class="btn btn-link backToHome position-absolute mt-1 fontbold" *ngIf="blnMemberDetailPageSession==true" (click)="goto('/searchMedicationClaims/MemberDetail')" tabindex="0">
                &nbsp;Back to MemberDetail
            </a>
            <a class="btn btn-link backToHome position-absolute mt-1 fontbold" *ngIf="blnMemberviewall==true" (click)="goto('/searchMedicationClaims/ViewAllMemberPAHistory')" tabindex="0">
                &nbsp;Back to view all submitted PA
            </a>
            <a class="btn btn-link backToHome position-absolute mt-1 fontbold"*ngIf="blnMemberDetailPageSession==false && blnMemberviewall==false" (click)="goto('home')" tabindex="0">
                &nbsp;Back to Homepage
            </a>
        </div>
        <div class="col-6 text-end mt-2">
            <i class="fa fa-print" style="color: #0470c5;position: relative; width: 2px; height: 20px;top: 8px;"></i>
            <a class="btn btn-link float-end fontbold" (click)="this.printPage=true;printFun('submissionDetails1')" tabindex="0">
                Print PA details
            </a>
        </div>
    </div>
    <!-- <div id="sectionToprint">
        <div class="row" id="print">
            <div class="img" style="align-content: top-left;">
                <img id="u11482.PNG" class="img" style="padding-top: 10px;" src="./../../../assets/images/u11482.png">

            </div>
        </div>
    </div> -->
    <div class="mt-2 mb-5 shadow col-8 offset-2" id="submissionDetails1">
        <div class="img d-none d-print-block" style="align-content: top-left;" id='logo' hidden>
            <img id="u11482.PNG" class="img" style="padding-top: 10px; height: 50%; width: 50%;" src="../../../assets/images/u11482.png" aria-hidden="true" focusable="false">
        </div>
        <div class="p-3 bg-white">
            <div class="row">
                <div class="col">
                    <h4 class="text-capitalize mt-3 col">Submission details</h4>
                </div>
            </div>
        </div>
        <div class="m-0 p-3 bg-white border-2  border-top row">
            <div class="row">
                <div class="col row">
                    <label class="font p-2">Member name</label>
                    <label class="text-dark" style="text-transform: capitalize !important;">{{this.details.firstName}}&nbsp;{{this.details.lastName}}</label>
                </div>
                <div class="col row">
                    <label class="font p-2">Product</label>
                    <label class="text-dark">{{this.details.drugName}}</label>

                </div>
                <div class="col row">
                    <label class="font p-2">Case ID</label>
                    <label class="text-dark">{{this.details.caseId}}</label>

                </div>
                <div class="col row">
                    <label class="font p-2">Date created</label>
                    <label class="text-dark">{{this.details.dateSubmitted | date: 'MM/dd/YYYY'}}</label>

                </div>

                <div class="col row">
                    <label class="font p-2">Status</label>
                    <span _ngcontent-vyo-c126="" *ngIf="this.details.paStatus == 'Cancelled' || this.details.paStatus == 'Denied' || this.details.paStatus == 'Reversed'">
                    <label class="text-dark" class="alert alert-danger text-capitalize resultsflag">{{this.details.paStatus}}</label></span>
                    <span _ngcontent-vyo-c126="" *ngIf="this.details.paStatus == 'Paid' || this.details.paStatus == 'Approved'">
                    <label class="text-dark" class="alert alert-success text-capitalize resultsflag">{{this.details.paStatus}}</label></span>
                     <span _ngcontent-vyo-c126="" *ngIf="this.details.paStatus == 'Pending'">
                        <label class="text-dark" class="alert alert-warning text-capitalize resultsflag">{{this.details.paStatus}}</label></span>
                </div>
            </div>
        </div>

        <div class="m-0 p-3 border-1 border-top row" style="background-color: rgba(242,246,249,1);">
            <div class="row">
                <div class="col row">
                    <label class="font p-2">Member ID</label>
                    <label class="text-dark">{{this.details.memberId}}</label>
                </div>
                <div class="col row">
                    <label class="font p-2">Prescriber name</label>
                    <label class="text-dark">{{this.details.presbrFirstName}} &nbsp;
                        {{this.details.presbrLstName}}</label>
                </div>
                <div class="col row">
                    <label class="font p-2">PA reference ID</label>
                    <label class="text-dark">{{this.details.completePaRefId}}</label>
                </div>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="row">
                <div class="col row">
                    <label class="font p-2">Response information</label>
                    <label class="text-dark">{{this.details.paNoteText}}</label>
                </div>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div class="row">
                <div class="col row">
                    <label class="font p-2">OptumRx correspondence</label>
                    <label class="text-dark">- - -</label>
                </div>
                <div class="col"></div>
            </div>
        </div>
        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
        <!-- <div class="mt-2 col-8 offset-2 mb-5 shadow"> -->
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="p-3 bg-white">
                <div class="row">
                    <div class="col">
                        <h4 class="mt-3 mb-3 col">Evaluation questions</h4>
                    </div>
                </div>
                <div class="col"></div>
            </div>
        </div>
        <!-- <div class="col-md-12 col-sm-12 col-xs-12">-->
        <!-- <div class="m-0 p-3 bg-white border-2  border-top row"> -->

        <div class="p-3 border-2 border-top  border-bottom bg-white" *ngIf="this.details.paStatus=='Cancelled'">
            <div class="row">
                <div class="col">
                    <label class="text-capitalize mt-3 mb-3 fs-6 col">Unable to display prior authorization questions because
                        the request was cancelled.</label>
                </div>
            </div>
        </div>
        <div class="p-3 border-2 border-top  border-bottom bg-white"  *ngIf="this.details.paStatus=='Pending' || this.details.paStatus=='Denied' || this.details.paStatus=='Approved' ">
            <div class="row" *ngFor="let question of quesArry;let i = index">
                <div class="col">
                    <label class="col row font p-3" style="display: inline;"> {{question.questionText}}</label>
                </div>

                &nbsp;

              <div class="row">
                <div class="col p-3" *ngIf="question.questionType.select!=undefined">
                    <label class="col row">&nbsp;&nbsp; {{question.questionType.select.answer[0].prescriberProvidedAnswer.additionalFreeText}} &nbsp;&nbsp;</label>
                </div>
                <div class="col p-3" *ngIf="question.questionType.date!=undefined">
                    <label class="col row">&nbsp;&nbsp; {{question.questionType.select.answer[0].prescriberProvidedAnswer.additionalFreeText}} &nbsp;&nbsp;</label>
                </div>
                <div class="col p-3" *ngIf="question.questionType.numeric!=undefined">
                    <label class="col row">&nbsp;&nbsp; {{question.questionType.numeric.answer?.prescriberProvidedAnswer}} &nbsp;&nbsp;</label>
                </div>
                <div class="col p-3" *ngIf="question.questionType.freeText!=undefined">
                    <label class="col row">&nbsp;&nbsp; {{question.questionType.freeText.answer?.prescriberProvidedAnswer}} &nbsp;&nbsp;</label>
                </div>
             </div> 
            </div>
            <div class="col">
                <label class="col row font p-2">&nbsp;&nbsp; Attachment &nbsp;&nbsp;</label>
                <div class="col" *ngIf="this.attachmentDetails.fileName!=undefined">
                    <a class="btn btn-link" target="_self" download="{{this.attachmentDetails.fileName}}.{{this.attachmentDetails.fileType}}" (click)="downloadFile()">&nbsp;&nbsp; {{this.attachmentDetails.fileName}}.{{this.attachmentDetails.fileType}} &nbsp;&nbsp;</a>
                </div>
                <div class="col"  *ngIf="this.attachmentDetails.fileName==undefined">
                    <label class="col row">&nbsp;&nbsp; No attachments &nbsp;&nbsp;</label>
                </div>
            </div>
            </div>
        </div>
        <div class="col"></div>

    </div>


    <ng-template #AttachmentConfirmModal let-modal>
        <div class="p-3" aria-labelledby="AttachmentConfirmModal">
            <div class="row text-center"><i class="fa fa-ban" aria-hidden="true"></i></div>
            <div class="text-center mt-2">
               An error occured while downloading the attachment.<br />
               Please try again.
            </div>
            <div class="row border-0 mt-4 p-3 ">
                <div class="col-7 position-absolute top-80 start-50 translate-middle">
                    <button class="col-5 btn btn-secondary" (click)="dismissAll()">Close</button>
                    <button class=" col-5 offset-1 btn btn-secondary"
                        (click)="tryAgain()">Try again</button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #loadingFileConfirmModal let-modal>
        <div class="p-3" aria-labelledby="loadingFileConfirmModal">
            <div class="row text-center">
                <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="text-center mt-2">
               Please wait while we retrieve the attachment.<br/>
               The file will download after it has been retrieved.
            </div>
            <div class="row border-0 mt-4 p-3 ">
                <div class="col-4 ">
                    <button class="col-2 position-absolute top-80 start-50 translate-middle btn btn btn-primary"
                        (click)="dismissAll()">Cancel</button>
                </div>
            </div>
        </div>
    </ng-template>