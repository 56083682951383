<div class="container-fluid" [ngClass]="{'nopadding':!blnProgressBar}">
    <div class="row">
        <div class="col">
            <div class="container-fluid" *ngIf="blnProgressBar">
                <div class="col-10 offset-1 timelinetitle">
                    <h1>Check Prescription Coverage
                    <a class="float-end mt-2 btn invisiblebtn btn-link fontbold" (click)="cancelSearch()">Cancel</a> </h1>
                </div>
                <!-- <div class="float-end">
                    <a class="btn invisiblebtn btn-link">Cancel</a>
                </div> -->
            </div>
            <div class="container-fluid" *ngIf="blnProgressBar">
                <div class="col-10 offset-1 timeline">
                    <!-- Progress bar  -->
                    <app-progress-tabs></app-progress-tabs>
                </div>
            </div>
            <div class="container-fluid" [ngClass]="{'nopadding':!blnProgressBar}">
                <div [ngClass]="{'timelinestepholder':blnProgressBar}">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <!-- <div class="col-12 float-end">
            <a class="col-3 p-5 float-end btn invisiblebtn btn-link">Cancel</a>
        </div> -->
    </div>
</div>

<ng-template #cancelSearchConfirmation let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <br/><br/>
            <p class="large">
                Are you sure you want to cancel this coverage check?
                <br/><br/>
            </p>
            <br/><br/>
            <button class="btn btn-secondary" (click)="modal.dismiss();">No</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" (click)="modal.dismiss();goto('home')">Yes</button>
        </div>
    </div>
</ng-template>