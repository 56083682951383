import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Event,NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PARefIDWebResponse } from 'src/app/modals/epa/priorauthresponse';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-epa',
  templateUrl: './epa.component.html',
  styleUrls: ['./epa.component.css']
})
export class EpaComponent implements OnInit {
  routeVal:string="";
  routeData!:Subscription;
  blnProgressBar:boolean;
  
  @ViewChild("cancelSearchConfirmation")
  cancelSearchConfirmationModal?:ElementRef<HTMLElement>;

  @ViewChild("errorModalTemplate")
  errorModalTemplateRef!:ElementRef<HTMLElement>;
   errorMessage:string="";
   PaREfID:any;
   paReferenceID:any;
   paResponse!: any;
   routedfromnotifications:boolean=false;
   routedfromSummaryPage:boolean=false;
   blnquespage: any = 1; 
  constructor(private router:Router,private modalService: NgbModal,private profileService:ProfileService, private logger: LoggerService,private titleService:Title,private epaProcessor:EpaprocessorService) { 
    this.blnProgressBar=true;
    this.routeData=this.router.events.subscribe((event:Event)=>{
      if(event instanceof NavigationEnd){
        if(event!=undefined && event.urlAfterRedirects!=undefined 
          && event.urlAfterRedirects.toLowerCase().indexOf("summary")!=-1){
            this.blnProgressBar=false;
          } else {
            this.blnProgressBar=true;
          }
      }
      if(sessionStorage.getItem('currentstate')!=undefined)
        this.blnquespage= Number.parseInt(""+sessionStorage.getItem('currentstate'));
      console.log(this.blnquespage);
    });
  }
  
  ngOnDestroy():void{
    if(this.routeData!=undefined)
      this.routeData.unsubscribe();
  }

  ngOnInit(): void {
    debugger;
    //code for notifications page routed content
    if(sessionStorage.getItem('routedfrom')!=undefined){
      this.routedfromnotifications=true;
    }
    else{
      this.routedfromnotifications=false;
    }
     //code for summarpage initiatePA link
     if (sessionStorage.getItem('routedfromsummary') != undefined) {
      this.routedfromSummaryPage = true;
    }
    else {
      this.routedfromSummaryPage = false;
    }
    console.log(this.routedfromSummaryPage);

    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    this.paReferenceID=sessionStorage.getItem("paReferenceID");
    this.PaREfID = sessionStorage.getItem('paRefID');
    console.log(this.PaREfID);
    this.errorMessage = ""+sessionStorage.getItem("errorMsg");
    this.titleService.setTitle("PreCheck MyScript - PA Initiation - Select Member");
    if(this.paReferenceID==undefined){
    this.epaProcessor.generatePaRefId(""+this.profileService.loginuser?.prescriber?.uuid).then((response: PARefIDWebResponse)=>{
     this.paReferenceID = response.paRefID;
      this.PaREfID = response.paRefPrefix+response.paRefID;
      debugger;
      this.paResponse = response;
      console.log(this.paResponse);
      sessionStorage.setItem("paReferenceID",this.paReferenceID);
      sessionStorage.setItem("paRefID",this.PaREfID);
      sessionStorage.setItem("PAResponse",JSON.stringify(this.paResponse));
    }, (error:any)=>{
      this.modalService.open(this.errorModalTemplateRef,{size:'md',backdrop:'static'});
      if(error!=undefined && error.status!=undefined && error.status.statusDesc!=undefined){
        this.errorMessage = error.status.statusDesc;
      } else {
        this.logger.log("Error Occured while Initiating PA.");
        this.errorMessage = "An error occured. Please try again.";
      }
    }).catch((error:any)=>{
      this.logger.error("Error Occured while Initiating PA.");
      this.modalService.open(this.errorModalTemplateRef,{size:'md',backdrop:'static'});
      this.errorMessage = "An error occured. Please try again.";
    });
  }
}
  
  cancelSearch(){
    this.modalService.open(this.cancelSearchConfirmationModal,{size:'md',backdrop:'static'});
  }
  goto(routeUrl:string){
    this.router.navigateByUrl("/"+routeUrl);
  }

}
