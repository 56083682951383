export interface viewAllNotificationsResponse {
    notificationResponse: NotificationResponse[]
    status: Status
  }
  
  export class NotificationResponse {
    referenceId!: number
    referenceType!: string
    presbrId!: number
    firstName!: string
    lastName!: string
    memberId!: string
    drugName!: string
    paStatus!: string
    date!: number
    deadlineForReply?: string
    readInd!: string
    notificationId!: number
    npi!: string
    caseId?: string
    dateSubmitted!: string
    completePaRefId!: string
    presbrFirstName!: string
    presbrLstName!: string
    paNoteText?: string
    msg:string='';
    //added to store the value of timestamp in standard format
    convertedTimestamp!: string 
  }
  
  export interface Status {
    statusCode: string
    dbStatusCode: string
  }