import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-search-fora-member',
  templateUrl: './search-fora-member.component.html',
  styleUrls: ['./search-fora-member.component.css']
})
export class SearchForaMemberComponent {

  pageType:string="SearchMedicationClaims";
  constructor(private router:Router, private logger: LoggerService,private titleService:Title) { 
  }
  goto(routerUrl:string){    
    this.logger.userAction("Goto home clicked.");
    this.router.navigateByUrl("/"+ routerUrl);
  }

}
