export class SteponeNPISearchRequest
{
    lastName !: string;
    firstName !:string;
    prescriberState !:String;
    prescriberCity !:String;
    prescriberId !:String;
    prescriberIdQualifier !:String;
}


