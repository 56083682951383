export class PreferredPharmacyWebrequest {
    carrierId!: string
    accountId!: string
    groupId!: string
    memberId!: string
    fillDateFrom!: string
    fillDateThru!: string
    instanceId!: string
    fillDateInd!: string
  }
  