import { Component } from '@angular/core';

@Component({
  selector: 'app-addressbk',
  templateUrl: './addressbk.component.html',
  styleUrls: ['./addressbk.component.css']
})
export class AddressbkComponent {

}
