import {Status} from 'src/app/modals/webresponsestatus';

import { PatientGender, QuestionSet } from 'src/app/modals/epa/questionsetviewmodal';
// import { DRUG_QUALIFIER, PersonGender } from 'src/app/constants/appconstant';
import { Address } from '../membersearch/membersearchwebresponse';
import { PrescriberIdentifier } from '../prescriberSearch/prescriberSearchwebresponse';
//import { MemberDetailWebResponse } from '../membersearch/memberdetailwebresponse';
import { DRUG_QUALIFIER, PersonGender } from 'src/app/constants/appconstants';
import { MemberDetailWebResponsehemi } from '../membersearch/memberdetailresponsehemi';

export interface EpaInitiationAckWebResponse{
    senderSoftwareProduct:string;
    status:Status;
}
interface EpaResponseNote{
    reasonCode:string;
    paNote:string;
}
export interface EpaCancellationWebResponse extends EpaInitiationAckWebResponse{
    paCancelResponse:EpaResponseNote;
}

/**************************Response structure while pooling DB for question set************************ */
export interface EpaInitiationResponse {
    paInitiationResponseWeb:PAInitiationResponseWeb;
    status:Status;
    attachments:SavedPAAttachment;
}
interface PAInitiationResponseWeb{
    header:PAInitiationHeader;
    body:SavedPABody;

    datatypesVersion:string;
    transportVersion:string;
    transactionDomain:string;
    transactionVersion:string;
    structuresVersion:string;
    eclversion:string;
}
/**************************Response structure while pooling DB for question set************************ */

/***************Response structure while pooling DB for PA response after PA submission****************/

export interface EpaResponse{
    status:Status;
    paResponse:EpaResponseNote;
}

/****************************************Header************************************/
interface SenderSoftware{
    senderSoftwareDeveloper:string;
    senderSoftwareProduct:string;
    senderSoftwareVersionRelease:string;
}

interface Transport{
    value:string;
    qualifier:string;
}

interface PAInitiationHeader{
    to:Transport;
    from:Transport;
    messageID:string;
    relatesToMessageID:string;
    sentTime:string;
    senderSoftware:SenderSoftware;
}
/****************************************Header************************************/

/*****************************Body*********************************/
interface QuestionSetResponse{
    responseStatus:QuestionSetResponseStatus;
}
export interface PAInitiationMedicationPrescribed{
    drugDescription:string;
    drugCoded:DrugCoded;
    quantity:DrugQuantity;
    daysSupply:string;
    diagnosis:any[];
    otherMedicationDate:any[];
    padaysSupplyDosePerDay:any[];
}
interface Prescriber{
    nonVeterinarian:PAInitiationPrescriber;
}
interface Patient{
    humanPatient:PAInitiationPatient
}
interface BenefitsCoordination{
    cardholderID:string;
    responsibilityDate:any[];
    pbmmemberID:string;
}
export interface PAInitiationResponse{
    benefitsCoordination:BenefitsCoordination[];
    patient:Patient;
    prescriber:Prescriber;
    medicationPrescribed:PAInitiationMedicationPrescribed;
    response:QuestionSetResponse;
    pareferenceID:string;
}
/*****************************Body*********************************/
/*****************************Patient***************************/
interface PatientDateOfBirth{
    date:number;
}

interface PatientIdentification{
    patientAccountNumber:string;
}
export interface PAInitiationPatient{
    identification:PatientIdentification;
    name:PersonName;
    gender:PatientGender;
    dateOfBirth:PatientDateOfBirth;
    address:PersonAddress;
}
/*****************************Patient***************************/

/*****************************Medication**********************/
interface QuantityUnitOfMeasure{
    code:string;
}
interface DrugQuantity{
    value:string;
    codeListQualifier:string;
    quantityUnitOfMeasure:QuantityUnitOfMeasure;
}
interface ProductCode{
    qualifier:string;
    code:string;
}
interface DrugCoded{
    productCode: ProductCode;
}
/*****************************Medication**********************/

/****************************Prescriber*************************/
interface PrescriberIdentification{
    npi:string;
    processorIdentificationNumber:string;
}
export interface PAInitiationPrescriber{
    identification:PrescriberIdentification;
    name:PersonName;
    address:PersonAddress;
    communicationNumbers:PAInitiationCommunicationNumbers;
}
/****************************Prescriber*************************/

/*******************************Common**************************************/
interface FaxOrTelephone{
    number:string;
}
export interface PAInitiationCommunicationNumbers{
    primaryTelephone:FaxOrTelephone;
    beeper:any[];
    electronicMail:any[];
    fax:FaxOrTelephone[];
    homeTelephone:FaxOrTelephone[];
    workTelephone:FaxOrTelephone[];
    otherTelephone:FaxOrTelephone[];
}
interface PersonName{
    lastName:string;
    firstName:string;
    middleName:string;
}
interface PersonAddress{
    addressLine1:string;
    city:string;
    stateProvince:string;
    postalCode:string;
    countryCode:string;
}
/*******************************Common**************************************/

/******************************QuestionSet********************/
interface QuestionSetResponseStatus{
    open:QuestionSetState;
}
interface QuestionSetState{
    deadlineForReply:DeadlineForReply;
    questionSet:QuestionSet;
    pacaseID:string;
}
interface DeadlineForReply{
    dateTime:number;
}



/******************************QuestionSet********************/

/**************************Response structure resuming a saved pa************************ */
export interface SavedPAResponse{
    paInitiationResponseWeb:SavedPAResponseWeb;
    status:Status;
    paResumeDetails:SavedPAResumeDetails;
}
interface SavedPAResponseWeb{
    body:SavedPABody;
}
export interface SavedPABody{
    parequest: SavedPARequestBody;
    painitiationResponse:PAInitiationResponse;
}
export interface SavedPARequestBody{
    benefitsCoordination:BenefitsCoordination[];
    patient:Patient;
    prescriber:Prescriber;
    medicationPrescribed: PAInitiationMedicationPrescribed;
    request: SavedPARequest;
    pareferenceID:string;
}
interface SavedPARequest{
    solicitedModel: SolicitedModel;
}
interface SolicitedModel{
    questionSet: QuestionSet;
}
export interface SavedPAResumeDetails{
    deadlineReply:number;
    paReferenceID: PAReferenceID;
    paCaseId:string;
    paAttachment: SavedPAAttachment;
}
export class PAReferenceID{
    paRefID!:string;
    completePaRefId!:string;
}
export class SavedPAAttachment{
    paAttachmentID!:number;
    fileName!:string;
    fileType!:string;
    fileSize!:number;
    isDeleted!:boolean;
    isModified!:boolean;
    isNew!:boolean;
    saveIndicator!:string;
}
/**************************Response structure resuming a saved pa************************ */

export interface PARefIDWebResponse{
    paRefID:string;
    status:Status;
    paRefPrefix:string;
}


/******************Paattchment response**************************** */
export interface PaattchmentResponse {
    attachmentBytes: string;
    status: Status;
}


/********************* resubmit PA response ********************************/
// export interface BenefitsCoordination {
//     cardholderID: string;
//     pBMMemberID: string;
// }

// export interface Prescriber {
//     uuid: string;
//     npi: string;
//     lastName: string;
//     firstName: string;
//     addressLine1: string;
//     city: string;
//     stateProvince: string;
//     postalCode: string;
//     countryCode: string;
//     primaryTelephoneNumber: string;
//     faxNumber: string;
// }

// export interface DrugCoded {
//     code: string;
//     qualifier: string;
// }

export interface Quantity {
    value: string;
    codeListQualifier: string;
    code: string;
}

export interface MedicationPrescribed {
    drugDescription: string;
    drugCoded: DrugCoded[];
    quantity: Quantity[];
    daysSupply: string;
    productIdQlfr: string;
}

export interface InitiationWebRequest {
    pAReferenceID: string;
    benefitsCoordination: BenefitsCoordination[];
    prescriber: PrescriberObject;
    medicationPrescribed: MedicationPrescribed;
}

export class PaReferenceID {
    paRefID!: number;
    status!: Status;
    paRefPrefix!: string;
}
export interface PrescriberObject  extends PrescriberDemographs {
    uuid: string;
    npi: string;
    lastName: string;
    firstName: string;
    addressLine1: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    countryCode: string;
    primaryTelephoneNumber: string;
    faxNumber: string;

    isFavorite: boolean;
    phone1: string;
    network: PrescriberNetwork[];
    additionalFields: PrescriberSearchAdditionalField[];
    primaryDegree:string;
    contact:PrescriberContact;
    alternateAddresses:PrescriberAlternateAddress[];
    selectedAlternativeAddress:number;
    specialty:PrescriberSpeciatly[];
    audit:PrescriberAudit;
}
interface PrescriberSpeciatly{
    code:string;
    name:string;
    audit:PrescriberAudit;
}
interface PrescriberContact{
    phone1:string;
    phone1Ext:string;
    phone2:string;
    phone2Ext:string;
    fax:string;
    faxExt:string;
    email:string;
}
interface PrescriberAudit{
    addUser:string;
    addDate:string;
    addTime:string;
    changeUser:string;
    changeDate:string;
    changeTime:string;
}
export class PrescriberAlternateAddress{
    qualifier!:string;
    address!:PrescriberAddress;
    contact!:PrescriberContact;
    audit!:PrescriberAudit;
}
interface PrescriberSearchAdditionalField {
    fieldDescription: string;
    fieldStringValue: string;
}
interface PrescriberNetwork {
    id: string;
    name: string;
    activeIndicator:string;
    contractPeriod: PrescriberContractPeriod;
}

interface PrescriberContractPeriod {
    fromDate: string;
    thruDate: string;
}




export interface DetailRequest {
    memberDetailResponse:MemberDetailWebResponsehemi;
    memberId: string;
    carrierId: string;
    accountId: string;
    groupId: string;
    instanceId: string;

    activeInd:string;
    firstName:string;
    lastName:string;
    dateOfBirth:string;
    effectiveFromDate:string;
    effectiveThruDate:string;
    planId:string;
    planEffectiveDate:string;
    address:Address;
    lob:string;
    pdl:string;
    relationshipCode:string;
    personCode:string;
    gender:PersonGender;
    isMedD:boolean;
    isFavorite:boolean;
}

export interface AdditionalField {
    fieldDescription: string;
    fieldStringValue: string;
    fieldDecimalValue: string;
    fieldIntegerValue: string;
}

export interface DrugSearchItem {
    prodNameAbbr: string;
    productNameExt: string;
    genericName: string;
    gpi: string;
    productIdQlfr: DRUG_QUALIFIER;
    productId: string;
    strength: string;
    strengthUnitOfMeasure: string;
    dosageFormCode: string;
    dosageFormDesc: string;
    multiSourceCode: string;
    unitDoseUnitUse: string;
    productStatus: string;
    otcCode: string;
    breakableIndicator: string;
    additionalField: AdditionalField[];
    detailedDrugName:string;
}

export interface Response {
    maxRowExceededInd: boolean;
    genericSubstitutionPerformed: boolean;
    drugSearchItems: DrugSearchItem[];
    ndcDrugsList: any[];
}

export interface DrugSearchWebResponse {
    response: Response;
    status: Status;
}

export class ResubmitSavedPA {
    initiationWebRequest!: InitiationWebRequest;
    paReferenceID!: PaReferenceID;
    detailRequest!: DetailRequest;
    status!: Status;
    drugSearchWebResponse!: DrugSearchWebResponse;
}

export interface PrescriberDemographs{
    identifiers: PrescriberIdentifier[];
    lastName: string;
    firstName: string;
    middleInitial: string;
    address: PrescriberAddress;
    instanceId: string;
}

interface PrescriberAddress {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip1: string;
    zip2: string;
    zip3: string;
    country: string;
}