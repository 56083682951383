import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PARefIDWebResponse } from 'src/app/modals/epa/priorauthresponse';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-initia-pa',
  templateUrl: './initia-pa.component.html',
  styleUrls: ['./initia-pa.component.css']
})
export class InitiaPAComponent  implements OnInit{
  pageType:string="InitiatePriorAuthorizationPA";
  blnInitiatePA:boolean=true;
  constructor( private modalService: NgbModal,private router:Router,private profileService:ProfileService, private logger: LoggerService,private titleService:Title,private epaProcessor:EpaprocessorService) { 
    
  }
 


  ngOnInit(): void {
    this.titleService.setTitle("PreCheck MyScript - PA Initiation - Select Member");
    // this.epaProcessor.generatePaRefId(""+this.profileService.loginuser?.prescriber?.uuid).then((response: PARefIDWebResponse)=>{
    //  debugger;
    //   this.PaREfID = response.paRefPrefix+response.paRefID;
    //   console.log(this.PaREfID);
    // }, (error:any)=>{
    //   this.modalService.open(this.errorModalTemplateRef,{size:'md'});
    //   if(error!=undefined && error.status!=undefined && error.status.statusDesc!=undefined){
    //     this.errorMessage = error.status.statusDesc;
    //   } else {
    //     this.logger.log("Error Occured while Initiating PA.");
    //     this.errorMessage = "An error occured. Please try again.";
    //   }
    // }).catch((error:any)=>{
    //   this.logger.error("Error Occured while Initiating PA.");
    //   this.modalService.open(this.errorModalTemplateRef,{size:'md'});
    //   this.errorMessage = "An error occured. Please try again.";
    // });
    // sessionStorage.setItem("PAreferenceID",this.PaREfID);
  }


goto(routerUrl:string){    
  this.logger.userAction("Goto home clicked.");
  this.router.navigateByUrl("/"+ routerUrl);
}

}