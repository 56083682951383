import {OnInit, Component, Input, TemplateRef} from '@angular/core';
import { TableCellData } from 'src/app/modals/datatable/datatable';

@Component({
  selector: '[cell]',
  templateUrl: './tablecell.component.html',
  styleUrls: ['./tablecell.component.css']
})
export class TablecellComponent implements OnInit {

  // @Input()
  // data:TableCellData<any> = new TableCellData<any>();

  // constructor() {
  // }

  // ngOnInit(): void {    
  //   //  console.log(this.data.data);
  // }

  @Input()
  data:TableCellData<any> = new TableCellData<any>();
 
  constructor() {
  }
 
  ngOnInit(): void {    
    //  console.log(this.data.data);
  }
}
