export class DateFieldEvent {    
    value:string="";
    valid:DateFieldResultType=DateFieldResultType.SUCCESS;
    validationMode?:DateFieldValidationMode;
}

export enum DateFieldValidationMode{
    ONBLUR=0,
    ONCHANGE=1
}

export enum DateFieldResultType{
    SUCCESS=0,
    INVALID_CHAR=1,
    INVALID_DATE=2,
    INVALID_DAY_IN_MONTH=3,
    FUTURE_DATE_NOT_ALLOWED=4,
    FUTURE_DATE_BEYOND_GIVENDAYS=5,
    ERROR=6,
    EMPTY=7,
    FROM_DATE_INVALID=8,
    FUTURE_DATE_BEYOND_GIVENMONTHS=9,
    FROM_MONTHS_ALLOWED=10
    
}