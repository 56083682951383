<div style="position: relative" *ngIf="addressChangeID!=item.presbrId"
    [ngClass]="{'small_box_deactivated':item.presbrStatus==deactPresc,'small_box':item.presbrStatus==addedPresc || item.presbrStatus==expPendPresc || item.presbrStatus==deniedPersc}">
    <div  class="padding_big"
    [ngClass]="{'padding_small':item.addressLine2!='' && item.presbrStatus==deactPresc}">
        <div class="defaultprovideralign">
            <div class="heading_new fontbold heading_new capitalize_text"
                [ngClass]="{'added_display':item.presbrStatus==addedPresc ,'deact_display':item.presbrStatus==deactPresc || item.presbrStatus==expPendPresc || item.presbrStatus==deniedPersc}">
                {{item.firstName}}&nbsp;{{item.lastName}}
            </div>
            <div class="defaultproviderStyle" *ngIf="item.presbrStatus==addedPresc">
                <button class="btn notfavorite" aria-label="Make your default medication" *ngIf="item.defaultInd=='N'"
                    (click)="changeDefaultPrescriber()"></button>
                <button class="btn favorite" aria-label="Make your default medication" *ngIf="item.defaultInd=='Y'"
                    (click)="changeDefaultPrescriber()"></button>
            </div>
        </div>

        <span style="color: #424249;">NPI:
            {{item.npi}}</span>
        <br>
        <div style="margin-top: 10px;" *ngIf="item.presbrStatus==addedPresc || item.presbrStatus==deactPresc">
            <div class="address_style">
                <div class="paragraph_small static_text">Preferred Address</div>
                <div class="paragraph_small capitalize_text">{{item.addressLine1}}</div>
                <div class="paragraph_small capitalize_text">{{item.addressLine2}}</div>
                <div class="paragraph_small capitalize_text">{{item.city}}, {{item.stateProvince}}
                    {{item.postalCode}}</div>
            </div>
            <div class="address_style"
                [ngClass]="{'margin_variance_a':item.presbrStatus==addedPresc,'margin_variance_d':item.presbrStatus==deactPresc}">
                <i class="fa fa-phone fax_imgs"></i>
                <span class="paragraph_small">{{faxFormat1(item.primaryTelephoneNumber)}}</span>
                <br>
                <i class="fa fa-fax fax_imgs"></i>
                <span class="paragraph_small" *ngIf="item.faxNumber=='' || item.faxNumber=='0'"> –&nbsp;–&nbsp;– </span>
                <span class="paragraph_small"
                    *ngIf="item.faxNumber!='' && item.faxNumber!='0'">{{faxFormat1(item.faxNumber)}}</span>

            </div>
        </div>
    </div>
    <div *ngIf="item.presbrStatus==addedPresc">
        <span class="remove_prsbr" *ngIf="item.defaultInd=='N'" (click)="removeAddedPresc()">Remove</span>
        <button type="button" class="btn btn-secondary view_details change_address_btn" (click)="changeAddress()">
            <span>Change address</span>
        </button>
    </div>
    <div class="banner bannerbrown bannersmall bannerborderbrown"
        *ngIf="item.presbrStatus==deactPresc || (item.presbrStatus==expPendPresc && item.daysLeft>0)">
        <div class="bannercontent">
            <i class="fa fa-circle pendingicon" aria-hidden="true">
                <div class="pendingiconbox" style="left: 6px;"></div>
                <div class="pendingiconbox" style="left: 14px;"></div>
                <div class="pendingiconbox" style="left: 22px;"></div>
            </i>
            <span class="nowrap" *ngIf="item.presbrStatus==deactPresc" style="padding-left: 8px;">Prescriber NPI needs
                reactivation.</span>
            <span *ngIf="item.presbrStatus==expPendPresc && item.daysLeft>0" class="bannertexbrown"
                style="padding-left: 8px;">
                Request is pending the prescriber's approval.
            </span>
        </div>
    </div>

    <div class="banner bannertextred bannerborderred" *ngIf="item.presbrStatus==expPendPresc && item.daysLeft<=0">
        <div class="bannercontent bannercontentexpired ">
            <i class="fa fa-exclamation-circle expiredicon" aria-hidden="true"></i>
            <div style="display: flex;flex-direction: column;padding-left: 12px;text-align: left;">
                <div>This request has expired.</div>
                <div class="requesttext" (click)="onRequestNewPIN()">Request a new PIN.</div>
            </div>

        </div>
    </div>
    <div class="banner bannersmall bannertextred bannerborderred" *ngIf="item.presbrStatus==deniedPersc">
        <div class="bannercontent">
            <i class="fa fa-exclamation-circle expiredicon" aria-hidden="true"></i>
            <div class="nowrap" style="padding-left: 12px;">This request has been denied.</div>
        </div>
    </div>

    <div class="bodytext" *ngIf="item.presbrStatus==expPendPresc && item.daysLeft>0">
        <div class="bodycontent">
            <div style="color: #424249;line-height: 21px;margin: 0px;">Verification PIN sent to
                <span>{{faxFormat2(item.faxNumber)}}</span>. Request will expire
                <span *ngIf="item.daysLeft>=2"> in {{item.daysLeft}} days.</span>
                <span *ngIf="item.daysLeft<2"> today.</span>
            </div>
            <div class="resendtext" (click)="onResendNewPIN()">Resend Request</div>
        </div>
    </div>
    <div style="padding:20px 15px 10px 30px;" *ngIf="item.presbrStatus==deniedPersc">
        <span>Please contact</span>
        <span class="changestyle capitalize_text boldtext"> {{item.firstName}}&nbsp;{{item.lastName}} </span><br>
        <span> at </span>
        <span class="changestyle boldtext"> {{faxFormat2(item.faxNumber)}} </span>
        <span> for information regarding the request denial. </span>
    </div>

    <button *ngIf="item.presbrStatus==deactPresc" class="btn btn-secondary boldtext" (click)="reactivatePrescriber()"
        style="width: 80%;margin: 15px 30px;">Reactivate</button>

    <button *ngIf="item.presbrStatus==expPendPresc" class="btn btn-secondary boldtext" (click)="onCancelRequestClick()"
    [ngClass]="{'expired_cancel_m':item.presbrStatus==expPendPresc && item.daysLeft<=0,'pending_cancel_m':item.presbrStatus==expPendPresc && item.daysLeft>0}"
        style="width: 80%;">Cancel request</button>

    <button *ngIf="item.presbrStatus==deniedPersc" class="btn btn-danger remove_btn "
        (click)="onCancelRequestClick()"><span>Remove</span></button>
</div>

<div class="fadeout-widget" style="width:100%;" *ngIf="addressChangeID==item.presbrId">
    <div class="big_box">
        <div style="padding: 30px 15px 20px 25px">
            <div class="defaultprovideralign" style="justify-content: space-around;">
                <div class="heading_new fontbold heading_new capitalize_text added_display"
                    style="padding-bottom: 5px;">
                    {{item.firstName}}&nbsp;{{item.lastName}}
                </div>
                <div style="display: flex;">
                    <span class="paragraph" style="padding-right: 5px;white-space: nowrap;align-content: end;">Preferred Prescriber</span>
                    <span class="defaultproviderStyle" *ngIf="item.presbrStatus==addedPresc">
                        <button class="btn notfavorite" aria-label="Make your default medication"
                            *ngIf="item.defaultInd=='N'" (click)="changeDefaultPrescriber()"></button>
                        <button class="btn favorite" aria-label="Make your default medication"
                            *ngIf="item.defaultInd=='Y'" (click)="changeDefaultPrescriber()"></button>
                    </span>
                </div>

            </div>

            <span class="paragraph_small1 ">NPI:
                {{item.npi}}</span>
            <br>
            <div style="display: flex;justify-content: space-between;">
                <div style="margin-top: 15px; " *ngIf="item.presbrStatus==addedPresc || item.presbrStatus==deactPresc">
                    <div class="address_style">
                        <div class="paragraph_small static_text" style="padding-bottom: 2px;">Preferred Address</div>
                        <div class="paragraph_small capitalize_text" style="font-size: 16px;padding-bottom: 2px;">
                            {{item.addressLine1}}</div>
                        <div class="paragraph_small capitalize_text" style="font-size: 16px;padding-bottom: 2px;">
                            {{item.addressLine2}}</div>
                        <div class="paragraph_small capitalize_text" style="font-size: 16px;padding-bottom: 2px;">
                            {{item.city}}, {{item.stateProvince}}
                            {{item.postalCode}}</div>
                    </div>
                </div>
                <div class="alt_addrs" style="position: relative;display: inline-block; float:right;margin-top:20px;">
                    <i class="fa fa-phone fax_img_big"></i><span
                        class="paragraph ng-binding">{{faxFormat1(item.primaryTelephoneNumber)}}</span><br>
                    <i class="fa fa-fax fax_img_big"></i>
                    <span class="paragraph" *ngIf="item.faxNumber=='' || item.faxNumber=='0'" style="line-height: 2.6;">
                        –&nbsp;–&nbsp;– </span>
                    <span class="paragraph" *ngIf="item.faxNumber!='' && item.faxNumber!='0'"
                        style="line-height: 2.6;">{{faxFormat1(item.faxNumber)}}</span>
                </div>
            </div>

        </div>
        <div>
            <presbr-change-address [prescID]="item.npi" [instanceID]="item.instanceId"
            (onSelectNewaddress)="storeNewAddress($event)"></presbr-change-address>
        </div>
        <span class="remove_prsbr" (click)="removeAddedPresc()" *ngIf="item.defaultInd=='N'">Remove</span>
        <span class="cancel_prsbr" (click)="onResetTile()">Cancel</span>
        <div>
            <button type="button" class="btn btn-secondary view_details change_address_btn" (click)="onSelectnewAddress()">
                <span>Save</span>
            </button>
        </div>


    </div>
</div>