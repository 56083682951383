export class PAHistorywebrequest {
    fromDate?: string;
    toDate?: string;
    maxResults?: number;
    adminPresbrID!: String|undefined;
    providerId?: string;
    npi?: string;
    providerNPI?: String|undefined;
}
/*export class PAHistorywebrequest {
    fromDate?: string;
    toDate?: string;
    maxResults?: number;
    providerNPI?: string;
    providerId?: string;
}*/
export class MemberPAHistorywebrequest {
    fromDate!: String;
    toDate!: String;
    maxDays!: number;
    isCustomDateSelected!: boolean;
    providerId!: String |undefined;
    instanceId!: String |undefined;
    carrierId!: String|undefined;
    groupId!: String|undefined;
    accountId!: String|undefined;
    adminPresbrID!:String |undefined; 
    memberId!: String|undefined;
    memberDOB!: String|undefined;
    memberLastName!: String|undefined;
    memberFirstName!: String|undefined;
    resultsFromPAS!: boolean;
    maxResults!: number;
    providerNPI!: String|undefined;
}