import { Component, OnInit, ɵɵstylePropInterpolate1, ViewChild, OnChanges, SimpleChanges, Input, EventEmitter, Output, ElementRef } from '@angular/core';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { PAHistorywebresponse, PAResponseHistory } from 'src/app/modals/pahistory/pahistorywebresponse';
import { ServertimeToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { Router } from '@angular/router';
import { Question, QuestionSet, QuestionsetViewmodal, UserGivenAnswer, AnswerValue, LoadNextQuestionEvent, BooleanCode, QuestionChoice, QuestionType, QuestionAttachment } from 'src/app/modals/epa/questionsetviewmodal';
import { SubmittedpahistoryComponent } from '../submittedpahistory/submittedpahistory.component';
import { TableClickEvent } from 'src/app/modals/datatable/datatable';
import { end } from '@popperjs/core';
import { SavedPAAttachment, PaattchmentResponse } from 'src/app/modals/epa/priorauthresponse';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { Title } from '@angular/platform-browser';
import { ViewAllRouteState, PAGE_TYPE } from 'src/app/constants/appconstants';
//import { msSaveBlob } from '/resources/app/extensions/node_modules/typescript\lib\lib.dom';



@Component({
  selector: 'app-pa-details',
  templateUrl: './pa-details.component.html',
  styleUrls: ['./pa-details.component.css']
})
export class PaDetailsComponent implements OnInit {

  private questionsetviewmodal!:QuestionsetViewmodal;
  private lastQuestionID!:string;
  blnMemberDetailPageSession:boolean = false;
  blnMemberviewall:boolean = false;

  @Input()
  disabled!:boolean;

  @Output()
  onUpdate:EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onCompleted:EventEmitter<boolean> = new EventEmitter<boolean>();

  blnShow!:boolean;
  questionsLoaded!:Question[];
  answeredQuestionIDs!:string[];

  @ViewChild("AttachmentConfirmModal")
  AttachmentConfirmModal!: ElementRef<HTMLElement>;

  @ViewChild("loadingFileConfirmModal")
  loadingFileConfirmModal!: ElementRef<HTMLElement>;



  constructor(private titleService:Title,private modalService: NgbModal,private epaServices:EpaprocessorService,private router:Router,private epaServiceHelper:EpaprocessorService, private serverTimetoStandardTime:ServertimeToStandardDate, private logger: LoggerService) {
    
  this.titleService.setTitle(" Prior Authorization Details");
   }
  
  details:any;
  newDate!:string;
  printPage:boolean=false;
  responsePendingPaDetails:Question[]=[];
  attachmentDetails:any=[];
  
  ngOnInit(): void {
      debugger;
      if(sessionStorage.getItem("lastPageBeforeReload")?.indexOf("home")!=-1){
        this.blnMemberDetailPageSession=false;
      }
      if(sessionStorage.getItem("lastPageBeforeReload")?.indexOf("ViewAllMemberPAHistory")!=-1){
      this.blnMemberviewall=true;
      }
      if(sessionStorage.getItem("lastPageBeforeReload")?.indexOf("MemberDetail")!=-1){
        this.blnMemberDetailPageSession==true;
      }
      sessionStorage.setItem("lastPageBeforeReload", this.router.url);
      
     this.responsePendingPaDetails = JSON.parse(""+sessionStorage.getItem("Array"));
     this.attachmentDetails = JSON.parse(""+sessionStorage.getItem("Attachements"));
     Question
     this.questionsetviewmodal = new QuestionsetViewmodal();
     this.questionsetviewmodal.questions=this.responsePendingPaDetails;
     
     this.details = JSON.parse(""+sessionStorage.getItem("pAdetails"));
     //console.log("details",this.details);
     //console.log("attachemnts",this.attachmentDetails);
     this.newDate = this.serverTimetoStandardTime.transform(this.details.date);
     //console.log(this.newDate);  
     
     //console.log("res",this.responsePendingPaDetails);

     this.quesArry.push(this.responsePendingPaDetails[0]);
     let nextQuestionId='0';
      for(let i=0;i<this.responsePendingPaDetails.length;i++){
        
        
        if(this.getNextQuestionID(Number.parseInt(nextQuestionId))!="END"){
          nextQuestionId=this.getNextQuestionID(Number.parseInt(nextQuestionId));
          //console.log("NextQuestID:"+nextQuestionId);
          nextQuestionId=""+this.responsePendingPaDetails.findIndex((question:Question)=>question.questionID==nextQuestionId);
          //console.log("Index:"+nextQuestionId);
          this.quesArry.push(this.responsePendingPaDetails[Number.parseInt(nextQuestionId)]);
        }

        if(this.responsePendingPaDetails[i].questionType.attachment!=undefined && this.responsePendingPaDetails[i].questionType.attachment.fileName!=undefined){
          this.quesArry.push(this.responsePendingPaDetails[i]);
        }
      }


      //console.log("ArrayVal:");
      //console.log(this.quesArry);

  }

  questionArry:any;
  answArry:any;
  quesArry:any = [];
  answerArray:any = [];


  private getNextQuestionID(currentQuestionIndex:number):string{
    
    const currentQuestion:Question = this.questionsetviewmodal.questions[currentQuestionIndex];
    //When current question is select type of question.
    if(currentQuestion.questionType.select!=undefined && currentQuestion.questionType.select.answer!=undefined
      && Array.isArray(currentQuestion.questionType.select.answer) && currentQuestion.questionType.select.answer.length>0){
        if(currentQuestion.questionType.select.selectMultiple == BooleanCode.No){
          if(currentQuestion.questionType.select.answer[0].prescriberProvidedAnswer!=undefined
            && currentQuestion.questionType.select.answer[0].prescriberProvidedAnswer.choiceID != undefined){
              const answeredChoiceIndex:number = currentQuestion.questionType.select.choice.findIndex((item:QuestionChoice)=>{
                if(Array.isArray(currentQuestion.questionType.select.answer) && currentQuestion.questionType.select.answer.length>0)
                  return item.choiceID == currentQuestion.questionType.select.answer[0].prescriberProvidedAnswer.choiceID;
                else
                  return false;
              });
              if(answeredChoiceIndex!=-1){
                return currentQuestion.questionType.select.choice[answeredChoiceIndex].nextQuestionID;
              }
            }
        } else {
          return currentQuestion.defaultNextQuestionID;
        }
    } else {
      if(currentQuestion.questionType.freeText!=undefined && currentQuestion.questionType.freeText.answer!=undefined
        && currentQuestion.questionType.freeText.answer.prescriberProvidedAnswer!=undefined
        && currentQuestion.questionType.freeText.answer.prescriberProvidedAnswer.length>0
        && currentQuestion.defaultNextQuestionID!=undefined && currentQuestion.defaultNextQuestionID.length>0){
          return currentQuestion.defaultNextQuestionID;
      }
    }
    return "";
  }
  goto(routerUrl:string){
    debugger;
    if(routerUrl.indexOf("ViewAllMemberPAHistory")!=-1){
      const submittedState: ViewAllRouteState = { pageType: PAGE_TYPE.SUBMITTEDPAS};
      this.logger.userAction("Back to Submitted Prior Authorizations clicked");
      this.router.navigateByUrl("/"+ routerUrl,{ state: submittedState });
    }else{
      this.logger.userAction("Back to "+routerUrl);
      this.router.navigateByUrl("/"+ routerUrl);
    }
  }

  tryAgain(){

    window.location.reload();
    this.router.navigateByUrl("/PriorAuthorizationDetails");
  }

  dismissAll() {
    this.modalService.dismissAll();
  }

  // download file functionality
  downloadFile(){
    
    this.logger.userAction("Download File clicked");
    this.modalService.open(this.loadingFileConfirmModal, { modalDialogClass: 'loadingFileConfirmModal' ,backdrop:'static'});
    this.epaServiceHelper.downloadFile(this.attachmentDetails.paAttachmentID).then((data:PaattchmentResponse)=>{
    if(data.status.statusCode.toLowerCase()!="success"){
      this.modalService.dismissAll();
      this.modalService.open(this.AttachmentConfirmModal, { modalDialogClass: 'AttachmentConfirmModal' ,backdrop:'static'});
    }
    else{
      if(data.status.dbStatusCode == "0"){
        var b64String = data.attachmentBytes;
        var contentType = this.getContentTypeOfFile(this.attachmentDetails.fileType);
        var blob = this.b64toBlob(b64String,contentType);
        var completeFileName = this.attachmentDetails.fileName+"."+this.attachmentDetails.fileType;
        //Download
            var objectUrl = URL.createObjectURL(blob);
            var a         = document.createElement('a');
                a.href        = objectUrl; 
                a.target      = '_blank';
                a.download    = completeFileName;
                document.body.appendChild(a);
                this.modalService.dismissAll();
                a.click();
        
    }
  }
    });

  }

  getContentTypeOfFile(filetype:string)
  {
    
    if(filetype.toLowerCase() == 'pdf')
      return "application/"+filetype;
    else
      return "image/"+filetype;
  }  
  b64toBlob(base64Data:string, contentType:string) {
    
     var byteCharacters:Uint8Array | string;
     var blobData:BlobPart[] | undefined;
     var blob:Blob;
     var byteNumbers:any;

     byteCharacters = atob(base64Data);
     byteNumbers = new Array(byteCharacters.length);
     for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
     blobData = this.getBlobDataAtOnce(byteCharacters,byteNumbers);
     blob = new Blob(blobData, { type: contentType });

     return blob;
  }
     
     getBlobDataAtOnce(bytechar:Uint8Array | string,byteNum:any): BlobPart[]|undefined {
       
        var byteArray: Uint8Array,
        byteArray = new Uint8Array(byteNum);

         return [byteArray];

        
    }
 
// Print functionality
printMode=false;
  printFun(divName2:string){
    this.printMode=true;
    this.logger.userAction("Printing PA Details.")
    const printContents = document.getElementById(divName2)?.innerHTML;  
    window.print();
        this.printMode=false;
  }
}
