import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { DataTableType, TableCellData, TableClickEvent, TableHeaderCellData, TableRowData } from 'src/app/modals/datatable/datatable';
import { FavoriteDrugsDetailResponse } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { MedDmemberRequest, SearchInputMetaData } from 'src/app/modals/membersearch/medDmemberwebrequest';
import { MedDmemberResponse } from 'src/app/modals/membersearch/medDmemberwebresponse';
import { Membersearchwebrequest } from 'src/app/modals/membersearch/membersearchwebrequest';
import { FavouriteMember, MemberSearchV5Item, Membersearchwebresponse } from 'src/app/modals/membersearch/membersearchwebresponse';
import { PreferredPharmacyWebrequest } from 'src/app/modals/preferredpharmacy/preferredpharmacywebrequest';
import { Status } from 'src/app/modals/webresponsestatus';
import { StandardDateToServertime } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { DrugsearchService } from 'src/app/services/drugsearch/drugsearch.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { environment } from 'src/environments/environment';
import { FavouritemembersComponent } from '../favouritemembers/favouritemembers.component';


enum MembersFilterMode{
  ACTIVE=1,
  INACTIVE=2,
  ALL=3
}

@Component({
  selector: 'memberresult',
  templateUrl: './memberresult.component.html',
  styleUrls: ['./memberresult.component.css']
})
export class MemberresultComponent implements OnInit {

  @ViewChild("favouriteErrorTemplate")
  favouriteErrorTemplateRef?: TemplateRef<HTMLElement>;
  blnFavouriteActionError:boolean=false;

  private currentPageNumber!:number;
  recordsPerPage:number = environment.paginationRecordsPerPage;
  totalPagesCount!:number;

  private _members !: MemberSearchV5Item[];
  public get members() : MemberSearchV5Item[] {
    return this._members;
  }
  @Input()
  public set members(v : MemberSearchV5Item[]) {
    this._members = v;
    this.firstHeader.sortAscending=true;
    this.currentPageNumber=1;
    
    if(this.members[0].isMedD==undefined){
      this.checkMedDMember();
    }
  }
  
  private _filterStatus : MembersFilterMode=MembersFilterMode.ACTIVE;
  public get filterStatus() : MembersFilterMode {
    return this._filterStatus;
  }
  public set filterStatus(v : MembersFilterMode) {
    this._filterStatus = v;
    this.currentPageNumber=1;
    this.generateMemberResults();
  }
  
  headers!:TableHeaderCellData[];
  firstHeader!:TableHeaderCellData;
  secondHeader!:TableHeaderCellData;
  thirdHeader!:TableHeaderCellData;
  forthHeader!:TableHeaderCellData;
  fifthHeader!:TableHeaderCellData;
  sixthHeader!:TableHeaderCellData;
  seventhHeader!:TableHeaderCellData;

  filteredMembers!:MemberSearchV5Item[];
  results!:TableRowData<MemberSearchV5Item>[];
  activeMembersCount!:number;
  inactiveMembersCount!:number;
  MedActiveMembers:MemberSearchV5Item[]=[];
  MedinActiveMembers:MemberSearchV5Item[]=[];
  MedActive!:boolean;

  @Input() pageType!:string;

  datatableType:DataTableType = DataTableType.HIGHLIGHT_ROWS;

  @ViewChild("memberNameTemplate", {static:true})
  memberNameTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("memberAddressTemplate", {static:true})
  memberAddressTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("memberIdTemplate", {static:true})
  memberIdTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("memberGroupIdTemplate", {static:true})
  memberGroupIdTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("memberEligibilityTemplate", {static:true})
  memberEligibilityTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("memberSaveTemplate", {static:true})
  memberSaveTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("memberSelectSymbolTemplate", {static:true})
  memberSelectSymbolTemplateRef!:TemplateRef<HTMLElement>;

  constructor(private memberSearchHelper: MembersearchService,private drugsearchHelper:DrugsearchService,
    private modalService: NgbModal,
    private profileService:ProfileService,
    private router:Router, private logger: LoggerService,
    private standardToServerTimeConversion: StandardDateToServertime) {

    this.headers=[];

    this.firstHeader = new TableHeaderCellData();
    this.firstHeader.content="Name";
    this.firstHeader.sortable=true;
    this.firstHeader.sortAscending=true;
    this.firstHeader.selectedForSort=true;
    this.headers.push(this.firstHeader);

    this.secondHeader = new TableHeaderCellData();
    this.secondHeader.content="Address";
    this.headers.push(this.secondHeader);

    this.thirdHeader = new TableHeaderCellData();
    this.thirdHeader.content = "Member ID";
    this.headers.push(this.thirdHeader);

    this.forthHeader = new TableHeaderCellData();
    this.forthHeader.content="Group ID";
    this.headers.push(this.forthHeader);

    this.fifthHeader = new TableHeaderCellData();
    this.fifthHeader.content = "Eligibility";
    this.headers.push(this.fifthHeader);

    this.sixthHeader = new TableHeaderCellData();
    this.sixthHeader.content="Save";
    this.headers.push(this.sixthHeader);

    this.seventhHeader = new TableHeaderCellData();
    this.seventhHeader.content=" ";
    this.seventhHeader.cssClass="tablesmallheader25";
    this.headers.push(this.seventhHeader);

  }

  onHeaderClicked(headerClickEvent:TableClickEvent<MemberSearchV5Item>):void{
    if(headerClickEvent.cell!=undefined){   
      if(this.activeMembersCount==0 && this.inactiveMembersCount!=0){
        this.filterStatus=MembersFilterMode.INACTIVE;
      } else {
        if(this.inactiveMembersCount==0){
          this.filterStatus=MembersFilterMode.ALL;
        } else {
          this.generateMemberResults();
        }
      }
    }
  }

  onCellClicked(cellClickEvent:TableClickEvent<MemberSearchV5Item>):void{
    //console.log("Clicked");
    
    if(cellClickEvent.column==5){
      //console.log("NO Redirect");
      //favourite or unfavourite column
       if(cellClickEvent.cell!=undefined && cellClickEvent.cell.data!=undefined){
         if(cellClickEvent.cell.data.isFavorite){
          //unfavourite
          this.logger.userAction("Remove Favourite Member :"+cellClickEvent.cell.data);
          this.removeFavouriteMember(cellClickEvent.cell.data);
         } else {
          //favourite
          this.logger.userAction("Add Favourite Member :"+cellClickEvent.cell.data);
          this.addFavouriteMember(cellClickEvent.cell.data);
         }
       }
    }else{
      //console.log("Redirect");
      if(cellClickEvent.cell?.data?.activeInd!=undefined && cellClickEvent.cell?.data?.activeInd.toLowerCase()=='a'){
        //console.log(cellClickEvent.cell?.data);
        debugger;
        this.logger.userAction("Selected Member :"+cellClickEvent.cell?.data);
        this.memberSearchHelper.storeSelectedMember(cellClickEvent.cell?.data);
        //console.log("Added to session:"+sessionStorage.getItem('memberdetails'));
        this.goto();
      }
      
    }
  }

  ngOnInit(): void {
    this.modalService.dismissAll();
    // if(this.profileService.loginuser?.prescriber?.presbrId!=undefined){
    //   this.memberSearchHelper.getFavouriteMembers(this.profileService.loginuser.prescriber.presbrId).then((response:any)=>{  
    //   },(error:any)=>{});
    // } y

    let favmember:FavouritemembersComponent=new FavouritemembersComponent(this.memberSearchHelper,this.profileService,this.modalService,this.logger);
          favmember.fetchSavedMembers();
          
    this.memberSearchHelper.savedMembers.subscribe((value:FavouriteMember[])=>{
      this.generateMemberResults();
    });

    sessionStorage.removeItem('prescribersearchresults');
    sessionStorage.removeItem('prescribersearchcriteria');
    sessionStorage.removeItem('selectedPrescriber');
    sessionStorage.removeItem('prescriberdetail');
    sessionStorage.removeItem('selectedMedicine');
    
  }

  private removeFavouriteMember(member: MemberSearchV5Item):void{
    
    const savedMember:FavouriteMember|undefined = this.memberSearchHelper.getFavouriteMember(member.memberId);
    if(savedMember!=undefined){
        this.memberSearchHelper.removeFavouriteMember(savedMember.mbrSearchId).then((response:Status)=>{
          member.isFavorite=false;
          this.blnFavouriteActionError=false;
          let favmember:FavouritemembersComponent=new FavouritemembersComponent(this.memberSearchHelper,this.profileService,this.modalService,this.logger);
          favmember.fetchSavedMembers();
        },(error:any)=>{
          member.isFavorite=true;
          this.blnFavouriteActionError=true;
          this.modalService.open(this.favouriteErrorTemplateRef, { modalDialogClass: 'favouriteActionErrorModal', size: 'sm' ,backdrop:'static'});
        });
    } else {
      this.blnFavouriteActionError=false;
      member.isFavorite=false;
    }
  }
  private addFavouriteMember(memberdata: MemberSearchV5Item):void{
    
    const member:FavouriteMember = new FavouriteMember();
    member.memberId = memberdata.memberId;
    member.lastName = memberdata.lastName;
    member.firstName = memberdata.firstName;
    member.dateOfBirth = this.standardToServerTimeConversion.transform(memberdata.dateOfBirth);
    member.zipCode = memberdata.address.zip1;
    member.address1 = memberdata.address.address1;
    member.address2 = memberdata.address.address2;
    member.presbrId = this.profileService.loginuser?.prescriber?.presbrId;
    member.uuid = this.profileService.loginuser?.prescriber?.uuid;
    this.memberSearchHelper.addFavouriteMember(member).then((response:Status)=>{
      if(response.statusCode!=undefined && response.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
        && response.dbStatusCode!=undefined){
        switch(response.dbStatusCode.toString()){
          case APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE:
            this.blnFavouriteActionError=false;
            memberdata.isFavorite=true;
            let favmember:FavouritemembersComponent=new FavouritemembersComponent(this.memberSearchHelper,this.profileService,this.modalService,this.logger);
            favmember.fetchSavedMembers();
            break;
          case APPCONSTANTS.API_RESPONSE_FAVOURITEMEMBER_LIMIT_EXCEEDED:
            //Favourite member limit exceeded.
            this.blnFavouriteActionError=false;
            memberdata.isFavorite=false;
            this.modalService.open(this.favouriteErrorTemplateRef, { modalDialogClass: 'favouriteActionErrorModal', size: 'sm' ,backdrop:'static'});
            break;
          default:
            //Error while saving member into favourite list.
            this.blnFavouriteActionError=true;
            memberdata.isFavorite=false;
            break;
        }
      }
    },(error:any)=>{
      //Error while saving member into favourite list.
      this.blnFavouriteActionError=true;
      memberdata.isFavorite=false;
      this.modalService.open(this.favouriteErrorTemplateRef, { modalDialogClass: 'favouriteActionErrorModal', size: 'sm' ,backdrop:'static'});
    });
  }

  private filterMembers():void{
    this.filteredMembers = this.members.filter((member:MemberSearchV5Item)=>{
        if(this.filterStatus==1 && member.activeInd!=undefined && member.activeInd.toLowerCase()=='a')
          return true;
        else{
          if(this.filterStatus==2 && member.activeInd!=undefined && member.activeInd.toLowerCase()=='i')
            return true;
          else{
            if(this.filterStatus==3)
              return true;
            else
              return false;
          }
        }
    });
  }
  private sortMembers():void{
    this.filteredMembers.sort((member1:MemberSearchV5Item,member2:MemberSearchV5Item)=>{
      if(this.firstHeader.sortAscending)
        return member1.activeInd.toLowerCase().localeCompare(member2.activeInd.toLowerCase());
      else
        return member2.activeInd.toLowerCase().localeCompare(member1.activeInd.toLowerCase());
    });
  }
  private updateMembersCount():void{
    this.activeMembersCount = this.members.filter((member:MemberSearchV5Item)=>member.activeInd!=undefined && member.activeInd.toLowerCase()=='a').length;
    this.inactiveMembersCount = this.members.filter((member:MemberSearchV5Item)=>member.activeInd!=undefined && member.activeInd.toLowerCase()=='i').length;
    if(this.activeMembersCount==0 && this.inactiveMembersCount!=0){
      this.filterStatus=MembersFilterMode.INACTIVE;
    } else {
      if(this.inactiveMembersCount==0){
        this.filterStatus=MembersFilterMode.ALL;
      } else {
        this.generateMemberResults();
      }
    }
  }

  private getCurrentPageMembers():MemberSearchV5Item[]{

    if(this.filteredMembers.length<=this.recordsPerPage){
      return this.filteredMembers;
    }
    else {
      const startIndex:number = (this.currentPageNumber-1) * this.recordsPerPage;
      const endIndex:number = (this.currentPageNumber * this.recordsPerPage);
      return this.filteredMembers.slice(startIndex, endIndex);
    }
    
  }

  private updateTotalPagesCount():void{
    this.totalPagesCount=(this.filteredMembers.length%this.recordsPerPage)!=0?Math.floor(this.filteredMembers.length/this.recordsPerPage)+1: Math.floor(this.filteredMembers.length/this.recordsPerPage);
  }

  private generateMemberResults():void{
    this.results=[];
    this.filterMembers();
    this.sortMembers();
    this.updateTotalPagesCount();

    this.getCurrentPageMembers().forEach((member:MemberSearchV5Item)=>{
      const row:TableRowData<MemberSearchV5Item> = new TableRowData<MemberSearchV5Item>();
      row.disabled=(member.activeInd!=undefined && member.activeInd.toLowerCase()=='i')? true:false;
      const firstCell:TableCellData<MemberSearchV5Item> = new TableCellData<MemberSearchV5Item>();
      firstCell.data=member;
      firstCell.sortable=true;
      firstCell.template = this.memberNameTemplateRef;
      row.cells.push(firstCell);

      const secondCell:TableCellData<MemberSearchV5Item> = new TableCellData<MemberSearchV5Item>();
      secondCell.data=member;
      secondCell.template = this.memberAddressTemplateRef;
      row.cells.push(secondCell);

      const thirdCell:TableCellData<MemberSearchV5Item> = new TableCellData<MemberSearchV5Item>();
      thirdCell.data=member;
      thirdCell.template=this.memberIdTemplateRef;
      row.cells.push(thirdCell);

      const forthCell:TableCellData<MemberSearchV5Item> = new TableCellData<MemberSearchV5Item>();
      forthCell.data=member;
      forthCell.template=this.memberGroupIdTemplateRef;
      row.cells.push(forthCell);

      const fifthCell:TableCellData<MemberSearchV5Item> = new TableCellData<MemberSearchV5Item>();
      fifthCell.data = member;
      fifthCell.template = this.memberEligibilityTemplateRef;
      row.cells.push(fifthCell);

      const sixthCell:TableCellData<MemberSearchV5Item> = new TableCellData<MemberSearchV5Item>();
     
      member.isFavorite = this.memberSearchHelper.isFavouriteMember(member.memberId);
      sixthCell.data = member;
      sixthCell.clickable=false;
      sixthCell.template = this.memberSaveTemplateRef;
      row.cells.push(sixthCell);

      const seventhCell:TableCellData<MemberSearchV5Item> = new TableCellData<MemberSearchV5Item>();
      seventhCell.template=this.memberSelectSymbolTemplateRef;
      seventhCell.data=member;
      sixthCell.clickable=false;
      row.cells.push(seventhCell);

      this.results.push(row);
    });
  }
  
  onPageChange(pageNumber:any){
    this.currentPageNumber=pageNumber;
    this.filterMembers();
    this.generateMemberResults();
  }

  goto(){
    //console.log("GOto:"+this.pageType)
    debugger;
    if(this.pageType!=undefined && this.pageType=="InitiatePriorAuthorizationPA"){
      this.router.navigateByUrl("/ePA/DrugSearch");
    }
    else if(this.pageType!=undefined && this.pageType=="SearchMedicationClaims"){
      this.router.navigateByUrl("/searchMedicationClaims/MemberDetail");
    }
    else{  
      if(this.profileService.USER_ROLE==12)
        this.router.navigateByUrl("/PriorAuthchecker/DrugSearch");
      else
        this.router.navigateByUrl("/PriorAuthChecker/DrugSearch");

    }


  }

  checkMedDMember(){
    if(this.members!=undefined){
      this.members.forEach((item:MemberSearchV5Item)=>{
        let webrequest1: MedDmemberRequest = new MedDmemberRequest();
        let searchInputMetaDataObject: SearchInputMetaData = new SearchInputMetaData();
         webrequest1.searchInputMetaData= searchInputMetaDataObject;
         webrequest1.searchInputMetaData.rXClaimInstanceId = item.instanceId;
         if(item.activeInd=='I')
         webrequest1.activeInd = "";
        else
        webrequest1.activeInd = item.activeInd;
         webrequest1.memberId = item.memberId;
         webrequest1.carrierId = item.carrierId;
         webrequest1.accountId = item.accountId;
         webrequest1.groupId = item.groupId;
         this.memberSearchHelper.checkMedDmember(webrequest1).then((response: MedDmemberResponse) => {
          if(response.response!=undefined)
            item.isMedD = true;
          else
            item.isMedD = false;
        });
      });
      this.updateMembersCount();
    }
    
  }

}