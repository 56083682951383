<!--Start Member Details -->
<div class="mt-2 col-8 offset-2 mb-5">
    <div class="p-3 bg-white">
        <h4 class="text-capitalize mt-3 mb-3">MEMBER INFORMATION</h4>
    </div>
    <div class="m-0 p-3 bg-white border-3 border-top">
        <div class="m-2 mt-3 p-3 bg-light border-3 border-top row">
            <div class="col-6">
                <div class="row">
                    <div class="col-6 row text-muted">
                        <label>Name</label>
                        <label class="text-dark">{{claimsummaryDetailsrequest.Name}}</label>
                    </div>
                    <div class="col-6 row text-muted">
                        <label>Member ID</label>
                        <label class="text-dark">{{claimsummaryDetailsrequest.MemberID}}</label>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 row text-muted">
                        <label>Address</label>
                        <label class="text-dark">{{claimsummaryDetailsrequest.Address}}</label>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4 row text-muted">
                        <label>Date of Birth</label>
                        <label class="text-dark">{{claimsummaryDetailsrequest.DateOfBirth}}</label>
                    </div>
                    <div class="col-4 row text-muted">
                        <label>Phone</label>
                        <label class="text-dark">{{claimsummaryDetailsrequest.Phone}}</label>
                    </div>
                    <div class="col-4 row text-muted">
                        <label>Gender</label>
                        <label class="text-dark">{{claimsummaryDetailsrequest.Gender}}</label>
                    </div>
                </div>
                <!-- <div class="row mt-3">
                    <div class="col-12 row text-muted">
                        <label>Bin/PCN/Group</label>
                        <label class="text-dark">{{claimsummaryDetailsrequest.BinPCNGroup}}</label>
                    </div>
                </div> -->
            </div>

        </div>
    </div>
</div>
 <!--End Member Details  -->