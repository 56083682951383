import {Status} from 'src/app/modals/webresponsestatus';

export interface PAHistorywebresponse {
    paResponseHistory:PAResponseHistory[];
    savedPaAutherizations:PAResponseHistory[];
    status:Status;
}

export class PAResponseHistory{
    lastName!:string;
    firstName!:string;
    memberId!:string;
    drugName!:string;
    caseId!:string;
    dateSubmitted!:string;
    paStatus?:string;
    correspondenceText!:string;
    deadlineForReply!:string;
    paRefId!:string;
    createdByUserId!:string;
    createdDateTime!:string;
    updatedByUserId!:string;
    updatedDateTime!:string;
    paNoteText!:string;
    completePaRefId!:string;
    fromDate!:string;
    thruDate!:string;
    prescriberName!:string;
    providerId!:string;
    productId!:string;
    productIdQualifier!:string;
    presbrFirstName!:string;
    presbrLstName!:string;
    displayPA!:string;
    date!:Date;
    readInd!:string;
    msg:string='';
    notificationId!:number;
    convertedTimestamp!: string 
}

export interface MemberPAHistorywebresponse {
    paResponseHistory:PAResponseHistory[];
    savedPaAutherizations:PAResponseHistory[];
    //paNotifications:PAResponseHistory[];
    status:Status;
}