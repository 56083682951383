import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/services/profile/profile.service';



@Component({
  selector: 'app-progress-tabs',
  templateUrl: './progress-tabs.component.html',
  styleUrls: ['./progress-tabs.component.css']
})
export class ProgressTabsComponent implements OnInit, OnChanges {

  @Input() route = "";
  eventenabled: boolean;
  currentState: number = 1;
  blnInitiatePA:boolean = false;
  blnAdvisorRole:boolean = false;
  userRole:any;


  constructor(private router: Router,private profileService:ProfileService) {
    debugger;
    this.eventenabled = true;
    this.userRole = this.profileService.USER_ROLE
      router.events.subscribe((event: Event)=>{
        
        if (event instanceof NavigationEnd) {
          if(this.eventenabled = (event.urlAfterRedirects.indexOf("/PriorAuthChecker/MemberSearch") != -1)) {
            this.currentState= 1;
            //console.log(this.eventenabled, this.currentState);
          } 
          else if(this.eventenabled = (event.urlAfterRedirects.indexOf("/PriorAuthChecker/DrugSearch") != -1) || (event.urlAfterRedirects.indexOf("/ePA/DrugSearch") != -1)|| (event.urlAfterRedirects.indexOf("/PriorAuthchecker/DrugSearch") != -1)){
            this.currentState= 2; 
            //console.log(this.eventenabled, this.currentState);
          }
          else if ( this.userRole==12 && (this.eventenabled = (event.urlAfterRedirects.indexOf("/PriorAuthChecker/PrescriberSearch") != -1) || (this.eventenabled = (event.urlAfterRedirects.indexOf("/PriorAuthchecker/PrescriberSearch") != -1)))){
            this.currentState= 3; 
            //console.log(this.eventenabled, this.currentState);
              }
              else if ( this.userRole==11 && (this.eventenabled = (event.urlAfterRedirects.indexOf("/PriorAuthChecker/PrescriberSearch") != -1) || (this.eventenabled = (event.urlAfterRedirects.indexOf("/PriorAuthchecker/PrescriberSearch") != -1)))){
                this.currentState= 3; 
                //console.log(this.eventenabled, this.currentState);
                  }
         else if (this.eventenabled = (event.urlAfterRedirects.indexOf("/PriorAuthChecker/VerifyPrescriber") != -1) || (event.urlAfterRedirects.indexOf("/ePA/ePAsubmit") != -1)){
          this.currentState= 3; 
          //console.log(this.eventenabled, this.currentState);
            }
            else if(this.eventenabled = event.urlAfterRedirects.indexOf("/ePA/MemberSearch") != -1){
              this.currentState = 1;
            }
            else if(this.eventenabled = event.urlAfterRedirects.indexOf("/ePA/DrugSearch") != -1){
              this.currentState = 2;
            }
            else if (this.userRole==13 && (this.eventenabled = (this.router.url.indexOf("/ePA/verifyProider") != -1))) {
              this.currentState = 3;
              //console.log(this.eventenabled, this.currentState);
             }
            else if(this.eventenabled = event.urlAfterRedirects.indexOf("/ePA/ePAsubmit") != -1){
              this.currentState = 3;
            }
            else if(this.eventenabled = event.urlAfterRedirects.indexOf("/ePA/ePAquestions") != -1){
              this.currentState = 4;
              //console.log(this.eventenabled, this.currentState);
            }

          else{
            this.currentState = 0;
          }
        }
        sessionStorage.setItem("currentstate",""+this.currentState);
      });
  }


  ngOnChanges(changes: SimpleChanges): void {
    //console.log("TEsting");
  }

  ngOnInit(): void {
    //console.log("TEsting2");
    debugger;
    if(sessionStorage.getItem('blnAdvisorRole')!=undefined){
      this.blnAdvisorRole = sessionStorage.getItem('blnAdvisorRole')=="true"?true:false;
    }
    
    if(sessionStorage.getItem('IntiatePA')!=undefined){
      this.blnInitiatePA = sessionStorage.getItem('IntiatePA')=="true"?true:false;
    }
    this.eventenabled = true;
    if (this.router.url.indexOf)
      if (this.eventenabled = (this.router.url.indexOf("/PriorAuthChecker/MemberSearch") != -1) || (this.router.url.indexOf("/PriorAuthchecker/MemberSearch") != -1)) {
        this.currentState = 1;
        //console.log(this.eventenabled, this.currentState);
      }
      else if (this.eventenabled = (this.router.url.indexOf("/PriorAuthChecker/DrugSearch") != -1) ||  (this.router.url.indexOf("/PriorAuthchecker/DrugSearch") != -1)) {
        this.currentState = 2;
        //console.log(this.eventenabled, this.currentState);
      }
      else if (this.userRole==12 && (this.eventenabled = (this.router.url.indexOf("/PriorAuthchecker/PrescriberSearch") != -1))) {
        this.currentState = 3;
        //console.log(this.eventenabled, this.currentState);
       }
       else if (this.userRole==11 && (this.eventenabled = (this.router.url.indexOf("/PriorAuthchecker/PrescriberSearch") != -1))) {
        this.currentState = 3;
        //console.log(this.eventenabled, this.currentState);
       }
      else if (this.eventenabled = (this.router.url.indexOf("/PriorAuthChecker/VerifyPrescriber") != -1)) {
        this.currentState = 3;
        //console.log(this.eventenabled, this.currentState);
       }
      else if(this.eventenabled = this.router.url.indexOf("/ePA/MemberSearch") != -1){
        this.currentState = 1;
      }
      else if(this.eventenabled = this.router.url.indexOf("/ePA/DrugSearch") != -1){
        this.currentState = 2;
      }
      else if (this.userRole==13 && (this.eventenabled = (this.router.url.indexOf("/ePA/verifyProider") != -1))) {
        this.currentState = 3;
        //console.log(this.eventenabled, this.currentState);
       }
      else if(this.eventenabled = this.router.url.indexOf("/ePA/ePAsubmit") != -1){
        this.currentState = 3;
      }
      else if(this.eventenabled = this.router.url.indexOf("/ePA/ePAquestions") != -1){
        this.currentState = 4;
        //console.log(this.eventenabled, this.currentState);
      }
      // else if(this.eventenabled = this.router.url.indexOf("/ePA/MemberSearch") != -1){
      //   this.currentState = 1;
      //   //console.log(this.eventenabled, this.currentState);
      // }
  }


}
