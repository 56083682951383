import { Pipe, PipeTransform } from '@angular/core';

//Converts any javascript date object to the display format "YYYY-MM-DD"
@Pipe({
  name: 'datetodisplaystring'
})
export class DatetoDisplayString implements PipeTransform {

  transform(value: Date, ...args: any[]): string {
    const stryear = value.getFullYear().toString();
    let strmonth:any = value.getMonth()+1;
    strmonth = (strmonth<10)?("0"+strmonth):strmonth.toString();
    const strday = (value.getDate()<10)?("0"+value.getDate()):value.getDate().toString();
    return stryear+"-"+strmonth+"-"+strday;
  }
}

//Converts any javascript date timestamp to the standard date dormat "MM/DD/YYYY"
@Pipe({
  name: 'timestamptostandarddate'
})
export class TimeStampToStandardDate implements PipeTransform {
  

  transform(value: string, ...args: any[]): string {
    debugger;
    const date:Date=new Date(parseInt(value));
    const stryear = date.getFullYear().toString();
    let strmonth:any = date.getMonth()+1;
    strmonth = (strmonth<10)?("0"+strmonth):strmonth.toString();
    const strday = (date.getDate()<10)?("0"+date.getDate()):date.getDate().toString();
    return strmonth+"/"+strday+"/"+stryear;;
  }
}

//Converts date from standard format "MM/DD/YYYY" to server date format "YYYYMMDD"
@Pipe({
  name: 'standarddatetoservertime'
})
export class StandardDateToServertime implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if(value.indexOf('/')!=-1 && value.split('/').length==3){
      const dateString:string[] = value.split("/");
      if(dateString[2].length != 4)
        throw "Invalid 4 digit year found in date string";
      let servertime:string=dateString[2];      
      servertime += (parseInt(dateString[0])<10 && dateString[0].length==1)? ("0"+dateString[0]): dateString[0]; //month
      servertime += (parseInt(dateString[1])<10 && dateString[1].length==1)? ("0"+dateString[1]): dateString[1]; //day

      return servertime;
    }
    else{
      if(value.indexOf("/")==-1 && value.toString().length==8)
        return value;
      else
        return "";
    }
  }
}


//Converts server date format "YYYYMMDD" to standard date format "MM/DD/YYYY"
@Pipe({
  name: 'servertimetostandarddate'
})
export class ServertimeToStandardDate implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if(value!=undefined && value.length==8){
      const year:string = value.substr(0,4);
      const month:string = value.substr(4,2);
      const day:string = value.substr(6,2);
      return month+"/"+day+"/"+year;
    }
    return "";
  }
}

//Converts any javascript date timestamp to the display date format "YYYY-MM-DD"
@Pipe({
  name: 'timestamptoservertime'
})
export class TimestamptoServerTime implements PipeTransform {

  transform(timestamp: number, ...args: any[]): string {
    const value:Date = new Date(timestamp);
    const stryear = value.getFullYear().toString();
    let strmonth:any = value.getMonth()+1;
    strmonth = (strmonth<10)?("0"+strmonth):strmonth.toString();
    const strday = (value.getDate()<10)?("0"+value.getDate()):value.getDate().toString();
    return stryear+"-"+strmonth+"-"+strday;
  }
}

//Converts any server date format 'YYYYMMDD' to the display date format "YYYY-MM-DD"
@Pipe({
  name: 'servertimetodisplaystring'
})
export class ServertimeToDisplayString implements PipeTransform{
  transform(value: string, ...args: any[]): string {
    if(value!=undefined && value.length==8){
      const year:string = value.substr(0,4);
      const month:string = value.substr(4,2);
      const day:string = value.substr(6,2);
      return year+"-"+month+"-"+day;
    }
    return "";
  }
}
