<div class="col container-fluid mb-5" @explainerAnim>
    <div class="col d-print-none" style="width: 100%; background-color: rgba(0, 129, 159, 1);">
        <div class="col-8 offset-2 timelinetitle pb-5">
            <h1 class="timeline h1">Prescription Coverage Results</h1>
            <div class="row">
                <div class="col-4 mb-3"><span class="text-light mt-2 h6">Member:
                        {{this.claimsummaryDetailsrequest.Name}}</span></div>
                <div class="col-4" *ngIf="this.claimsummaryDetailsrequest.isMedD==true && this.claimsummaryDetailsrequest.beginPhase!=undefined"><span
                        class="text-light mt-2 h6">Medicare | Begin Phase:
                        {{this.claimsummaryDetailsrequest.beginPhase}}</span></div>
            </div>
        <!-- Show alternate pharmacy pick alternates from array and the selected one from the session -->
            <div class="pb-5 text-dark descriptionSection mt-5 mb-5" 
                *ngIf="this.validAlternatePharmacies.length>0">
                <div  *ngFor="let item of this.validAlternatePharmacies;index as i">
                    <div *ngIf="this.phamcyIndex!=i+1" class="row mb-2 m-1 ps-3 selectPharmacy" style="background-color: #f2f6f9!important;" (click)="this.setpharmacyId(this.validAlternatePharmacies[i].serviceProviderId)"> 
                        <span class="text-dark mt-2 h6 row">
                            <div class="col-2 me-2 mt-3 pharmacyicon1"></div>
                            <div class="col-9 mt-3">{{item.serviceProviderName}}&nbsp;{{item.serviceProviderName.indexOf('Retail')==-1?"(Retail) ...":""}}</div>
                            <div class="col-2 mt-2 text-end pt-1"><p style="color: #0470c5;">Show pricing</p></div>                        
                        </span> 
                    </div>
                    
                    <div *ngIf="this.phamcyIndex==i+1" class="row shadow  p-2 m-1 selectedPharmacy">
                        <span class="text-dark mt-3 h4 row">
                             <div class=" col-2 me-2 pharmacyicon2"></div>
                             {{item.serviceProviderName}}
                             <span class="col" *ngIf="item.serviceProviderName.indexOf('Retail')==-1">&nbsp;(Retail) ...</span>
                         </span>
                     </div>
                </div>

                <div *ngIf="this.phamcyIndex!=0" class="row mb-2 m-1  mt-2 ps-3 selectPharmacy" style="background-color: #f2f6f9!important;" (click)="this.phamcyIndex=0">
                    <span class="text-dark mt-2 h6 row">
                        <div class="col-2 me-2 mt-3 pharmacyicon1"></div>
                        <div class="col-9 mt-3" >{{claimResponse.pharmacySearchItem?.pharmacyName}}&nbsp;{{claimResponse.pharmacySearchItem?.pharmacyName?.indexOf('Retail')==-1?"(Retail)":""}}</div>
                        <div class="col-2 mt-2 text-end pt-1"><p style="color: #0470c5;">Show pricing</p></div>                        
                    </span>                    
                </div>
                <div *ngIf="this.phamcyIndex==0" class="row shadow  p-2 m-1 selectedPharmacy">
                   <span class="text-dark mt-3 h4 row">
                        <div class=" col-2 me-2 pharmacyicon2"></div>
                        {{claimResponse.pharmacySearchItem?.pharmacyName}} 
                        <span class="col" *ngIf="claimResponse.pharmacySearchItem?.pharmacyName?.indexOf('Retail')==-1">&nbsp;(Retail) ...</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
   <!-- End Alternate pharmacy -->
    <div class="col" style="position: relative; top:-50px;">
        <app-payble-claim-summary *ngIf="true" [claimsummaryDetailsrequest]="claimsummaryDetailsrequest"
            [claimresponse]="claimResponse" (updateevent)="loadClaimSummary()">
        </app-payble-claim-summary>


        <!-- <app-prior-auth-claim-summary></app-prior-auth-claim-summary>
        <app-limit-exceeded-claim-summary></app-limit-exceeded-claim-summary> -->

        <!-- <app-member-summary [claimsummaryDetailsrequest]="claimsummaryDetailsrequest">
        </app-member-summary> -->

        <!-- <div class="col-8 offset-2 mt-5 mb-5" *ngIf="!this.claimsummaryDetailsrequest.claimSubmitted"> -->
        <div *ngIf="this.claimsummaryDetailsrequest.claimStsTypId<3" class="col-8 offset-2 mt-5 mb-5 d-print-none">
            <button class="btn btn-outline-primary border border-primary border-2 float-end" type="submit" role="button"
                (click)="goToSelectMember()">Search for another member</button>
            <button class="btn btn-link float-end m-2" (click)="goToSelectMedication()">Check another medication for
                this
                member</button>
        </div>

        <div class="col-8 offset-2 mt-5 mb-5 pt-3 d-print-none">
            <div class="pt-3 pb-5 mt-5 mb-5 border-3 border-top">
                <p class="fw-light">Prices are estimates. Prices on the medication(s) listed may vary depending on the
                    days
                    of supply, directions for use or the manufacturers’ prices at this time and at the time of the
                    transaction. Please note: Not all manufacturers for specified medications may be priced with this
                    tool.
                    Please refer to the member’s covered medications list (formulary) for more information.</p>
                <p class="fw-light">Medications that are listed as preferred alternatives may not be covered by the
                    member’s
                    benefits plan. Please refer to the member’s covered medications list (formulary) to determine
                    coverage.
                </p>
            </div>
        </div>
    </div>
</div>