import { Status } from "../webresponsestatus"

export class SubmitUserProvisionListRequest {
    provisionId!: number
    provisionUsers!: ProvisionUser[]
  }
  
  export class ProvisionUser {
    optumId!: string
    firstName!: string
    lastName!: string
    npiList!: string[]
    role!: string
    userType!: string
    note!: string
    email!: string
    flagRecordChange!: boolean
    uniquekey!: string
  }

  /******ReviewProvision********* */

  export class ReviewProvisionResponse {
    provisionId!: number
    provisionUsers!: ReviewProvisionUser[]
    status!: Status
    recordsCount!: number
    reviewProvisionYesNo!: boolean
  }
  
  export class ReviewProvisionUser {
    optumId!: string
    firstName!: string
    lastName!: string
    npiList!: string[]
    role!: string
    userType!: string
    note!: string
    email!: string
    provisionStatus!: string
    flagRecordChange!: boolean
    userActive!: string
    editMode!:boolean
    isInternalUser!:boolean
    isUserActive!:boolean
  }

  export class ReviewProvisionUserDatatable {
    editMode!:boolean
    isInternalUser!:boolean
    isUserActive!:boolean
    reviewProvisionUser!:ReviewProvisionUser
  }
  
  /********************Resubmit user provisioning**************************8 */

  export class ResubmitProvisionRequest {
    provisionId!: number
    provisionUsers!: ResubmitProvisionUser[]
    reviewProvisionYesNo!: string
  }
  
  export class ResubmitProvisionUser {
    optumId!: string
    firstName!: string
    lastName!: string
    npiList!: string[]
    role!: string
    userType!: string
    note!: string
    email!: string
    provisionStatus!: string
    provisionError!: string
    flagRecordChange!: boolean
    uniquekey!: string
  }

  /************UpdateProvisionStatus Response************/
  export class UpdateProvisionStatusResponse  {
    status!: Status
    presbrIdStatus!: PresbrIdStatus
  }
  
  export class PresbrIdStatus {
    optumId!: string
  }


  /***********************Init review provision response**************************************** */
  export interface InitReviewProvisionResponse {
    provisionId: number
    presbrId: number
    provisionUsers: ProvisionUser[]
    status: Status
    recordsCount: number
    reviewProvisionYesNo: boolean
  }
  
  export interface ProvisionUser {
    optumId: string
    uuid: string
    firstName: string
    lastName: string
    npiList: string[]
    role: string
    userType: string
    note: string
    email: string
    provisionStatus: string
    prescriberSearchResponseList: PrescriberSearchResponseList[]
    pharmacyDetailsResponseList: any[]
    flagRecordChange: boolean
    userActive: string
  }
  
  export interface PrescriberSearchResponseList {
    response: Response
    status: Status
  }
  
  export interface Response {
    maxRowExceededInd: boolean
    prescriberSearchItems: PrescriberSearchItem[]
  }
  
  export interface PrescriberSearchItem {
    identifiers: Identifier[]
    lastName: string
    firstName: string
    middleInitial: string
    address: Address
    phone1: string
    network: Network[]
    additionalFields: AdditionalField[]
    instanceId: string
  }
  
  export interface Identifier {
    id: string
    qualifier: string
  }
  
  export interface Address {
    address1: string
    address2: string
    city: string
    state: string
    zip1: string
    zip2: string
    zip3: string
    country: string
  }
  
  export interface Network {
    id: string
    name: string
    contractPeriod: ContractPeriod
  }
  
  export interface ContractPeriod {
    fromDate: string
    thruDate: string
  }
  
  export interface AdditionalField {
    fieldDescription: string
    fieldStringValue: string
  }
  
  
 