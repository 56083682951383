<div class="container-fluid col-10 offset-1" style="padding: 20px; background-color: white;" *ngIf="this.isProviderPage==undefined || this.isProviderPage=='false'">
    <div class="row">
        <div class="col">
            <h3 class="float-start" *ngIf="!blnInitiatePA">STEP 3 OF 3: VERIFY PRESCRIBER INFORMATION</h3>
            <h3 class="float-start" *ngIf="blnInitiatePA">STEP 3 OF 4: VERIFY PRESCRIBER INFORMATION</h3>
            <!-- <span class="paragraph" *ngIf="this.isProviderPage=='true'">Verify the information below before submitting.</span> -->
            <button class="btn btn-link fontbold float-end" (click)="edit()" *ngIf="blnShow==false">
                Edit provider information
            </button>
            <div class="float-end" *ngIf="blnShow==true">
                <button class="btn btn-link" (click)="discardEdit()">Cancel</button>
                &nbsp;
                <button class="btn btn-primary" (click)="saveEdit()">Save</button>
            </div>
        </div>
    </div>
</div>
<!-- Summary Page intiate Page in EPA resposne from claim summary -->
<div class="container-fluid col-10 offset-1" style="padding: 20px; background-color: white;"  *ngIf="this.isProviderPage!=undefined || this.isProviderPage=='true'">
    <div class="row">
        <div class="col">
            <label class="paragraph">Verify the information below before submitting.</label>
            <button class="btn btn-link fontbold float-end" (click)="edit()" *ngIf="blnShow==false">
                Edit provider information
            </button>
            <div class="float-end" *ngIf="blnShow==true">
                <button class="btn btn-link" (click)="discardEdit()">Cancel</button>
                &nbsp;
                <button class="btn btn-primary" (click)="saveEdit()">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid  col-10 offset-1 content-card mt-1 bg-white" *ngIf="this.isProviderPage!=undefined || this.isProviderPage=='true'">
    <div class="col-sm-12 text-center" id="providerLoader" *ngIf="this.providerLoader==true">
        <div><i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i><span style="font-size: 16px;">
                &nbsp;Loading</span></div>
    </div>
    <form name="verifyProviderPanelForm" *ngIf="this.providerLoader==false" [formGroup]="verifyProviderPanelForm" (ngSubmit)="saveEdit()">
        <div class="row" style="padding: 5px;">
            <div class="col-4 control" *ngIf="this.prescriberDetailItems.length==1">
                <label class="form-label fontbold">
                    Prescriber
                </label>
                <br />
                <span class="inputFieldLabel fontbold">{{fullName}}</span>
            </div>
            <div class="col-4 control" *ngIf="this.prescriberDetailItems.length==1">
                <label class="form-label fontbold">
                    NPI
                </label>
                <br />
                <span class="inputFieldLabel fontbold">{{NPI}}</span>
            </div>
            <div class="col-8 control" *ngIf="this.prescriberDetailItems.length>1">
                <label class="form-label fontbold">
                    Prescriber
                </label>
                <br />
                <select [(ngModel)]="selectedprescriberIndex" class="rounded-0 inp form-select dropdown"
                    style="width: 100%;" formControlName="presciber">
                    <option *ngFor="let item of prescriberDetailItems;index as i;" [ngValue]="i" value="item">
                        {{item.lastName}}&nbsp;{{item.firstName}}
                    </option>
                </select>
            </div>
            <div class="col-2 control" *ngIf="blnShow==false">
                <label class="form-label fontbold">
                    Phone
                </label>
                <br />
                <span class="inputFieldLabel">{{selectedAddressPhone| formatphoneorfax}}</span>
            </div>
            <div class="col-2 control" *ngIf="blnShow==true">
                <label class="form-label fontbold" for="phone">
                    Phone
                </label>
                <input type="text" name="editedAddressPhone" id="editedAddressPhone" class="inputFieldLabel" maxlengt
                    h="10" minlength="10" pattern="/^([0-9]+)$/i" (focus)="phoneFocus=true" (blur)="phoneFocus=false"
                    formControlName="editedAddressPhone" style="width: 100%;height: 55%;text-align:center"
                    [ngClass]="{'inpError':(blnEditingPanelSubmitted && !verifyProviderPanelForm.controls['editedAddressPhone'].valid  || verifyProviderPanelForm.controls['editedAddressPhone'].value.length<10) 
                || ( !blnEditingPanelSubmitted && !verifyProviderPanelForm.controls['editedAddressPhone'].pristine 
                && verifyProviderPanelForm.controls['editedAddressPhone'].errors && verifyProviderPanelForm.controls['editedAddressPhone'].errors['pattern'])}" required />
                <p class="text">
                    <!-- <span class="text-danger" *ngIf="(!verifyProviderPanelForm.controls['editedAddressPhone'].valid && !verifyProviderPanelForm.controls['editedAddressPhone'].value.pattern)">Invalid Character.</span> -->
                    <span class="text-danger"
                        *ngIf="((!verifyProviderPanelForm.controls['editedAddressPhone'].valid && verifyProviderPanelForm.controls['editedAddressPhone'].value.length<10)&& (!phoneFocus))">
                        Enter a valid phone number.
                    </span>
                </p>
            </div>
            <div class="col-2 control" *ngIf="blnShow==true">
                <label class="form-label fontbold" for="fax"> Fax
                </label>
                <input type="text" name="editFaxNumber" id="phone" class="inputFieldLabel" maxlength="10" minlength="10"
                    pattern="/^([0-9]+)$/i" (focus)="faxFocus=true" (blur)="faxFocus=false"
                    formControlName="editedAddressFax" style="width: 100%;height: 55%;text-align:center"
                    [ngClass]="{'inpError':(blnEditingPanelSubmitted && !verifyProviderPanelForm.controls['editedAddressFax'].valid  || verifyProviderPanelForm.controls['editedAddressFax'].value.length<10) 
             || (!blnEditingPanelSubmitted && !verifyProviderPanelForm.controls['editedAddressFax'].pristine 
             && verifyProviderPanelForm.controls['editedAddressFax'].errors && verifyProviderPanelForm.controls['editedAddressFax'].errors['pattern'])}" required />
                <p class="text">
                    <!-- <span class="text-danger" *ngIf="(!verifyProviderPanelForm.controls['editedAddressPhone'].valid && verifyProviderPanelForm.controls['editedAddressPhone'].errors && verifyProviderPanelForm.controls['editedAddressPhone'].errors?.['pattern'])">Invalid Character.</span> -->
                    <span class="text-danger"
                        *ngIf="((!verifyProviderPanelForm.controls['editedAddressFax'].valid && verifyProviderPanelForm.controls['editedAddressFax'].value.length<10)&& (!faxFocus))">
                        Enter a valid fax number.
                    </span>
                </p>
            </div>
            <div class="col-2 control" *ngIf="blnShow==false">
                <label class="form-label fontbold">
                    Fax
                </label>
                <br />
                <span class="inputFieldLabel">{{selectedAddressFax | formatphoneorfax}}</span>
            </div>
        </div>
        <div class="row" style="padding: 5px;">
            <div class="col-8 control">
                <label class="form-label fontbold">
                    Address
                </label>
                <br />
                <span class="inputFieldLabel fontbold"
                    *ngIf="this.alternativeAddresses.length==1">{{this.prescriberDetailItems[selectedprescriberIndex].address.address1}},{{this.prescriberDetailItems[selectedprescriberIndex].address.city}},
                    {{this.prescriberDetailItems[0].address.state}}&nbsp;{{this.prescriberDetailItems[0].address.zip1}}</span>
                <!-- <select [(ngModel)]="primaryAddress" class="rounded-0 inp form-select dropdown" style="width: 100%;" *ngIf="this.providers.length>1" formControlName="address" (change)="onAddressChange()">
                <option *ngFor="let item of prescriberDetailItems;index as i;" [ngValue]="i" value="item">{{item.alternateAddresses}}, {{item.alternateAddresses[i].address.city}},
                    {{item.alternateAddresses[i].address.state}}&nbsp;{{item.alternateAddresses[i].address.zip1}}</option>
            </select> -->
                <select [(ngModel)]="selectedAltAddressIndex" class="rounded-0 inp form-select dropdown"
                    style="width: 100%;" *ngIf="this.alternativeAddresses.length>1" formControlName="address">
                    <option *ngFor="let item of alternativeAddresses;index as i;" [ngValue]="i" value="item">
                        {{item.address.address1}}, {{item.address.city}},
                        {{item.address.state}}&nbsp;{{item.address.zip1}}</option>
                </select>
            </div>
        </div>
    </form>
</div>


<div class="container-fluid shadow col-10 offset-1 content-card mt-1 bg-white" *ngIf="this.isProviderPage==undefined || this.isProviderPage=='false'">
    <div class="col-sm-12 text-center" id="providerLoader" *ngIf="this.providerLoader==true">
        <div><i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i><span style="font-size: 16px;">
                &nbsp;Loading</span></div>
    </div>
    <form name="verifyProviderPanelForm" *ngIf="this.providerLoader==false" [formGroup]="verifyProviderPanelForm" (ngSubmit)="saveEdit()">
        <div class="row" style="padding: 5px;">
            <div class="col-4 control" *ngIf="this.prescriberDetailItems.length==1">
                <label class="form-label fontbold">
                    Prescriber
                </label>
                <br />
                <span class="inputFieldLabel fontbold">{{fullName}}</span>
            </div>
            <div class="col-4 control" *ngIf="this.prescriberDetailItems.length==1">
                <label class="form-label fontbold">
                    NPI
                </label>
                <br />
                <span class="inputFieldLabel fontbold">{{NPI}}</span>
            </div>
            <div class="col-8 control" *ngIf="this.prescriberDetailItems.length>1">
                <label class="form-label fontbold">
                    Prescriber
                </label>
                <select [(ngModel)]="selectedprescriberIndex" class="rounded-0 inp form-select dropdown"
                    style="width: 100%;" formControlName="presciber">
                    <option *ngFor="let item of prescriberDetailItems;index as i;" [ngValue]="i" value="item">
                        {{item.lastName}}&nbsp;{{item.firstName}}
                    </option>
                </select>
                <!-- <div style="width: 100%;">
                    <prescriberfilter (presbrId)="changePrescriber($event)" [presLabel]="presLabel">

                    </prescriberfilter>
                </div> -->
            </div>
            <!-- <div class="col-4 control" *ngIf="this.providers.length==1">
            <label class="form-label fontbold">
                Prescriber
            </label>
            <br />
            <span class="inputFieldLabel">{{this.providers[0].lastName}}&nbsp;{{this.providers[0].firstName}}</span>
        </div>
        <div class="col-4 control" *ngIf="this.providers.length==1">
            <label class="form-label fontbold">
                NPI
            </label>
            <br />
            <span class="inputFieldLabel">{{this.providers[0].npi}}</span>
        </div> -->
            <div class="col-2 control" *ngIf="blnShow==false">
                <label class="form-label fontbold">
                    Phone
                </label>
                <br />
                <span class="inputFieldLabel">{{selectedAddressPhone| formatphoneorfax}}</span>
            </div>
            <div class="col-2 control" *ngIf="blnShow==true">
                <label class="form-label fontbold" for="phone">
                    Phone
                </label>
                <input type="text" name="editedAddressPhone" id="editedAddressPhone" class="inputFieldLabel" maxlengt
                    h="10" minlength="10" pattern="/^([0-9]+)$/i" (focus)="phoneFocus=true" (blur)="phoneFocus=false"
                    formControlName="editedAddressPhone" style="width: 100%;height: 55%;text-align:center"
                    [ngClass]="{'inpError':(blnEditingPanelSubmitted && !verifyProviderPanelForm.controls['editedAddressPhone'].valid  || verifyProviderPanelForm.controls['editedAddressPhone'].value.length<10) 
                || ( !blnEditingPanelSubmitted && !verifyProviderPanelForm.controls['editedAddressPhone'].pristine 
                && verifyProviderPanelForm.controls['editedAddressPhone'].errors && verifyProviderPanelForm.controls['editedAddressPhone'].errors['pattern'])}" required />
                <p class="text">
                    <!-- <span class="text-danger" *ngIf="(!verifyProviderPanelForm.controls['editedAddressPhone'].valid && !verifyProviderPanelForm.controls['editedAddressPhone'].value.pattern)">Invalid Character.</span> -->
                    <span class="text-danger"
                        *ngIf="((!verifyProviderPanelForm.controls['editedAddressPhone'].valid && verifyProviderPanelForm.controls['editedAddressPhone'].value.length<10)&& (!phoneFocus))">
                        Enter a valid phone number.
                    </span>
                </p>
            </div>
            <div class="col-2 control" *ngIf="blnShow==true">
                <label class="form-label fontbold" for="fax"> Fax
                </label>
                <input type="text" name="editFaxNumber" id="phone" class="inputFieldLabel" maxlength="10" minlength="10"
                    pattern="/^([0-9]+)$/i" (focus)="faxFocus=true" (blur)="faxFocus=false"
                    formControlName="editedAddressFax" style="width: 100%;height: 55%;text-align:center"
                    [ngClass]="{'inpError':(blnEditingPanelSubmitted && !verifyProviderPanelForm.controls['editedAddressFax'].valid  || verifyProviderPanelForm.controls['editedAddressFax'].value.length<10) 
             || (!blnEditingPanelSubmitted && !verifyProviderPanelForm.controls['editedAddressFax'].pristine 
             && verifyProviderPanelForm.controls['editedAddressFax'].errors && verifyProviderPanelForm.controls['editedAddressFax'].errors['pattern'])}" required />
                <p class="text">
                    <!-- <span class="text-danger" *ngIf="(!verifyProviderPanelForm.controls['editedAddressPhone'].valid && verifyProviderPanelForm.controls['editedAddressPhone'].errors && verifyProviderPanelForm.controls['editedAddressPhone'].errors?.['pattern'])">Invalid Character.</span> -->
                    <span class="text-danger"
                        *ngIf="((!verifyProviderPanelForm.controls['editedAddressFax'].valid && verifyProviderPanelForm.controls['editedAddressFax'].value.length<10)&& (!faxFocus))">
                        Enter a valid fax number.
                    </span>
                </p>
            </div>
            <div class="col-2 control" *ngIf="blnShow==false">
                <label class="form-label fontbold">
                    Fax
                </label>
                <br />
                <span class="inputFieldLabel">{{selectedAddressFax | formatphoneorfax}}</span>
            </div>
        </div>
        <div class="row" style="padding: 5px;">
            <div class="col-8 control">
                <label class="form-label fontbold">
                    Address
                </label>
                <br />
                <span class="inputFieldLabel fontbold"
                    *ngIf="this.alternativeAddresses.length==1">{{this.prescriberDetailItems[selectedprescriberIndex].address.address1}},{{this.prescriberDetailItems[selectedprescriberIndex].address.city}},
                    {{this.prescriberDetailItems[0].address.state}}&nbsp;{{this.prescriberDetailItems[0].address.zip1}}</span>
                <!-- <select [(ngModel)]="primaryAddress" class="rounded-0 inp form-select dropdown" style="width: 100%;" *ngIf="this.providers.length>1" formControlName="address" (change)="onAddressChange()">
                <option *ngFor="let item of prescriberDetailItems;index as i;" [ngValue]="i" value="item">{{item.alternateAddresses}}, {{item.alternateAddresses[i].address.city}},
                    {{item.alternateAddresses[i].address.state}}&nbsp;{{item.alternateAddresses[i].address.zip1}}</option>
            </select> -->
                <select [(ngModel)]="selectedAltAddressIndex" class="rounded-0 inp form-select dropdown"
                    style="width: 100%;" *ngIf="this.alternativeAddresses.length>1" formControlName="address">
                    <option *ngFor="let item of alternativeAddresses;index as i;" [ngValue]="i" value="item">
                        {{item.address.address1}}, {{item.address.city}},
                        {{item.address.state}}&nbsp;{{item.address.zip1}}</option>
                </select>
            </div>
        </div>
        <!-- <div class="row" style="margin-top:20px;" *ngIf="blnShow==true">
        <div class="col-2 control">
            <label class="form-label fontbold" for="phone">
                Phone
            </label>
            <input type="text" name="editPhoneNumber"id="phone" class="inputFieldLabel" (change)="phoneChanged()"
            maxlength="10" minlength="10" pattern="/^([0-9]+)$/i" (focus)="phoneFocus=true" (blur)="phoneFocus=false" formControlName="editedAddressPhone" required>
        </div>
        <div class="col-2 control">
            <label class="form-label fontbold" for="fax">
                Fax
            </label>
            <input type="text" name="editFaxNumber"id="phone" class="inputFieldLabel" (change)="faxChanged()"
            maxlength="10" minlength="10" pattern="/^([0-9]+)$/i" (focus)="faxFocus=true" (blur)="faxFocus=false" formControlName="editedAddressFax" required>
        </div>
    </div> -->
    </form>
    <div class="row mb-5" *ngIf="!this.blnInitiatePA">
        <div class="col card-footer text-end">
            <button class="btn btn-primary float-end fontbold" role="button" *ngIf="!this.blnInitiatePA" (click)="checkCoverage()" [disabled]="this.providerLoader" >Check coverage</button>&nbsp;&nbsp;
            <a class="btn invisiblebtn btn-link float-end fontbold" (click)="back($event)">Back</a>
        </div>
    </div>
    <div class="row mb-5" *ngIf="this.blnInitiatePA">
        <div class="col card-footer text-end">
            <button class="btn btn-primary float-end fontbold" role="button" *ngIf="this.blnInitiatePA"
            [disabled]="this.providerLoader" (click)="initiatePA()">Continue to ePA Questions</button>&nbsp;&nbsp;
            <a class="btn invisiblebtn btn-link float-end fontbold" *ngIf="this.blnInitiatePA" (click)="backEpa($event)">Back</a>
        </div>
    </div>
    <!-- <form name="verifyProviderPanelForm" [formGroup]="verifyProviderPanel" (ngSubmit)="saveEdit()">
        <div class="row">
            <div class="col-md-3 col-xs-6 control">
                <label class="form-label fontbold">
                    Phone
                </label>
                <br/>
                <input type="text" maxlength="10" name="editedAddressPhone" class="rounded-0 form-control inp" aria-required="true"
                formControlName="editedAddressPhone"
                [ngClass]="{'inpError':(blnEditingPanelSubmitted && (!verifyProviderPanel.controls['editedAddressPhone'].valid || !zeroRegExp.test(verifyProviderPanel.controls['editedAddressPhone'].value) || verifyProviderPanel.controls['editedAddressPhone'].value.length<10)) 
                || (!blnEditingPanelSubmitted && !verifyProviderPanel.controls['editedAddressPhone'].pristine 
                && verifyProviderPanel.controls['editedAddressPhone'].errors && verifyProviderPanel.controls['editedAddressPhone'].errors['pattern'])}"/>
                
                <span class="text-danger" *ngIf="!verifyProviderPanel.controls['editedAddressPhone'].valid 
                && verifyProviderPanel.controls['editedAddressPhone'].errors
                && verifyProviderPanel.controls['editedAddressPhone'].errors['pattern']">Invalid Character.</span>
                <span class="text-danger" *ngIf="blnEditingPanelSubmitted 
                && verifyProviderPanel.controls['editedAddressPhone'].valid && ((!verifyProviderPanel.controls['editedAddressPhone'].valid || !zeroRegExp.test(verifyProviderPanel.controls['editedAddressPhone'].value) || verifyProviderPanel.controls['editedAddressPhone'].value.length<10))">
                Enter a valid phone number.
                </span>
            </div>
            <div class="col-md-3 col-xs-6 control">
                <label class="form-label fontbold">
                    Fax
                </label>
                <br/>
                <input type="text"  maxlength="10" name="editedAddressFax" class="rounded-0 form-control inp" aria-required="true" 
                formControlName="editedAddressFax"
                [ngClass]="{'inpError':(blnEditingPanelSubmitted && (!verifyProviderPanel.controls['editedAddressFax'].valid || !zeroRegExp.test(verifyProviderPanel.controls['editedAddressFax'].value) || verifyProviderPanel.controls['editedAddressFax'].value.length<10)) 
                || (!blnEditingPanelSubmitted && !verifyProviderPanel.controls['editedAddressFax'].pristine 
                && verifyProviderPanel.controls['editedAddressFax'].errors && verifyProviderPanel.controls['editedAddressFax'].errors['pattern'])}"/>

                <span class="text-danger" *ngIf="!verifyProviderPanel.controls['editedAddressFax'].valid 
                && verifyProviderPanel.controls['editedAddressFax'].errors
                && verifyProviderPanel.controls['editedAddressFax'].errors['pattern']">Invalid Character.</span>
                <span class="text-danger" *ngIf="blnEditingPanelSubmitted 
                && verifyProviderPanel.controls['editedAddressFax'].valid && ((!verifyProviderPanel.controls['editedAddressFax'].valid || !zeroRegExp.test(verifyProviderPanel.controls['editedAddressFax'].value) || verifyProviderPanel.controls['editedAddressFax'].value.length<10))">
                Enter a valid phone number.
                </span>
            </div>
        </div>
    </form> -->
</div>






<!--End Summary Page intiate Page in EPA resposne from claim summary -->

<!-- [(ngClass)]="{'error': ((!phoneFocus && verifyProviderPanelForm.editedAddressPhone.$error.minlength && !verifyProviderPanelForm.editedAddressPhone.$pristine) || (verifyProviderPanelForm.editedAddressPhone.$error.pattern && !verifyProviderPanelForm.editedAddressPhone.$error.required) || (blnGenericError && verifyProviderPanelForm.editedAddressPhone.$invalid && !phoneFocus) || (blnInvalidPhoneNo && !phoneFocus) )}" -->
<!-- [(ng-class)]="{'error': ( (!faxFocus && verifyProviderPanelForm.editedAddressFax.$error.minlength && !verifyProviderPanelForm.editedAddressFax.$pristine) || (verifyProviderPanelForm.editedAddressFax.$error.pattern && !verifyProviderPanelForm.editedAddressFax.$error.required) || (blnGenericError && verifyProviderPanelForm.editedAddressFax.$invalid && !phoneFocus) || (blnInvalidPhoneNo && !phoneFocus) )}" -->


<!-- Progress Summary in EPA scenario -->
<div class="col-10 container-fluid content-card mt-4 mb-1 offset-1" style="padding: 30px; background-color: white;"
    *ngIf="this.blnInitiatePA">
    <h4 class="header">PROGRESS SUMMARY
        <button class="btn btn-link float-end fontbold" *ngIf="blnShowHide" (click)="show()">Show &nbsp;
            <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
        <button class="btn btn-link float-end fontbold" *ngIf="!blnShowHide" (click)="hide()">Hide &nbsp;
            <i class="fa fa-minus" aria-hidden="true"></i>
        </button>
    </h4>
</div>

<!--Start Member Details -->
<div class="col-10 offset-1 mt-1" *ngIf="this.blnInitiatePA && !blnShowHide">
    <div class="p-4 bg-white">
        <h4 class="text-capitalize mt-3 mb-3">MEMBER INFORMATION
            <button class="btn btn-link float-end fontbold" (click)="changeMember()">Change member</button>
        </h4>
    </div>
    <div class="m-0 p-3 bg-white border-3 border-top">
        <div class="m-2 mt-3 p-3 bg-light border-3 border-top row">
            <div class="col-6">
                <div class="row">
                    <div class="col-6 row text-muted">
                        <label>Name</label>
                        <label class="text-dark">{{this.memberDetails.firstName}}</label>
                        <label class="text-dark">{{this.memberDetails.lastName}}</label>
                    </div>
                    <div class="col-6 row text-muted">
                        <label>Member ID</label>
                        <label class="text-dark">{{this.memberDetails.memberId}}</label>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 row text-muted">
                        <label>Address</label>
                        <label
                            class="text-dark">{{this.memberDetails.address.address1}},&nbsp;{{this.memberDetails.address.city}},&nbsp;{{this.memberDetails.address.state}},&nbsp;{{this.memberDetails.address.country}},&nbsp;{{this.memberDetails.address.zip1}}</label>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4 row text-muted">
                        <label>Date of Birth</label>
                        <label class="text-dark">{{this.newDate}}</label>
                    </div>
                    <div class="col-4 row text-muted">
                        <label>Phone</label>
                        <label class="text-dark">{{this.memberDetails.phone}}</label>
                    </div>
                    <div class="col-4 row text-muted">
                        <label>Gender</label>
                        <label class="text-dark">{{this.memberDetails.gender}}</label>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--End Member Details  -->

<!--Start Drug Details -->
<div class="col-10 offset-1" style="padding-bottom:50px" *ngIf="this.blnInitiatePA && !blnShowHide">
    <div class="p-4 bg-white">
        <h4 class="text-capitalize mt-3 mb-3">Medication Information
            <button class="btn btn-link float-end fontbold" (click)="changeDrug()">Edit medication</button>
        </h4>
    </div>
    <div class="m-0 p-3 bg-white border-3 border-top">
        <div class="m-2 mt-3 p-3 bg-light border-3 border-top row">
            <div class="col-12">
                <div class="row">
                    <div class="col-6 row text-muted">
                        <label>Medication Name</label>
                        <label class="text-dark">{{this.drugDetails.drugSearchItem.prodNameAbbr}}</label>
                    </div>
                    <div class="col-6 row text-muted float-end">
                        <label>Product ID</label>
                        <label class="text-dark">{{this.drugDetails.drugSearchItem.productId}}</label>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 row text-muted">
                        <label>Diagnosis Code</label>
                        <!-- <label class="text-dark">{{this.drugDetails.DiagnosisCode}}</label> -->
                        <label class="text-dark"></label>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-4 row text-muted">
                        <label>Quantity</label>
                        <label class="text-dark">{{this.drugDetails.Quantity}}</label>
                    </div>
                    <div class="col-4 row text-muted">
                        <label>Quantity Calculated</label>
                        <label class="text-dark">{{this.drugDetails.quantytyCalculate}}</label>
                    </div>
                    <div class="col-4 row text-muted">
                        <label>Days of Supply</label>
                        <label class="text-dark">{{this.drugDetails.Days}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 <!--End Drug Details  -->
<!--End  Progress Summary in EPA scenario -->


     <ng-template #errorModalTemplate let-modal>
        <div class="content-card errorModal" aria-modal="true" aria-hidden="true" role="dialog"
            aria-labelledby="errorModal">
            <div class="card-content text-center">
                <i class="fa fa-ban"></i>
                <br /><br />
                <div [innerHtml]="errorMessage"></div>
                <br />
                <button class="btn btn-primary" (click)="modal.dismiss()">Ok</button>
            </div>
        </div>
    </ng-template>

<!-- EPA processing Template -->
     <ng-template #paInitiationTemplate let-modal>
        <div class="content-card bg-white" aria-labelledby="paInitiationTemplate">
            <div class="card-content painitiation" *ngIf="epaState<5">
                <div class="text-center">
                    <!-- <loader [loaderText]="loaderText" [cssClass]="loaderClass"></loader> -->
                    <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i><span style="font-size: 16px;"></span>
                </div>
                <div class="step" [ngClass]="{'active':epaState>0}">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    <span class="paragraph large inputFieldLabel">Verifying information.</span>
                </div>
                <div class="step" [ngClass]="{'active':epaState>1}">
                    <i class="fa fa-check" aria-hidden="true"> </i>
                    <span class="paragraph large inputFieldLabel">Submitting PA request form.</span>
                </div>
                <div class="step" [ngClass]="{'active':epaState>2}">
                    <i class="fa fa-check" aria-hidden="true"> </i>
                    <span class="paragraph large inputFieldLabel">Retrieving PA evaluation questions.</span>
                </div>
                <div class="step" [ngClass]="{'active':epaState>3}">
                    <i class="fa fa-check" aria-hidden="true"> </i>
                    <span class="paragraph large inputFieldLabel">Questions received.</span>
                </div>
                <div class="painitiationfooter text-center">
                    <!-- <span class="paragraph" style="line-height: normal;">This process may take up to 3 minutes.</span> -->
                    <span class="paragraph large inputFieldLabel">Thank you and we've received your request. Your
                        question set will
                        typically be returned in less than 1 minute.</span>
                </div>
            </div>
            <div class="card-content text-center timeline" *ngIf="epaState==5">
                <i class="fa fa-check-circle"></i>
                <br />
                <span x-ms-format-detection="none" class="paragraph large inputFieldLabel">
                    The questions have been received. You have...
                </span>
                <br />
                <div class="text-center">
                    <div class="timerblock">
                        <div class="inputFieldLabel">{{epaHoursRemaining}}</div>
                        <div>Hours</div>
                    </div>
                    <div class="timerblock">
                        <div class="inputFieldLabel">{{epaMinutesRemaining}}</div>
                        <div>Minutes</div>
                    </div>
                    <div class="timerblock">
                        <div class="inputFieldLabel">{{epaSecondsRemaining}}</div>
                        <div>Seconds</div>
                    </div>
                </div>
                <br />
                <span x-ms-format-detection="none" class="paragraph large inputFieldLabel">
                    ...to complete the questions before this request expires.
                </span>
                <br /><br />
            </div>
            <div class="card-footer text-center" *ngIf="epaState==5">
                <button class="btn btn-primary" style="width:40%" (click)="goToEPAQuestions()">View PA questions</button>
            </div>
        </div>
    </ng-template>

<!--End EPA processing Template -->


    <ng-template #paInitiationFaxFormTemplate let-modal>
        <div class="content-card bg-white" aria-labelledby="paInitiationFaxFormTemplate">
            <div class="card-content text-center">
                <i class="fa fa-ban"></i>
                <br />
                <span x-ms-format-detection="none" class="paragraph large inputFieldLabel"
                    [innerHtml]="errorMessage"></span>
                <br /><br />
                <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
                &nbsp;&nbsp;
                <button type="button" class="btn btn-primary rounded-pill" (click)="openfaxforms()">View fax
                    forms</button>
            </div>
        </div>
    </ng-template>

    <ng-template #paInitiationErrorTemplate let-modal>
        <div class="content-card bg-white" aria-labelledby="paInitiationErrorTemplate">
            <div class="card-content text-center">
                <i class="fa fa-ban"></i>
                <br />
                <span x-ms-format-detection="none" class="paragraph large inputFieldLabel"
                    [innerHtml]="errorMessage"></span>
                <br /><br />
                <button type="button" class="btn btn-secondary" (click)="modal.dismiss();goToHome()">Ok</button>
            </div>
        </div>
    </ng-template>
  <ng-template #paInitiationFaxFormTemplate let-modal>
    <div class="content-card bg-white" aria-labelledby="paInitiationFaxFormTemplate"> 
      <div class="card-content text-center">
        <i class="fa fa-ban"></i>
        <br />
        <span x-ms-format-detection="none" class="paragraph large inputFieldLabel" [innerHtml]="errorMessage"></span>
        <br /><br />
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
        &nbsp;&nbsp;
        <button type="button" class="btn btn-primary rounded-pill" (click)="openfaxforms()">View fax forms</button>
      </div>
    </div>
  </ng-template>

  <ng-template #claimSummaryProgress let-modal>
    <div style="height:200px" role="dialog" aria-labelledby="claimSummaryProgress">
        <div class="modal-body mt-2">
            <div class="p-3">
                <div *ngIf="this.counter==10" class="row text-center"><i class="fa fa-ban  text-danger fs-1"
                        aria-hidden="true"></i></div>
                <div @slideDown *ngIf="this.counter==10" class="text-center mt-3 fs-6">
                    {{this.trailAdjuducicationError}}</div>
                <div *ngIf="this.counter==10" @slideDown class="modal-footer border-0 mt-5">
                    <button class="btn btn-secondary position-absolute top-80 start-50 translate-middle"
                        (click)="dismissModal()">ok</button>
                </div>
                <div *ngIf="this.counter==3" class="circle">
                    <div class="checkMark"></div>
                </div>
                <div *ngIf="this.counter<3" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div @slideDown *ngIf="this.counter==3" class="text-center fs-6" tabindex="-1" aria-modal="true">Check coverage complete</div>

                <div @slideDown *ngIf="this.counter==2" class="text-center fs-6" tabindex="-1" aria-modal="true">Retrieving results...</div>

                <div @slideDown *ngIf="this.counter==1" class="text-center fs-6" tabindex="-1" aria-modal="true">Analyzing information…</div>

            </div>
        </div>

    </div>

</ng-template>

<ng-template #errorPopup let-modal>
    <div class="p-3" role="dialog" aria-labelledby="errorPopup">
        <div class="row text-center"><i class="fa fa-ban text-danger" aria-hidden="true"></i></div>
        <div class="text-center mt-2" tabindex="-1" aria-modal="true">{{errorPopupText}}</div>
        <div class="row border-0 mt-4 p-3 "><button
                class="col-2  position-absolute top-80 start-50 translate-middle btn btn btn-primary"
                (click)="modal.dismiss()">OK</button></div>
    </div>
</ng-template>
  
