import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { InitiaPAComponent } from 'src/app/components/initia-pa/initia-pa.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InitiatePaStep2Component } from 'src/app/components/initiate-pa-step2/initiate-pa-step2.component';
import { EpaComponent } from 'src/app/components/routecontent/epa/epa.component';
import { ProgressTabsComponent } from 'src/app/components/progress-tabs/progress-tabs.component';
import { FindmemberComponent } from 'src/app/components/findmember/findmember.component';
// import { MemberresultComponent } from 'src/app/components/memberresult/memberresult/memberresult.component';
import { SelectMedicationComponent } from 'src/app/components/select-medication/select-medication.component';
import { CheckcoverageComponent } from 'src/app/components/routecontent/checkcoverage/checkcoverage.component';
import { CheckcoverageModule } from '../checkcoverage/checkcoverage.module';
import { InitiatePaStep3Component } from 'src/app/components/initiate-pa-step3/initiate-pa-step3.component';
import { EpasummaryComponent } from 'src/app/components/routecontent/epasummary/epasummary.component';
import { EpadetailsComponent } from 'src/app/components/epa/epadetails/epadetails.component';
import { QuestionsetComponent } from 'src/app/components/epa/questionset/questionset.component';
import { SelectquestionComponent } from 'src/app/components/epa/selectquestion/selectquestion.component';
import { NumericquestionComponent } from 'src/app/components/epa/numericquestion/numericquestion.component';
import { FreetextquestionComponent } from 'src/app/components/epa/freetextquestion/freetextquestion.component';
import { AttachmentquestionComponent } from 'src/app/components/epa/attachmentquestion/attachmentquestion.component';
import { DatequestionComponent } from 'src/app/components/epa/datequestion/datequestion.component';
import { SelectPrescriberComponent } from 'src/app/components/select-prescriber/select-prescriber.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const initiatePriorAuthorizationPARoutes: Routes = [
  {
    path:'', 
    redirectTo:'/ePA/MemberSearch',
    pathMatch:'full'
  },
  {path:'MemberSearch', component: InitiaPAComponent, data:{breadcrumb:{label:'PA Initiation: Select Member', step:1}},pathMatch:'full'},
  {path:'DrugSearch', component:InitiatePaStep2Component, data:{breadcrumb:{label:'Select Medication', step:2}}, pathMatch:'full'},
  {path:'verifyProider',component:SelectPrescriberComponent, data:{breadcrumb:{label:'Verify Provider', step:3}}, pathMatch:'full'},
  {path:'ePAsubmit', component:InitiatePaStep3Component, data:{breadcrumb:{label:'Verify Provider', step:3}}, pathMatch:'full'},
  {path:'ePAquestions', component:EpasummaryComponent, data:{breadcrumb:{label:'ePA Questions', step:4}}, pathMatch:'full'}
];


@NgModule({
  declarations: [
    InitiaPAComponent,
    InitiatePaStep2Component,
    InitiatePaStep3Component,
    EpaComponent,  
    EpasummaryComponent,
    EpadetailsComponent,
    QuestionsetComponent,
    SelectquestionComponent,
    NumericquestionComponent,
    DatequestionComponent,
    FreetextquestionComponent,
    AttachmentquestionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    CheckcoverageModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule.forChild(initiatePriorAuthorizationPARoutes)
  ],
  exports:[
    InitiaPAComponent,
    InitiatePaStep2Component,
    InitiatePaStep3Component

  ],
  schemas: [NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ]

})
export class EPAModule { }
