export class changeDefaultdAdressRequest {
    presbrId!: string
    address!: Address
    contact!: Contact
  }
  
  export class Address {
    address1!: string
    address2!: string
    city!: string
    state!: string
    zip1!: string
    zip2!: string
    zip3!: string
    country!: string
  }
  
  export class Contact {
    phone1!: string
    fax!: string
    email!: string
  }