import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { QuestionsetViewmodal, QuestionSetOperation } from 'src/app/modals/epa/questionsetviewmodal';
import { SavedPARequest, EPACancellationWebRequest } from 'src/app/modals/epa/priorauthrequest';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { TimeremaininghoursPipe, TimeremainingDescPipe, TimeremainingminutesPipe } from 'src/app/pipes/timeremaining/timeremaining.pipe';
import { APPCONSTANTS, EPA_TRANSACTIon_STATUS } from 'src/app/constants/appconstants';
import { Status } from 'src/app/modals/webresponsestatus';
import { EpaCancellationWebResponse } from 'src/app/modals/epa/priorauthresponse';
import { LoggerService } from 'src/app/services/logger/logger.service';

enum AUTOSAVESTATUS {
  INPROGRESS = 0,
  COMPLETED = 1,
  ERROR = 2
}

@Component({
  selector: 'app-epasummary',
  templateUrl: './epasummary.component.html',
  styleUrls: ['./epasummary.component.css']
})
export class EpasummaryComponent implements OnInit, OnDestroy {

  private questionsetviewmodal!: QuestionsetViewmodal;
  private paExpiredSubscription!: Subscription;
  private paExpiringWarningCount!: number;
  private autoSaveSubscription!: Subscription;

  paCaseID!: string;
  deadlineForReply!: number;

  timeRemainingInHours!: number;
  timeRemainingDesc!: string;
  blnquestionnariedisabled!: boolean;
  autoSaveStatus!: AUTOSAVESTATUS;
  blnQSEntirelyAnswered!: boolean;
  errorMessage!: string;
  loaderText: string = "";
  summarySubmissionStatus: number = 0;
  routedfromnotifications: boolean = false;
  routedfromSummaryPage:boolean = false;


  @ViewChild("paExpiredModal")
  paExpiredModal?: ElementRef<HTMLElement>;

  @ViewChild("paExpiringModal")
  paExpiringModal?: ElementRef<HTMLElement>;

  @ViewChild("saveForLaterFailure")
  saveForLaterFailureModal?: ElementRef<HTMLElement>;

  @ViewChild("saveForLaterConfirmation")
  saveForLaterConfirmationModal?: ElementRef<HTMLElement>;

  @ViewChild("cancelPAProgressModel")
  cancelPAProgressModel?: ElementRef<HTMLElement>;

  @ViewChild("cancelPAConfirmation")
  cancelPAConfirmationModal?: ElementRef<HTMLElement>;

  @ViewChild("cancelPAInternalErrorModal")
  cancelPAInternalErrorModal?: ElementRef<HTMLElement>;

  @ViewChild("cancelPAErrorModal")
  cancelPAErrorModal?: ElementRef<HTMLElement>;

  @ViewChild("paGenericErrorTemplate")
  paGenericErrorTemplate?: ElementRef<HTMLElement>;

  @ViewChild("paSubmissionTemplate")
  paSubmissionTemplateRef?: ElementRef<HTMLElement>;

  @ViewChild("paStatusTemplate")
  paStatusTemplateRef?: ElementRef<HTMLElement>;

  paStatusIcon: string = "";
  paStatusText: string = "";

  constructor(private epaServices: EpaprocessorService,
    private timeRemainingHoursPipe: TimeremaininghoursPipe,
    private timeRemainingDescPipe: TimeremainingDescPipe,
    private timeRemainingMinutesPipe: TimeremainingminutesPipe,
    private modalService: NgbModal,
    private logger: LoggerService,
    private router: Router,
    private profileService: ProfileService) {

    this.blnquestionnariedisabled = false;
    this.paExpiringWarningCount = 0;
    this.autoSaveStatus = AUTOSAVESTATUS.COMPLETED;
    this.blnQSEntirelyAnswered = false;

    this.timeRemainingDesc = "";
    this.timeRemainingInHours = -1;

    this.errorMessage = "";
  }
  private startTimmers(): void {
    const timer1$ = timer(0, 10000);
    this.paExpiredSubscription = timer1$.subscribe((val: any) => {
      this.updateExpirationTime();
    });

    const timer2$ = timer(2000, APPCONSTANTS.EPAAUTOSAVEMILSEC);
    this.autoSaveSubscription = timer2$.subscribe((value: any) => {
      this.autoSave(false);
    });
  }
  private updateQuestionSetViewModalFromSession(): void {
    const questionsetviewmodalsession: QuestionsetViewmodal | null = this.epaServices.getQuestionSetViewModal();
    if (questionsetviewmodalsession != null && questionsetviewmodalsession.questions.length > 0) {
      this.questionsetviewmodal = questionsetviewmodalsession;
      if (this.questionsetviewmodal.deadlineForReply != undefined && this.questionsetviewmodal.deadlineForReply.toString().length > 0) {

        this.deadlineForReply = this.questionsetviewmodal.deadlineForReply;
        this.paCaseID = this.questionsetviewmodal.pacaseID;
      } else {
        this.blnquestionnariedisabled = true;
        this.errorMessage = "";
      }
    } else {
      this.blnquestionnariedisabled = true;
      this.errorMessage = "";
    }
    sessionStorage.setItem('paCaseID', JSON.stringify(this.questionsetviewmodal.pacaseID));
  }

  private updateExpirationTime(): void {
    this.timeRemainingInHours = this.timeRemainingHoursPipe.transform(this.questionsetviewmodal.deadlineForReply);
    const timeRemainingInMinutes = this.timeRemainingMinutesPipe.transform(this.questionsetviewmodal.deadlineForReply);

    if (timeRemainingInMinutes <= 0) {
      this.blnquestionnariedisabled = true;
      this.timeRemainingDesc = "Submission deadline has expired.";
      this.modalService.open(this.paExpiredModal, { size: 'md' ,backdrop:'static'});
      this.offAllSubscriptions();
    } else {
      this.timeRemainingDesc = this.timeRemainingDescPipe.transform(this.questionsetviewmodal.deadlineForReply) + " until request expires.";
      if (timeRemainingInMinutes <= 30 && this.paExpiringWarningCount == 0) {
        this.modalService.open(this.paExpiringModal, { size: 'md' ,backdrop:'static'});
        this.paExpiringWarningCount++;
      }
    }
  }

  private offAllSubscriptions(): void {
    if (this.paExpiredSubscription != undefined) {
      this.paExpiredSubscription.unsubscribe();
    }
    if (this.autoSaveSubscription != undefined) {
      this.autoSaveSubscription.unsubscribe();
    }
    this.epaServices.cancelActiveSavePAAction();
  }

  ngOnInit(): void {
    this.updateQuestionSetViewModalFromSession();
    this.startTimmers();
    //code for notifications page routed content
    if (sessionStorage.getItem('routedfrom') != undefined) {
      this.routedfromnotifications = true;
    }
    else {
      this.routedfromnotifications = false;
    }
    //code for summarpage initiatePA link
    debugger;
    if (sessionStorage.getItem('routedfromsummary') != undefined) {
      this.routedfromSummaryPage = true;
    }
    else {
      this.routedfromSummaryPage = false;
    }
  }

  ngOnDestroy(): void {
    this.offAllSubscriptions();
    this.modalService.dismissAll();
  }
  goToHome():void{
    debugger;
    // sessionStorage.clear();
    this.logger.userAction("Go to Home Clicked");
    this.router.navigateByUrl("/home");
  }

  saveForLater(blnConfirmed:boolean=false):void{
    debugger;
    this.logger.userAction("Save For Later clicked");
    if (blnConfirmed) {
      const questionsetviewmodalsession: QuestionsetViewmodal | null = this.epaServices.getQuestionSetViewModal();
      if (questionsetviewmodalsession != null && questionsetviewmodalsession.questions.length > 0) {
        this.questionsetviewmodal = questionsetviewmodalsession;

        if (this.questionsetviewmodal.deadlineForReply != undefined
          && this.questionsetviewmodal.deadlineForReply.toString().length > 0) {
          this.errorMessage = "";
          const webrequest: SavedPARequest | undefined = this.epaServices.extractSavedPARequestFromQuestionSetViewModal(this.questionsetviewmodal, QuestionSetOperation.SAVE_FOR_LATER);

          if (webrequest != undefined) {

            this.epaServices.saveOrUpdate(webrequest).then((response: Status) => {
              if (response != undefined && response.dbStatusCode != undefined && response.dbStatusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
                debugger;
              sessionStorage.setItem("savelater",JSON.stringify(webrequest));
                this.goToHome();
              } else {
                if (response != undefined && response.customErrorCode != undefined && response.customErrorCode == "1") {
                  this.errorMessage = "1-866-842-3278";
                }
                this.modalService.open(this.saveForLaterFailureModal, { size: 'md', modalDialogClass: 'errorModal',backdrop:'static' });
              }
            }, (error: any) => {
              if (error != undefined && error.customErrorCode != undefined && error.customErrorCode == "1") {
                this.errorMessage = "1-866-842-3278";
              }
              this.modalService.open(this.saveForLaterFailureModal, { size: 'md', modalDialogClass: 'errorModal' ,backdrop:'static'});
            }).catch((error: any) => {
              if (error != undefined && error.customErrorCode != undefined && error.customErrorCode == "1") {
                this.errorMessage = "1-866-842-3278";
              }
              this.modalService.open(this.saveForLaterFailureModal, { size: 'md', modalDialogClass: 'errorModal' ,backdrop:'static'});
            });
          }
          sessionStorage.setItem("savelater",JSON.stringify(this.questionsetviewmodal.pacaseID));

        }
      }
    } else {
      this.modalService.open(this.saveForLaterConfirmationModal,{size:'md',backdrop:'static'});
      sessionStorage.setItem("savelater",this.questionsetviewmodal.pacaseID);
      

    }

  }

  autoSave(blnOnQuestionAnswered: boolean = true): void {
    this.logger.log("Auto Saving PA.");
    const questionsetviewmodalsession: QuestionsetViewmodal | null = this.epaServices.getQuestionSetViewModal();
    if (questionsetviewmodalsession != null && questionsetviewmodalsession.questions.length > 0) {
      this.questionsetviewmodal = questionsetviewmodalsession;

      if (this.questionsetviewmodal.deadlineForReply != undefined
        && this.questionsetviewmodal.deadlineForReply.toString().length > 0) {
        this.errorMessage = "";
        const webrequest: SavedPARequest | undefined = this.epaServices.extractSavedPARequestFromQuestionSetViewModal(this.questionsetviewmodal, (blnOnQuestionAnswered) ? QuestionSetOperation.NEW_QUESTION_LOADED : QuestionSetOperation.AUTOSAVE);
        if (webrequest != undefined) {
          this.autoSaveStatus = AUTOSAVESTATUS.INPROGRESS;
          this.epaServices.saveOrUpdate(webrequest).then((response: Status) => {
            if (response.customErrorCode == "1") {
              this.autoSaveStatus = AUTOSAVESTATUS.ERROR;
            } else {
              this.autoSaveStatus = AUTOSAVESTATUS.COMPLETED;
            }
          }, (error: any) => {
            this.autoSaveStatus = AUTOSAVESTATUS.ERROR;
          }).catch((error: any) => {
            this.autoSaveStatus = AUTOSAVESTATUS.ERROR;
          });
        }
      }
    }
  }

  onQuestionSetCompleted(blnCompleted: boolean): void {
    setTimeout(() => {
      this.blnQSEntirelyAnswered = blnCompleted;
    }, 50);
  }
  private startPoollingForPACancellationResponse(webrequest: EPACancellationWebRequest) {
    this.epaServices.poolForPACancellationResponse(webrequest).then((response: EpaCancellationWebResponse) => {
      this.modalService.dismissAll();
      this.goToHome();
    }, (error: any) => {
      this.modalService.dismissAll();
      if (error == undefined || (typeof error == "object" && (error.status == -1 || error.status == 0))) {
        this.modalService.open(this.paGenericErrorTemplate, { size: "md" ,backdrop:'static'});
      } else {
        if (typeof error == "object") {
          if (error.status != undefined && error.status.dbStatusCode == EPA_TRANSACTIon_STATUS.CANCELLATION_FAILED_REFID_MISMATCH && error.paCancelResponse != undefined) {
            if (error.paCancelResponse.reasonCode != undefined && ["bz", "ca", "by", "cb"].indexOf(error.paCancelResponse.reasonCode.toLowerCase()) != -1) {
              this.errorMessage = error.paCancelResponse.paNote;
            } else {
              this.errorMessage = "We are experiencing a technical error. Please contact <br/> OptumRx at 1-800-711-4555 to initiate a new prior <br/> authorization request";
            }
            this.modalService.open(this.cancelPAErrorModal, { size: "md" ,backdrop:'static'});
          } else {
            this.modalService.open(this.cancelPAInternalErrorModal, { size: "md" ,backdrop:'static'});
          }
        } else {
          switch (error) {
            case EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR:
            case EPA_TRANSACTIon_STATUS.SERVICE_TIMEOUT:
              this.modalService.open(this.cancelPAInternalErrorModal, { size: "md" ,backdrop:'static'});
              break;
            case EPA_TRANSACTIon_STATUS.POOLING_TIME_EXCEEDED:
              this.errorMessage = "We are experiencing a technical error. Please contact <br/> OptumRx at 1-800-711-4555 to initiate a new prior <br/> authorization request";
              this.modalService.open(this.cancelPAErrorModal, { size: "md" ,backdrop:'static'});
              break;
            default:
              this.errorMessage = error;
              this.modalService.open(this.cancelPAErrorModal, { size: "md" ,backdrop:'static'});
              break;
          }
        }
      }
    }).catch((error: any) => {
      this.modalService.dismissAll();
      if (typeof error == "object" && (error.status == -1 || error.status == 0)) {
        this.modalService.open(this.paGenericErrorTemplate, { size: "md" ,backdrop:'static'});
      } else {
        this.modalService.open(this.cancelPAInternalErrorModal, { size: "md" ,backdrop:'static'});
      }
    });
  }
  cancelPA(blnConfirmed: boolean): void {
    this.logger.userAction("Canceling PA");
    if (!blnConfirmed) {
      this.modalService.open(this.cancelPAConfirmationModal, { size: 'md' ,backdrop:'static'});
    } else {
      const webrequest: EPACancellationWebRequest = new EPACancellationWebRequest();
      webrequest.paReferenceID = this.questionsetviewmodal.paReferenceID.paRefID;
      webrequest.uuid = "" + this.profileService.loginuser?.prescriber?.uuid;
      this.errorMessage = "";
      this.modalService.open(this.cancelPAProgressModel, { size: "md" ,backdrop:'static'});
      this.epaServices.cancelPA(webrequest).then((response: EpaCancellationWebResponse) => {
        this.modalService.dismissAll();
        //No more auto save and timmer for deadline are permitted to run irrespective of whether user clicked on go to home button or not.
        this.offAllSubscriptions();
        this.startPoollingForPACancellationResponse(webrequest);
      }, (error: any) => {
        this.modalService.dismissAll();
        if (error == undefined || (typeof error == "object" && (error.status == -1 || error.status == 0))) {
          this.modalService.open(this.paGenericErrorTemplate, { size: "md" ,backdrop:'static'});
        } else {
          switch (error) {
            case EPA_TRANSACTIon_STATUS.SERVICE_TIMEOUT:
            case EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR:
            case EPA_TRANSACTIon_STATUS.CANCELLATION_DB_ERROR:
              this.modalService.open(this.cancelPAInternalErrorModal, { size: "md" ,backdrop:'static'});
              break;
            default:
              //No more auto save and timmer for deadline are permitted to run irrespective of whether user clicked on go to home button or not.
              this.offAllSubscriptions();
              if (typeof error == "string")
                this.errorMessage = error;
              this.modalService.open(this.cancelPAErrorModal, { size: "md" ,backdrop:'static'});
              break;
          }
        }
      }).catch((error: any) => {
        this.modalService.dismissAll();
        if (typeof error == "object" && (error.status == -1 || error.status == 0)) {
          this.modalService.open(this.paGenericErrorTemplate, { size: "md",backdrop:'static' });
        } else {
          this.modalService.open(this.cancelPAInternalErrorModal, { size: "md",backdrop:'static' });
        }
      });
    }
  }

  private startPoollingPAResponse(paReferenceId: string): void {
    this.epaServices.poolPAResponse(paReferenceId).then((response: any) => {
      this.summarySubmissionStatus = 0;
      this.modalService.dismissAll();
      this.paStatusIcon = "fa fa-check-circle";
      this.paStatusText = "Prior authorization has been approved.";
      this.modalService.open(this.paStatusTemplateRef, { size: "md" ,backdrop:'static'});
    }, (error: any) => {
      this.modalService.dismissAll();
      if (typeof error == "object") {
        if (error.paResponse != undefined && error.paResponse.paNote != undefined) {
          this.paStatusText = error.paResponse.paNote;
          this.paStatusIcon = "fa fa-times-circle";
          this.modalService.open(this.paStatusTemplateRef, { size: "md" ,backdrop:'static'});
        } else {
          this.errorMessage = "";
          this.modalService.open(this.paGenericErrorTemplate, { size: "md",backdrop:'static' });
        }
      } else {
        switch (error) {
          case EPA_TRANSACTIon_STATUS.PRIORAUTH_DENIED:
            this.paStatusText = "Prior authorization has been rejected.";
            this.paStatusIcon = "fa fa-times-circle";
            this.modalService.open(this.paStatusTemplateRef, { size: "md",backdrop:'static' });
            break;
          case EPA_TRANSACTIon_STATUS.POOLING_TIME_EXCEEDED:
            this.summarySubmissionStatus = 2;
            this.modalService.open(this.paSubmissionTemplateRef, { size: "md",backdrop:'static' });
            break;
          case EPA_TRANSACTIon_STATUS.PRIORAUTH_ERROR:
            this.errorMessage = "";
            this.modalService.open(this.paGenericErrorTemplate, { size: "md" ,backdrop:'static'});
            break;
          default:
            this.errorMessage = error;
            this.modalService.open(this.paGenericErrorTemplate, { size: "md",backdrop:'static' });
            break;
        }
      }
    }).catch((error: any) => {
      this.errorMessage = "";
      this.modalService.open(this.paGenericErrorTemplate, { size: "md" ,backdrop:'static'});
    });
  }

  submitPARequest(): void {
    debugger;
    this.logger.userAction("Submitting PA Request");
    const questionsetviewmodalsession: QuestionsetViewmodal | null = this.epaServices.getQuestionSetViewModal();
    if (questionsetviewmodalsession != null && questionsetviewmodalsession.questions.length > 0) {
      this.questionsetviewmodal = questionsetviewmodalsession;

      if (this.questionsetviewmodal.deadlineForReply != undefined
        && this.questionsetviewmodal.deadlineForReply.toString().length > 0) {
        this.errorMessage = "";
        const webrequest: SavedPARequest | undefined = this.epaServices.extractSavedPARequestFromQuestionSetViewModal(this.questionsetviewmodal, QuestionSetOperation.SUBMIT_PA_REQUEST);
        if (webrequest != undefined) {
          this.offAllSubscriptions();
          this.summarySubmissionStatus = 1;
          this.modalService.open(this.paSubmissionTemplateRef, { size: "md" ,backdrop:'static'});
          this.epaServices.saveOrUpdate(webrequest).then((response: Status) => {
            if (response.respCode == EPA_TRANSACTIon_STATUS.INITIATION_SUCCESS_RESPCODE) {
              //pullresponse.
              this.summarySubmissionStatus = 1;
              this.startPoollingPAResponse(webrequest.pAReferenceID);
            } else {
              this.modalService.dismissAll();
              this.summarySubmissionStatus = 0;
              if (response.customErrorCode == EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR) {
                this.errorMessage = "";
              } else {
                //Error modal.
                if (response.respMessage != undefined)
                  this.errorMessage = response.respMessage;
                else {
                  if (response.statusDesc != undefined)
                    this.errorMessage = response.statusDesc;
                  else
                    this.errorMessage = "Bad response";
                }
              }
              this.modalService.open(this.paGenericErrorTemplate, { size: "md",backdrop:'static' });
            }
          }, (error: any) => {
            this.modalService.dismissAll();
            this.summarySubmissionStatus = 0;
            if (error.customErrorCode == EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR) {
              this.errorMessage = "";
            } else {
              //Error modal.
              if (error.respMessage != undefined)
                this.errorMessage = error.respMessage;
              else {
                if (error.statusDesc != undefined)
                  this.errorMessage = error.statusDesc;
                else {
                  if (error.status != undefined && error.statusText != undefined) {
                    this.errorMessage = error.status + ": " + error.statusText;
                  } else {
                    this.errorMessage = "Bad response";
                  }
                }

              }
            }
            this.modalService.open(this.paGenericErrorTemplate, { size: "md" ,backdrop:'static'});
          }).catch((error: any) => {
            this.modalService.dismissAll();
            this.summarySubmissionStatus = 0;
            this.errorMessage = error.message;
            this.modalService.open(this.paGenericErrorTemplate, { size: "md" ,backdrop:'static'});
          });
        }
      }
    }
  }

}