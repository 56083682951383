import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrescriberDetailItem } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { ProvisioningAddUser } from 'src/app/modals/provisioning/adduserResponse';
import {  ProvisionUser, SubmitSingleUserProvisioningRequest } from 'src/app/modals/provisioning/submitSingleUserProvisioning';
import { Status } from 'src/app/modals/webresponsestatus';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ProvisioningService } from 'src/app/services/provisioning/provisioning.service';

@Component({
  selector: 'app-add-user-provisioning',
  templateUrl: './add-user-provisioning.component.html',
  styleUrls: ['./add-user-provisioning.component.css']
})
export class AddUserProvisioningComponent implements OnInit {
  optumIdFocus:boolean = false;
  blnFormSubmitted: boolean = false;
  isoptumIdFound:any;
  email:any;
  OptumIdvalue!:string;
  blncheckboxChecked:boolean = false;
  blnEditCheckboxChecked:boolean = false;
  selectedRole!: any;
  blnrouteToAddPrescriber:boolean = false;
  blnAddUserAdmin:boolean = false;
  filterAddedPrescribersList:any = new Array();
  filterAddedPharmacyList:any = new Array();
  blnEditUser:boolean = false;
  blnShowNPI:boolean = false;
  itemsToShow: number = 4;
  userRole!:any;
  filterStatus: string = '';
  selectedPharmacyNPI:any;
  firstName:any;
  lastName:any;
  uuid:any;
  oldRole:number=-1;


  @ViewChild("LoadingContent")
  loadingContent?: ElementRef<HTMLElement>;

  @ViewChild("optumIdProvisionedModal")
  optumIdProvisionedModalElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("provisionOptumIdModal")
  provisionOptumIdModalElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("provisionConfirmModal")
  provisionConfirmModalElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("OnChgePresPharModal")
  OnChgePresPharModalElmtRef?: ElementRef<HTMLElement>;


  @ViewChild("SelectOnePrescriberModal")
  SelectOnePrescriberModalElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("maxPresbrlimitexceed")
  maxPresbrlimitexceed!: ElementRef<HTMLElement>;

  optumidsearchform: FormGroup = new FormGroup({
    OptumId: new FormControl('', { validators: [Validators.required, Validators.minLength(6),Validators.pattern(/^(?!\d+$)([a-z0-9 ._\!-@$]+)$/i)] }),
  })
  // Getters for formControls
    get OptumId() {
      return this.optumidsearchform.get('OptumId');
    }
  constructor(private profileService: ProfileService,private uploadHistoryHelper: ProvisioningService,private modalService: NgbModal,protected router:Router) {

  }

  ngOnInit(): void {
    debugger;
    this.selectedPharmacyNPI= sessionStorage.getItem("selectedPharmacyNPI");
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    if(sessionStorage.getItem("selectedRole")!=undefined){
      this.selectedRole = sessionStorage.getItem("selectedRole");
      this.oldRole=this.selectedRole;
    }
    else{
      this.selectedRole = "-1";
    }
    this.userRole = this.profileService.USER_ROLE;
    this.blnFormSubmitted = false;
    this.blnAddUserAdmin = sessionStorage.getItem("blnAddUserAdmin")?.toLowerCase() == "true"?true:false;
    if( this.blnAddUserAdmin==true){
     this.OptumIdvalue=""+sessionStorage.getItem("userId");
     this.email = sessionStorage.getItem("email");
    //  if(sessionStorage.getItem("addedPrescribersList")!=undefined && (this.selectedRole==10 || this.selectedRole==20)){
    //  this.filterAddedPrescribersList=JSON.parse(""+sessionStorage.getItem("addedPrescribersList"));
    //  }
    //  if(sessionStorage.getItem("addedPharmacyList")!=undefined && (this.selectedRole==11 || this.selectedRole==13)){
    //   this.filterAddedPharmacyList=JSON.parse(""+sessionStorage.getItem("addedPharmacyList"));
    //  }
    //  else{
    //  sessionStorage.setItem("addedPrescribersList",JSON.stringify([]));
    //  }
     debugger;
     this.blnEditCheckboxChecked = true;
     this.blncheckboxChecked = true;
    }

    if(sessionStorage.getItem("addedPrescribersList")!=undefined && (this.selectedRole==10 || this.selectedRole==20)){
      this.filterAddedPrescribersList=JSON.parse(""+sessionStorage.getItem("addedPrescribersList"));
      }
      else{
      sessionStorage.setItem("addedPrescribersList",JSON.stringify([]));
      }
      if(sessionStorage.getItem("addedPharmacyList")!=undefined && (this.selectedRole==11 || this.selectedRole==13)){
        this.filterAddedPharmacyList=JSON.parse(""+sessionStorage.getItem("addedPharmacyList"));
       }
       else{
        sessionStorage.setItem("addedPharmacyList",JSON.stringify([]));

       }

  }

  onAddClick(){
    debugger;
    this.blnFormSubmitted = true;
    if(this.optumidsearchform.controls['OptumId'].valid){
      debugger;
    this.modalService.open(this.loadingContent,{size:'md',windowClass:'loadingContent',backdrop:'static'});
    this.uploadHistoryHelper.provisioningaddUser(this.optumidsearchform.value.OptumId).then((response: ProvisioningAddUser) => {
     console.log(response);
     this.modalService.dismissAll();
     if(response.status.statusCode == "Success" && response.status.dbStatusCode == "Success" && response.status.dbStatusDesc=="OptumId found in PCMS DB"){
      debugger;
      this.modalService.open(this.optumIdProvisionedModalElmtRef, {size:'md',windowClass:'loadingContent1',backdrop:'static'});
     }
     if(response.status.statusCode == "Success" && response.queryUserResponse!=undefined){
      debugger;
      this.isoptumIdFound = response.queryUserResponse;
     this.email = response.queryUserResponse.Resource.emails.email[0].value;
     this.OptumIdvalue = response.queryUserResponse.Resource.userName;
     this.firstName= response.queryUserResponse.Resource.name.familyName;
     this.lastName = response.queryUserResponse.Resource.name.givenName;
     this.uuid=response.queryUserResponse.Resource.id;
     sessionStorage.setItem("userId",this.OptumIdvalue);
     sessionStorage.setItem("email",this.email);
     sessionStorage.setItem("firstName",this.firstName);
     sessionStorage.setItem("lastName",this.lastName);
     sessionStorage.setItem("uuid",this.uuid);
     }
  }).catch((error: any) => {
    console.log(error);
  });
    }
  
}
editUser(){
  this.blnEditUser = true;
  
}

checkboxClick(){
  debugger;
  this.blncheckboxChecked==false?this.blncheckboxChecked=true:this.blncheckboxChecked=false;
}
editCheckboxClick(){
  debugger;
  this.blncheckboxChecked = false;
  this.blnEditCheckboxChecked==false?this.blnEditCheckboxChecked=true:this.blnEditCheckboxChecked=false;
 }
onRoleChange(value: any) {
  debugger;
  this.selectedRole = value;
  this.oldRole=value;
  sessionStorage.setItem("selectedRole",this.selectedRole);
}

onRoleChangeEdit(value: any) {
  debugger;
  // Phamracy to admin 
  if(value==20 && (this.oldRole==11 || this.oldRole==13)){
    this.oldRole=value;
    this.modalService.open(this.OnChgePresPharModalElmtRef,{size:'md',windowClass:'loadingContent1',backdrop:'static'})
    sessionStorage.setItem("addedPharmacyList",JSON.stringify([]));
  }
  // Prescriber to admin
  else if(value==20 && this.oldRole==10){
    this.oldRole=value; 
  }

  // Other then Admin to prescriber
  if(value==10 && this.oldRole!=20){
    this.oldRole=value;
    this.modalService.open(this.OnChgePresPharModalElmtRef,{size:'md',windowClass:'loadingContent1',backdrop:'static'})
    this.filterAddedPharmacyList=[];
    sessionStorage.setItem("addedPharmacyList",JSON.stringify([]));
  }
  // Admin to prescriber
  else if(value==10 && this.oldRole==20 && this.filterAddedPrescribersList.length>1 ){
    this.modalService.open(this.SelectOnePrescriberModalElmtRef, {size:'md',backdrop:'static'});
  }else{
    this.oldRole=value;
  }
// Any role to advisor
  if(value==12){
    this.oldRole=value;
    sessionStorage.setItem("addedPharmacyList",JSON.stringify([]));
    sessionStorage.setItem("addedPrescribersList",JSON.stringify([]));
  }

  // Any role to pharmacy
  if(value==11 || value==13){
    this.oldRole=value;
    this.filterAddedPrescribersList=[];
    sessionStorage.setItem("addedPrescribersList",JSON.stringify([]));
    this.modalService.open(this.OnChgePresPharModalElmtRef,{size:'md',windowClass:'loadingContent1',backdrop:'static'})
  }
  sessionStorage.setItem("selectedRole",this.selectedRole);

}

provisionClick(){
  debugger;
  this.firstName = sessionStorage.getItem("firstName");
  this.lastName = sessionStorage.getItem("lastName");
  if(this.selectedRole!=-1 && (this.selectedRole==12 || this.filterAddedPrescribersList.length>0 || this.filterAddedPharmacyList.length>0)){
  let webrequest:SubmitSingleUserProvisioningRequest = new SubmitSingleUserProvisioningRequest();
  webrequest.presbrId = ""+this.profileService.loginuser?.prescriber?.presbrId;
  webrequest.provisionId = null;
  let provisionUser:ProvisionUser[] = [];
  let provisionUserObj = new ProvisionUser();
  provisionUser[0] = provisionUserObj;
  provisionUserObj.email = this.email;
  provisionUserObj.firstName = this.firstName;
  provisionUserObj.lastName = this.lastName;
  provisionUserObj.note = "";
  debugger;
  for(let i=0;i<this.filterAddedPrescribersList.length;i++){
   provisionUserObj.npiList = [this.filterAddedPrescribersList[i].npi]
  }
  provisionUserObj.optumId = this.OptumIdvalue;
  provisionUserObj.pharmacyDetailsResponseList =[];
  provisionUserObj.prescriberSearchResponseList = [];
  provisionUserObj.userType = "E";
  provisionUserObj.uuid = this.uuid;
  webrequest.provisionUsers = provisionUser;

  switch(this.selectedRole){
    case "10":
      provisionUserObj.role = "Prescriber";
      break;
    case "11":
      provisionUserObj.role = "Pharmacist";
      break;
    case "12":
      provisionUserObj.role = "Advisor";
      break;
    case "13":
      provisionUserObj.role = "Specialtypharmacist";
      break;
    case "20":
      provisionUserObj.role = "Admin";
      break;
  }
  if(this.filterAddedPrescribersList.length==0 && provisionUserObj.role=='Advisor'){
    provisionUserObj.npiList = ['0'];
  }

  this.uploadHistoryHelper.submitSingleUserProvisionList(webrequest).then((response:Status)=>{})
  this.modalService.open(this.provisionConfirmModalElmtRef, {size:'md',windowClass:'loadingContent1',backdrop:'static'});
}
else{
  this.modalService.open(this.provisionOptumIdModalElmtRef, {size:'md',windowClass:'loadingContent1',backdrop:'static'});
}
}

dismiss(){
  this.modalService.dismissAll();
}

removeUser(){
  this.isoptumIdFound = undefined;
  this.blnAddUserAdmin = false; 
  this.blncheckboxChecked = false;
  sessionStorage.setItem("addedPrescribersList",JSON.stringify([]));
}
cancelClick(){
  this.blnEditUser = false;
}

saveEdits(){
  this.blnEditUser = false;
  sessionStorage.setItem("addedPrescribersList",JSON.stringify(this.filterAddedPrescribersList));

}

// selectNPI(){
//   debugger;
// this.blnrouteToAddPrescriber = true;
// this.router.navigateByUrl("/provision/addusers");}

// }
goto(routeUrl:string){
  debugger;  
  this.blnrouteToAddPrescriber = true;
  console.log(this.selectedRole);
  sessionStorage.setItem("blnrouteToAddPrescriber",""+this.blnrouteToAddPrescriber);
  if(this.selectedRole==10 && this.filterAddedPrescribersList.length>=1){
    this.modalService.open(this.maxPresbrlimitexceed, { size: 'md', windowClass: 'loadingContent1' ,backdrop:'static'});
  }else{
    this.router.navigateByUrl("/"+routeUrl);
  }
}

removeNPISelected(item:any){
  debugger;
  // console.log(item);
  //   for(let i=0;i<item.identifiers.length;i++){
  //     if(item.identifiers[i].qualifier=='01'){
  //   this.filterAddedPrescribersList=this.filterAddedPrescribersList.filter((data:any)=>data.identifiers[i].id!=item.identifiers[i].id)
  //     }
  //   }
  //   console.log(this.filterAddedPrescribersList);
      this.filterAddedPrescribersList=this.filterAddedPrescribersList.filter((data:any)=>data.npi!=item.npi);
      this.filterAddedPharmacyList = this.filterAddedPharmacyList.filter((data:any)=>data.fax!=item.fax);
    }

  showAllNpi(){
    this.blnShowNPI = true;
  }

  hideNpi(){
    this.blnShowNPI = false;
  }

  selectPresNpi(event:any){
    debugger;
    console.log(event);
    for(let i=0;i<this.filterAddedPrescribersList.length;i++){
      if(this.filterAddedPrescribersList[i].npi==event){
        this.filterAddedPrescribersList=this.filterAddedPrescribersList.filter((data:any)=>data.npi==event);
        sessionStorage.setItem("addedPrescribersList",JSON.stringify(this.filterAddedPrescribersList)); 
        this.selectedRole=10;
        this.oldRole=10;
        sessionStorage.setItem("selectedRole",this.selectedRole);
        }
    }
    this.modalService.dismissAll();
  }
}