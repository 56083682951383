import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APPCONSTANTS, PRESCRIBER_QUALIFIER } from 'src/app/constants/appconstants';
import { Pharmacy } from 'src/app/modals/drugSearch/pharmacySearchresponse';
import { SteponePharmacyDetailsRequest } from 'src/app/modals/registration/SteponePharmacyDetailsRequest';
import { PharmacyDetailItem, SteponePharmacyDetailsResponse } from 'src/app/modals/registration/SteponePharmacyDetailsResponse';
import { StringToSentenceCase } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { registrationService } from 'src/app/services/registrationSteps/registration.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-pharmacy',
  templateUrl: './add-pharmacy.component.html',
  styleUrls: ['./add-pharmacy.component.css']
})
export class AddPharmacyComponent implements OnInit {
  noMatchingrecordsvalue: string = "false";
  timeOutValue: string = "false";
  timeoutError!:string;
  nomatchesFound!:string;
  userId: any;
  selectedRole: any;
  blnshow: boolean = false;
  errorMessage!: string;
  blnSelectFax: boolean = false;
  selectedPharmacyNPI: any;
  addedPharmacyList: any = new Array();
  pharmacyFaxDetails: any=[];
  pharmacyDetailList: PharmacyDetailItem[]=[];
  pharmacyName!:string;
  pharmacyAddress!:string;
  filterStatus: string = '';
  PharmacyDetailItem!:PharmacyDetailItem;
  blnAddUserAdmin:boolean = false;
  email:any;
  blnrouteToAddPrescriber:any;
  itemCount:number = 20;
  filterPharmacyList: any = new Array();
  searchHistoryLength!: number;
  


  @ViewChild("selectFaxModal")
  selectFaxModal!: ElementRef<HTMLElement>;

  @ViewChild("PharmacySelectError")
  PharmacySelectError!: ElementRef<HTMLElement>;

  @ViewChild("maxPharmacylimitexceed")
  maxPharmacylimitexceed!: ElementRef<HTMLElement>;


  @ViewChild("noFaxSelected")
  noFaxSelected!: ElementRef<HTMLElement>;








  constructor(private registrationServiceHelper:registrationService,private stringToSentenceCase: StringToSentenceCase, private router: Router, private profileService: ProfileService, private modalService: NgbModal, private prescriberSearchService: PrescriberSearchService) {
  }
  ngOnInit(): void {
    this.userId = sessionStorage.getItem("userId");
    this.selectedRole = sessionStorage.getItem("selectedRole");
    if (sessionStorage.getItem('addedPharmacyList') == undefined) {
      this.addedPharmacyList = [];
    }
    else {
      this.filterPharmacyList = JSON.parse("" + sessionStorage.getItem('addedPrescribersList'));
      this.addedPharmacyList = [];
      if(this.selectedRole==20){
      this.itemCount = 20 - this.filterPharmacyList.length;
      }
      else{
        this.itemCount = this.filterPharmacyList.length;
      }
    }
  }

  dismiss() {
    this.noMatchingrecordsvalue = "false";
    this.timeOutValue = "false";
    this.blnshow = true;
    this.modalService.dismissAll();
  }

  errorPopupHandling($event1: any) {
    debugger;
    console.log($event1);
    this.noMatchingrecordsvalue = $event1.matchFound;
    this.timeOutValue = $event1.timeOut;

  }
  checkAddedPharmacyNPI(): boolean {
    debugger;
    var flag: boolean = false;
    for (let i = 0; i < this.addedPharmacyList.length; i++) {
      if (this.addedPharmacyList[i].npi == this.selectedPharmacyNPI) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  selectPharmacyFax(data: Pharmacy): void {
    debugger;
    console.log(data);
    this.blnSelectFax = false;
    this.selectedPharmacyNPI = data.npi;

    if (this.addedPharmacyList.length == 1 && this.userId != undefined && (this.selectedRole == 11 || this.selectedRole == 13)) {
      this.modalService.open(this.maxPharmacylimitexceed, { size: 'md', windowClass: 'loadingContent1',backdrop:'static' });
    }

    else if (this.userId != undefined && this.checkAddedPharmacyNPI() == true) {
      this.blnSelectFax = false;
      this.errorMessage = "You have already selected this prescriber.<br> You cannot add this prescriber to the queue again.";
      this.modalService.open(this.PharmacySelectError, { size: 'md', windowClass: 'loadingContent1' });
    }

    else{
      this.blnSelectFax = true;
      this.getPharmacyDetail(data);
      }

  }

  getPharmacyDetail(data:Pharmacy){
    debugger;
    var faxFlag:boolean = false;
    const pharmacydetailswebrequest: SteponePharmacyDetailsRequest = new SteponePharmacyDetailsRequest();
    pharmacydetailswebrequest.instanceId = ""+this.profileService.loginuser.prescriber?.instanceId;
    pharmacydetailswebrequest.pharmacyIdQualifier = PRESCRIBER_QUALIFIER.NPI;
    pharmacydetailswebrequest.pharmacyId = data.npi;
    this.registrationServiceHelper.postPharmacyDetails(pharmacydetailswebrequest).then((phyResponse: SteponePharmacyDetailsResponse) => {
      debugger;
      if (phyResponse.status.statusCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
        debugger;
        this.errorMessage = "";
        this.pharmacyFaxDetails = [];
        this.pharmacyDetailList = phyResponse.pharmacyDetailItems;
        // code to check for fax 0
        for(let i=0;i<this.pharmacyDetailList.length;i++){
          debugger;
            let faxSlice = this.pharmacyDetailList[i].fax;
            faxSlice = faxSlice.slice(0,3) + '-' + faxSlice.slice(3,6) + '-' + faxSlice.slice(6);
            this.pharmacyName = this.pharmacyDetailList[i].pharmacyName;
            this.pharmacyAddress = this.pharmacyDetailList[i].storeAddress.address1 + ", " + this.pharmacyDetailList[i].storeAddress.city + "," + this.pharmacyDetailList[i].storeAddress.state + "," + this.pharmacyDetailList[i].storeAddress.zip1;
            this.pharmacyFaxDetails.push(faxSlice);
            faxFlag = true;
            this.pharmacyFaxDetails = [...new Set(this.pharmacyFaxDetails)];
          
          }
         this.modalService.open(this.selectFaxModal, { size: 'md',backdrop:'static'});
  
          if(faxFlag==false){
          this.blnSelectFax = false;
          this.errorMessage = "We cannot find a fax associated with your prescriber's office. Contact us for assistance at 1-866-842-3278.";
          this.modalService.open(this.selectFaxModal, { size: 'md',backdrop:'static'});
          }
      }
}, (error: any) => {
  debugger;
  this.modalService.dismissAll();
  if (error.status.respCode == APPCONSTANTS.API_RESPONSE_NO_RECORDS) {
    sessionStorage.setItem("nomatchingrecordsvalue", "true");
    this.nomatchesFound = "" + sessionStorage.getItem("nomatchingrecordsvalue");
    this.noMatchingrecordsvalue = this.nomatchesFound;
  }
  if (error.status.respCode == "504") {
    sessionStorage.setItem("timeoutErrorvalue", "true");
    this.timeoutError = "" + sessionStorage.getItem("timeoutErrorvalue");
    this.timeOutValue = this.timeoutError;
  }
});
}

receiveNPI($event:PharmacyDetailItem){
  debugger;
 this.PharmacyDetailItem = $event;
this.addedPharmacyList.push(this.PharmacyDetailItem);
console.log(this.addedPharmacyList);
console.log(this.PharmacyDetailItem);
this.modalService.dismissAll();
}
modalPopup(){
  this.modalService.dismissAll();
  this.modalService.open(this.noFaxSelected, { size: 'md', windowClass:'loadingContent1',backdrop:'static' });
}

goto(routeUrl:string){
  debugger; 
  if (sessionStorage.getItem('addedPharmacyList') != undefined) {
    this.filterPharmacyList = JSON.parse("" + sessionStorage.getItem('addedPharmacyList'));
    if (this.filterPharmacyList.length > 0) {
      for (let i = 0; i < this.filterPharmacyList.length; i++) {
        this.itemCount = sessionStorage.getItem("itemCount") == null ? 20 : parseInt("" + sessionStorage.getItem("itemCount"));
        this.addedPharmacyList.push(this.filterPharmacyList[i]);
      }
    }
    sessionStorage.setItem("addedPharmacyList", JSON.stringify(this.addedPharmacyList));
  } 
  sessionStorage.removeItem("itemCount");
  this.blnAddUserAdmin = true;
  sessionStorage.setItem("selectedPharmacyNPI",this.selectedPharmacyNPI);
  sessionStorage.setItem("blnAddUserAdmin",""+this.blnAddUserAdmin);
  this.itemCount = this.itemCount - this.addedPharmacyList.length;
  sessionStorage.setItem("itemCount",""+this.itemCount);
  this.email=sessionStorage.getItem("email");
  this.router.navigateByUrl("/"+routeUrl);
}
removeAddedPharmacy(item:any){
  debugger;
  console.log(item);
  this.addedPharmacyList=this.addedPharmacyList.filter((data:any)=>data.fax!=item.fax);
    
  console.log(this.addedPharmacyList);
 }

 pharmacySearchHistory($event: any) {
  debugger;
  this.searchHistoryLength = $event.length;
  console.log($event);
}

}
