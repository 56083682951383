<!-- <div [attr.data-sort]="data.sortable" >
    <div *ngIf="data.content!=undefined && data.content.length>0" [innerHtml]="data.content">
    </div>

    <ng-container *ngIf="data.template!=undefined" [ngTemplateOutlet]="data.template" [ngTemplateOutletContext]="data">
    </ng-container>
</div> -->

<div [attr.data-sort]="data.sortable" >
    <div *ngIf="data.content!=undefined && data.content.length>0" [innerHtml]="data.content">
    </div>
    <!-- <div *ngIf="data.data!=undefined && data.data.length==0" [innerHtml]="0"></div> -->
    <ng-container *ngIf="data.template!=undefined" [ngTemplateOutlet]="data.template" [ngTemplateOutletContext]="data">
    </ng-container>
</div>

