import { NgModule,NO_ERRORS_SCHEMA ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from '../../components/datatable/datatable.component';
import { TablecellComponent } from '../../components/datatable/tablecell/tablecell.component';
import { TablerowComponent } from '../../components/datatable/tablerow/tablerow.component';
import { TableheaderComponent } from '../../components/datatable/tableheader/tableheader.component';



@NgModule({
  declarations: [
    DatatableComponent,
    TablecellComponent,
    TablerowComponent,
    TableheaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    TablecellComponent, 
    TablerowComponent, 
    TableheaderComponent, 
    DatatableComponent
  ],
  schemas: [NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ]

})
export class DatatableModule { }
