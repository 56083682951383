<div class="loader" [ngClass]="cssClass">
     <!-- <i class="fa fa-circle-o-notch fa-spin"></i> 
     <span [innerHtml]="loaderText"></span>  -->
     
    <div class="content">
        <div class="spinner-border text-secondary" role="status">
        </div>
        <span [innerHtml]="loaderText"></span>
    </div>
</div>

