import { LoggerService } from 'src/app/services/logger/logger.service';
import { Title } from '@angular/platform-browser';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild ,CUSTOM_ELEMENTS_SCHEMA,Input} from '@angular/core';
import { Router,Event, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { USER_ROLE } from 'src/app/constants/appconstants';
import { ProfileService } from 'src/app/services/profile/profile.service';

import { PAGE_TYPE } from 'src/app/constants/routeconstants';
import { ViewAllRouteState } from 'src/app/constants/routeconstants';

@Component({
  selector: 'searchMember',
  templateUrl: './search-member.component.html',
  styleUrls: ['./search-member.component.css']
})
export class SearchMemberComponent implements OnInit {
  @Input() pageType:string="MemberSearch";
  @Input() blnMember:boolean =false;
  routeVal:string="";
  routeData!:Subscription;
 
  constructor(private router:Router, private logger: LoggerService,private modalService: NgbModal,private titleService:Title) { 
  }
  ngOnDestroy():void{
    if(this.routeData!=undefined)
      this.routeData.unsubscribe();
  }
  ngOnInit(): void {
    this.titleService.setTitle("Search for a member");
  }
  goto(routerUrl:string){    
    this.logger.userAction("Goto home clicked.");
    this.router.navigateByUrl("/"+ routerUrl);
  }

}
