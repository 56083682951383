import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableRowData, TableClickEvent, TableCellData } from 'src/app/modals/datatable/datatable';

@Component({
  selector: '[tr]',
  templateUrl: './tablerow.component.html',
  styleUrls: ['./tablerow.component.css']
})
export class TablerowComponent implements OnInit{

  @Input()
  row:TableRowData<any>;
 
  @Input()
  rowIndex!:number;
 
  @Output()
  onCellClicked:EventEmitter<TableClickEvent<any>> = new EventEmitter<TableClickEvent<any>>();
 
  constructor() {
    this.row = new TableRowData<any>();
  }
 
  ngOnInit(): void {
  }
 
  onCellClick(_event:any, _cell:TableCellData<any>,_index:number):boolean{
    if (_cell.clickable || _event.target.getAttribute("enableAction") == "true" ){
      this.onCellClicked.emit({cell:_cell,event:_event,column:_index, row:this.rowIndex});
    }
    return true;
  }
}
