<div class="container-fluid viewAll savedHistory" *ngIf="state.pageType==0">
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
            <h1 name="title" class="fs-3">View all Saved PAs</h1>
        </div>
        <div class="offset-md-2 col-md-6 offset-xs-1 col-xs-8 mt-3">
            <p name="description" class="large">Find all saved PAs here. Narrow your results by using the filter or <br>search options.</p>
        </div>
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
            <div class="content-card bg-white">
                <div class="card-header">

                    <div class="control">
                        <label>View:</label>&nbsp;
                        <select class="rounded-0 inp form-select dropdown responsive"
                            [(ngModel)]="savedHistory.selectedStatus">
                            <option *ngFor="let option of savedHistory.savedStatus | keyvalue"
                                [innerHtml]="option.value" [value]="option.key"></option>
                        </select>
                    </div>

                    <div class="control">
                        <input type="text" class="form-control rounded-0 inp" [(ngModel)]="savedHistory.searchText" />
                    </div>
                    <div class="control">
                        <button class="btn btn-primary fontbold"
                            (click)="filterHistory()">Search</button>
                    </div>

                </div>
                <div class="card-content">
                    <savedmemberpahistory [memberId]="this.memberId" [pageType]="this.memberSpecific"
                        [searchText]="savedHistory.searchText" [filterByStatus]="savedHistory.selectedStatus">
                    </savedmemberpahistory>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 text-start backToHome">
            <i class="fa fa-angle-left"></i>
            &nbsp;
            <a class="btn btn-link backToHome" (click)="goto('/searchMedicationClaims/MemberDetail')" (keydown.enter)="goto('home')" tabindex="0" aria-hidden="true">
                Back to member detail
            </a>
        </div>
    </div>
</div>

<div class="container-fluid viewAll submittedHistory" *ngIf="state.pageType==1">
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
            <h1 name="title">View All PA History</h1>
        </div>
        <div class="offset-md-2 col-md-6 offset-xs-1 col-xs-8">
            <p name="description" class="large">Find all submitted prior authorizations here. Narrow your results by
                using <br>the filter or search options.</p>
        </div>
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
            <div class="content-card bg-white">
                <div class="card-header">
                    <div class="control">
                        <div class="row">
                            <label class="p-0 mb-1" for="dateRange" id="dateRange">Date range</label>
                            <select class="rounded-0 inp form-select dropdown responsive"
                                [(ngModel)]="submittedHistory.selectedDate">
                                <option *ngFor="let option of submittedHistory.dateRange | keyvalue"
                                    [innerHtml]="option.value" [value]="option.key"></option>
                            </select>
                        </div>
                    </div>
                    <div class="control">
                        <div class="row">
                            <label class="p-0 mb-1">Status</label>
                            <select class="rounded-0 inp form-select dropdown responsive"
                                [(ngModel)]="submittedHistory.selectedStatus">
                                <option *ngFor="let option of submittedHistory.statuses | keyvalue"
                                    [innerHtml]="option.value" [value]="option.key"></option>
                            </select>
                        </div>
                    </div>
                    <div class="control">
                        <input type="text" class="form-control rounded-0 inp"
                            [(ngModel)]="submittedHistory.searchText" />
                    </div>
                    <div class="control">
                        <button class="btn btn-primary fontbold"
                            (click)="filterHistory()">Search</button>
                    </div>
                </div>
                <div class="card-footer" *ngIf="submittedHistory.selectedDate==5">
                    <!--Below ngIf on fromDate component is required because, fromDate component depends on toDate component to be loaded first.
                        Hence, We use this flag to delay fromDate component loading until toDate component loads.-->
                    <div class="control" *ngIf="submittedHistory.blnShowFromDate">
                        <label for="dateSearch" id="dateSearch">Search by date:</label>&nbsp;
                        <datefield #FromDate [validationMode]="submittedHistory.fromDateValidationMode"
                            [content]="submittedHistory.fromDateValue"
                            (onUpdate)="submittedHistory.onFromDateUpdate($event)"
                            [emptyValid]="!submittedHistory.blnCustomDateFormSubmitted"
                            [toDateComponent]="ToDateComponent" [placeHolder]="submittedHistory.dateFormatPlaceHolder">
                        </datefield>
                    </div>
                    <div class="control">
                        &nbsp;<label for="todateSearch" id="todateSearch">To</label>&nbsp;
                        <datefield #ToDate [validationMode]="submittedHistory.toDateValidationMode"
                            [content]="submittedHistory.toDateValue"
                            (onUpdate)="submittedHistory.onToDateUpdate($event)"
                            [emptyValid]="!submittedHistory.blnCustomDateFormSubmitted"
                            [placeHolder]="submittedHistory.dateFormatPlaceHolder"></datefield>
                    </div>
        
                    <div class="text-end">
                        <button class="btn btn-secondary" (click)="filterHistory()">Update</button>
                    </div>
                </div>
                <div class="card-content">
                    <!--<submittedpahistory *ngIf="this.memberSpecific==undefined" [memberId]="this.memberId"
                    [fromDate]="submittedHistory.fromDate" [toDate]="submittedHistory.toDate"
                    [searchText]="submittedHistory.searchText" [filterByStatus]="submittedHistory.selectedStatus">
                </submittedpahistory>-->
                <membersubmittedpahistory *ngIf="this.memberSpecific!=undefined" [memberId]="this.memberId" [fromDate]="submittedHistory.fromDate" [toDate]="submittedHistory.toDate"
                [searchText]="submittedHistory.searchText" [filterByStatus]="submittedHistory.selectedStatus"></membersubmittedpahistory>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 text-start backToHome">
            <i class="fa fa-angle-left"></i>
            &nbsp;
            <a class="btn btn-link backToHome" (click)="goto('/searchMedicationClaims/MemberDetail')"  tabindex="0" aria-hidden="true">
            Back to member detail
            </a>
        </div>
    </div>
</div>