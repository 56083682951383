import {Prescriber} from './loginwebresponse';
import { BehaviorSubject } from 'rxjs';

export class Loginuser {
    ohid!:string;
    UUID!:string;
    linkUuid!:string;
    email!:string;
    prescriber:Prescriber|undefined;
    defaultPrescriber:Prescriber|undefined;
    //prescriber:Prescriber=new Prescriber();

    //defaultPrescriber:Prescriber = new Prescriber();
    selectedPrescriber:BehaviorSubject<Prescriber> = new BehaviorSubject<Prescriber>(new Prescriber());
    prescriberList:Prescriber[] = [];
    private _username : string="";
    public get username() : string {
        
        if(this._username.length==0 && this.prescriber!=undefined){
            if(this.prescriber.firstName!=undefined){
                this._username=this.prescriber.firstName;
            }
            if(this.prescriber.lastName!=undefined){
                this._username += (this._username.length>0)?(", "+this.prescriber.lastName):this.prescriber.lastName;                
            }
        }
        return this._username;
    }
}