import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableCellData, TableHeaderCellData, TableRowData } from 'src/app/modals/datatable/datatable';
import { Status } from 'src/app/modals/drugSearch/reverseclaimResponse';
import { DownloadProvisionSheetRequest, DownloadTemplateRequest, DownloadTemplateResponse, ProvisionUserList } from 'src/app/modals/provisioning/DownloadTemplateResponse';
import {  InitReviewProvisionResponse, ProvisionUser, ResubmitProvisionRequest, ResubmitProvisionUser, ReviewProvisionResponse, ReviewProvisionUser, ReviewProvisionUserDatatable, UpdateProvisionStatusResponse } from 'src/app/modals/provisioning/submitUserProvisioningrequest';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ProvisioningService } from 'src/app/services/provisioning/provisioning.service';

@Component({
  selector: 'app-provisionreport',
  templateUrl: './provisionreport.component.html',
  styleUrls: ['./provisionreport.component.css']
})
export class ProvisionreportComponent implements OnInit {
  event:any;
  filename = "sheet.xlsx";
  private contentType: string = "application/x-www-form-urlencoded; charset=UTF-8";

  uploadDate:any;
  uploadTime:any;
  recordsModified:boolean = false;

  blnEdit:boolean=false;

  provisionId:any;
  provisionStatus:any;
  provisionUserList:any[]=[];

  recordsCount:any;
  reviewProvisionString:any;
  provisionStatusCode:any;

  excelSuccessfulrecordheaders!: TableHeaderCellData[];
  excelSuccessfulrecordentries!: TableRowData<ReviewProvisionUserDatatable>[];

  excelUnSuccessfulrecordheaders!: TableHeaderCellData[];
  excelUnSuccessfulrecordentries!: TableRowData<ReviewProvisionUserDatatable>[];

   

  firstHeader!: TableHeaderCellData;
  secondHeader!: TableHeaderCellData;
  thirdHeader!: TableHeaderCellData;
  forthHeader!: TableHeaderCellData;
  fifthHeader!: TableHeaderCellData;
  sixthHeader!: TableHeaderCellData;
  seventhHeader!: TableHeaderCellData;

  

  @ViewChild("UpdatesSavedConfirmModal")
  UpdatesSavedConfirmModalElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("RecordsNotModifiedModal")
  RecordsNotModifiedModalElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("provisionConfirmModal")
  provisionConfirmModalElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("optumIDTemplate", { static: true })
  optumIDTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("npiTemplate", { static: true })
  npiTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("roleTemplate", { static: true })
  roleTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("internalTemplate", { static: true })
  internalTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("emailTemplate", { static: true })
  emailTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("statusTemplate", { static: true })
  statusTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("editTemplate", { static: true })
  editTemplateRef!: TemplateRef<HTMLElement>;

  constructor(private modalService: NgbModal, private uploadHistoryHelper: ProvisioningService, private profileService: ProfileService, private loggerService: LoggerService, protected router: Router) {

    this.excelSuccessfulrecordheaders = [];
    this.excelUnSuccessfulrecordheaders =[];

    this.firstHeader = new TableHeaderCellData();
    this.firstHeader.content = "Optum ID";
    this.firstHeader.sortable = true;
    this.firstHeader.sortAscending = true;
    this.firstHeader.selectedForSort = true;
    this.excelSuccessfulrecordheaders.push(this.firstHeader);
    this.excelUnSuccessfulrecordheaders.push(this.firstHeader);

    this.secondHeader = new TableHeaderCellData();
    this.secondHeader.content = "NPI";
    this.secondHeader.sortable = true;
    this.excelSuccessfulrecordheaders.push(this.secondHeader);
    this.excelUnSuccessfulrecordheaders.push(this.secondHeader);

    this.thirdHeader = new TableHeaderCellData();
    this.thirdHeader.content = "Role";
    this.excelSuccessfulrecordheaders.push(this.thirdHeader);
    this.excelUnSuccessfulrecordheaders.push(this.thirdHeader);

    this.forthHeader = new TableHeaderCellData();
    this.forthHeader.content = "Internal";
    this.forthHeader.sortable = true;
    this.excelSuccessfulrecordheaders.push(this.forthHeader);
    this.excelUnSuccessfulrecordheaders.push(this.forthHeader);

    this.fifthHeader = new TableHeaderCellData();
    this.fifthHeader.content = "Email";
    this.fifthHeader.sortable = true;
    this.fifthHeader.sortAscending = true;
    this.fifthHeader.selectedForSort = true;
    this.excelSuccessfulrecordheaders.push(this.fifthHeader);
    this.excelUnSuccessfulrecordheaders.push(this.fifthHeader);

    this.sixthHeader = new TableHeaderCellData();
    this.sixthHeader.content = "Status";
    this.sixthHeader.sortable = true;
    this.sixthHeader.sortAscending = true;
    this.sixthHeader.selectedForSort = true;
    this.excelSuccessfulrecordheaders.push(this.sixthHeader);
    this.excelUnSuccessfulrecordheaders.push(this.sixthHeader);

    this.seventhHeader = new TableHeaderCellData();
    this.seventhHeader.content = "Edit";
    this.excelUnSuccessfulrecordheaders.push(this.seventhHeader);
    

  }
  ngOnInit(): void {
    debugger;
    // this.event=JSON.parse(""+sessionStorage.getItem("event"));
    this.provisionId=sessionStorage.getItem("provisionId");
    this.uploadDate = sessionStorage.getItem("uploadDate");    
    this.uploadTime = sessionStorage.getItem("uploadTime");
    this.uploadHistoryHelper.reviewProvision(this.provisionId).then((response:InitReviewProvisionResponse)=>{
      console.log(response);
      debugger;  
      for(let i=0;i<response.provisionUsers.length;i++){
      this.provisionStatus = response.provisionUsers[i].provisionStatus;
      }
       this.provisionUserList = response.provisionUsers; 
       this.recordsCount = response.recordsCount;
       this.reviewProvisionString = response.reviewProvisionYesNo;
      this.loadReviewProvisionHistory(response.provisionUsers);
    }, (error: any) => {
    }).catch((reason: any) => {

    });
  }

  reviewProvisionUserDatatableList:ReviewProvisionUserDatatable[]=[];

  private loadReviewProvisionHistory(event:any): void {
   debugger;
   this.excelSuccessfulrecordentries = [];
   this.excelUnSuccessfulrecordentries = [];
   for(let x=0;x<event.length;x++){
    let reviewProvisionUserDatatable= new ReviewProvisionUserDatatable();
    reviewProvisionUserDatatable.reviewProvisionUser=event[x];
    reviewProvisionUserDatatable.editMode=false;
    reviewProvisionUserDatatable.isInternalUser=event[x].userType=="I"?true:false;
    reviewProvisionUserDatatable.isUserActive=event[x].userActive=="Y"?true:false;
    this.reviewProvisionUserDatatableList[x]=reviewProvisionUserDatatable;
  }
     this.reviewProvisionUserDatatableList.forEach((history: ReviewProvisionUserDatatable) => {
      const row: TableRowData<ReviewProvisionUserDatatable> = new TableRowData<ReviewProvisionUserDatatable>();
      debugger;
      const firstCell: TableCellData<ReviewProvisionUserDatatable> = new TableCellData<ReviewProvisionUserDatatable>();
      firstCell.data = history;
      firstCell.template = this.optumIDTemplateRef;
      row.cells.push(firstCell);

      const secondCell: TableCellData<ReviewProvisionUserDatatable> = new TableCellData<ReviewProvisionUserDatatable>();
      secondCell.data = history;
      secondCell.template = this.npiTemplateRef;
      secondCell.clickable = false;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<ReviewProvisionUserDatatable> = new TableCellData<ReviewProvisionUserDatatable>();
      thirdCell.data = history;
      thirdCell.template = this.roleTemplateRef;
      row.cells.push(thirdCell);

      const forthCell: TableCellData<ReviewProvisionUserDatatable> = new TableCellData<ReviewProvisionUserDatatable>();
        forthCell.data = history;
        forthCell.template = this.internalTemplateRef;
        row.cells.push(forthCell);
      
        const fifthCell: TableCellData<ReviewProvisionUserDatatable> = new TableCellData<ReviewProvisionUserDatatable>();
        fifthCell.data = history;
        fifthCell.sortable = true;
        fifthCell.template = this.emailTemplateRef;
        row.cells.push(fifthCell);

        const sixthCell: TableCellData<ReviewProvisionUserDatatable> = new TableCellData<ReviewProvisionUserDatatable>();
        sixthCell.data = history;
        sixthCell.sortable = true;
        sixthCell.template = this.statusTemplateRef;
        row.cells.push(sixthCell);

        this.excelSuccessfulrecordentries.push(row);

        const seventhCell: TableCellData<ReviewProvisionUserDatatable> = new TableCellData<ReviewProvisionUserDatatable>();
        seventhCell.data = history;
        seventhCell.sortable = true;
        seventhCell.template = this.editTemplateRef;
        row.cells.push(seventhCell);
      
      this.excelUnSuccessfulrecordentries.push(row);
    });


  }
  
  downloadProvisionSheet(){
    debugger;
    let webrequest: DownloadProvisionSheetRequest = new DownloadProvisionSheetRequest();
    let provisionUserList:ProvisionUserList = new ProvisionUserList();
    let provisionUser:ProvisionUser[]=[];
   webrequest.isEmptySheet = "N";
    webrequest.provisionUserList = provisionUserList;
    debugger;
    if(this.provisionStatus=='Failure'){
    provisionUser = this.provisionUserList;
    webrequest.provisionUserList.provisionUsers =provisionUser;
    }
    else{
      webrequest.provisionUserList.provisionUsers = [];
    }
    
    this.uploadHistoryHelper.getUserProvisioningSheet(webrequest).then((response: DownloadTemplateResponse) => {
      if (response.status.statusCode == "Success") {
        var b64String = response.fileBytes;
        let blob: Blob = this.b64toBlob(b64String);
        var objectUrl = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = objectUrl;
        a.target = '_blank';
        a.download = this.filename;
        document.body.appendChild(a);
        a.click();
      }
    }, (error: any) => {
    }).catch((reason: any) => {

    });
  }
  private b64toBlob(base64Data: any): Blob {
    let byteCharacters: string, blobData: [Uint8Array], blob: Blob;

    byteCharacters = atob(base64Data);
    blobData = this.getBlobDataAtOnce(byteCharacters);
    blob = new Blob(blobData, { type: this.contentType });

    return blob;
  }
  private getBlobDataAtOnce(byteCharacters: string): [Uint8Array] {
    let byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    return [byteArray];
  }
  editPanel(){
    this.blnEdit=true;
  }
  dismiss(){
    this.modalService.dismissAll();
  }
  dismissEdit(){
    this.blnEdit=false;
  }
  internalSliderChange(data:string){
    debugger;
    if(data=='I')
      data='E';
    else
    data='I'
  }

  reprovisionUsers(){
    let webrequest:ResubmitProvisionRequest = new ResubmitProvisionRequest();
    let provisionUser:ResubmitProvisionUser[]=[];
    webrequest.provisionId = this.provisionId;
    provisionUser = this.provisionUserList
    webrequest.provisionUsers = provisionUser;
    webrequest.reviewProvisionYesNo = "true";
    this.uploadHistoryHelper.submitUserProvisionList(webrequest).then((response:Status)=>{
      console.log(response);
      this.modalService.open(this.provisionConfirmModalElmtRef,{size:'md',windowClass:'loadingContent1',backdrop:'static'});
    }, (error: any) => {
      
    }).catch((reason: any) => {

    });

  }

  goto() {
    this.router.navigateByUrl("/provision/upload");
  }

  edit(data:any){
    debugger;
    console.log(data);
    data.editMode = true;
  }

  save(data: any) {
    data.editMode = false;
    if(data.isInternalUser){
      data.reviewProvisionUser.userType = "I";
    }else{
      data.reviewProvisionUser.userType = "E";
    }
    // debugger;
    // console.log(this.provisionUserList);
    // this.event.body.provisionUsers =  this.provisionUserList;
  }

  SaveChangesClick(){
    debugger;
    if(this.recordsModified==true){
     let data:ReviewProvisionUserDatatable = new ReviewProvisionUserDatatable();
     console.log(this.reviewProvisionUserDatatableList);
     let webrequest:ReviewProvisionResponse = new ReviewProvisionResponse();
     webrequest.provisionId = this.provisionId;
     webrequest.provisionUsers = this.reviewProvisionUserDatatableList.map(x=>x.reviewProvisionUser);
     webrequest.recordsCount = this.recordsCount;
     webrequest.reviewProvisionYesNo = this.reviewProvisionString;
     let status:Status = new Status();
     webrequest.status = status;
     webrequest.status.statusCode = this.provisionStatus;
     status
    this.uploadHistoryHelper.updateUserProvision(webrequest).then((response:UpdateProvisionStatusResponse)=> {
      console.log(response);
      this.modalService.open(this.UpdatesSavedConfirmModalElmtRef,{size:'md',windowClass:'loadingContent1',backdrop:'static'});

    })
  }
  else{
    this.modalService.open(this.RecordsNotModifiedModalElmtRef,{size:'md',windowClass:'loadingContent1',backdrop:'static'});

  }
  }

  updateUserType(data:any){
    debugger;
    this.recordsModified = true;
    if(data.reviewProvisionUser.userType=="I"){
      data.reviewProvisionUser.userType = "E";
    }else{
      data.reviewProvisionUser.userType = "I";

  }
  }
  updateUserActiveType(data:any){
    this.recordsModified = true;
    if(data.reviewProvisionUser.userActive=="Y"){
      data.reviewProvisionUser.userActive = "N";
    }else{
      data.reviewProvisionUser.userActive = "Y";

  }
  }

}
