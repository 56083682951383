import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

// import { SubmittedpahistoryComponent } from 'src/app/components/submittedpahistory/submittedpahistory.component';
// import { SavedpahistoryComponent } from 'src/app/components/savedpahistory/savedpahistory.component';
// import { ViewaallComponent } from 'src/app/components/routecontent/viewaall/viewaall.component';

// import { SavedclaimhistoryComponent } from 'src/app/components/savedclaimhistory/savedclaimhistory.component';
// import { SmapclaimsComponent } from 'src/app/components/routecontent/smapclaims/smapclaims.component';

import { SharedModule} from 'src/app/modules/shared/shared.module';
// import { ViewAllSubmittedClaimsComponent } from 'src/app/components/view-all-submitted-claims/view-all-submitted-claims.component';
import { SearchMedicationClaimsComponent } from 'src/app/components/search-medication-claims/search-medication-claims.component';
import { CheckcoverageModule } from '../checkcoverage/checkcoverage.module';
// import { PaidClaimsComponent } from 'src/app/components/paid-claims/paid-claims.component';
import { MemberDetailComponent } from 'src/app/components/member-detail/member-detail.component';
// import { MemberSubmittedPAHistoryComponent } from 'src/app/member-submitted-pahistory/member-submitted-pahistory.component';
import { RouterModule, Routes } from '@angular/router';
// import { SearchMemberComponent } from './search-member/search-member.component';
import { SearchForaMemberComponent } from 'src/app/components/search-fora-member/search-fora-member.component';
//import { MemberViewAllComponent } from 'src/app/components/member-view-all/member-view-all.component';
import { ViewalldatatableComponent } from 'src/app/components/viewalldatatable/viewalldatatable.component';
import { DatatableModule } from '../datatable/datatable.module';
import { FilterforprescriberModule } from '../filterforprescriber/filterforprescriber.module';
import { ViewalldatatableModule } from '../viewalldatatable/viewalldatatable.module';
import { MemberdatatableComponent } from 'src/app/components/memberdatatable/memberdatatable.component';
// Remove the import statement for 'MemberSubmittedPAHistoryComponent'
//import { MemberSubmittedPAHistoryComponent } from 'src/app/components/member-submitted-pahistory/member-submitted-pahistory.component';
import { SubmittedpahistoryComponent } from 'src/app/components/submittedpahistory/submittedpahistory.component';
import { SavedpahistoryComponent } from 'src/app/components/savedpahistory/savedpahistory.component';
import { ViewallComponent } from 'src/app/components/routecontent/viewall/viewall/viewall.component';
import { ViewallnotificationsComponent } from 'src/app/components/viewallnotifications/viewallnotifications.component';
//import { ViewaallComponent } from 'src/app/components/routecontent/viewaall/viewaall.component';
import { MemberSubmittedPAHistoryComponent } from 'src/app/components/member-submitted-pahistory/member-submitted-pahistory.component';
import { ViewallpaComponent } from 'src/app/components/routecontent/viewallpa/viewallpa.component';
import { PaDetailsComponent } from 'src/app/components/pa-details/pa-details.component';
import { SearchMemberComponent } from './search-member/search-member.component';
import { MemberViewallComponent } from 'src/app/components/memberviewall/memberviewall.component';
import { SelectMedicationComponent } from 'src/app/components/select-medication/select-medication.component';
import { MembernotificationsComponent } from 'src/app/components/membernotifications/membernotifications.component';
import { MemberdeletedComponent } from 'src/app/components/memberdeleted/memberdeleted.component';
import { InitiatePaStep2Component } from 'src/app/components/initiate-pa-step2/initiate-pa-step2.component';
import { InitiatePaStep3Component } from 'src/app/components/initiate-pa-step3/initiate-pa-step3.component';
import { EpasummaryComponent } from 'src/app/components/routecontent/epasummary/epasummary.component';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { SavedmemberpahistoryComponent } from 'src/app/components/savedmemberpahistory/savedmemberpahistory.component';

export const searchMediactionClaimsRoutes: Routes = [
  {
    path:'', 
    redirectTo:'/searchMedicationClaims/MemberSearch',
    pathMatch:'full'
  },
  {path:'MemberSearch', component: SearchForaMemberComponent, data:{breadcrumb:{label:'Member Search', step:1}},pathMatch:'full'},
  {path: 'MemberDetail', component:MemberDetailComponent, data:{breadcrumb:{label:'Member Detail', step:2}}, pathMatch:'full'},
  {path:'ViewAllMemberPAHistory', component:ViewallComponent, data:{breadcrumb:{label:'View All',step:3}}, pathMatch:'full'},
  {path:'ViewAllMemberSavedPAs', component:ViewallComponent, data:{breadcrumb:{label:'View All',step:3}}, pathMatch:'full'},
  {path:'MemberDetailsViewAll',component:MembernotificationsComponent ,data:{ breadcrumb : {label:'View All',step:3}},pathMatch:'full'},
  {path:'MemberDetailsViewDeleted',component:MemberdeletedComponent,data:{ breadcrumb : {label:'Deleted member notifications'}},pathMatch:'full'},
  //{path:'ePA/ePAsubmit',component:InitiatePaStep3Component, data:{breadcrumb:{label:'Verify Provider'}}, pathMatch:'full'},
];
@NgModule({
  declarations: [ 
    SubmittedpahistoryComponent,
    MemberSubmittedPAHistoryComponent,
    SavedmemberpahistoryComponent,
    SavedpahistoryComponent,
    //ViewaallComponent,
    ViewallComponent,
    SearchMedicationClaimsComponent,
    MemberDetailComponent,
    SearchForaMemberComponent,
    ViewallpaComponent,
    PaDetailsComponent,
    SearchMemberComponent,
    //ViewallComponent,
    MemberViewallComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    CheckcoverageModule,
    ViewalldatatableModule,
    FilterforprescriberModule,
    DatatableModule,
    RouterModule.forChild(searchMediactionClaimsRoutes)
  ],
  exports:[ 
    SavedpahistoryComponent,
    MemberSubmittedPAHistoryComponent,
    SubmittedpahistoryComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class HistoryModule { }

