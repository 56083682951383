import { Status } from "../webresponsestatus"

/*******Request**********************/
export class addPrescriberRequest {
    addPrescriberList!: AddPrescriber[]
    prescribersLimit!: string
    verifyURL!: string
  }
  
  export class AddPrescriber {
    role!: string
    linkRequestId!: string
    reActivateInd!: string
    user!: User
    prescriber!: Prescriber
  }
  
  export class User {
    firstName!: string
    lastName!: string
    streetAddress!: string
    city!: string
    state!: string
    zipCode!: string
    phoneNumber!: string
    faxNumber!: string
    email!: string
    npi!: string
    optumID!: string
    uuid!: string
    country!: string
    educationQualification!: string
    addrLine2!: string
    presbrQualTypeId!: string
    instanceId!: string
  }
  
  export class Prescriber {
    firstName!: string
    lastName!: string
    streetAddress!: string
    city!: string
    state!: string
    zipCode!: string
    phoneNumber!: string
    faxNumber!: string
    email!: string
    npi!: string
    optumID!: string
    uuid!: string
    country!: string
    educationQualification!: string
    addrLine2!: string
    presbrQualTypeId!: string
    instanceId!: string
  }

  /****************************Response********************/

  export class  addPrescriberResponse{
    addprescriberslist!: Addprescriberslist[]
  }
  
  export class Addprescriberslist {
    status!: Status
    npi!: string
  }
  
  
  