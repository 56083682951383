import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MemberSearchItem } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { MemberDetailWebRequesthemi } from 'src/app/modals/membersearch/memberdetailwebrequest';
import { MemberSearchV5Item } from 'src/app/modals/membersearch/membersearchwebresponse';
import { ServertimeToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { MemberDetailService } from 'src/app/services/memberdetail/memberdetail.service';
//import { MemberDetailWebResponse } from 'src/app/modals/membersearch/memberdetailwebresponse';
import { APPCONSTANTS, PersonGender } from 'src/app/constants/appconstants';
import { PAGE_TYPE, ViewAllRouteState } from 'src/app/constants/routeconstants';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { MemberDetailWebResponsehemi } from 'src/app/modals/membersearch/memberdetailresponsehemi';
//import { ViewaallComponent } from '../routecontent/viewaall/viewaall.component';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-memberdetail',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.css']
})
export class MemberDetailComponent implements OnInit {
  pageType:string="MemberDetail"
  memberSearchResult!: MemberSearchV5Item | MemberSearchItem | undefined;
  memberDetailResult!: any;
  gender!: string;
  eligibilityDates!: any;
  lastUpdated!: any;
  relationshipCode!: any;
  memberSpecific="MemberSpecificResults";
  newNotificationsCount:number=0;
  state!:ViewAllRouteState;
  memberId!:any;
  maxHistoryCount: number = 10;
  maxResults?: number;
  allNotifications !: any;
  refreshData = false; 
  userRole:any;
 

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;

  private _activeTab: number = 1;
  private _currenttab:number=1;
  public get activeTab(): number {
    return this._activeTab;
  }
  public set activeTab(v: number) {
    this._activeTab = v;
    switch (v) {
      case 1:
        this.loggerService.log(" Submitted prior authorization history tab selected.");
        // setTimeout(()=>{
        // this.submittedHistoryComponent?.reload();
        // },100);
        break;
      case 2:
        this.loggerService.log("Saved prior authorization history tab selected.");
        break;
    }
  }
  constructor(private memberSearchHelper: MembersearchService,
    private router: Router, private loggerService: LoggerService,
    private trailclaimadjudicationHelper: MemberDetailService,
    private servertimetostandarddate: ServertimeToStandardDate,
    private modalService: NgbModal,private profileService:ProfileService,
    private logger: LoggerService,private titleService:Title) {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    this.titleService.setTitle("Member Detail");
    // this.memberDetail!.
  }

  ngOnInit(): void {
    this.userRole = this.profileService.USER_ROLE;
    if (this.memberSearchHelper.getSelectedMember() != undefined) {
      this.memberSearchResult = this.memberSearchHelper.getSelectedMember();
      if (this.memberSearchResult!.gender == PersonGender.Female) {
        this.gender = "Female";
      }
      else if (this.memberSearchResult!.gender == PersonGender.NonBinary) {
        this.gender = "Non-binary";
      }
      else {
        this.gender = "Male";
      }

    }
    this.refreshMemberDetails();

  }


  refreshMemberDetails() {
    debugger;
    this.logger.userAction("Refreshing Member Details");
    let memberDetailrequest: MemberDetailWebRequesthemi = new MemberDetailWebRequesthemi();
    memberDetailrequest.carrierId = this.memberSearchResult!.carrierId;
    memberDetailrequest.accountId = this.memberSearchResult!.accountId;
    memberDetailrequest.groupId = this.memberSearchResult!.groupId;
    memberDetailrequest.id = this.memberSearchResult!.memberId;
    memberDetailrequest.sourceSystemInstance = this.memberSearchResult!.instanceId;
    
    this.refreshData = true;
    this.trailclaimadjudicationHelper.getMemberDetailshemi(memberDetailrequest).then((response: MemberDetailWebResponsehemi) => {
      if (response) {
        this.memberDetailResult = response;
        console.log("Refresh Eligibility");
        //console.log(this.memberDetailResult);
        //console.log(this.memberSearchResult);
        //.log(this.memberDetailResult.eligibilityList[0].audit);
        this.lastUpdated = this.servertimetostandarddate.transform(this.memberDetailResult.memberItems[0].memberEligibility[0].createDate) + " @" + ("" + this.memberDetailResult.memberItems[0].memberEligibility[0].updateTime).slice(0, 2) + ":" + ("" + this.memberDetailResult.memberItems[0].memberEligibility[0].updateTime).slice(2, 4) + " " + (parseInt((this.memberDetailResult.memberItems[0].memberEligibility[0].updateTime).slice(0, 2)) < 12 ? "a.m." : "p.m.");
        console.log(("" + this.memberDetailResult.memberItems[0].memberEligibility[0].updateTime).slice(0, 2) + ":" + ("" + this.memberDetailResult.memberItems[0].memberEligibility[0].updateTime).slice(2, 4) + " " + (parseInt((this.memberDetailResult.memberItems[0].memberEligibility[0].updateTime).slice(0, 2)) < 12 ? "a.m." : "p.m."));

        
        this.memberSearchResult!.dateOfBirth = this.servertimetostandarddate.transform(this.memberDetailResult.memberItems[0].demographics.dateOfBirth);
        this.memberSearchResult!.effectiveFromDate = this.servertimetostandarddate.transform(this.memberDetailResult.memberItems[0].memberEligibility[0].eligibilityStartDate);
        this.memberSearchResult!.effectiveThruDate = this.servertimetostandarddate.transform(this.memberDetailResult.memberItems[0].memberEligibility[0].eligibilityEndDate);
        this.memberSearchResult!.activeInd=this.memberDetailResult.memberItems[0].memberEligibility[0].eligibilityStatus;
        var eligibiltystartDate = new Date(this.memberSearchResult!.effectiveFromDate);
        var eligibiltyendDate = new Date(this.memberSearchResult!.effectiveThruDate);

        if (eligibiltyendDate > eligibiltystartDate) {
          this.eligibilityDates = this.memberSearchResult!.effectiveFromDate + " - " + "Current";
        } else {
          this.eligibilityDates = this.memberSearchResult!.effectiveFromDate + " - " + this.memberSearchResult!.effectiveThruDate;
        }

        if (this.memberSearchResult!.relationshipCode.trim().length > 0) {
          switch (parseInt(this.memberSearchResult!.relationshipCode)) {
            case 0:
              this.relationshipCode = "unspecified";
              break;
            case 1:
              this.relationshipCode = "cardholder";
              break;
            case 2:
              this.relationshipCode = "spouse";
              break;
            case 3:
              this.relationshipCode = "child dependent";
              break;
            case 4:
              this.relationshipCode = "other dependent";
              break;
            default:
              this.relationshipCode = "";
              break;
          }
        }
        else {
          this.relationshipCode = "";
        }
      }
      this.refreshData = false;
    }, (error: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      this.refreshData = false;
    }).catch((reason: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      this.refreshData = false;
    });
  }
  gotoViewAllSubmittedPA() {
    debugger;
    this.logger.userAction("PA_History")
    const submittedState: ViewAllRouteState = { pageType: PAGE_TYPE.SUBMITTEDPAS};
    sessionStorage.setItem("blnMemberDetailPageSession","true");
    this.router.navigateByUrl("/searchMedicationClaims/ViewAllMemberPAHistory", { state: submittedState });
    this.titleService.setTitle("View All Submitted PA History - Member Detail");
    
  }
  gotoViewAllSavedPA() {
    this.logger.userAction("View All Saved PA clicked")
    const savedState: ViewAllRouteState = { pageType: PAGE_TYPE.SAVEDPAS };
    this.router.navigateByUrl("/searchMedicationClaims/ViewAllMemberSavedPAs", { state: savedState });
    this.titleService.setTitle("View All Saved PAs - Member Detail");
  }
  gotoCheckcoverage(){
    this.router.navigateByUrl("/PriorAuthChecker/DrugSearch");
  }
  getnewNotificationsCount($event:number){
    debugger;
    this.newNotificationsCount=$event;

  }
  gotoEpa(){
    this.router.navigateByUrl("/ePA/ePAsubmit");
  }
  goto(routerUrl: string) {

    if(routerUrl=="/ePA/DrugSearch"){
      sessionStorage.setItem('IntiatePA','true');
    }
    sessionStorage.setItem("blnMemberDetailPageSession","true");
   // this.logger.userAction("Going Back to Member Search")
    this.router.navigateByUrl("/" + routerUrl);
  }

}