import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APPCONSTANTS, MANAGE_PRESCRIBER_STATUS } from 'src/app/constants/appconstants';
import { approveOrDenyPrescriberRequest } from 'src/app/modals/verifyPrescriber/approveOrDenyPrescriberRequest';
import { approveOrDenyPrescriberResponse } from 'src/app/modals/verifyPrescriber/approveOrDenyPrescriberResponse';
import { VerifyprescriberService } from 'src/app/services/verifyPrescriber/verifyprescriber.service';

@Component({
  selector: 'verify-prescribers',
  templateUrl: './verify-prescribers.component.html',
  styleUrls: ['./verify-prescribers.component.css']
})
export class VerifyPrescribersComponent implements OnInit {
  @ViewChild("otpExceededPopup", { static: true })
  otpExceededPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("alreadyDenied", { static: true })
  alreadyDeniedPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("alreadyGranted", { static: true })
  alreadyGrantedPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("cancelledRecord", { static: true })
  cancelledRecordPopupTemplateRef?: TemplateRef<HTMLElement>;
  
  @ViewChild("error", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;

  ngOnInit() {
  }
  constructor(private fb: FormBuilder, private verifyPrescriber: VerifyprescriberService, private modalService: NgbModal) {

  }
  show: boolean = true;
  showErrorPopup: boolean = false;
  npipinmismatch: boolean = false;
  invalidPinFormat: boolean = false;
  vefifydenied: boolean = false;
  

  npiRequired = false;
  pinRequired = false;

  NPI_Form = this.fb.group({
    NPI_Field: ['', [Validators.required, Validators.pattern('[0-9]*$')]]
  });

  PIN_Form = this.fb.group({
    PIN_Field: ['', [Validators.required, Validators.pattern('[1-9][0-9]{3}-[1-9][0-9]{3}$')]]
  });

  insideTheBox() {
    this.show = false;
  }
  outsideTheBox() {
    this.show = true;
  }

  openErrorPopup() {
    this.showErrorPopup = true;
  }
  closeErrorPopup() {
    this.showErrorPopup = false;
  }
  npiFeildTouched() {
    this.npiRequired = true;
  }
  pinFeildTouched() {
    this.pinRequired = true;
    this.invalidPinFormat = false;
    this.npipinmismatch = false;
  }
  onGrantAccess() {
    if (this.NPI_Form.valid && this.PIN_Form.valid) {
      this.hiphen = false;
      this.npipinmismatch = false;
      var webrequest = new approveOrDenyPrescriberRequest();
      webrequest.npi = '' + this.NPI_Form.value.NPI_Field;
      webrequest.isApprove = true;
      webrequest.pin = "" + this.PIN_Form?.value.PIN_Field?.substring(0, 4);
      webrequest.correlationId = "" + this.PIN_Form?.value.PIN_Field?.substring(5, 9);
      this.verifyPrescriberCall(webrequest);
    }
    else if (this.NPI_Form.invalid) {
      this.npiRequired = true;
      this.pinRequired = true;
    }
  }
  onDenyAccess() {
    if (this.NPI_Form.valid && this.PIN_Form.valid) {
      this.hiphen = false;
      this.npipinmismatch = false;
      var webrequest = new approveOrDenyPrescriberRequest();
      webrequest.npi = '' + this.NPI_Form.value.NPI_Field;
      webrequest.isApprove = false;
      webrequest.pin = "" + this.PIN_Form?.value.PIN_Field?.substring(0, 4);
      webrequest.correlationId = "" + this.PIN_Form?.value.PIN_Field?.substring(5, 9);
      this.verifyPrescriberCall(webrequest);
    }
    else if (this.NPI_Form.invalid) {
      this.npiRequired = true;
      this.pinRequired = true;
    }
  }

  verifyPrescriberCall(webrequest: approveOrDenyPrescriberRequest) {
    this.verifyPrescriber.postApproveOrDenyPrescriber(webrequest).then((data: approveOrDenyPrescriberResponse) => {
      debugger;
      if (data.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {

        if (data.dbStatusCode == MANAGE_PRESCRIBER_STATUS.DB_CODE1) {
          this.npipinmismatch = true;
        }
        else if (data.dbStatusCode == MANAGE_PRESCRIBER_STATUS.DB_CODE3) {
          this.modalService.open(this.otpExceededPopupTemplateRef, { size: 'md' ,backdrop:'static'});
        }
        else if (data.dbStatusCode == MANAGE_PRESCRIBER_STATUS.DB_CODE4) {
          this.modalService.open(this.alreadyGrantedPopupTemplateRef, { size: 'md' ,backdrop:'static'});
        }
        else if (data.dbStatusCode == MANAGE_PRESCRIBER_STATUS.DB_CODE5) {
          this.modalService.open(this.cancelledRecordPopupTemplateRef, { size: 'md' ,backdrop:'static'});
        }
        else if (data.dbStatusCode == MANAGE_PRESCRIBER_STATUS.DB_CODE6) {
          this.invalidPinFormat = true;
        }
        else if (data.dbStatusCode == MANAGE_PRESCRIBER_STATUS.DB_CODE7) {
          this.modalService.open(this.alreadyDeniedPopupTemplateRef, { size: 'md',backdrop:'static' });
        }
        else if (data.statusDesc == MANAGE_PRESCRIBER_STATUS.VEFIFY_DENIED || data.statusDesc == MANAGE_PRESCRIBER_STATUS.VEFIFY_APPROVED) {
          this.vefifydenied = true;
        }
      }
      else {
        this.modalService.open(this.errorPopupTemplateRef, { size: 'md' ,backdrop:'static'});
      }
    });

  }
  hiphen: boolean = false;
  onInput(): void {
    let input = this.PIN_Form?.value.PIN_Field;
    if (input) {
      // // Remove any non-digit or hyphen characters
      // input = input.replace(/[^0-9\-]/g, '');

      // Remove any existing hyphens before reformatting
      input = input.replace(/-/g, '');

      // Format the input to 'xxxx-xxxx' if more than 4 digits are entered
      if (input.length > 4) {
        this.PIN_Form.get('PIN_Field')?.setValue(input.substring(0, 4) + '-' + input.substring(4, 8));
      }
      else if (input.length == 4) {
        //code to make sure hyphen is not added if the user is deleting the previous hyphen
        if (this.hiphen) {
          this.PIN_Form.get('PIN_Field')?.setValue(input);
          this.hiphen = false;
        }
        else {
          this.PIN_Form.get('PIN_Field')?.setValue(input + '-');
          this.hiphen = true;
        }
      }
      else {
        // Just display the first part if less than 4 digits
        this.PIN_Form.get('PIN_Field')?.setValue(input);
      }
    }
  }
  registerForPreCheckMyScript() {
    // window.open("https://precheckmyscript-stage.uhcprovider.com");
    window.location.href = "https://localhost/rxlinkapp-msepa/";
  }
  loginToPreCheckMyScript() {
    // window.open("https://precheckmyscript-stage.uhcprovider.com");
    window.location.href = "https://localhost/rxlinkapp-msepa/";
  }



}
