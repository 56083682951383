import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { getRecentPrescriberDetailsRequest } from 'src/app/modals/viewallnotificationscalls/getRecentPrescriberDetailsRequest';
import { getRecentPrescriberDetailsResponse } from 'src/app/modals/viewallnotificationscalls/getRecentPrescriberDetailsResponse';
import { updateNotificationStatusRequest } from 'src/app/modals/viewallnotificationscalls/updateNotificationStatusRequest';
import { updateNotificationStatusResponse } from 'src/app/modals/viewallnotificationscalls/updateNotificationStatusResponse';
import { viewAllNotificationsRequest } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsRequest';
import { viewAllNotificationsResponse } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private http: HttpClient ) { }
  url = environment.proxyurl

  postGetNotifications(webrequest: viewAllNotificationsRequest): Promise<viewAllNotificationsResponse> {
    const promise: Promise<viewAllNotificationsResponse> = new Promise<viewAllNotificationsResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getNotificationsUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postGetDeletedNotifications(webrequest: viewAllNotificationsRequest): Promise<viewAllNotificationsResponse> {
    const promise: Promise<viewAllNotificationsResponse> = new Promise<viewAllNotificationsResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getDeletedNotificationsUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postGetPrescriberDetails(webrequest: getRecentPrescriberDetailsRequest): Promise<getRecentPrescriberDetailsResponse> {
    const promise: Promise<getRecentPrescriberDetailsResponse> = new Promise<getRecentPrescriberDetailsResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getRecentPrescriberDetailsUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }


  postUpdateNotfication(webrequest: updateNotificationStatusRequest): Promise<updateNotificationStatusResponse> {
    const promise: Promise<updateNotificationStatusResponse> = new Promise<updateNotificationStatusResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getUpdateNotficationStatusUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }
}
