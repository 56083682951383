import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-initiate-pa-step3',
  templateUrl: './initiate-pa-step3.component.html',
  styleUrls: ['./initiate-pa-step3.component.css']
})
export class InitiatePaStep3Component implements OnInit{
  blnInitiatePA:boolean=true;
  pageType:string="InitiatePriorAuthorizationPA";
  blnSession:any;

  constructor( private modalService: NgbModal,private router:Router,private profileService:ProfileService, private logger: LoggerService,private titleService:Title,private epaProcessor:EpaprocessorService) { }

  ngOnInit(): void {
    debugger;
    this.titleService.setTitle("PreCheck MyScript - PA Initiation - Select Drug");
    this.blnSession = sessionStorage.getItem("PAreferenceID");
}
}
