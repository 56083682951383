import { APPCONSTANTS, PRESCRIBER_QUALIFIER, PersonGender } from "src/app/constants/appconstants";
import { SelectedMedicine } from "./drugsearchwebresponse";

export class Trailadjudicationwebrequest {
    userId!: string;
    isBenefitInquiry: string = APPCONSTANTS.RTB_ISBENEFITINQUIRY;
    zipCode!: string;
    bin: string="";
    pcn: string="";
    transactionType: string=APPCONSTANTS.RTB_TRAILCLAIM_TRANSACTIONTYPE;
    cardholderId!: string;
    personCode!: string;
    relationshipCode!: string;
    groupId: string="RXLINK";
    firstName!: string;
    lastName!: string;
    dateOfBirth!: string;
    gender!: PersonGender;
    rxServiceReferenceQualifier!: any;
    rxServiceReferenceNo!: any;
    serviceProviderIdQualifier!: string;
    serviceProviderId!: string;
    productServiceIdQualifier!: string;
    productServiceId!: string;
    prescriberIdQualifier:PRESCRIBER_QUALIFIER= PRESCRIBER_QUALIFIER.NPI;
    prescriberId!: string;
    usualAndCustomaryCharge: string=APPCONSTANTS.RTB_USUALANDCUSTOMARYCHARGE;
    fillNumber: string = APPCONSTANTS.RTB_FILLNUMBER;
    numberOfRefillsAuthorized: string=APPCONSTANTS.RTB_NUMBEROFREFILLSAUTHORIZED;
    daysSupply!: number;
    qtyCalculationOption1!: QtyCalculationOption1;
    qtyCalculationOption2!: QtyCalculationOption2;
    dispenseAsWrittenCode: string=APPCONSTANTS.RTB_DAW;
    dateOfService!: string;
    datePrescriptionWritten!: string;
    patientResidence: string="";
    routeOfAdmin: string="";
    placeOfService!: string;
    grossAmountDue: string=APPCONSTANTS.RTB_GROSSAMOUNTDUE;
    scheduledRxId: string="";
    transactionCode: string="";
    sbmClarificationCode: string="";
    specialPackaging: string="";
    unitOfMeasure: string="";
    pharmacyServiceType: string="";
    durPharmacyProfessionalService: string="";
    otherCoverageCode: string="";
    compound: string="";
    paType: string="";
    paNumber: string="";
    instanceId!: string;
    uuid!: string;
    drugName!: string;
    cagCarrierId!: string;
    cagAccountId!: string;
    cagGroupId!: string;
    gpi!: string;
    diagnosisCode!: any;
    diagnosisCodeDescription!: any;
    diagnosisCodeQualifier!: any;
    durSegment!: DurSegment[];
    // consumingApp: string = APPCONSTANTS.RTB_CONSUMINGAPP;
    // userIdType: string="USER_ID";
}

export class QtyCalculationOption1 {
    submittedIngredientCost: string = APPCONSTANTS.RTB_SUBMITTEDINGRIDIENTCOST;
    quantityDispensed!: number;
    additionalField: any[]=[];
}

export class QtyCalculationOption2 {
    dailyQuantity: string="";
    dosageForm: string="";
    dispensingUnit: string="";
    packageSize: string="";
    awp: string="";
    additionalField: any[]=[];
}

export class DurSegment{
    durMsgId:number=0;
	reasonSrvcCd!:string;
	prfsnlSrvcCd:string="";
	rsltSrvcCd:string="";
	levelOfEffortHrs!:string;
	freeText:string="";
	additionalText:string="";
	mainMsg!:string;
	durMessage:string="";
	durMsg!:string;
	response:string="";
	levelOfEffort:string="";
}


