<div class="container-fluid viewAll savedHistory mt-4">
    <div class="row">
        <div class="offset-md-1 col-md-8 offset-xs-1 col-xs-10">
            <h1 name="title" class="fs-3">Search for a Member</h1>
        </div>
    </div>
    <div class="row">
        <div class="offset-md-1 col-md-6 offset-xs-1 col-xs-8 mt-3">
            <p name="description" class="large">Enter the required fields to select a member.</p>
        </div>
    </div>
    <div class="row">
        <!-- <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 mt-3"> -->
            <app-findmember [pageType]="pageType"></app-findmember>
        <!-- </div> -->
    </div>
</div>