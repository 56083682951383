<div class="container showallNotifications">
    <div class="notificationHeader">
        <div style="margin-left: 8%;padding: 0 !important;width: 99.5%;display: flex;margin-bottom: 10px;">
            <div class="col-md-12 col-lg-10 col-lg-offset-1"
                style="display: flex;align-items: center;justify-content: space-between;">
                <h1 class="pull-left">EMR Regression Flow</h1>
            </div>
        </div>
        <div style="margin-left: 8%;padding: 0 !important;width: 99.5%;display: flex;margin-bottom: 10px;">
            <div class="col-md-12 col-lg-10 col-lg-offset-1"
                style="display: flex;align-items: center;justify-content: space-between;">
                <span class="paragraph">Add test cases in bulk.</span>
                <div class="pull-right text-right">
                    <i class="fa fa-file-text" style="color:#0470c5"></i>&nbsp;
                    <span class="download_button" (click)="onDownloadTemplateClick()"> Download Template </span>
                    <img alt="excel image" src="assets/images/microsoft-excel.png"
                        style="width:25px;height:25px;position:relative;top:-3px;">
                </div>
            </div>
        </div>
    </div>

    <div class="row home_dashboard">
        <div style="margin-left: 8%;padding: 0 !important;width: 99.5%;display: flex;">
            <div class="col-md-12 col-lg-10 col-lg-offset-1">
                <div class="content-card no-shadow nopadding col-12">
                    <div style="padding: 26px;">
                        <div class="h2_custom" style="margin-bottom: 10px;">
                            Add an attachment (optional)
                        </div>
                        <span class="paragraph">Please attach an Excel file. Download the template to start.</span>
                        <div class="row" style="margin: 20px 0px 0px;">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding" *ngIf="selectedFile==null"
                                style="background-color:rgba(242, 246, 249, 1);border:1px solid lightgray">
                                <div style="margin-top:40px;margin-bottom:40px;text-align:center">
                                    <input type="file" id="uploadbutton" #uploaderelement (change)="onSelectClick($event)"
                                        style="display:none;">
                                    <button type="button" class="btn primary btn-primary fontbold"
                                        (click)="triggerFileInput()"
                                        style="height:60px;font-size:18px;width:auto;border-radius:0px;padding: 0px 20px;">
                                        <i class="fa fa-folder-open" aria-hidden="true"></i> Select file from my
                                        computer</button>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 nopadding files"
                            *ngIf="selectedFile!=null"
                                style="background-color:rgba(242, 246, 249, 1);border:1px solid lightgray;border-radius:4px;">
                                <div class="filenames">
                                    <div class="container-fluid" style="padding:25px 15px">
                                        <div class="row">
                                            <div class="col-xs-8 col-sm-10 col-md-10">
                                                <i class="fa fa-file fileicon" aria-hidden="true"
                                                    style="color: #424249;"></i>
                                                &nbsp;
                                                <div
                                                    style="max-width:80%;padding-right:10px;overflow:hidden;text-overflow:ellipsis;display:inline-block;vertical-align:middle;white-space:nowrap;color: #424249;">
                                                    {{selectedFile.name}}
                                                </div>
                                                <div style="display:inline-block;vertical-align:middle;opacity:0.8">
                                                    {{selectedFile.size/(1024*1024) | number:'1.2-2'}} MB
                                                </div>
                                            </div>
                                            <div class="col-xs-4 col-sm-2 col-md-2 deleteatta"
                                                style="text-align: right;">
                                                <div style="cursor: pointer;" (click)="onDeleteFileClick()">
                                                    <span class="push-left" style="color: #C60F13;">Remove
                                                        file</span>&nbsp;
                                                    <i class="fa fa-trash-o deleteicon" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 text-right"
                    style="position: relative;min-height: 1px;margin:25px 0px 25px;text-align: right;">
                    <button type="button" class="btn btn-primary fontbold" (click)="onUploadClick()"
                        style="font-size:18px;width:auto;border-radius:0px;padding-left: 20px;padding-right: 20px;height: 44px;">
                        Upload
                    </button>
                </div>
                <div class="col-md-12 d-flex" style="margin-bottom: 15px;">
                    <h1 class="pull-left">General</h1>
                </div>

                <!-- <h1 class="pull-left">General</h1> -->

                <div class="content-card no-shadow nopadding col-12">
                    <div style="padding: 20px;">
                        <form [formGroup]="GeneralForm">
                            <div class="row">
                                <div class="col-4 feildstyle">
                                    <label for="consumingAppBI" class="inputFieldLabel ">* Consuming
                                        App</label><br>
                                    <select class="drop-list" id="consumingAppBI" formControlName="ConsumingApp">
                                        <option value="RXLINK">RXLINK</option>
                                        <option value="ALLSCRIPTS">ALLSCRIPTS</option>
                                        <option value="ATHENA">ATHENA</option>
                                        <option value="DRFIRST">DRFIRST</option>
                                        <option value="NEWCROP">NEWCROP</option>
                                        <option value="RXREVU">RXREVU</option>
                                        <option value="CENTERX">CENTERX</option>
                                    </select>
                                </div>
                                <div class="col-4 feildstyle">
                                    <label for="groupIdBI" class="inputFieldLabel">Group ID</label><br>
                                    <input type="text" class="emrManualFlow disabledinput" id="groupIdBI"
                                        formControlName="GroupID">
                                </div>
                                <div class="col-4 feildstyle">
                                    <label for="userIdBI" class="inputFieldLabel">* User ID</label><br>
                                    <input type="text" class="emrManualFlow disabledinput" id="userIdBI"
                                        formControlName="UserID">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 feildstyle">
                                    <label for="userIdTypeBI" class="inputFieldLabel">* User ID
                                        Type</label><br>
                                    <input type="text" class="emrManualFlow disabledinput" id="userIdTypeBI"
                                        formControlName="UserIDType">
                                </div>
                                <div class="col-4 feildstyle">
                                    <label for="binBI" class="inputFieldLabel">BIN Number</label><br>
                                    <input type="text" class="emrManualFlow disabledinput" id="binBI"
                                        formControlName="BINNumber">
                                </div>
                                <div class="col-4 feildstyle">
                                    <label for="pcnBI" class="inputFieldLabel ">Personal Control
                                        Number</label><br>
                                    <input type="text" class="emrManualFlow disabledinput" id="pcnBI"
                                        formControlName="PersonalControlNumber">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 feildstyle">
                                    <label class="inputFieldLabel">Request Alternate Drugs</label><br>
                                    <div class="btn-group">
                                        <label (click)="GeneralForm.value.RequestAlternateDrugs=true"
                                            class="radioInput">
                                            <input type="radio" id="requestalternatedrugstrue" value="true"
                                                formControlName="RequestAlternateDrugs">
                                            <i class="fa fa-circle-o hoverchange"
                                                *ngIf="GeneralForm.value.RequestAlternateDrugs!==true"></i>
                                            <i class="fa fa-dot-circle-o hoverchange"
                                                *ngIf="GeneralForm.value.RequestAlternateDrugs===true"></i>
                                            <label for="requestalternatedrugstrue" class="radiolabel hoverchange"
                                                style="padding-left: 10px;cursor: pointer;">True</label>
                                        </label>

                                        <label (click)="GeneralForm.value.RequestAlternateDrugs=false"
                                            class="radioInput ">
                                            <input type="radio" id="requestalternatedrugsfalse" value="false"
                                                formControlName="RequestAlternateDrugs">
                                            <i class="fa fa-circle-o hoverchange"
                                                *ngIf="GeneralForm.value.RequestAlternateDrugs!==false"></i>
                                            <i class="fa fa-dot-circle-o hoverchange"
                                                *ngIf="GeneralForm.value.RequestAlternateDrugs===false"></i>
                                            <label for="requestalternatedrugsfalse" class="radiolabel hoverchange"
                                                style="padding-left: 10px;cursor: pointer;">False</label>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4 feildstyle">
                                    <label class="inputFieldLabel">Request Alternate Pharmacies</label><br>
                                    <div class="btn-group">
                                        <label (click)="GeneralForm.value.RequestAlternatePharmacies=true"
                                            class="radioInput">
                                            <input type="radio" id="RequestAlternatepharmaciestrue" value="true"
                                                formControlName="RequestAlternatePharmacies">
                                            <i class="fa fa-circle-o hoverchange"
                                                *ngIf="GeneralForm.value.RequestAlternatePharmacies!==true"></i>
                                            <i class="fa fa-dot-circle-o hoverchange"
                                                *ngIf="GeneralForm.value.RequestAlternatePharmacies===true"></i>
                                            <label for="RequestAlternatepharmaciestrue" class="radiolabel hoverchange"
                                                style="padding-left: 10px;cursor: pointer;">True</label>
                                        </label>

                                        <label (click)="GeneralForm.value.RequestAlternatePharmacies=false"
                                            class="radioInput ">
                                            <input type="radio" id="requestalternatepharmaciesfalse" value="false"
                                                formControlName="RequestAlternatePharmacies">
                                            <i class="fa fa-circle-o hoverchange"
                                                *ngIf="GeneralForm.value.RequestAlternatePharmacies!==false"></i>
                                            <i class="fa fa-dot-circle-o hoverchange"
                                                *ngIf="GeneralForm.value.RequestAlternatePharmacies===false"></i>
                                            <label for="requestalternatepharmaciesfalse" class="radiolabel hoverchange"
                                                style="padding-left: 10px;cursor: pointer;">False</label>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4 feildstyle">
                                    <label class="inputFieldLabel">Identify Alternate Pharmacies Only</label><br>
                                    <div class="btn-group">
                                        <label (click)="GeneralForm.value.IdentifyAlternatePharmaciesOnly=true"
                                            class="radioInput">
                                            <input type="radio" id="identifyalternatepharmaciesonlytrue" value="true"
                                                formControlName="IdentifyAlternatePharmaciesOnly">
                                            <i class="fa fa-circle-o hoverchange"
                                                *ngIf="GeneralForm.value.IdentifyAlternatePharmaciesOnly!==true"></i>
                                            <i class="fa fa-dot-circle-o hoverchange"
                                                *ngIf="GeneralForm.value.IdentifyAlternatePharmaciesOnly===true"></i>
                                            <label for="identifyalternatepharmaciesonlytrue"
                                                class="radiolabel hoverchange"
                                                style="padding-left: 10px;cursor: pointer;">True</label>
                                        </label>

                                        <label (click)="GeneralForm.value.IdentifyAlternatePharmaciesOnly=false"
                                            class="radioInput ">
                                            <input type="radio" id="identifyalternatepharmaciesonlyfalse" value="false"
                                                formControlName="IdentifyAlternatePharmaciesOnly">
                                            <i class="fa fa-circle-o hoverchange"
                                                *ngIf="GeneralForm.value.IdentifyAlternatePharmaciesOnly!==false"></i>
                                            <i class="fa fa-dot-circle-o hoverchange"
                                                *ngIf="GeneralForm.value.IdentifyAlternatePharmaciesOnly===false"></i>
                                            <label for="identifyalternatepharmaciesonlyfalse"
                                                class="radiolabel hoverchange"
                                                style="padding-left: 10px;cursor: pointer;">False</label>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div class="col-md-12 d-flex" style="    margin-bottom: 15px;margin-top: 12px;">
                    <h1 class="pull-left">Upload summary</h1>
                </div>
                <div class="content-card no-shadow nopadding col-12">
                    <div class="tab-pane" style="padding: 10px 5px;">
                        <div class="submitted_PA_results">
                        </div>
                    </div>
                    <div class="container-fluid card_footer">
                        <div class="row">
                            <div class="col-10">
                            </div>
                            <div class="col-2" style="text-align: right;">
                                <button type="button" class="btn primary btn-primary disabled"
                                    style="height: 44px;padding: 0px 35px;">Run
                                    all</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #FileSizeExceeded let-modal>
    <div class="modal-body text-center errorModal" aria-labelledby="FileSizeExceeded">
        <i class="fa fa-exclamation-circle"></i>
        <p class="large">
            The file size exceeds the amount permitted for attachments.
            <br/>
            Please upload a smaller file
        </p>
        <button class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
        <button class="btn btn-primary rounded-pill" (click)="modal.dismiss();">Select new file</button>
    </div>
</ng-template>

<ng-template #FileTypeError let-modal>
    <div class="modal-body text-center errorModal" aria-labelledby="FileTypeError">
        <i class="fa fa-exclamation-triangle error"></i>
        <p class="large">
            The file failed to upload.
            <br/>
            Only these types of files are supported: xlsx
        </p>
        <button class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
        <button class="btn btn-primary rounded-pill" (click)="modal.dismiss();">Select new file</button>
    </div>
</ng-template>

<ng-template #UploadProgress let-modal>
    <div class="modal-body text-center" aria-labelledby="UploadProgress">
        <p class="large">
            {{selectedFile?.name}}
        </p>
        <div class="uploadprogress">
            <div [style.width]="progress+'%'"></div>
        </div>
        <br/>
        <div>Uploading attachment...</div>
    </div>
</ng-template>

<ng-template #UploadError let-modal>
    <div class="modal-body text-center" aria-labelledby="UploadError">
        <i class="fa fa-exclamation-circle"></i>
        <p class="large" [innerHtml]="errorMessage">
        </p>
        <button class="btn btn-secondary" (click)="modal.dismiss()">Ok</button>
    </div>
</ng-template>