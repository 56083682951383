
import { MemberSearchItem } from './drugsearchwebrequest';
import { PrescriberDetailItem, PrescriberInfoAll, PrescriberSearchItem } from '../prescriberSearch/prescriberSearchwebresponse';
//import { MemberDetailWebResponse } from '../membersearch/memberdetailwebresponse';
import { DRUG_QUALIFIER } from 'src/app/constants/appconstants';
import { MemberSearchV5Item } from '../membersearch/membersearchwebresponse';
import { Status } from '../webresponsestatus';
import { Pharmacy, PharmacySearchResponse } from './pharmacySearchresponse';
import { PreferredPharmacyWebresponse } from '../preferredpharmacy/preferredpharmacywebresponse';
import { ClaimSummaryDurReject } from '../claimsummary/claimsummary';
import { Trailadjudicationwebresponse } from './Trailadjudicationwebresponse';
import { Trailadjudicationwebrequest } from './Trailadjudicationwebrequest';
import { DrugSearchWebResponse } from '../epa/priorauthresponse';
import { MemberDetailWebResponsehemi } from '../membersearch/memberdetailresponsehemi';
import { PharmacyDetailItem } from '../registration/SteponePharmacyDetailsResponse';


export interface Drugsearchwebresponse {
    response: DrugResponse;
    status: Status;
}

/*export interface drugSearchResponse{
    response: DrugResponse;
    notification: memberNtfyResponse;
    status: Status;
}*/

export interface MemberNtfyResponse{
    drugSearchResponse: Drugsearchwebresponse;
    memberNtfyResponse:memberNtofyResponse;
    status:Status;
}

export class memberNtofyResponse{
    notificationId!: number;
    dismissStsCd!: number;
    ntfyStatus!: string;
    readInd!: string;
    //readInd: String ;
    //ntfyStatus: Status;
    //readInd: String ;
   // msg:string='';
}
export interface DrugResponse {
    maxRowExceededInd: boolean;
    genericSubstitutionPerformed: boolean;
    drugSearchItems: DrugSearchItem[];
    // ndcDrugsList: string[];
}

export class DrugSearchItem {
    prodNameAbbr!: string;
    productNameExt!: string;
    genericName!: string;
    gpi!: string;
    productIdQlfr!: DRUG_QUALIFIER;
    productId!: string;
    strength!: string;
    strengthUnitOfMeasure!: string;
    dosageFormCode!: string;
    dosageFormDesc!: string;
    multiSourceCode!: string;
    unitDoseUnitUse!: string;
    productStatus!: string;
    otcCode!: string;
    breakableIndicator!: string;
    additionalField!: AdditionalField[];
    detailedDrugName!:string;

}

export interface AdditionalField {
    fieldDescription: string;
    fieldStringValue: string;
    fieldDecimalValue: string;
    fieldIntegerValue: string;
}


export interface DrugCoded {
    code: string;
}

export interface FavoriteDrugsDetail {
    drugDescription: string;
    drugCoded: DrugCoded[];
    productIdQlfr: string;
    drugInfoId: string;
}

export interface FavoriteDrugsDetailResponse {
    favoriteDrugsDetail: FavoriteDrugsDetail[];
    loggedPresbrId: string;
    loggedUuid: string;
    status: Status;
}
export interface FavDrugStatus {
    statusCode: string;
    statusDesc: string;
    dbStatusCode: string;
}

export interface FavDrugStatusResponse {
    drugInfoId: string;
    status: FavDrugStatus;
}

// export interface SpecialityProductResponse {
//     smapProductInfoResponse: SmapProductInfoResponse;
//     cagValidationResponse: CagValidationResponse;
// }

// export interface SmapProductInfoResponse {
//     specialityProducts: SpecialityProduct[];
//     records: number;
//     status: SmapProductInfoStatus;
// }

// export interface SpecialityProduct {
//     prdctInfoId: number;
//     ndc: string;
//     displayName: string;
//     genericName: string;
//     brandName: string;
//     drgLableNm: string;
//     drgAdmin: number;
//     jCode: string;
// }

// export interface CagValidationResponse {
//     isCagValid: string;
//     status: CagValidationStatus2;
// }

// export interface CagValidationStatus2 {
//     statusCode: string;
//     dbStatusCode: string;
// }

// export interface SmapProductInfoStatus {
//     statusCode: string;
//     dbStatusCode: string;
// }

//diagnosis responses
export interface DiagnosisCodeMessage {
    responseCode: string;
    responseMessage: string;
}

export class DiagnosisCodeSearchItem {
    diagnosisCode!: string;
    diagnosisDescription!: string;
    diagnosisQualifier!: string;
    diagnosisType!: string;
    additionalField!: any[];
    diagnosisCodeDetailedName!: string;
    private _selected!: boolean;
    public get selected(): boolean {
        return this._selected;
    }
    public set selected(value: boolean) {
        this._selected = value;
    }
}

export interface DiagnosisCodeSearchResponse {
    maxRowExceededInd: boolean;
    diagnosisCodeSearchItems: DiagnosisCodeSearchItem[];
    correlationId: string;
}

export interface DiagnosisSearchResult {
    message: DiagnosisCodeMessage;
    diagnosisCodeSearchResponse: DiagnosisCodeSearchResponse;
}

export interface DiagnosisCodeSearchStatus {
    statusCode: string;
    statusDesc: string;
}

export interface DiagnosisCodeSearchResponses {
    diagnosisSearchResult: DiagnosisSearchResult;
    status: DiagnosisCodeSearchStatus;
}

export class SelectedMedicine{
    memberDetailResponse!:MemberDetailWebResponsehemi;
    recentPrescriber!:PrescriberInfoAll[];
    drugSearchModel!: string;
    MedStrength!: string;
    Quantity!: string;
    quantytyCalculate!: string;
    quantytyDesc!: string;   
    quantityEntered!:string;
    Days!: string;
    DrugAdmin!: string;
    DiagnosisCode!: string;
    MedStrengthName!: string;
    drugSearchItem!: DrugSearchItem;
    drugSearchItemOnTrail!:DrugSearchItem;
    dateOfservice!:string;
    placeOfservice!:string;
    diagnosisCodeSearchItem!: DiagnosisCodeSearchItem | undefined;
    daw!: string;
    selectedMember!:MemberSearchItem;
    selectedPrescriber!: PrescriberSearchItem;
    selectedPrescriberDetail!: PrescriberDetailItem;
    selectedPharmacyDetail!:PharmacySearchResponse;
    preferredPharmacyDetail!:PreferredPharmacyWebresponse;
    pharmacySearchItem!:Pharmacy | undefined;
    pharmacyDetailItem!:PharmacyDetailItem | undefined;
    trailadjudicationwebresponse!:Trailadjudicationwebresponse;
    trailadjudicationwebrequest!:Trailadjudicationwebrequest;
    //clmgroupId!:string;
    //claimDetailId:number=0;
    // dateOfLastTrial!:string;
    // blnSavedAfterTrail:boolean=false;
    //dateOfClaimSubmission!:string;
    //dateOfReversal!:string;
    //submitClaimStatus!:string;

    //saveClaimRequestLstClaim!:LstClaim;
    saveClaimResponseLstClaim!:Response;

    //saveClaimCompleteRequest!:SaveTrailClaimRequest;
    //saveClaimCompleteResponse!:SaveTrailClaimResponse;
    
    //submitClaimRequestLstClaim!:SubmitClaimRequest;
    //submitClaimResponseLstClaim?:SubmitClaimResponseDetail;

    //submitClaimCompleteRequest!:SubmitMultipleClaimRequest;
    //submitClaimCompleteResponse!: SubmitMultipleClaimResponse;

    //reverseClaimRequest!:ReverseClaimRequest;
   // reverseClaimResponseLstClaim?:ReverseClaimResponse;
    durReject!:ClaimSummaryDurReject;
    clmgroupId!: string;
    dateOfLastTrial!: string;
    blnSavedAfterTrail!: boolean;
    }

    export interface MemberNtfyResponse{
        drugSearchResponse: Drugsearchwebresponse;
        memberNtfyResponse:memberNtofyResponse;
        status:Status;
    }
    
   /* export class memberNtofyResponse{
        notificationId!: number;
        dismissStsCd!: number;
        ntfyStatus!: string;
        readInd!: string;
        //readInd: String ;
        //ntfyStatus: Status;
        //readInd: String ;
       // msg:string='';
    }*/
